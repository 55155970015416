class DBStatus {
  parseProjectStatus(e) {
    switch (e) {
      case 1:
        return "Pending Quotation";
      case 2:
        return "Pending Admin Confirmation";
      case 3:
        return "Pending Client Confirmation";
      case 4:
        return "Admin Denied Quote";
      case 5:
        return "Client Denied Quote";
      case 6:
        return "Ongoing";
      case 7:
        return "Finished";
      case 8:
        return "Cancelled";
      default:
        break;
    }
  }
  parseOrderStatus(e) {
    switch (e) {
      case 1:
        return "PR Pending Approval";
      case 2:
        return "PR Denied";
      case 3:
        return "PR Approved";
      case 4:
        return "PO Purchased";
      case 5:
        return "PO Finished";
      case 6:
        return "PO Cancelled";
      case 7:
        return "PO Dispute";
      case 8:
        return "PO Released";
      case 10:
        return "Transaction Ongoing";

      default:
        break;
    }
  }
  parseActorType(e) {
    switch (e) {
      case 0:
        return "Super Admin";
      case 1:
        return "User";
      case 2:
        return "Client";
      case 3:
        return "Supplier";
      case 4:
        return "Executive";
      case 5:
        return "Purchaser";
      case 6:
        return "Site Manager";
      case 7:
        return "Project Manager";
      case 8:
        return "Requester";

      default:
        break;
    }
  }
  parsePOType(e) {
    switch (e) {
      case 1:
        return "Ongoing";
      case 2:
        return "Finished";
      case 3:
        return "Pending Approval";
      case 4:
        return "Denied Approval";
      case 8:
        return "PO Pending Removal";
      case 9:
        return "PO Archived";

      default:
        break;
    }
  }
}
export default new DBStatus();
