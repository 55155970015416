import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import DBStatus from "../../functions/DBStatus";
import "../../css/po.css";
import JVLogo from "../../assets/rebLogoRed.png";
import Card from "react-bootstrap/Card";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import {
  Canvas,
  BlobProvider,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

// Font.register({
//   family: "Roboto",
//   fonts: [
//     {
//       src: `/Roboto-Regular.ttf`,
//     },
//     {
//       src: `/Roboto-Bold.ttf`,
//       fontWeight: "bold",
//     },
//     {
//       src: `/Roboto-Italic.ttf`,
//       fontWeight: "normal",
//       fontStyle: "italic",
//     },
//     {
//       src: `/Roboto-BoldItalic.ttf`,
//       fontWeight: "bold",
//       fontStyle: "italic",
//     },
//   ],
// });

const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 12,
    marginLeft: 2,
    marginRight: 2,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const POPDF = (props) => (
  <Document>
    <Page
      size="A4"
      style={[
        {
         // //fontFamily: "Roboto",
          paddingTop: 10,
          paddingBottom: 10,
          paddingHorizontal: 20,
        },
      ]}
    >
      <View
        style={[
          {
            marginTop: 10,
          },
        ]}
      >
        <Image style={[{ marginHorizontal: 220, width: 80 }]} src={JVLogo} />
      </View>
      <View
        style={[
          {
            textAlign: "center",
            color: "black",
            width: "100%",
            marginTop: 5,
            marginBottom: 10,
          },
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 20,
            },
          ]}
        >
          <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
            <Text style={[{ textAlign: "left" }]}>PURCHASE ORDER</Text>
          </View>
          <View style={[{ flex: 1, width: "50%" }]}>
            <Text style={[{ fontSize: 15, textAlign: "right" }]}>
              Date: {moment(props.poData.datePosted).format("MMMM Do YYYY")}
            </Text>
          </View>
        </View>

        <Canvas
          style={[
            {
              width: 600,
              height: 3,
              position: "center",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("black")
              .lineWidth(3)
              .moveTo(50, 0)
              .lineTo(540, 0)
              .stroke();
          }}
        />
      </View>
      <View>
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 10,
            },
          ]}
        >
          <View style={[{ flex: 1, width: "50%", marginBottom: 10, fontSize: 10 }]}>
            <Text style={[{ textAlign: "left" }]}>
              PO #: {props.poData.uniqueId}
            </Text>
          </View>
          <View style={[{ flex: 1, width: "50%" }]}>
            {(() => {
              if (Number(props.poData.isPrintable) !== 1) {
                return (
                  <Text style={[{ fontSize: 15, textAlign: "right" }]}>
                    Status:{" "}
                    {Number(props.poData.status) === 1 ? "Ongoing" : "Finished"}
                  </Text>
                );
              }
            })()}
          </View>
        </View>
        <View
          style={[
            {
              marginTop: 8,
              marginBottom: 8,
              paddingLeft: 80,
              paddingRight: 80,
            },
          ]}
        >
          <Text
            style={[
              {
                marginBottom: 5,
              },
            ]}
          >
            Supplier: {props.poData.multiMaterials[0]?.supplier?.name}
          </Text>
          <Text
            style={[
              {
                marginBottom: 20,
                fontSize: 15,
              },
            ]}
          >
            Address: {props.poData.multiMaterials[0]?.supplier?.address}
          </Text>
          <Text
            style={[
              {
                marginBottom: 10,
                fontSize: 15,
              },
            ]}
          >
            Deliver To: {props.poData.deliverTo}
          </Text>
        </View>
      </View>

      <View
        style={[
          {
            height: 325,
            paddingLeft: 50,
            paddingRight: 50,
          },
        ]}
      >
        <Table data={props.poData.multiMaterials}>
          <TableHeader>
            {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              PR #
            </TableCell> */}

            <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
              ITEM DESCRIPTION
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              QTY
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              UNIT PRICE
            </TableCell>
            <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
              AMOUNT
            </TableCell>
          </TableHeader>
          <TableBody>
            {/* <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => (d.uniqueId === null ? "" : d.uniqueId)}
            /> */}

            {/* <DataTableCell
              style={[tableStyles.tableCell]}
              getContent={(d) =>
                d.material.unit.name === null ? "" : d.material.unit.name
              }
            /> */}
            <DataTableCell
              style={[tableStyles.tableCell, tableStyles.tableCellNames]}
              weighting={0.5}
              getContent={(d) =>
                d.brand === null ? "" : d.brand + "-" + d.material.item.name
              }
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) =>
                d.qty === null || d.material.unit.name === null
                  ? ""
                  : d.qty + " " + d.material.unit.name
              }
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.cost === null) {
                  return "";
                } else {
                  return new Intl.NumberFormat("en-US").format(d.cost);
                }
              }}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.2}
              getContent={(d) => {
                if (d.budget === null) {
                  return "";
                } else {
                  return new Intl.NumberFormat("en-US").format(d.qty * d.cost);
                }
              }}
            />
          </TableBody>
        </Table>
        <Text
          style={[
            {
              textAlign: "right",
              marginTop: 10,
            },
          ]}
        >
          TOTAL PRICE:{" "}
          {((e) => {
            let total = 0;
            props.poData.multiMaterials.forEach((x) => {
              total += x.qty * x.cost;
            });
            return new Intl.NumberFormat("en-US").format(total);
          })()}{" "}
          PHP
        </Text>
      </View>
      {(() => {
        if (Number(props.poData.isPrintable) !== 1) {
          return (
            <View style={[{ marginTop: 5 }]}>
              <Text style={[{ textAlign: "center", color: "red" }]}>
                --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
              </Text>
            </View>
          );
        }
      })()}

      <View>
        <View
          style={[
            {
              marginTop: 5,

              paddingLeft: 80,
              paddingRight: 80,
            },
          ]}
        >
          <View
            style={[
              {
                marginTop: 10,
                flex: 1,
                flexDirection: "row",
              },
            ]}
          >
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                  },
                ]}
              >
                Prepared by:
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                  },
                ]}
              >
                Approved By:
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                  },
                ]}
              >
                Received Original Copy:
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View>
        <View
          style={[
            {
              marginTop: 5,
              marginBottom: 25,
              paddingLeft: 80,
              paddingRight: 80,
            },
          ]}
        >
          <View
            style={[
              {
                marginTop: 10,
                flex: 1,
                flexDirection: "row",
              },
            ]}
          >
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                    textAlign: "center",
                  },
                ]}
              >
                {props.poData.generatedBy?.name ? props.poData.generatedBy?.name : ''}
              </Text>
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                    textAlign: "center",
                  },
                ]}
              >
                {props.poData.approvedBy?.name ? props.poData.approvedBy?.name : ''}
              </Text>
              {(() => {
                if (
                  Number(props.poData.isPrintable) === 1 &&
                  Number(props.poData.approvedBy.uploads.length) > 0
                ) {
                  return (
                    <Image
                      style={[
                        { position: "absolute", width: 150, marginTop: -30 },
                      ]}
                      src={{
                        uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${props.poData.approvedBy.uniqueId}&filename=${props.poData.approvedBy.uploads[0].filename}`,
                        method: "GET",
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("LUT"),
                        },
                        body: "",
                      }}
                    />
                  );
                }
              })()}
            </View>
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                    textAlign: "center",
                  },
                ]}
              >
                __________________________
              </Text>
            </View>
          </View>
        </View>
        <Canvas
          style={[
            {
              width: 600,
              height: 3,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("#0d0d0d")
              .lineWidth(3)
              .moveTo(50, 0)
              .lineTo(540, 0)
              .stroke();
          }}
        />
        <View
          style={[
            {
              textAlign: "center",
              color: "black",
              width: "100%",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 10,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",
               
                fontWeight: 1,
                fontSize: 9,
              },
            ]}
          >
            Magsaysay, Lapaz, Iloilo City
          </Text>
          {/* <Text
            style={[
              {
                color: "#0d0d0d",
                textAlign: "left",
               
                fontSize: 9,
              },
            ]}
          >
            EMAIL:
            <Text
              style={[
                {
                  color: "black",
                  textAlign: "left",
                  
                  fontSize: 9,
                },
              ]}
            >
              jnjbuilders88@gmail.com
            </Text>
          </Text>
          <Text
            style={[
              {
                color: "#0d0d0d",
                textAlign: "left",
                fontSize: 9,
                fontWeight: 700,
              },
            ]}
          >
            TEL:
            <Text style={[{ color: "black", textAlign: "left", fontSize: 9 }]}>
              (033) 320 2392
            </Text>
          </Text> */}
        </View>
      </View>
    </Page>
  </Document>
);

function poColumn(userType, purchased, finished, poData, handlePONotes) {
  return [
    {
      Header: "PO Materials",

      Cell: (row) => (
        <>
          <Row className="order-tc-cont" noGutters>
            <Col md={1} lg={1}>
              <div className="po-stat-cont">
                <Form.Label
                  className="text-center order-stat-label"
                  style={{
                    background:
                      (row && Number(row.original.status) === 1 && "#DFE24A") ||
                      (row && Number(row.original.status) === 2 && "#E24A4A") ||
                      (row && Number(row.original.status) === 3 && "#DFE24A") ||
                      (row && Number(row.original.status) === 4 && "#4A87E2") ||
                      (row && Number(row.original.status) === 5 && "#5DB55D") ||
                      (row && Number(row.original.status) === 6 && "#E2934A") ||
                      (row && Number(row.original.status) === 7 && "#E24A4A") ||
                      (row && Number(row.original.status) === 8 && "#E2934A"),
                  }}
                >
                  {(() => {
                    return DBStatus.parseOrderStatus(row.original.status);
                  })()}
                </Form.Label>
              </div>
            </Col>
            <Col md={11} lg={11}>
              <Row noGutters>
                <Col md={4} lg={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        Item
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        //console.log(row.original);
                        return row.original.brand === null
                          ? ""
                          : row.original.material.item.name +
                              "-" +
                              row.original.brand;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        PR#
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.uniqueId === null
                          ? ""
                          : row.original.uniqueId;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        Purchaser
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.purchaser.name === null
                          ? ""
                          : row.original.purchaser.name;
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        Qty
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        //console.log(row.original);
                        return row.original.qty === null
                          ? ""
                          : row.original.qty +
                              " " +
                              row.original.material.unit.name;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        Cost
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.cost === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.cost
                              );
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="po-tc-labels">
                        Total
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.cost === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.cost * row.original.qty
                              );
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={3} lg={3}>
                  <InputGroup className="h-100">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="font-weight-bold po-info-labels">
                        Notes
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      as="textarea"
                      style={{ resize: "none" }}
                      //placeholder={props.state.txtPONotes.placeholder}
                      name={`txtPONotes${row.original.id}`}
                      defaultValue={row.original.notes}
                      // onChange={(x) => {
                      //   console.log(x.currentTarget.value);
                      // }}
                      // onFocus={(x) => {
                      //   console.log("focus");
                      // }}
                      onBlur={(x) => {
                        handlePONotes(x, row.original);
                        // console.log(
                        //   x.currentTarget.value,
                        //   x.currentTarget.name
                        // );
                      }}
                      //className={props.state.txtPONotes.className + " css-projectInput"}
                      //readOnly={props.state.txtPONotes.readOnly}
                    />
                  </InputGroup>
                </Col>
                <Col md={2} className="pr-2 pl-2">
                  {(() => {
                    const s = row.original.status;
                    if (Number(poData.status) === 1) {
                      if (
                        (userType.includes(5) || userType.includes(0)) &&
                        s === 8 &&
                        Number(poData.isPrintable) === 0
                      ) {
                        return (
                          <Button
                            block
                            onClick={(e) => {
                              purchased(row.original.id, row.original.uniqueId);
                            }}
                          >
                            Set Purchased
                          </Button>
                        );
                      }
                      if (
                        (userType.includes(6) ||
                          userType.includes(7) ||
                          userType.includes(0)) &&
                        (s === 8 || s === 4) &&
                        Number(poData.isPrintable) === 0
                      ) {
                        return (
                          <Button
                            block
                            onClick={(e) => {
                              finished(row.original.id, row.original.uniqueId);
                            }}
                          >
                            Set Finished
                          </Button>
                        );
                      }
                    }
                  })()}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row></Row>
        </>
      ),
    },
  ];
}

function poHistoryColumn() {
  return [
    {
      Header: "ID",
      id: "brand",
      accessor: (d) => (d.uniqueId === null ? "" : d.uniqueId),
    },
    {
      Header: "Delivery Address",
      id: "address",
      accessor: (d) => (d.deliverTo === null ? "" : d.deliverTo),
    },
    {
      Header: "Date Posted",
      id: "posted",
      accessor: (d) =>
        d.datePosted === null
          ? ""
          : moment(d.datePosted, "YYYY-MM-DD").format("YYYY-MM-DD"),
    },
    {
      Header: "Posted By",
      id: "postedBy",
      accessor: (d) => (d.generatedBy.name === null ? "" : d.generatedBy.name),
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => DBStatus.parsePOType(d.status),
    },
  ];
}

function PDFGeneratePO(props) {
  const [btn, setBtn] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend>

      {(() => {
        if (btn) {
          return (
            <BlobProvider
              document={
                <POPDF state={props.state} poData={props.state.poData} />
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return (
                    <Button className="btnPhaseExport" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  );
                } else {
                  return (
                    <>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.state.downloadPDF(blob);
                          setBtn(false);
                          //props.state.resetPOModal();
                        }}
                      >
                        {" "}
                        Download
                      </Button>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: (
                              <POPDF
                                state={props.state}
                                poData={props.state.poData}
                              />
                            ),
                          });
                          setBtn(false);
                        }}
                      >
                        {" "}
                        View
                      </Button>
                    </>
                  );
                }
              }}
            </BlobProvider>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              className="btnPhaseExport"
              size={"sm"}
              onClick={(e) => {
                props.state.handleGeneratePO((x) => {
                  setBtn(true);
                });
              }}
            >
              {(() => {
                if (Number(props.state.poData.isPrintable) === 1)
                  return "Generate Signed PO";
                else return "Generate PO";
              })()}
            </Button>
          );
        }
      })()}
    </InputGroup>
  );
}

function PDFGeneratePOHistory(props) {
  const [btn, setBtn] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend>

      {(() => {
        if (btn) {
          return (
            <BlobProvider
              document={
                <POPDF
                  state={props.state}
                  poData={props.state.selectedPoHist}
                />
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return "Loading";
                } else {
                  return (
                    <>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.state.downloadPDF(blob, true);
                          setBtn(false);
                          //props.state.resetPOModal();
                        }}
                      >
                        {" "}
                        Download
                      </Button>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: (
                              <POPDF
                                state={props.state}
                                poData={props.state.selectedPoHist}
                              />
                            ),
                          });
                          setBtn(false);
                        }}
                      >
                        {" "}
                        View
                      </Button>
                    </>
                  );
                }
              }}
            </BlobProvider>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              className="btnPhaseExport"
              size={"sm"}
              onClick={(e) => {
                props.state.handleGeneratePOHistory((x) => {
                  setBtn(true);
                });
              }}
            >
              {" "}
              Generate PO
            </Button>
          );
        }
      })()}
    </InputGroup>
  );
}

function TxtFileUpload(props) {
  const [btnSave, setbtnSave] = useState(true);

  return (
    <>
      <Card
        className="h-100"
        // hidden={(() => {
        //   if (props.state.orderSelection) {
        //     let s = Number(props.state.orderSelection.status);
        //     let t = Number(props.userType);
        //     if (t !== 0) {
        //       if (s === 8 || s === 4) return false;
        //       else return true;
        //     } else return false;
        //   } else {
        //     return true;
        //   }
        // })()}
        //style={{ height: "200px" }}
      >
        <Card.Header>
          <strong>File Upload</strong>
        </Card.Header>
        <Card.Body className="overflow-auto h-25">
          {Number(props.state.poBakData.status) === 1 ? (
            <InputGroup className="mb-3">
              <FormControl
                type="file"
                multiple
                custom
                data-browse="Browse"
                className="inputFileUpload"
                onChange={(e) => {
                  props.state.fileUpload.handler(e);
                  console.log(Array.from(e.target.files));

                  if (Array.from(e.target.files).length === 0) {
                    setbtnSave(true);
                  } else {
                    setbtnSave(false);
                  }
                }}
              />

              <ButtonGroup className="">
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    name=""
                    size="sm"
                    className="btnSaveFileMultimat"
                    onClick={(e) => {
                      props.state.fileUpload.handleSubmit(e);
                    }}
                    disabled={btnSave}
                  >
                    Save
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </InputGroup>
          ) : (
            <></>
          )}

          {props.state.poData
            ? props.state.poData.uploads.map((i, index) => (
                <Row key={index}>
                  <Col key={index}>
                    <InputGroup>
                      {props.userType.includes(7) ||
                      props.userType.includes(0) ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">Remove</Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <Button
                              name={`delete${i.filename}`}
                              value={i.filename}
                              size="sm"
                              variant="danger"
                              className=""
                              onClick={(e) => {
                                props.state.deleteFile(
                                  i.filename,
                                  i.originalname
                                );
                              }}
                              style={{
                                borderRadius: 0,
                              }}
                            >
                              X
                            </Button>
                          </span>
                        </OverlayTrigger>
                      ) : (
                        <></>
                      )}

                      <Button
                        onClick={(e) => {
                          props.state.downloadFile({
                            name: i.filename,
                            complete: i.originalname,
                            id: props.state.poData.uniqueId,
                          });
                          // props.state.handleActionChangeOrder(e, {
                          //   name: i.filename,
                          //   complete: i.originalname,
                          //   id: props.state.orderSelection.uniqueId,
                          // });
                        }}
                        name="btnDLLink"
                        key={index}
                        variant="link"
                      >
                        {i.originalname}
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              ))
            : ""}
        </Card.Body>
      </Card>
    </>
  );
}

let TxtPONotes = (props) => (
  <>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text className="font-weight-bold po-info-labels">
          Notes
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="textarea"
        placeholder={props.state.txtPONotes.placeholder}
        name="txtPONotes"
        value={props.state.txtPONotes.value}
        onChange={props.state.inputChange}
        className={props.state.txtPONotes.className + " css-projectInput"}
        readOnly={props.state.txtPONotes.readOnly}
      />
    </InputGroup>
  </>
);

let TxtPODatePosted = (props) => (
  <>
    <InputGroup className="mb-3">
      <InputGroup.Prepend className="">
        <InputGroup.Text className="font-weight-bold po-info-labels">
          Date Posted
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        type="datetime-local"
        placeholder={props.state.txtPODatePosted.placeholder}
        name="txtPODatePosted"
        value={props.state.txtPODatePosted.value}
        onChange={props.state.inputChange}
        className={props.state.txtPODatePosted.className + " css-projectInput"}
        readOnly={props.state.txtPODatePosted.readOnly}
        required
      />
    </InputGroup>
  </>
);

let TxtPOStatus = (props) => (
  <>
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text className="font-weight-bold po-info-labels">
          Status
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        value={props.state.txtPOStatus.value}
        disabled={props.state.txtPOStatus.readOnly}
        name="txtPOStatus"
        className={props.state.txtPOStatus.className + " css-orderInput"}
        onChange={(e) => {
          props.state.inputChange(e);
        }}
      >
        <option value={0} key={0} disabled hidden>
          Select PO
        </option>
        <option value={1} key={1}>
          PO Ongoing
        </option>
        <option value={2} key={2}>
          PO Finished
        </option>
        <option value={3} key={3}>
          PO Pending Approval
        </option>
        <option value={4} key={4}>
          PO Denied Approval
        </option>
        <option value={8} key={8}>
          PO Pending Removal
        </option>
        <option value={9} key={9}>
          PO Archived
        </option>
      </FormControl>
    </InputGroup>
  </>
);

let SelPO = (props) => (
  <>
    <InputGroup className="pl-5 pr-5">
      <InputGroup.Prepend>
        <InputGroup.Text>POs:</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        value={props.state.selPO.value}
        name="selPO"
        className={""}
        onChange={(e) => {
          props.state.handlePOChange(e);
          props.state.inputChange(e);
        }}
      >
        {props.POData.map((po, index) => (
          <option key={po.id} value={po.id}>
            {po.uniqueId}
          </option>
        ))}
        <option value={0} key={0} disabled hidden>
          Select PO
        </option>
      </FormControl>
      <InputGroup.Prepend className="">
        <InputGroup.Text className="font-weight-bold po-list-length">
          {props.POData.length}
        </InputGroup.Text>
      </InputGroup.Prepend>
    </InputGroup>
  </>
);

let SelPOFilter = (props) => (
  <>
    <InputGroup className="pl-3" size="">
      <InputGroup.Prepend>
        <InputGroup.Text className="">Filter</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        value={props.state.selPOFilter.value}
        name="selPOFilter"
        className=""
        disabled={props.state.selPOFilter.readOnly}
        onChange={(e) => {
          props.state.inputChange(e);
          props.state.handlePOFilterChange(e.target.value);
        }}
      >
        <option value={0} key={0}>
          All
        </option>
        <option value={1} key={1}>
          Not Purchased
        </option>
        <option value={2} key={2}>
          Purchased
        </option>
        <option value={3} key={3}>
          Finished
        </option>
      </FormControl>
    </InputGroup>
  </>
);

function PO(props) {
  const [isPOHistModalOpen, togglePOHistModal] = useState(false);
  const [isPOCancelledModalOpen, togglePOCancelledModal] = useState(false);
  //const [isCancelPO, toggleCancelPO] = useState(false);
  return (
    <>
      <Modal
        show={isPOCancelledModalOpen}
        centered
        onHide={(e) => {
          togglePOCancelledModal(false);
        }}
        onEntered={(e) => {
          props.state.getPO();
          props.state.getHistPO();
        }}
        size="sm"
      >
        <Modal.Header closeButton className="po-modal-header-cont">
          <Row className="po-modal-header">
            <Col>
              <h4 className="text-center po-modal-header-text">
                Admin Denied PO
              </h4>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="d-flex justify-content-end"></Col>
          </Row>
          <Row></Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={isPOHistModalOpen}
        centered
        onHide={(e) => {
          togglePOHistModal(false);
        }}
        dialogClassName="modal-po-90w"
      >
        <Modal.Body className="po-modal-body">
          <Row className="po-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    togglePOHistModal(false);
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col md={11} lg={11}>
              <h4 className="font-weight-bold po-modal-header-text">
                Project POs
              </h4>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="d-flex justify-content-end mb-3">
              <PDFGeneratePOHistory
                state={props.state}
                dispatch={props.dispatch}
              />
            </Col>
          </Row>
          <Row>
            <Col>{props.tableHist}</Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={props.isShow}
        onHide={(e) => {
          props.dispatch({
            type: "PO_MODAL_TOGGLE",
          });
        }}
        onExit={(e) => {
          props.state.handlePOModal.handleExit(e);
        }}
        dialogClassName="modal-po-90w"
      >
        <Modal.Body className="po-modal-body">
          <Row className="po-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.dispatch({
                      type: "PO_MODAL_TOGGLE",
                    });
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col md={11} lg={11}>
              <h4 className="font-weight-bold po-modal-header-text">
                PO Management
              </h4>
            </Col>
          </Row>
          <Row className="row-crud" noGutters>
            <Col md={4} lg={4}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>PO #</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as={NumericFormat}
                  inputMode="numeric"
                  prefix={"POR"}
                  placeholder={props.state.txtSearchPO.placeholder}
                  name="txtSearchPO"
                  value={props.state.txtSearchPO.value}
                  onValueChange={(x) => {
                    props.state.inputChange({
                      target: {
                        value: Number(x.value),
                        name: "txtSearchPO",
                        placeholder: props.state.txtSearchPO.placeholder,
                        className: props.state.txtSearchPO.className,
                      },
                    });
                  }}
                  className={
                    props.state.txtSearchPO.className + " css-projectInput"
                  }
                  readOnly={props.state.txtSearchPO.readOnly}
                />

                <InputGroup.Prepend>
                  <Button
                    name="btnSearchPO"
                    className="btnSearchPO"
                    onClick={(e) => {
                      props.state.searchPO(true);
                    }}
                  >
                    Search PO
                  </Button>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
            <Col md={4} lg={4}>
              <SelPO state={props.state} POData={props.POData} />
            </Col>
            <Col md={2} lg={2} className="d-flex justify-content-end ">
              <Button
                block
                className="btnPoList"
                onClick={(e) => {
                  togglePOHistModal(true);
                }}
              >
                PO List
              </Button>
            </Col>
          </Row>
          <Row className="mb-3 " noGutters>
            <Col className="pr-1">
              <Card>
                <Card.Header className="font-weight-bold">
                  PO Information
                </Card.Header>
                <Card.Body>
                  <TxtPODatePosted state={props.state} />
                  <TxtPOStatus state={props.state} />
                  <TxtPONotes state={props.state} />
                  <div className="d-flex justify-content-end mt-3">
                    {(() => {
                      if (props.state.poData.id) {
                        if (
                          Number(props.state.poData.status) === 1 &&
                          !props.state.isCancel
                        ) {
                          return (
                            <Button
                              className="btnRevertPO mr-3"
                              onClick={(e) => {
                                props.state.toggleCancel(e);
                              }}
                              hidden={
                                props.userType.includes(8) ||
                                props.userType.includes(5) ||
                                props.userType.includes(6)
                                  ? true
                                  : false
                              }
                            >
                              Cancel PO
                            </Button>
                          );
                        }
                      }
                    })()}
                    {(() => {
                      if (props.state.poData.id)
                        if (
                          Number(props.state.poData.status) === 1 &&
                          props.state.isCancel
                        ) {
                          return (
                            <Button
                              className="btnRevertPO mr-3"
                              onClick={(e) => {
                                props.state.cancelPO(e);
                              }}
                              hidden={
                                !props.state.isCancel ||
                                props.userType.includes(8) ||
                                props.userType.includes(5)
                                  ? true
                                  : false
                              }
                            >
                              Submit Cancellation
                            </Button>
                          );
                        }
                    })()}
                    {(() => {
                      if (props.state.poData.id)
                        if (
                          Number(props.state.poData.status) === 1 &&
                          Number(props.state.poData.isPrintable) === 0
                        ) {
                          return (
                            <Button
                              className="mr-3"
                              onClick={(e) => {
                                props.state.handlePOFinishedClick(e);
                              }}
                              hidden={
                                props.userType.includes(8) ||
                                props.userType.includes(5)
                                  ? true
                                  : false
                              }
                            >
                              Set PO Finished
                            </Button>
                          );
                        }
                    })()}

                    {(() => {
                      if (props.state.poData.id)
                        if (Number(props.state.poData.status) === 4) {
                          return (
                            <Button
                              className="btnRevertPO"
                              onClick={(e) => {
                                //togglePOCancelledModal(true);
                                //props.state.handlePOFinishedClick(e);
                                props.state.setDeniedPo(e);
                              }}
                              hidden={
                                props.userType.includes(0) ||
                                props.userType.includes(5)
                                  ? false
                                  : true
                              }
                            >
                              Revert PO
                            </Button>
                          );
                        }
                    })()}
                    {(() => {
                      if (props.state.poData.id) {
                        if (
                          props.userType.includes(7) ||
                          props.userType.includes(5) ||
                          props.userType.includes(0)
                        ) {
                          if (
                            Number(props.state.poData.status) === 1 ||
                            Number(props.state.poData.isPrintable) === 1
                          ) {
                            return (
                              <PDFGeneratePO
                                state={props.state}
                                dispatch={props.dispatch}
                              />
                            );
                          }
                        } else {
                          if (
                            Number(props.state.poData.status) === 1 &&
                            Number(props.state.poData.isPrintable) === 0
                          ) {
                            return (
                              <PDFGeneratePO
                                state={props.state}
                                dispatch={props.dispatch}
                              />
                            );
                          } else {
                            //return "OFFICIAL PO NOT PRINTED";
                          }
                        }
                      }

                      // if (props.state.poData.id)
                      //   if (
                      //     Number(props.state.poData.isPrintable) === 1 &&
                      //     (props.userType.includes(7) ||
                      //       props.userType.includes(5) ||
                      //       props.userType.includes(0))
                      //   ) {
                      //     if (
                      //       Number(props.state.poData.status) === 1
                      //       // && Number(props.state.poData.isPrintable) === 1
                      //     ) {
                      //       return <PDFGeneratePO state={props.state} />;
                      //     }
                      //   } else {
                      //     return <PDFGeneratePO state={props.state}/>;
                      //   }
                    })()}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="pl-1">
              {(() => {
                //let t = Number(props.userType);

                if (
                  props.userType.includes(0) ||
                  props.userType.includes(6) ||
                  props.userType.includes(7)
                ) {
                  if (
                    props.state.POUniqueId !== 0 &&
                    props.state.POUniqueId &&
                    //Number(props.state.poBakData.status) === 1 &&
                    Number(props.state.poBakData.isPrintable) === 0
                  ) {
                    return (
                      <TxtFileUpload
                        state={props.state}
                        userType={props.userType}
                      />
                    );
                  }
                }
              })()}
            </Col>
          </Row>

          <Row className="row-crud" noGutters={true}>
            <Col md="auto" lg="auto" className="options-column">
              <InputGroup className="">
                <InputGroup.Prepend>
                  <InputGroup.Text className="ico-search">
                    <i className="icofont-search icofont-1x"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search"
                  aria-label="Username"
                  name="searchInput"
                  className="searchTable shadow-none"
                  aria-describedby="basic-addon1"
                  onChange={props.state.handleSearch}
                />
              </InputGroup>
            </Col>
            <Col md="auto" lg="auto">
              <SelPOFilter state={props.state} />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>{props.tableList}</Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { PO, poColumn, poHistoryColumn };
