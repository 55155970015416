import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import JVLogo from "../../assets/jv-logo.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";

import Tooltip from "react-bootstrap/Tooltip";
import "../../css/verification.css";

import {POPDF} from '../PO/PO'

import {
  Canvas,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  BlobProvider,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: `/Roboto-Regular.ttf`,
    },
    {
      src: `/Roboto-Bold.ttf`,
      fontWeight: "bold",
    },
    {
      src: `/Roboto-Italic.ttf`,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: `/Roboto-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const tableStyles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 12,
    marginLeft: 2,
    marginRight: 2,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// let POPDF = (props) => (
//   <Document>
//     {((x) => {})()}
//     <Page
//       size="A4"
//       style={[
//         {
//           //fontFamily: "Roboto",
//           paddingTop: 35,
//           paddingBottom: 65,
//           paddingHorizontal: 20,
//         },
//       ]}
//     >
//       <View
//         style={[
//           {
//             marginTop: 20,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 220, width: 80 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 5,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 20,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>PURCHASE ORDER</Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date: {moment(props.poData.datePosted).format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>

//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left", fontSize: 9 }]}>
//               PO #: {props.poData.uniqueId}
//             </Text>
//           </View>
//           {/* <View style={[{ flex: 1, width: "50%" }]}>
//             {(() => {
//               if (Number(props.poData.isPrintable) !== 1) {
//                 return (
//                   <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//                     Status:{" "}
//                     {Number(props.poData.status) === 1 ? "Ongoing" : "Finished"}
//                   </Text>
//                 );
//               }
//             })()}
//           </View> */}
//         </View>
//         <View
//           style={[
//             {
//               marginTop: 8,
//               marginBottom: 8,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             Supplier: {props.poData.multiMaterials[0].supplier.name}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 20,
//                 fontSize: 15,
//               },
//             ]}
//           >
//             Address: {props.poData.multiMaterials[0].supplier.address}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 10,
//                 fontSize: 15,
//               },
//             ]}
//           >
//             Deliver To: {props.poData.deliverTo}
//           </Text>
//         </View>
//       </View>

//       <View
//         style={[
//           {
//             height: 325,
//             paddingLeft: 50,
//             paddingRight: 50,
//           },
//         ]}
//       >
//         <Table data={props.poData.multiMaterials}>
//           <TableHeader>
//             {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               PR #
//             </TableCell> */}

//             <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
//               ITEM DESCRIPTION
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               QTY
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               UNIT PRICE
//             </TableCell>
//             <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
//               AMOUNT
//             </TableCell>
//           </TableHeader>
//           <TableBody>
//             {/* <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => (d.uniqueId === null ? "" : d.uniqueId)}
//             /> */}

//             {/* <DataTableCell
//               style={[tableStyles.tableCell]}
//               getContent={(d) =>
//                 d.material.unit.name === null ? "" : d.material.unit.name
//               }
//             /> */}
//             <DataTableCell
//               style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//               weighting={0.5}
//               getContent={(d) =>
//                 d.brand === null ? "" : d.brand + "-" + d.material.item.name
//               }
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d, index) => {
//                 console.log(index);
//                 return d.qty === null || d.material.unit.name === null
//                   ? ""
//                   : d.qty + " " + d.material.unit.name;
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.cost === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.cost);
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.budget === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.qty * d.cost);
//                 }
//               }}
//             />
//           </TableBody>
//         </Table>
//         <Text
//           style={[
//             {
//               textAlign: "right",
//               marginTop: 10,
//             },
//           ]}
//         >
//           TOTAL PRICE:{" "}
//           {((e) => {
//             let total = 0;
//             props.poData.multiMaterials.forEach((x) => {
//               total += x.qty * x.cost;
//             });
//             return new Intl.NumberFormat("en-US").format(total);
//           })()}{" "}
//           PHP
//         </Text>
//       </View>
//       {/* {(() => {
//         if (Number(props.poData.isPrintable) !== 1) {
//           return (
//             <View style={[{ marginTop: 5 }]}>
//               <Text style={[{ textAlign: "center", color: "red" }]}>
//                 --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
//               </Text>
//             </View>
//           );
//         }
//       })()} */}

//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,

//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Prepared by:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Approved By:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Received Original Copy:
//               </Text>
//             </View>
//           </View>
//         </View>
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,
//               marginBottom: 25,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 {props.poData.generatedBy !== null
//                   ? props.poData.generatedBy.name
//                   : "None"}
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 {props.poData.approvedBy !== null
//                   ? props.poData.approvedBy.name
//                   : "Pending"}
//               </Text>
//               {(() => {
//                 if (
//                   Number(props.poData.isPrintable) === 1 &&
//                   Number(props.poData.approvedBy.uploads.length) > 0
//                 ) {
//                   return (
//                     <Image
//                       style={[
//                         { position: "absolute", width: 150, marginTop: -30 },
//                       ]}
//                       src={{
//                         uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${props.poData.approvedBy.uniqueId}&filename=${props.poData.approvedBy.uploads[0].filename}`,
//                         method: "GET",
//                         headers: {
//                           Authorization:
//                             "Bearer " + localStorage.getItem("LUT"),
//                         },
//                         body: "",
//                       }}
//                     />
//                   );
//                 }
//               })()}
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 __________________________
//               </Text>
//             </View>
//           </View>
//         </View>
//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//         <View
//           style={[
//             {
//               textAlign: "center",
//               color: "black",
//               width: "100%",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 textAlign: "left",

//                 fontWeight: 1,
//                 fontSize: 9,
//               },
//             ]}
//           >
//             JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo City
//           </Text>
//           <Text
//             style={[
//               {
//                 color: "#0d0d0d",
//                 textAlign: "left",

//                 fontSize: 9,
//               },
//             ]}
//           >
//             EMAIL:
//             <Text
//               style={[
//                 {
//                   color: "black",
//                   textAlign: "left",

//                   fontSize: 9,
//                 },
//               ]}
//             >
//               jnjbuilders88@gmail.com
//             </Text>
//           </Text>
//           <Text
//             style={[
//               {
//                 color: "#0d0d0d",
//                 textAlign: "left",
//                 fontSize: 9,
//                 fontWeight: 700,
//               },
//             ]}
//           >
//             TEL:
//             <Text style={[{ color: "black", textAlign: "left", fontSize: 9 }]}>
//               (033) 320 2392
//             </Text>
//           </Text>
//         </View>
//       </View>
//     </Page>
//   </Document>
// );

const verifyColumn = [
  {
    Header: "Details",
    Cell: (row) => (
      <>
        <Row className="d-flex justify-content-center verification-tr-header">
          <h4 className="">
            {(() => {
              //console.log(row);
              switch (row.original.tableName) {
                case "Project":
                  return row.original.tableName === null
                    ? ""
                    : row.original.tableName;

                case "MultipleMaterials":
                  if (row.original?.object?.itemId) {
                    return row.original.tableName === null ? "" : "Order";
                  } else {
                    //console.log(row.original.object.material.materialType)
                    return row.original.object?.material?.materialType
                      ? `${row.original.object?.material?.materialType.toUpperCase()}`
                      : "";
                  }

                case "Phase":
                  return row.original.tableName === null
                    ? ""
                    : row.original.tableName;
                case "PurchaseOrder":
                  if (Number(row.original.object.status) === 8) {
                    return row.original.tableName === null
                      ? ""
                      : "Purchase Order Cancellation";
                  } else {
                    return row.original.tableName === null
                      ? ""
                      : "Purchase Order";
                  }

                default:
                  break;
              }
            })()}{" "}
            Approval
          </h4>
        </Row>
        {(() => {
          if (row.original.tableName === "MultipleMaterials") {
            return (
              <Row className="phase-tc-cont" noGutters>
                {(() => {
                  if (row.original) {
                    if (Number(row.original.object.finance.loss) === 0) {
                      return <></>;
                    } else {
                      return (
                        <Col md={6}>
                          <p className="text-center font-weight-bold notifOver ">
                            OVER BUDGET
                          </p>
                        </Col>
                      );
                    }
                  }
                })()}
                {(() => {
                  if (row.original) {
                    if (
                      Number(row.original.object?.totalQty) >
                      Number(row.original.object?.material?.requiredQty)
                    ) {
                      return (
                        <Col md={6}>
                          <p className="text-center font-weight-bold notifOver ">
                            OVER QTY
                          </p>
                        </Col>
                      );
                    } else {
                      return <></>;
                    }
                  }
                })()}
              </Row>
            );
          }
        })()}
        {(() => {
          if (row.original.tableName === "PurchaseOrder") {
            return (
              <Row>
                <Col>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Project</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.project.name === null
                          ? ""
                          : row.original.object.project.name;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>PO ID</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.uniqueId === null
                          ? ""
                          : row.original.object.uniqueId;
                      })()}
                    />
                  </InputGroup>

                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Dlvry Addr.</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.deliverTo === null
                          ? ""
                          : row.original.object.deliverTo;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Date Posted</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.datePosted === null || ""
                          ? "None"
                          : moment(
                              row.original.object.datePosted,
                              "YYYY-MM-DDTk:m:Z"
                            ).format("llll");
                      })()}
                    />
                  </InputGroup>

                  {/* <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Total Budget</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.budget === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.object.budget
                              );
                      })()}
                    />
                  </InputGroup> */}
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Posted By</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.generatedBy === null
                          ? ""
                          : row.original.object.generatedBy.name;
                      })()}
                    />
                  </InputGroup>
                </Col>
              </Row>
            );
          }
        })()}
        {(() => {
          if (row.original.tableName === "Project") {
            return (
              <Row>
                <Col>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Name</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.name === null
                          ? ""
                          : row.original.object.name;
                      })()}
                    />
                  </InputGroup>
                  {/* <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Code</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.uniqueId === null
                          ? ""
                          : row.original.object.uniqueId;
                      })()}
                    />
                  </InputGroup> */}
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Jobsite</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.address === null
                          ? ""
                          : row.original.object.address;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Date Started</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        if (
                          row.original.object.dateStart === null ||
                          row.original.object.dateStart === ""
                        ) {
                          return "Empty";
                        } else {
                          return moment(
                            row.original.object.dateStart,
                            "YYYY-MM-DD"
                          ).format("LL");
                        }
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Deadline</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        if (
                          row.original.object.dateEstEnd === null ||
                          row.original.object.dateEstEnd === ""
                        ) {
                          return "Empty";
                        } else {
                          return moment(
                            row.original.object.dateEstEnd,
                            "YYYY-MM-DD"
                          ).format("LL");
                        }
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Total Budget</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.budget === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.object.budget
                              );
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Assigned</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        let actors = "";
                        if (row.original.object.projectActors) {
                          row.original.object.projectActors.forEach((x) => {
                            actors += `${x.name}, `;
                          });
                          // row.original.object.projectActors.map((x, index) => {
                          //   actors += `${x.name}, `;
                          // });
                        }
                        return actors;
                      })()}
                    />
                  </InputGroup>
                  {/* <InputGroup className="">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Status</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        switch (row.original.tableName) {
                          case "Project":
                            return DBStatus.parseProjectStatus(
                              row.original.object.status
                            );

                          case "MultipleMaterials":
                            return DBStatus.parseOrderStatus(
                              row.original.object.status
                            );

                          case "Phase":
                            return row.original.object.phaseCreatedBy.name ===
                              null
                              ? ""
                              : row.original.object.phaseCreatedBy.name;
                          default:
                            break;
                        }
                      })()}
                    />
                  </InputGroup> */}
                </Col>
              </Row>
            );
          }
        })()}
        {(() => {
          if (row.original.tableName === "MultipleMaterials") {
            let particularsName = "";
            if (row.original.object?.material?.item?.name) {
              particularsName = row.original?.object?.material?.item?.name;
            }
            if (row.original?.object?.brand) {
              particularsName = `${particularsName} - ${row.original?.object?.brand}`;
            }
            if (row.original?.object?.material?.name) {
              particularsName = `${row.original?.object?.material?.name}`;
            }
            return (
              <Row className="d-flex justify-content-center" noGutters>
                <Col md={6} lg={6} sm={12} xs={12}>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Name</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={particularsName}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Posted</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.datePosted === null || ""
                          ? "None"
                          : moment(
                              row.original.object.datePosted,
                              "YYYY-MM-DDTk:m:Z"
                            ).format("llll");
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Project</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original?.object?.material?.project?.name ===
                          null
                          ? ""
                          : row.original?.object?.material?.project?.name;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Requester</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original?.object?.material?.requester
                          ? row.original?.object?.material?.requester?.name
                          : "None";
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Purchaser</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original?.object?.material?.purchaser
                          ? row.original?.object?.material?.purchaser?.name
                          : "None";
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={6} lg={6} sm={12} xs={12}>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Supplier</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.supplier === null
                          ? "None"
                          : row.original.object.supplier.name === null
                          ? "None"
                          : row.original.object.supplier.name;
                      })()}
                    />
                  </InputGroup>

                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Deadline</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.deadline === null || ""
                          ? "None"
                          : moment(
                              row.original.object.deadline,
                              "YYYY-MM-DDTk:m:Z"
                            ).format("llll");
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Unit Cost</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.cost === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.object.cost
                              );
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Qty</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return `${
                          row.original.object?.qty
                            ? row.original.object?.qty
                            : ""
                        } ${
                          row.original.object?.material?.unit?.name
                            ? row.original.object?.material?.unit?.name
                            : ""
                        }`;
                        // row.original.object.qty === null
                        //   ? ""
                        //   : row.original.object.qty +
                        //       " " +
                        //       row.original.object.material.unit.name;
                      })()}
                    />
                  </InputGroup>
                  <InputGroup className="verification-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="verification-tc-labels">
                        <strong>Total Cost</strong>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="verification-tc-txtbox"
                      value={(() => {
                        return row.original.object.qty === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.object.cost *
                                  row.original.object.qty
                              );
                      })()}
                    />
                  </InputGroup>
                </Col>
              </Row>
            );
          }
        })()}
      </>
    ),
  },
];

let TxtNote = (props) => (
  <>
    <Form.Label className="font-weight-bold">Notes</Form.Label>
    <FormControl
      as="textarea"
      placeholder={props.state.txtVerNotes.placeholder}
      name="txtVerNotes"
      value={props.state.txtVerNotes.value}
      onChange={props.state.inputChange}
      className={props.state.txtVerNotes.className + " css-projectInput"}
      readOnly={props.state.txtVerNotes.readOnly}
    />
  </>
);

// let TxtBy = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">By</Form.Label>
//     <FormControl
//       type="text"
//       placeholder={props.state.txtVerBy.placeholder}
//       name="txtVerBy"
//       value={props.state.txtVerBy.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtVerBy.className + " css-projectInput"}
//       readOnly={true}
//     />
//   </>
// );
// let TxtStatus = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">Status</Form.Label>
//     <FormControl
//       type="text"
//       placeholder={props.state.txtVerStatus.placeholder}
//       name="txtVerStatus"
//       value={props.state.txtVerStatus.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtVerStatus.className + " css-projectInput"}
//       readOnly={true}
//     />
//   </>
// );
// let TxtPosted = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">Date Posted</Form.Label>
//     <FormControl
//       type="date"
//       placeholder={props.state.txtVerPosted.placeholder}
//       name="txtVerPosted"
//       value={props.state.txtVerPosted.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtVerPosted.className + " css-projectInput"}
//       readOnly={true}
//     />
//   </>
// );
// let TxtName = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">Name</Form.Label>
//     <FormControl
//       type="text"
//       placeholder={props.state.txtVerName.placeholder}
//       name="txtVerName"
//       value={props.state.txtVerName.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtVerName.className + " css-projectInput"}
//       readOnly={true}
//     />
//   </>
// );

function VerifyModal(props) {
  const [btn, setBtn] = useState(false);
  let selectedParticulars = null;
  if (props.state.selectedVerification?.object) {
    selectedParticulars = props.state.selectedVerification?.object;
  }
  return (
    <>
      <Modal
        show={props.state.handleVerifyModal.isShow}
        onHide={props.state.handleVerifyModal.hide}
        onExit={(e) => {
          props.state.handleVerifyModal.handleExit(e);
        }}
        dialogClassName="modal-ver-90w"
        centered
      >
        <Modal.Body className="verif-modal-body">
          <Row className="verif-modal-header pt-3 pb-3" noGutters>
            <Col xs={2} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleVerifyModal.hide();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={10} sm={11} md={11} lg={11}>
              <h4 className=" verif-modal-header-text">
                {(() => {
                  switch (props.state.selectedVerification.tableName) {
                    case "MultipleMaterials":
                      return "Order";

                    case "Project":
                      return "Project";
                    case "PurchaseOrder":
                      if (
                        Number(
                          props.state.selectedVerification.object.status
                        ) === 8
                      ) {
                        return props.state.selectedVerification.tableName ===
                          null
                          ? ""
                          : "Purchase Order Cancellation";
                      } else {
                        return props.state.selectedVerification.tableName ===
                          null
                          ? ""
                          : "Purchase Order";
                      }
                    default:
                      break;
                  }
                })()}{" "}
                Verification
              </h4>
            </Col>
          </Row>
          {(() => {
            if (
              props.selObj &&
              props.state.selectedVerification.tableName === "MultipleMaterials"
            ) {
              return (
                <Row className=" mb-3" noGutters>
                  {(() => {
                    if (props.state.selectedVerification) {
                      if (
                        Number(
                          props.state.selectedVerification.object.finance.loss
                        ) === 0
                      ) {
                        return <></>;
                      } else {
                        return (
                          <Col md={6}>
                            <p className="text-center font-weight-bold notifOver ">
                              OVER BUDGET
                            </p>
                          </Col>
                        );
                      }
                    }
                  })()}
                  {(() => {
                    if (props.state.selectedVerification) {
                      if (
                        Number(
                          props.state.selectedVerification.object.totalQty
                        ) >
                        Number(
                          props.state.selectedVerification.object.material
                            .requiredQty
                        )
                      ) {
                        return (
                          <Col md={6}>
                            <p className="text-center font-weight-bold notifOver ">
                              OVER QTY
                            </p>
                          </Col>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  })()}
                </Row>
              );
            }
          })()}
          {(() => {
            if (
              props.selObj &&
              props.state.selectedVerification.tableName === "PurchaseOrder"
            ) {
              return (
                <>
                  <Row className="mt-3" noGutters>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-left"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Project</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .project.name === null
                              ? ""
                              : props.state.selectedVerification.object.project
                                  .name;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Dlvry Addr.</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .deliverTo === null
                              ? ""
                              : props.state.selectedVerification.object
                                  .deliverTo;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Posted By</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .generatedBy === null
                              ? ""
                              : props.state.selectedVerification.object
                                  .generatedBy.name;
                          })()}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-right"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Proj. Address</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .project.address === null
                              ? ""
                              : props.state.selectedVerification.object.project
                                  .address;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>ID</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.uniqueId ===
                              null
                              ? ""
                              : props.state.selectedVerification.uniqueId;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Date Posted</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .datePosted === null || ""
                              ? "None"
                              : moment(
                                  props.state.selectedVerification.object
                                    .datePosted,
                                  "YYYY-MM-DDTk:m:Z"
                                ).format("llll");
                          })()}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <InputGroup className="mb-3 mt-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="">PDF</InputGroup.Text>
                    </InputGroup.Prepend>
                    {(() => {
                      if (btn) {
                        console.log(props.state.poData);
                        return (
                          <BlobProvider
                            document={<POPDF poData={props.state.poData} />}
                          >
                            {({ blob, url, loading, error }) => {
                              if (loading) {
                                return (
                                  <Button className="btnPhaseExport" disabled>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">Loading...</span>
                                  </Button>
                                );
                              } else {
                                return (
                                  <>
                                    <Button
                                      name="btnGeneratePDF"
                                      className="btnPhaseExport"
                                      onClick={(e) => {
                                        props.state.downloadPDF(blob, true);
                                        setBtn(false);
                                        //props.state.resetPOModal();
                                      }}
                                    >
                                      {" "}
                                      Download
                                    </Button>
                                    <Button
                                      name="btnGeneratePDF"
                                      className="btnPhaseExport"
                                      onClick={(e) => {
                                        props.dispatch({
                                          type: "PDF_MODAL_TOGGLE",
                                          doc: (
                                            <POPDF
                                              poData={props.state.poData}
                                            />
                                          ),
                                        });
                                        setBtn(false);
                                      }}
                                    >
                                      {" "}
                                      View
                                    </Button>
                                  </>
                                );
                              }
                            }}
                          </BlobProvider>
                        );
                      } else {
                        return (
                          <Button
                            name="btnGeneratePDF"
                            className="btnPhaseExport"
                            size={"sm"}
                            onClick={(e) => {
                              props.state.getPO((x) => {
                                setBtn(true);
                              });
                            }}
                          >
                            {" "}
                            Generate PO
                          </Button>
                        );
                      }
                    })()}
                  </InputGroup>
                  <TxtNote state={props.state} />
                </>
              );
            }
          })()}
          {(() => {
            if (
              props.selObj &&
              props.state.selectedVerification.tableName === "MultipleMaterials"
            ) {
              let particularsName = "";
              if (selectedParticulars.material?.item?.name) {
                particularsName = selectedParticulars.material?.item?.name;
              }
              if (selectedParticulars.brand) {
                particularsName = `${particularsName} - ${selectedParticulars.brand}`;
              }
              if (selectedParticulars.material?.name) {
                particularsName = `${selectedParticulars.material?.name}`;
              }
              return (
                <>
                  <Row className="page-selectedProject-row">
                    <h4>Order Details</h4>
                  </Row>
                  <Row className="d-flex justify-content-center mb-3" noGutters>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-left"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Name</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={particularsName}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Posted</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .datePosted === null || ""
                              ? "None"
                              : moment(
                                  props.state.selectedVerification.object
                                    .datePosted,
                                  "YYYY-MM-DDTk:m:Z"
                                ).format("llll");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Project</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .material.project.name === null
                              ? ""
                              : props.state.selectedVerification.object.material
                                  .project.name;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Phase</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .material.phase.name === null
                              ? ""
                              : props.state.selectedVerification.object.material
                                  .phase.name;
                          })()}
                        />
                      </InputGroup>

                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Requester</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .material.requester === null
                              ? "None"
                              : props.state.selectedVerification.object.material
                                  .requester.name === null
                              ? "None"
                              : props.state.selectedVerification.object.material
                                  .requester.name;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Purchaser</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .purchaser === null
                              ? "None"
                              : props.state.selectedVerification.object
                                  .purchaser.name === null
                              ? "None"
                              : props.state.selectedVerification.object
                                  .purchaser.name;
                          })()}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-right"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Supplier</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .supplier === null
                              ? "None"
                              : props.state.selectedVerification.object.supplier
                                  .name === null
                              ? "None"
                              : props.state.selectedVerification.object.supplier
                                  .name;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Deadline</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .deadline === null || ""
                              ? "None"
                              : moment(
                                  props.state.selectedVerification.object
                                    .deadline,
                                  "YYYY-MM-DDTk:m:Z"
                                ).format("llll");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Unit Cost</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .cost === null
                              ? "None"
                              : "₱ " +
                                  new Intl.NumberFormat("en-US").format(
                                    props.state.selectedVerification.object.cost
                                  );
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Qty</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={`${
                            selectedParticulars.qty
                              ? selectedParticulars.qty
                              : ""
                          } ${
                            selectedParticulars.material?.unit?.name
                              ? selectedParticulars.material?.unit?.name
                              : ""
                          }`}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Total Cost</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .qty === null
                              ? ""
                              : "₱ " +
                                  new Intl.NumberFormat("en-US").format(
                                    props.state.selectedVerification.object
                                      .cost *
                                      props.state.selectedVerification.object
                                        .qty
                                  );
                          })()}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="page-selectedProject-row">
                    <h4>
                      {selectedParticulars?.material?.materialType
                        ? `${selectedParticulars?.material?.materialType.toUpperCase()}`
                        : ""}{" "}
                      Details
                    </h4>
                  </Row>
                  <Row className="d-flex justify-content-center mb-3" noGutters>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-left"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Name</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={particularsName}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Use</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .material.usage === null
                              ? ""
                              : props.state.selectedVerification.object.material
                                  .usage;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Total Budget</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .qty === null
                              ? ""
                              : "₱ " +
                                  new Intl.NumberFormat("en-US").format(
                                    props.state.selectedVerification.object
                                      .finance.totalBudget
                                  );
                          })()}
                        />
                      </InputGroup>

                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            className={
                              (() => {
                                if (
                                  props.state.selectedVerification.object
                                    .finance.loss === 0
                                ) {
                                  return "save";
                                } else {
                                  return "loss";
                                }
                              })() + " verification-tc-labels font-weight-bold"
                            }
                          >
                            {(() => {
                              if (
                                props.state.selectedVerification.object.finance
                                  .loss === 0
                              ) {
                                return "Budget Left";
                              } else {
                                return "Over Budget";
                              }
                            })()}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className={
                            (() => {
                              if (
                                props.state.selectedVerification.object.finance
                                  .loss === 0
                              ) {
                                return "save";
                              } else {
                                return "loss";
                              }
                            })() + " verification-tc-txtbox"
                          }
                          value={(() => {
                            if (
                              props.state.selectedVerification.object.finance
                                .loss === 0
                            ) {
                              return props.state.selectedVerification.object
                                .finance === null
                                ? ""
                                : "₱ " +
                                    new Intl.NumberFormat("en-US").format(
                                      props.state.selectedVerification.object
                                        .finance.totalBudget -
                                        props.state.selectedVerification.object
                                          .finance.used
                                    );
                            } else {
                              return props.state.selectedVerification.object
                                .finance === null
                                ? ""
                                : "₱ " +
                                    new Intl.NumberFormat("en-US").format(
                                      props.state.selectedVerification.object
                                        .finance.loss
                                    );
                            }
                          })()}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-right"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Required Qty</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .material === null
                              ? ""
                              : new Intl.NumberFormat("en-US").format(
                                  props.state.selectedVerification.object
                                    .material.requiredQty
                                );
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Ordered</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .totalQty === null
                              ? ""
                              : new Intl.NumberFormat("en-US").format(
                                  props.state.selectedVerification.object
                                    .totalQty
                                );
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Purchased</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .purchased === null
                              ? ""
                              : new Intl.NumberFormat("en-US").format(
                                  props.state.selectedVerification.object
                                    .purchased
                                );
                          })()}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <TxtNote state={props.state} />
                </>
              );
            }
          })()}
          {(() => {
            if (
              props.selObj &&
              props.state.selectedVerification.tableName === "Project"
            ) {
              return (
                <>
                  <Row className="mt-3 mb-3" noGutters>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-left"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Name</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .name === null
                              ? ""
                              : props.state.selectedVerification.object.name;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Code</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .uniqueId === null
                              ? ""
                              : props.state.selectedVerification.object
                                  .uniqueId;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Jobsite</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .address === null
                              ? ""
                              : props.state.selectedVerification.object.address;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Date Started</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            if (
                              props.state.selectedVerification.object
                                .dateStart === null ||
                              props.state.selectedVerification.object
                                .dateStart === ""
                            ) {
                              return "Empty";
                            } else {
                              return moment(
                                props.state.selectedVerification.object
                                  .dateStart,
                                "YYYY-MM-DD"
                              ).format("LL");
                            }
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Deadline</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            if (
                              props.state.selectedVerification.dateEstEnd ===
                                null ||
                              props.state.selectedVerification.dateEstEnd === ""
                            ) {
                              return "Empty";
                            } else {
                              return moment(
                                props.state.selectedVerification.object
                                  .dateEstEnd,
                                "YYYY-MM-DD"
                              ).format("LL");
                            }
                          })()}
                        />
                      </InputGroup>
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      sm={12}
                      xs={12}
                      className="ver-modal-body-cols-right"
                    >
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Proj Mngr</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className="verification-tc-txtbox"
                          spellCheck={false}
                          readOnly
                          defaultValue={(() => {
                            let act = "";
                            if (
                              props.state.selectedVerification.object
                                .projectActors
                            ) {
                              props.state.selectedVerification.object.projectActors.forEach(
                                (x) => {
                                  if (x.types.includes("7"))
                                    act += `${x.name}, `;
                                }
                              );
                              // props.state.selectedVerification.object.projectActors.map(
                              //   (x, index) => {
                              //     console.log(x);

                              //   }
                              // );
                            }
                            return act;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Site Mngr</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            let act = "";
                            if (
                              props.state.selectedVerification.object
                                .projectActors
                            ) {
                              props.state.selectedVerification.object.projectActors.forEach(
                                (x) => {
                                  if (x.types.includes("6"))
                                    act += `${x.name}, `;
                                }
                              );
                              // props.state.selectedVerification.object.projectActors.map(
                              //   (x, index) => {
                              //     if (x.types.includes("6"))
                              //       act += `${x.name}, `;
                              //   }
                              // );
                            }
                            return act;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Purchaser</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            let act = "";
                            if (
                              props.state.selectedVerification.object
                                .projectActors
                            ) {
                              props.state.selectedVerification.object.projectActors.forEach(
                                (x) => {
                                  if (x.types.includes("5"))
                                    act += `${x.name}, `;
                                }
                              );
                              // props.state.selectedVerification.object.projectActors.map(
                              //   (x, index) => {
                              //     if (x.types.includes("5"))
                              //       act += `${x.name}, `;
                              //   }
                              // );
                            }
                            return act;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Requester</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            let act = "";
                            if (
                              props.state.selectedVerification.object
                                .projectActors
                            ) {
                              props.state.selectedVerification.object.projectActors.forEach(
                                (x) => {
                                  if (x.types.includes("8"))
                                    act += `${x.name}, `;
                                }
                              );
                              // props.state.selectedVerification.object.projectActors.map(
                              //   (x, index) => {
                              //     if (x.types.includes("8"))
                              //       act += `${x.name}, `;
                              //   }
                              // );
                            }
                            return act;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup className="verification-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="verification-tc-labels">
                            <strong>Total Budget</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="verification-tc-txtbox"
                          value={(() => {
                            return props.state.selectedVerification.object
                              .budget === null
                              ? ""
                              : "₱ " +
                                  new Intl.NumberFormat("en-US").format(
                                    props.state.selectedVerification.object
                                      .budget
                                  );
                          })()}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <TxtNote state={props.state} />
                </>
              );
            }
          })()}
          <Row className="pb-3 pt-3 verif-modal-footer " noGutters>
            <Col xs={0} sm={0} md={8} xl={8}></Col>
            <Col
              className="justify-content-end d-flex"
              xs={12}
              sm={12}
              md={2}
              xl={2}
            >
              <Button
                block
                className="btnVarConfirm"
                name="btnVarConfirm"
                onClick={(x) => {
                  props.state.handleVerifyModal.handleSubmit(x);
                }}
              >
                ACCEPT
              </Button>
            </Col>
            <Col
              className="justify-content-end d-flex"
              xs={12}
              sm={12}
              md={2}
              xl={2}
            >
              <Button
                block
                className="btnVarDeny"
                name="btnVarDeny"
                onClick={(x) => {
                  props.state.handleVerifyModal.handleSubmit(x);
                }}
              >
                DENY
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Verification = (props) => (
  <>
    <div className="verification-header-cont">
      <Row className="page-selectedProject-row">
        <h4>Verification</h4>
      </Row>
      <Row className="page-date-row">
        <h4>
          Date:{" "}
          <u className="project-date-date">{moment().format("MMMM Do YYYY")}</u>
        </h4>
      </Row>
    </div>
    <Row className="tableContainerRow" noGutters={true}>
      <Col className="tableContainerCol">{props.tableList}</Col>
    </Row>
    <VerifyModal
      state={props.state}
      selObj={props.selObj}
      dispatch={props.dispatch}
    />
  </>
);

export { Verification, verifyColumn };
