import React from "react";
import { Phase, materialColumn, historyColumn } from "./Phase";

import Table from "react-table";
import Filters from "../../functions/filters";
import { connect } from "react-redux";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import moment from "moment";
//import socketIOClient from "socket.io-client";
//const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);
//import LoadingProcess from "../Loading/LoadingProcess";

//const SelectTable = selectTableHOC(Table);

class PhaseProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      readProjectBOQ: this.readProjectBOQ.bind(this),
      //parseProjectStatus: this.parseProjectStatus.bind(this),
      handleRefreshPhase: this.handleRefreshPhase.bind(this),
      handleMatFilterChange: this.handleMatFilterChange.bind(this),
      handleCopyPhase: this.handleCopyPhase.bind(this),
      readMaterialFinance: this.readMaterialFinance.bind(this),
      handlePDFExport: this.handlePDFExport.bind(this),

      handleSupplierChange: this.handleSupplierChange.bind(this),

      handlePhaseChange: this.handlePhaseChange.bind(this),
      handleActionChangePhase: this.handleActionChangePhase.bind(this),

      //handleMaterialChange: this.handleMaterialChange.bind(this),
      handleActionChangeMaterial: this.handleActionChangeMaterial.bind(this),

      readItems: this.readItems.bind(this),
      readUnits: this.readUnits.bind(this),

      downloadPDF: this.downloadPDF.bind(this),

      changePageContent: this.changePageContent.bind(this),

      materialHistoryData: [],

      selectAll: false,
      selection: [],
      searchInput: "",

      checkboxTable: {},
      columns: materialColumn,

      selectedPhaseOperation: { id: 0, name: "Add", disabled: false },
      selectedPhaseModalOperation: { id: 0, name: "Add" },

      selectedMaterialOperation: { id: 0, name: "Add", disabled: false },
      selectedMaterialModalOperation: { id: 0, name: "Add" },

      isLoading: false,
      isFiltered: false,
      isDetailed: { val: false, name: "Show Details" },

      isGenerate: false,

      projectBOQData: [],
      materialData: [],
      bakMaterialData: [],
      supplierData: [],
      materialSelection: null,

      phaseData: [],
      projectId: this.props.project.id,
      userInfo: this.props.state.user.name,
      phaseId: 0,
      materialId: 0,

      isHistoryLoading: false,

      phaseFinance: {
        loss: 0,
        savings: 0,
        totalBudget: 0,
        used: 0,
      },

      handleOrderOverviewModal: {
        handleSubmit: this.handleOrderOverviewSubmit.bind(this),
        handleExit: this.handleOrderOverviewExit.bind(this),
        show: this.handleOrderOverviewShowModal.bind(this),
        hide: this.handleOrderOverviewHideModal.bind(this),
        toggleBG: this.handleOrderOverviewBG.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isEdit: false,
        title: "",
        desc: "",
        response: false,
        className: "",
      },

      handleMaterialModal: {
        handleSubmit: this.handleMaterialSubmit.bind(this),
        handleExit: this.handleMaterialExit.bind(this),
        show: this.handleMaterialShowModal.bind(this),
        hide: this.handleMaterialHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isEdit: false,
        isView: true,
        title: "",
        desc: "",
        response: false,
      },

      handlePhaseModal: {
        handleSubmit: this.handlePhaseSubmit.bind(this),
        handleExit: this.handlePhaseExit.bind(this),
        show: this.handlePhaseShowModal.bind(this),
        hide: this.handlePhaseHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isEdit: false,
        title: "",
        desc: "",
        response: false,
      },

      //Supplier
      handleActorModal: {
        handleSubmit: this.handleActorSubmit.bind(this),
        show: this.handleActorShowModal.bind(this),
        hide: this.handleActorHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      txtPhaseCrud: {
        value: 0,
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
        data: {},
      },
      // === PHASE ===
      txtPhaseName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseDescription: {
        value: "",
        placeholder: "Description",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseBudget: {
        value: "",
        placeholder: "Budget",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseDateStart: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseDateEnd: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseDateEstEnd: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      // === MATERIAL ===
      txtMaterialName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialUnit: {
        value: "",
        placeholder: "Unit",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialReqQty: {
        value: "",
        placeholder: "Required Qty",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialUsage: {
        value: "",
        placeholder: "Usage",
        className: "",
        readOnly: false,
      },
      txtMatUniqueID: {
        value: "",
        placeholder: "ID",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialRequester: {
        value: "",
        placeholder: "Requester",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialBudget: {
        value: "",
        placeholder: "Budget",
        className: "",
        readOnly: false,
      },
      txtMaterialBaseCost: {
        value: "",
        placeholder: "Base Cost",
        className: "",
        readOnly: false,
      },
      txtPhaseDateLastPayment: {
        value: "",
        placeholder: "Budget",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseClientPayable: {
        value: "",
        placeholder: "Client Payable",
        className: "mb-3",
        readOnly: false,
      },
      txtPhaseClientLastPayAmount: {
        value: "",
        placeholder: "Last Payment Amount",
        className: "mb-3",
        readOnly: false,
      },
      txtMaterialDeadline: {
        value: "",
        placeholder: "Deadline",
        className: "",
        readOnly: false,
      },
      phasePDFSelect: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtDateLastModifiedMat: {
        value: "",
        placeholder: "",
        className: "mb-3",
        readOnly: false,
      },
      txtLastModifiedByMat: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtProjUniqueIDMat: {
        value: "",
        placeholder: "ID",
        className: "mb-3",
        readOnly: false,
      },
      txtDatePostedMat: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtDateLastModifiedPhase: {
        value: "",
        placeholder: "",
        className: "mb-3",
        readOnly: false,
      },
      txtLastModifiedByPhase: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtProjUniqueIDPhase: {
        value: "",
        placeholder: "ID",
        className: "mb-3",
        readOnly: false,
      },
      txtDatePostedPhase: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      selItem: {
        value: "",
        readOnly: false,
      },
      selUnit: {
        value: "",
        readOnly: false,
      },
      selMatFilter: {
        value: 0,
        readOnly: false,
      },
      selPdfGenerate: {
        value: 1,
        readOnly: false,
      },
      selMaterialEntryType: {
        value: "",
        readOnly: false,
      },

      txtSupplierCrud: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
        data: {},
      },

      txtActorName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtContact: {
        value: "",
        placeholder: "Contact",
        className: "mb-3",
        readOnly: false,
      },

      txtAddress: {
        value: "",
        placeholder: "Address",
        className: "mb-3",
        readOnly: false,
      },

      //PROJECT
      //MATERIAL_ITEM
      //MATERIAL_ORDER
      //MATERIAL_PURCHASE
      selectedContent: "PROJECT",
    };
  }

  handleOrderOverviewBG = (e) => {
    // if (e) {
    //   this.setState((x) => ({
    //     handleOrderOverviewModal: {
    //       ...x.handleOrderOverviewModal,
    //       className: "ord-bg-dark",
    //     },
    //   }));
    // } else {
    //   this.setState((x) => ({
    //     handleOrderOverviewModal: {
    //       ...x.handleOrderOverviewModal,
    //       className: "ord-bg-light",
    //     },
    //   }));
    // }
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
        readOnly: e.target.readOnly,
      },
    });
  };

  handleActorSubmit = (e, cb) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },
      }));
    } else {
      this.createActor();
      this.handleActorHideModal();
      this.clearActorInputs();
      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },
      }));
    }
  };
  createActor = () => {
    let urlActorAdd = "actor";

    let data = {
      name: this.state.txtActorName.value.toUpperCase(),
      address: this.state.txtAddress.value.toUpperCase(),
      email: "",
      contact: this.state.txtContact.value,
      username: "",
      password: "",
      website: "",
      type: [3],
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: urlActorAdd,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readSupplier();

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Supplier Added Successfully`,
        },
      });
    });
  };

  clearActorInputs = () => {
    this.setState((x) => ({
      txtActorName: { ...x.txtActorName, value: "", readOnly: false },
      txtContact: { ...x.txtContact, value: "", readOnly: false },
      txtAddress: { ...x.txtAddress, value: "", readOnly: false },
    }));
  };

  handleActorShowModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: true,
      },
    }));
  };
  handleActorHideModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: false,
      },
    }));
  };

  changePageContent = (content) => {
    this.setState({
      selectedContent: content,
    });
  };

  componentDidMount() {
    //console.log(this.props);
    this.readPhase(this.state.projectId);
    this.readItems();
    this.readUnits();
    this.readSupplier();
    this.props.socketio.socket.on(`supplierRefresh`, (data) => {
      this.readSupplier();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Suppliers refreshed`,
      //   },
      // });
    });
    //this.readProjectBOQ();
    this.props.socketio.socket.on(
      `multiMatRefresh${this.state.projectId}`,
      (data) => {
        this.readPhase(this.state.projectId, true);
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Orders refreshed`,
        //   },
        // });
      }
    );
    this.props.socketio.socket.on(
      `multMaterialsUpdate${this.state.projectId}`,
      (data) => {
        this.readPhase(this.state.projectId, true);
        this.readPR();
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Orders refreshed`,
        //   },
        // });
      }
    );
    this.props.socketio.socket.on(
      `materialRefresh${this.state.projectId}`,
      (data) => {
        this.readPhase(this.state.projectId, true);
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Materials refreshed`,
        //   },
        // });
      }
    );
    this.props.socketio.socket.on(
      `phaseRefresh${this.state.projectId}`,
      (data) => {
        this.readPhase(this.state.projectId, true);
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Phases refreshed`,
        //   },
        // });
      }
    );
    this.props.socketio.socket.on(`poUpdate${this.state.projectId}`, (data) => {
      this.getPO();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `PO refreshed`,
      //   },
      // });
    });
    this.props.socketio.socket.on(`itemRefresh`, (data) => {
      this.readItems();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Items refreshed`,
      //   },
      // });
    });
    this.props.socketio.socket.on(`unitRefresh`, (data) => {
      this.readUnits();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Units refreshed`,
      //   },
      // });
    });
  }

  componentWillUnmount() {
    //this.props.socketio.socket.disconnect();
  }

  getPO = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching POs", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getPO",
        payload: this.props.project.id,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //let POData = api.data;

      //console.log("PO DATA: ", api.data);

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  handleSupplierChange = (e, isFront) => {
    if (!isFront) {
      if (e.length > 0) {
        this.setState((x) => ({
          supplierId: e[0].id,
          txtSupplierCrud: {
            ...x.txtSupplierCrud,
            data: e[0],
            value: e[0].id,
          },
        }));
        //this.populatePhaseModal(e[0], false, false);
        //this.readMaterial(e[0].id);
        //console.log(this.state.supplierId);
      } else {
        this.setState((x) => ({
          supplierId: 0,
          txtSupplierCrud: {
            ...x.txtSupplierCrud,
            data: {},
            value: 0,
          },
        }));
        //console.log(this.state.supplierId);
      }
    } else {
      let tempId = e.target.value;
      let dat =
        this.state.supplierData[
          this.state.supplierData.findIndex((x) => x.id === tempId)
        ];
      //this.populatePhaseModal(dat, false, false);
      //console.log(dat);
      this.setState((x) => ({
        supplierId: tempId,
        txtSupplierCrud: {
          ...x.txtSupplierCrud,
          data: dat,
          value: tempId,
        },
      }));
      //this.readMaterial(tempId);
      //console.log(this.state.supplierId, tempId);
    }
  };

  readPR = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PRs", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: this.props.project.id,
        reqType: "getPR",

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //let PRData = api.data;

      //console.log("CONFIRMED MULTIMAT PRs: ", api.data);

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  handleMatFilterChange = async (e) => {
    let filteredMatData = [];

    switch (Number(e)) {
      case 0:
        this.setState((x) => ({
          materialData: this.state.bakMaterialData,
        }));
        break;
      case 1:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) => Number(x.purchased) < Number(x.requiredQty)
        );

        this.setState((x) => ({
          materialData: filteredMatData,
        }));
        break;
      case 2:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) =>
            Number(x.requiredQty) === Number(x.purchased) &&
            Number(x.warn) === 0
        );
        this.setState((x) => ({
          materialData: filteredMatData,
        }));

        break;
      case 3:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) => Number(x.warn) > 0
        );

        this.setState((x) => ({
          materialData: filteredMatData,
        }));
        break;
      default:
        break;
    }
  };

  handleRefreshPhase = (e) => {
    this.readPhaseFinance(this.state.phaseId);
    this.readPhase(this.state.projectId, true);
    this.readMaterial(this.state.phaseId);
    this.readProject(this.state.projectId);
  };
  handleCopyPhase = (e) => {
    switch (e) {
      case "btnMaterialUsage":
        this.setState((x) => ({
          txtMaterialUsage: {
            ...x.txtMaterialUsage,
            value: this.state.txtPhaseCrud.data.name,
          },
        }));
        break;
      case "btnMaterialBudget":
        if (
          this.state.txtMaterialBaseCost.value !== "" &&
          this.state.txtMaterialReqQty.value !== ""
        ) {
          this.setState((x) => ({
            txtMaterialBudget: {
              ...x.txtMaterialBudget,
              value:
                this.state.txtMaterialBaseCost.value *
                this.state.txtMaterialReqQty.value,
            },
          }));
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Notice",
              desc: `Base Cost or Quantity Empty.`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnMaterialDeadline":
        if (this.state.txtPhaseCrud.data.dateEstEnd)
          this.setState((x) => ({
            txtMaterialDeadline: {
              ...x.txtMaterialDeadline,
              value: this.state.txtPhaseCrud.data.dateEstEnd,
            },
          }));
        else
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Notice",
              desc: `Phase deadline is empty.`,
              isYesNo: false,
            },
          });
        break;
      default:
        break;
    }
  };

  readProject = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Project", id: loadId },
    });
    let url = `project/getProject?id=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;

      this.props.dispatch({
        type: "API_STORE_PROJECT_SELECTED",
        payload: {
          data: projData[0],
        },
      });

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      //console.log(projData);
    });
  };

  downloadPDF = async (blobData) => {
    const fileName = "PROJECT#-" + this.props.project.uniqueId + "-SUMMARY";
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();
  };

  handlePDFExport = (e) => {
    this.setState((x) => ({
      isGenerate: true,
    }));
  };

  handleOrderOverviewExit = (e) => {
    this.props.socketio.socket.on(
      `multMaterialsUpdate${this.state.projectId}`,
      (data) => {
        this.readPhase(this.state.projectId, true);
        this.readPR();
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Orders refreshed`,
        //   },
        // });
      }
    );
  };

  handleOrderOverviewSubmit = (e) => {};

  handleOrderOverviewShowModal = (e) => {
    this.setState((x) => ({
      handleOrderOverviewModal: {
        ...x.handleOrderOverviewModal,
        isShow: true,
      },
    }));
  };
  handleOrderOverviewHideModal = (e) => {
    //console.log("HIDE");
    this.setState((x) => ({
      handleOrderOverviewModal: {
        ...x.handleOrderOverviewModal,
        isShow: false,
      },
    }));
  };

  // === MATERIALS ===
  handleMaterialExit = (e) => {};

  handleMaterialSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    let isValid = true;

    // if (!this.state.selItem.value.id) isValid = false;
    // if (!this.state.selUnit.value.id) isValid = false;

    //console.log(isValid);
    if (form.checkValidity() === false || !isValid) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleMaterialModal: {
          ...x.handleMaterialModal,
          isValidated: false,
        },
        selection: [],
      }));
    } else {
      this.handleMaterialHideModal();

      if (this.state.selectedMaterialOperation.id === 0) {
       // console.log(this.state.phaseId, this.state.phaseData);

        this.createMaterial();
      } else {
        this.UpdateMaterial();
      }
      //this.clearMaterialInputs();
      this.setState((x) => ({
        handleMaterialModal: {
          ...x.handleMaterialModal,
          isValidated: false,
        },

        selection: [],
      }));
    }
  };

  handleMaterialShowModal = (e) => {
    this.setState((x) => ({
      handleMaterialModal: {
        ...x.handleMaterialModal,
        isShow: true,
      },
    }));
  };
  handleMaterialHideModal = (e) => {
    this.setState((x) => ({
      handleMaterialModal: {
        ...x.handleMaterialModal,
        isShow: false,
      },
    }));
  };
  // handleMaterialChange = (e, isFront) => {
  //   if (!isFront) {
  //     if (e.length > 0) {
  //       this.setState((x) => ({
  //         txtMaterialCrud: {
  //           ...x.txtMaterialCrud,
  //           data: e[0],
  //           value: e[0].id,
  //         },
  //       }));
  //       this.populateMaterialModal(e[0], false, false);
  //     }
  //   } else {
  //     let dat = this.state.materialData[
  //       this.state.materialData.findIndex((x) => x.id === e.target.value)
  //     ];
  //     this.populateMaterialModal(dat, false, false);
  //     this.setState((x) => ({
  //       txtMaterialCrud: {
  //         ...x.txtMaterialCrud,
  //         data: dat,
  //         value: e.value,
  //       },
  //     }));
  //   }
  // };

  handleActionChangeMaterial = (e) => {
    switch (e.target.name) {
      case "btnUnlockMaterial":
        this.setState((x) => ({
          txtMaterialName: {
            ...x.txtMaterialName,
            readOnly: false,
          },
          txtMaterialUnit: {
            ...x.txtMaterialUnit,
            readOnly: false,
          },
          txtMaterialBudget: {
            ...x.txtMaterialBudget,
            readOnly: false,
          },
          txtMaterialBaseCost: {
            ...x.txtMaterialBaseCost,
            readOnly: false,
          },
          txtMaterialReqQty: {
            ...x.txtMaterialReqQty,
            readOnly: false,
          },
          txtMaterialUsage: {
            ...x.txtMaterialUsage,
            readOnly: false,
          },
          handleMaterialModal: {
            ...x.handleMaterialModal,
            isView: false,
          },
          txtMaterialDeadline: {
            ...x.txtMaterialDeadline,
            readOnly: false,
          },
          selItem: {
            ...x.selItem,
            readOnly: false,
          },
          selUnit: {
            ...x.selUnit,
            readOnly: false,
          },
          txtSupplierCrud: {
            ...x.txtSupplierCrud,

            readOnly: false,
          },
        }));
        break;
      case "btnViewOrders":
        this.setState((x) => ({
          handleOrderOverviewModal: {
            ...x.handleOrderOverviewModal,
            isShow: true,
          },
        }));
        break;
      case "btnAddMaterial":
        if (this.state.phaseId === 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Phase Found",
              desc: `please add a phase before adding materials`,
              isYesNo: false,
            },
          });
        } else {
          this.setState(
            (x) => ({
              selectedMaterialOperation: {
                id: 0,
                name: "Add",
                disabled: false,
              },
              handleMaterialModal: {
                ...x.handleMaterialModal,
                isEdit: true,
                isView: false,
              },
              selectedContent: "MATERIAL_ITEM",
              //selection: [],
              selMaterialEntryType: {
                value: "material",
                readOnly: false,
              },
            }),
            (x) => {
              this.clearMaterialInputs();
              this.handleMaterialExit();
              this.handleMaterialShowModal();
            }
          );
        }

        break;
      case "btnEditMaterial":
        if (this.state.selection.length === 1) {
          this.setState((x) => ({
            selectedMaterialOperation: { id: 1, name: "Edit", disabled: false },
            handleMaterialModal: {
              ...x.handleMaterialModal,
              isEdit: true,
              isView: true,
            },
          }));
          this.handleMaterialShowModal();
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select a Material`,

              isYesNo: false,
            },
          });
        }

        break;
      case "btnDeleteMaterial":
        if (this.state.selection.length === 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Remove Material?`,
              isYesNo: true,
              cb: (e) => {
                this.deleteMaterial();
                this.handleMaterialHideModal();
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select a Material`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnOpenDetails":
        if (this.state.isDetailed.val) {
          this.setState((x) => ({
            isDetailed: { val: false, name: "View Details" },
          }));
        } else {
          this.setState((x) => ({
            isDetailed: { val: true, name: "Hide Details" },
          }));
        }
        break;
      default:
        break;
    }
  };

  populateMaterialModal = (e, _readOnly) => {
    //console.log("SELECTED MATERIAL: ", e);
    let supplier = "";
    if (e.materialType !== "material") {
      //console.log(e.multMaterials[0]);
      supplier =  this.state.supplierData[0]?.options.find(
        (x) => x.id === e.multMaterials[0].supplierId
      );
      //console.log(supplier)
      //supplier = e.multMaterials[0];
      // for (const obj of e.multMaterials) {

      // }
    }

    this.setState(
      (x) => ({
        materialId: e.id,
        txtMaterialName: {
          ...x.txtMaterialName,
          value: e.name,
          readOnly: _readOnly,
        },
        txtMaterialUnit: {
          ...x.txtMaterialUnit,
          value: e.unit,
          readOnly: _readOnly,
        },
        txtMaterialBudget: {
          ...x.txtMaterialBudget,
          value: e.budget,
          readOnly: _readOnly,
        },
        txtMaterialReqQty: {
          ...x.txtMaterialReqQty,
          value: e.requiredQty,
          readOnly: _readOnly,
        },
        txtMaterialUsage: {
          ...x.txtMaterialUsage,
          value: e.usage,
          readOnly: _readOnly,
        },
        txtMatUniqueID: {
          ...x.txtMatUniqueID,
          value: e.uniqueId,
          readOnly: _readOnly,
        },
        txtMaterialDeadline: {
          ...x.txtMaterialDeadline,
          value: e.deadline != null ? e.deadline : "",
          readOnly: _readOnly,
        },
        txtLastModifiedByMat: {
          ...x.txtLastModifiedByMat,
          value: e.lastModifiedBy != null ? e.lastModifiedBy.name : "",
          readOnly: _readOnly,
        },
        txtMaterialRequester: {
          ...x.txtMaterialRequester,
          value: e.requester != null ? e.requester.name : "",
          readOnly: _readOnly,
        },
        txtMaterialBaseCost: {
          ...x.txtMaterialBaseCost,
          value: e.baseCost,
          readOnly: _readOnly,
        },
        txtDatePostedMat: {
          ...x.txtDatePostedMat,
          value:
            e.datePosted != null
              ? moment(e.datePosted, "YYYY-MM-DDTk:m:Z").format(
                  "YYYY-MM-DDTHH:mm"
                )
              : "",
          readOnly: _readOnly,
        },
        txtDateLastModifiedMat: {
          ...x.txtDateLastModifiedMat,
          value:
            e.lastDateModified != null
              ? moment(e.lastDateModified, "YYYY-MM-DDTk:m:Z").format(
                  "YYYY-MM-DDTHH:mm"
                )
              : "",
          readOnly: _readOnly,
        },
        selItem: {
          ...x.selItem,
          value: e.item != null ? e.item : "",
          readOnly: _readOnly,
        },
        selUnit: {
          ...x.selUnit,
          value: e.unit != null ? e.unit : "",
          readOnly: _readOnly,
        },
        selMaterialEntryType: {
          ...x.selMaterialEntryType,
          value: e.materialType && e.materialType,
          readOnly: _readOnly,
        },
        txtSupplierCrud: {
          ...x.txtSupplierCrud,
          value: supplier,
          readOnly: _readOnly,
        },
      }),
      (x) => {
        this.setState((x) => ({
          txtMaterialReqQty: { ...x.txtMaterialReqQty, readOnly: true },
          txtMaterialBudget: { ...x.txtMaterialBudget, readOnly: true },
          txtMaterialBaseCost: {
            ...x.txtMaterialBaseCost,
            readOnly: true,
          },
        }));
      }
    );
  };

  clearMaterialInputs = () => {
    this.setState((x) => ({
      txtMaterialName: { ...x.txtMaterialName, value: "", readOnly: false },
      txtMaterialUnit: {
        ...x.txtMaterialUnit,
        value: "",
        readOnly: false,
      },
      txtMaterialBudget: { ...x.txtMaterialBudget, value: "", readOnly: false },
      txtMaterialReqQty: {
        ...x.txtMaterialReqQty,
        value: "",
        readOnly: false,
      },
      txtMaterialUsage: {
        ...x.txtMaterialUsage,
        value: "",
        readOnly: false,
      },
      txtMatUniqueID: {
        ...x.txtMatUniqueID,
        value: "",
        readOnly: false,
      },
      txtMaterialDeadline: {
        ...x.txtMaterialDeadline,
        value: "",
        readOnly: false,
      },
      txtLastModifiedByMat: {
        ...x.txtLastModifiedByMat,
        value: "",
        readOnly: false,
      },
      txtDatePostedMat: {
        ...x.txtDatePostedMat,
        value: "",
        readOnly: false,
      },

      txtDateLastModifiedMat: {
        ...x.txtDateLastModifiedMat,
        value: "",
        readOnly: false,
      },
      txtMaterialBaseCost: {
        ...x.txtMaterialBaseCost,
        value: "",
        readOnly: false,
      },
      selItem: {
        ...x.selItem,
        value: "",
        readOnly: false,
      },
      selUnit: {
        ...x.selUnit,
        value: "",
        readOnly: false,
      },
    }));
  };

  readPhaseFinance = (e) => {
    this.setState({ isLoading: true });
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Phase", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `project/getPhaseFinance?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let PFD = api.data;
      this.setState((x) => ({
        phaseFinance: {
          loss: PFD.loss,
          savings: PFD.savings,
          totalBudget: PFD.totalBudget,
          used: PFD.used,
          initialCostBudgetTotal: PFD.initialCostBudgetTotal,
        },
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readMaterialFinance = (e) => {
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMaterialFinance?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //console.log(api);
    });
  };

  readMaterial = (e, fn) => {
    this.setState({ isLoading: true });
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Materials", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMaterialByPhase?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then(async (api) => {
      let materialData = api.data;

      if (materialData.length === 0) {
        this.setState((x) => ({
          selectedMaterialOperation: {
            ...x.selectedMaterialOperation,
            disabled: true,
          },
        }));
      } else {
        this.setState((x) => ({
          selectedMaterialOperation: {
            ...x.selectedMaterialOperation,
            disabled: false,
          },
        }));
      }

      //console.log("MATERIAL:", materialData);
      this.setState(
        (x) => ({
          materialData: materialData,
          bakMaterialData: materialData,
          isLoading: false,
        }),
        (x) => {
          this.handleMatFilterChange(0);
        }
      );
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  createMaterial = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting new Material", id: loadId },
    });
    let data = {
      name: this.state.txtMaterialName.value,
      materialType: this.state.selMaterialEntryType.value,
      budget: Number(this.state.txtMaterialBudget.value),
      requiredQty: Number(this.state.txtMaterialReqQty.value),
      projectId: Number(this.state.projectId),
      phaseId: Number(this.state.phaseId),
      usage: this.state.txtMaterialUsage.value.toUpperCase(),
      deadline:
        this.state.txtMaterialDeadline.value === ""
          ? null
          : this.state.txtMaterialDeadline.value,
      baseCost: Number(this.state.txtMaterialBaseCost.value),
      itemId: Number(this.state.selItem.value.id),
      unitId: Number(this.state.selUnit.value.id),
    };
    //console.log(this.state.phaseId);

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: { data: data, supplierId: this.state.txtSupplierCrud?.value?.id },
        payload: "inventory/createMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readMaterial(this.state.phaseId);
      // this.readPhaseFinance(this.state.phaseId);
      this.handleRefreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
        selectedContent: "PROJECT",
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Material Added Successfully`,
        },
      });
    });
  };

  UpdateMaterial = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Update Material",
        id: loadId,
      },
    });
    let data = {
      id: Number(this.state.materialId),
      name: this.state.txtMaterialName.value,
      itemId: Number(this.state.selItem.value.id),
      materialType: this.state.selMaterialEntryType.value,
      unitId: Number(this.state.selUnit.value.id),
      budget: Number(this.state.txtMaterialBudget.value),
      requiredQty: Number(this.state.txtMaterialReqQty.value),
      usage: this.state.txtMaterialUsage.value.toUpperCase(),
      deadline:
        this.state.txtMaterialDeadline.value === ""
          ? null
          : this.state.txtMaterialDeadline.value,
      baseCost: Number(this.state.txtMaterialBaseCost.value),
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { data: data, supplierId: this.state.txtSupplierCrud?.value?.id },
        payload: "inventory/updateMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readMaterial(this.state.phaseId);
      this.handleRefreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
        selectedContent: "PROJECT",
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Material Edited Successfully`,
        },
      });
    });
  };

  deleteMaterial = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Material Deletion",
        id: loadId,
      },
    });
    let dat = {
      id: Number(this.state.materialId),
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: "inventory/deleteMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readMaterial(this.state.phaseId);
      this.handleRefreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
        selectedContent: "PROJECT",
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Material Deleted Successfully`,
        },
      });
    });
  };
  //=== PHASE ===

  handlePhaseExit = (e) => {
    this.handlePhaseChange(this.state.phaseData, false);
  };

  handlePhaseSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    let isValid = true;

    //console.log(form.checkValidity());
    if (form.checkValidity() === false || !isValid) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handlePhaseModal: {
          ...x.handlePhaseModal,
          isValidated: false,
        },
        //selection: [],
      }));
    } else {
      this.handlePhaseHideModal();

      if (this.state.selectedPhaseOperation.id === 0) {
        this.createPhase();
      } else {
        this.UpdatePhase();
      }
      this.clearPhaseInputs();
      this.setState((x) => ({
        handlePhaseModal: {
          ...x.handlePhaseModal,
          isValidated: false,
        },

        //selection: [],
      }));
    }
  };

  handlePhaseShowModal = (e) => {
    this.setState((x) => ({
      handlePhaseModal: {
        ...x.handlePhaseModal,
        isShow: true,
      },
    }));
  };
  handlePhaseHideModal = (e) => {
    this.setState((x) => ({
      handlePhaseModal: {
        ...x.handlePhaseModal,
        isShow: false,
      },
    }));
  };
  handlePhaseChange = (e, isFront) => {
    //console.log("Trigger", e);
    if (!isFront) {
      if (e.length > 0) {
        this.setState((x) => ({
          phaseId: e[0].id,
          txtPhaseCrud: {
            ...x.txtPhaseCrud,
            data: e[0],
            value: e[0].id,
          },
        }));
        this.populatePhaseModal(e[0], false, false);
        this.readMaterial(e[0].id);

        this.readPhaseFinance(e[0].id);
        //this.handleMatFilterChange(1);
        //console.log(this.state.phaseId);
      } else {
        this.setState((x) => ({
          phaseId: 0,
          txtPhaseCrud: {
            ...x.txtPhaseCrud,
            data: {},
            value: 0,
          },
        }));
        //console.log(this.state.phaseId);
      }
    } else {
      let tempId = e.target.value;
      let dat =
        this.state.phaseData[
          this.state.phaseData.findIndex((x) => Number(x.id) === Number(tempId))
        ];
      //console.log(dat);
      this.populatePhaseModal(dat, false, false);

      this.setState((x) => ({
        phaseId: tempId,
        txtPhaseCrud: {
          ...x.txtPhaseCrud,
          data: dat,
          value: tempId,
        },
      }));
      this.readMaterial(tempId);
      this.readPhaseFinance(tempId);
      //console.log(this.state.phaseId, tempId);
    }
  };

  handleActionChangePhase = (e) => {
    switch (e.target.name) {
      case "btnAddPhase":
        this.setState((x) => ({
          selectedPhaseOperation: { id: 0, name: "Add", disabled: false },
          handlePhaseModal: { ...x.handlePhaseModal, isEdit: false },
          //selection: [],
        }));
        this.clearPhaseInputs();
        //this.handlePhaseExit();
        this.handlePhaseShowModal();

        break;
      case "btnEditPhase":
        if (this.state.phaseData.length > 0) {
          this.setState((x) => ({
            selectedPhaseOperation: { id: 1, name: "Edit", disabled: false },
            handlePhaseModal: { ...x.handlePhaseModal, isEdit: true },
          }));
          this.handlePhaseShowModal();
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Empty phase",
              desc: `Please add a phase before editing`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnDeletePhase":
        if (this.state.phaseData.length > 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Remove Phase?`,
              isYesNo: true,
              cb: (e) => {
                this.deletePhase();
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Empty phase",
              desc: `Please add a phase before editing`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnOpenDetails":
        if (this.state.isDetailed.val) {
          this.setState((x) => ({
            isDetailed: { val: false, name: "View Details" },
          }));
        } else {
          this.setState((x) => ({
            isDetailed: { val: true, name: "Hide Details" },
          }));
        }
        break;
      case "btnClientAccepted":
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Confirmation",
            desc: `Proceed to start the project? \n Note: This will update the project status to ONGOING`,
            isYesNo: true,
            cb: (e) => {
              const loadId = Filters.uuidv4();
              this.props.dispatch({
                type: "TOGGLE_LOADING",
                payload: {
                  isLoading: true,
                  name: "Updating Project Status",
                  id: loadId,
                },
              });

              new Promise((resolve, reject) => {
                this.props.dispatch({
                  type: "USER_GET_REQUEST",
                  payload: `global/updateStatus`,
                  reqType: "put",
                  data: {
                    uniqueId: this.props.project.uniqueId,
                    status: 6,
                  },
                  resolve: resolve,
                  reject: reject,
                });
              }).then((api) => {
                this.props.dispatch({
                  type: "TOAST_ADD",
                  payload: {
                    title: `Verified Successfully`,
                  },
                });
                this.handleRefreshPhase();
                this.props.dispatch({
                  type: "TOGGLE_LOADING",
                  payload: { isLoading: false, id: loadId },
                });
              });
            },
          },
        });
        break;
      case "btnsetFinished":
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Confirmation",
            desc: `Proceed to finish the project? \n Note: This will update the project status to Finished`,
            isYesNo: true,
            cb: (e) => {
              const loadId = Filters.uuidv4();
              this.props.dispatch({
                type: "TOGGLE_LOADING",
                payload: {
                  isLoading: true,
                  name: "Updating Project Status",
                  id: loadId,
                },
              });

              new Promise((resolve, reject) => {
                this.props.dispatch({
                  type: "USER_GET_REQUEST",
                  payload: `global/updateStatus`,
                  reqType: "put",
                  data: {
                    uniqueId: this.props.project.uniqueId,
                    status: 7,
                  },
                  resolve: resolve,
                  reject: reject,
                });
              }).then((api) => {
                this.props.dispatch({
                  type: "TOAST_ADD",
                  payload: {
                    title: `Project Finished`,
                  },
                });
                window.location.reload();
                this.props.dispatch({
                  type: "TOGGLE_LOADING",
                  payload: { isLoading: false, id: loadId },
                });
              });
            },
          },
        });
        break;
      case "btnForApproval":
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Confirmation",
            desc: `Submit project for admin approval? \n Note: Admin will review all of the information you provided.`,
            isYesNo: true,
            cb: (e) => {
              const loadId = Filters.uuidv4();
              this.props.dispatch({
                type: "TOGGLE_LOADING",
                payload: {
                  isLoading: true,
                  name: "Updating Project Status",
                  id: loadId,
                },
              });

              new Promise((resolve, reject) => {
                this.props.dispatch({
                  type: "USER_GET_REQUEST",
                  payload: `global/updateStatus`,
                  reqType: "put",
                  data: {
                    uniqueId: this.props.project.uniqueId,
                    status: 2,
                  },
                  resolve: resolve,
                  reject: reject,
                });
              }).then((api) => {
                this.props.dispatch({
                  type: "TOAST_ADD",
                  payload: {
                    title: `Verified Successfully`,
                  },
                });
                this.props.dispatch({
                  type: "TOGGLE_LOADING",
                  payload: { isLoading: false, id: loadId },
                });
                this.handleRefreshPhase();
                //window.location.reload();
              });
            },
          },
        });
        break;
      default:
        break;
    }
  };

  populatePhaseModal = (e, view, _readOnly) => {
    //console.log(e);
    this.setState((x) => ({
      txtPhaseName: { ...x.txtPhaseName, value: e.name, readOnly: _readOnly },
      txtPhaseDescription: {
        ...x.txtPhaseDescription,
        value: e.description,
        readOnly: _readOnly,
      },
      txtPhaseBudget: {
        ...x.txtPhaseBudget,
        value: e.budget,
        readOnly: _readOnly,
      },
      txtPhaseDateEstEnd: {
        ...x.txtPhaseDateEstEnd,
        value: e.dateEstEnd != null ? e.dateEstEnd : "",
        //value: e.dateEstEnd,
        readOnly: _readOnly,
      },
      txtPhaseDateStart: {
        ...x.txtPhaseDateStart,
        value: e.dateStart != null ? e.dateStart : "",
        //value: e.dateStart,
        readOnly: _readOnly,
      },
      txtPhaseDateEnd: {
        ...x.txtPhaseDateEnd,
        value: e.dateEnd != null ? e.dateEnd : "",
        //value: e.dateEnd,
        readOnly: _readOnly,
      },
      // txtPhaseDateLastPayment: {
      //   ...x.txtPhaseDateLastPayment,
      //   value: e.dateLastPayment,
      //   readOnly: _readOnly,
      // },
      // txtPhaseClientPayable: {
      //   ...x.txtPhaseClientPayable,
      //   value: e.clientPayable,
      //   readOnly: _readOnly,
      // },
      // txtPhaseClientLastPayAmount: {
      //   ...x.txtPhaseClientLastPayAmount,
      //   value: e.clientLastPayAmount,
      //   readOnly: _readOnly,
      // },

      txtProjUniqueIDPhase: {
        ...x.txtProjUniqueIDPhase,
        value: e.uniqueId,
        readOnly: true,
      },

      txtLastModifiedByPhase: {
        ...x.txtLastModifiedByPhase,
        value: e.lastModifiedBy != null ? e.lastModifiedBy.name : "None",
        readOnly: true,
      },

      txtDatePostedPhase: {
        ...x.txtDatePostedPhase,
        value:
          e.datePosted != null
            ? moment(e.datePosted, "YYYY-MM-DDTk:m:Z").format(
                "YYYY-MM-DDTHH:mm"
              )
            : "",
        readOnly: true,
      },
      txtDateLastModifiedPhase: {
        ...x.txtDateLastModifiedPhase,
        value:
          e.lastDateModified != null
            ? moment(e.lastDateModified, "YYYY-MM-DDTk:m:Z").format(
                "YYYY-MM-DDTHH:mm"
              )
            : "",
        readOnly: true,
      },
    }));
  };

  clearPhaseInputs = () => {
    this.setState((x) => ({
      txtPhaseName: { ...x.txtPhaseName, value: "", readOnly: false },
      txtPhaseDescription: {
        ...x.txtPhaseDescription,
        value: "",
        readOnly: false,
      },
      txtPhaseBudget: { ...x.txtPhaseBudget, value: "", readOnly: false },
      txtPhaseDateEstEnd: {
        ...x.txtPhaseDateEstEnd,
        value: "",
        readOnly: false,
      },
      txtPhaseDateStart: { ...x.txtPhaseDateStart, value: "", readOnly: false },
      txtPhaseDateEnd: { ...x.txtPhaseDateEnd, value: "", readOnly: false },
      // txtPhaseDateLastPayment: {
      //   ...x.txtPhaseDateLastPayment,
      //   value: "",
      //   readOnly: false,
      // },
      // txtPhaseClientPayable: {
      //   ...x.txtPhaseClientPayable,
      //   value: "",
      //   readOnly: false,
      // },
      // txtPhaseClientLastPayAmount: {
      //   ...x.txtPhaseClientLastPayAmount,
      //   value: "",
      //   readOnly: false,
      // },
      txtProjUniqueIDPhase: {
        ...x.txtProjUniqueIDPhase,
        value: "",
        readOnly: false,
      },
      txtDateLastModifiedPhase: {
        ...x.txtDateLastModifiedPhase,
        value: "",
        readOnly: false,
      },
      txtLastModifiedByPhase: {
        ...x.txtLastModifiedByPhase,
        value: "",
        readOnly: false,
      },
      txtDatePostedPhase: {
        ...x.txtDatePostedPhase,
        value: "",
        readOnly: false,
      },
    }));
  };

  readItems = (e) => {
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Items", id: 3 },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getAllItems`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //console.log("ITEMS: ", api.data);
      let itemData = api.data;
      this.props.dispatch({
        type: "API_STORE_ITEM",
        payload: {
          data: itemData,
        },
      });
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, name: "Fetching Items", id: 3 },
      });
    });
  };

  readUnits = (e) => {
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Units", id: 2 },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getAllUnits`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let unitData = api.data;
      this.props.dispatch({
        type: "API_STORE_UNIT",
        payload: {
          data: unitData,
        },
      });
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, name: "Fetching Units", id: 2 },
      });
    });
  };

  readPhase = (e, refresh) => {
    //console.log(this.props);
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Phases", id: 1 },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `project/getPhasesByProject?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let phaseData = api.data;
      if (!refresh) this.handlePhaseChange(phaseData, false);

      //console.log("PHASES: ", phaseData);

      if (phaseData.length === 0) {
        this.setState((x) => ({
          //phaseId: 0,
          selectedPhaseOperation: {
            ...x.selectedPhaseOperation,
            disabled: true,
          },
        }));
      } else {
        //console.log(phaseData);
        this.setState((x) => ({
          //phaseId: phaseData.id,
          selectedPhaseOperation: {
            ...x.selectedPhaseOperation,
            disabled: false,
          },
        }));
      }

      // if (!fn) {
      // } else {
      //   fn(phaseData);
      // }
      this.setState((x) => ({
        phaseData: [...phaseData],
        //isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, name: "Fetching Phases", id: 1 },
      });
    });
  };
  readSupplier = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Suppliers", id: loadId },
    });
    let url = `actor/getActorByType?type=${3}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let actorData = api.data;
      const tempSupp = [];
      if (actorData) {
        tempSupp.push({ options: actorData, label: "suppliers" });
      }
      this.setState((prevState) => ({
        supplierData: [...tempSupp],
        //isLoading: false,
      }));

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  createPhase = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting new Phase", id: loadId },
    });
    let data = {
      name: this.state.txtPhaseName.value.toUpperCase(),
      description: this.state.txtPhaseDescription.value.toUpperCase(),
      budget: Number(this.state.txtPhaseBudget.value),
      projectId: this.state.projectId,
      dateStart:
        this.state.txtPhaseDateStart.value === ""
          ? null
          : this.state.txtPhaseDateStart.value,
      dateEstEnd:
        this.state.txtPhaseDateEstEnd.value === ""
          ? null
          : this.state.txtPhaseDateEstEnd.value,
      dateEnd:
        this.state.txtPhaseDateEnd.value === ""
          ? null
          : this.state.txtPhaseDateEnd.value,
      // dateLastPayment:
      //   this.state.txtPhaseDateLastPayment.value === ""
      //     ? null
      //     : this.state.txtPhaseDateLastPayment.value,
      //clientPayable: this.state.txtPhaseClientPayable.value,
      //clientLastPayAmount: this.state.txtPhaseClientLastPayAmount.value,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: "project/createPhase",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readPhase(this.state.projectId);
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Phase Added Successfully`,
        },
      });
    });
  };

  UpdatePhase = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Updating Phase", id: loadId },
    });
    let data = {
      id: this.state.txtPhaseCrud.data.id,
      name: this.state.txtPhaseName.value.toUpperCase(),
      description: this.state.txtPhaseDescription.value.toUpperCase(),
      budget: Number(this.state.txtPhaseBudget.value),
      projectId: this.state.projectId,
      dateStart:
        this.state.txtPhaseDateStart.value === ""
          ? null
          : this.state.txtPhaseDateStart.value,
      dateEstEnd:
        this.state.txtPhaseDateEstEnd.value === ""
          ? null
          : this.state.txtPhaseDateEstEnd.value,
      dateEnd:
        this.state.txtPhaseDateEnd.value === ""
          ? null
          : this.state.txtPhaseDateEnd.value,
      // dateLastPayment:
      //   this.state.txtPhaseDateLastPayment.value === ""
      //     ? null
      //     : this.state.txtPhaseDateLastPayment.value,
      // clientPayable: this.state.txtPhaseClientPayable.value,

      // clientLastPayAmount: this.state.txtPhaseClientLastPayAmount.value,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "project/updatePhase",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readPhase(this.state.projectId);
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Phase Edited Successfully`,
        },
      });
    });
  };

  deletePhase = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Phase Deletion",
        id: loadId,
      },
    });
    let dat = {
      id: this.state.txtPhaseCrud.data.id,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: "project/deletePhase",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readPhase(this.state.projectId);
      this.readMaterial(this.state.phaseId);
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Phase Deleted Successfully`,
        },
      });
    });
  };

  readItemHistoryPurchases = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Purchases", id: loadId },
    });
    //console.log(this.state.materialSelection);
    let url = `inventory/getItemHistoryPrices?materialId=${this.state.materialSelection.id}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const API_DATA = api.data;
      this.setState((prevState) => ({
        materialHistoryData: API_DATA,
        isHistoryLoading: false,
        selectedContent: "MATERIAL_ITEM",
      }));

      //console.log("ORDER HISTORY", API_DATA);

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readProjectBOQ = (cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Fetching Project Summary",
        id: loadId,
      },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `project/getProjectMaterials?id=${this.props.project.id}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const boqData = api.data;
      //console.log("OVERALL PROJ MATERIALS: ", boqData);
      this.setState((prevState) => ({
        projectBOQData: boqData,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      this.setState((x) => ({
        materialSelection: null,
      }));
    } else {
      selection.length = 0;
      selection.push(key);
      this.setState(
        (x) => ({
          materialSelection: row,
        }),
        (x) => {
          //this.clearMaterialInputs();
          this.populateMaterialModal(row, true);
          this.readItemHistoryPurchases();
        }
      );
    }

    this.setState((x) => ({
      selection,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    } else {
      this.setState({ selection: [] });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.name === "btnModifyOrderRow") {
        //console.log(e.target.getAttribute("id"));
        // this.populateOrderModal(rowInfo.original, true, true);

        this.setState((x) => ({
          materialSelection: rowInfo.original,
          handleOrderOverviewModal: {
            ...x.handleOrderOverviewModal,
            isShow: true,
          },
        }));
      }
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyOrderRow"
      ) {
        if (
          this.state.selection.length > 0 &&
          this.state.selection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            selection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            //console.log(this.props);

            this.setState({
              //materialSelection: rowInfo.original,
              //selectedMaterialOperation: { id: 4, name: "View", disabled: false },
              selection: ["select-" + rowInfo.original.id],
            });
            //console.log(this.props.state.user.type);
            if (this.props.state.user.type.includes(5)) {
              this.handleActionChangeMaterial({
                target: { name: "btnViewOrders" },
              });
            } else {
              this.handleActionChangeMaterial({
                target: { name: "btnEditMaterial" },
              });
            }
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });

  handleSearch = (event) => {
    //console.log(this.state.bakMaterialData);
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.bakMaterialData,
        event.target.value
      );

      if (data != null) {
        this.setState({ materialData: data });
      } else {
        this.setState({ materialData: this.state.bakMaterialData });
      }
    } else this.setState({ isFiltered: false });
  };

  render() {
    //console.log(this.props);

    let tablelist = (
      <Table
        data={this.state.materialData}
        columns={this.state.columns}
        keyField="id"
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        // selectAll={this.state.selectAll}
        // selectType="checkbox"
        loading={this.state.isLoading}
        // toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        showPageJump={false}
        showPageSizeOptions={false}
        defaultPageSize={10}
        style={{
          height: "60vh",
        }}
        className="tableProject -highlight"
      />
    );

    let tableHistory = (
      <Table
        data={this.state.materialHistoryData}
        columns={historyColumn(this.state.materialSelection)}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isHistoryLoading}
        //getTrProps={this.historyRowFn}
        defaultPageSize={20}
        className="tableHistory -highlight"
      />
    );

    let phase = (
      <Phase
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        data={this.props.project}
        userType={this.props.state.user.type}
        tablelist={tablelist}
        dispatch={this.props.dispatch}
        items={this.props.items}
        units={this.props.units}
        POData={this.props.POData}
        PRData={this.props.PRData}
        projectId={this.props.project.id}
        tableHistory={tableHistory}
        socket={this.props.socketio}
      />
    );

    return <>{phase}</>;
  }
}
const mapStateToProps = (state) => {
  return {
    POData: state.apiData.POData,
    PRData: state.apiData.PRData,
    units: state.apiData.unitData,
    items: state.apiData.itemData,
    project: state.apiData.projectDataSel,
    socketio: state.SocketHandler,
  };
};
export default connect(mapStateToProps)(PhaseProcess);
