import React from "react";
import PropTypes from "prop-types";
//import Filters from "../../functions/filters";
import { connect } from "react-redux";
import moment from "moment";
import { PO, poColumn, poHistoryColumn } from "./PO";
import RTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import Filters from "../../functions/filters";
const SelectTable = selectTableHOC(RTable);

class POProcess extends React.Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      poHistData: [],
      poHistDataBak: [],
      selectedPoHist: [],
      poHistSelection: [],
      poData: { uploads: [] },
      poBakData: { uploads: [] },
      filteredPo: [],
      isFiltered: false,
      newPo: [],

      selPOFilter: {
        value: 0,
        readOnly: false,
      },

      poList: [],
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      handlePOFilterChange: this.handlePOFilterChange.bind(this),
      handlePONotes: this.handlePONotes.bind(this),

      searchPO: this.searchPO.bind(this),
      resetPOModal: this.resetPOModal.bind(this),
      setPOPurchased: this.setPOPurchased.bind(this),
      getPO: this.getPO.bind(this),
      getHistPO: this.getHistPO.bind(this),

      setPOFinished: this.setPOFinished.bind(this),
      downloadPDF: this.downloadPDF.bind(this),

      deleteFile: this.deleteFile.bind(this),
      downloadFile: this.downloadFile.bind(this),
      setDeniedPo: this.setDeniedPo.bind(this),

      handlePOChange: this.handlePOChange.bind(this),
      handlePOFinishedClick: this.handlePOFinishedClick.bind(this),
      handleGeneratePO: this.handleGeneratePO.bind(this),
      handleGeneratePOHistory: this.handleGeneratePOHistory.bind(this),
      cancelPO: this.cancelPO.bind(this),
      toggleCancel: this.toggleCancel.bind(this),

      fileUpload: {
        handleSubmit: this.handleFileUploadSubmit.bind(this),
        handler: this.handleFileUploadChange.bind(this),
        value: "",
      },

      selectAll: false,
      selection: [],
      searchInput: "",

      checkboxTable: {},
      poSelection: [],

      isCancel: false,

      POUniqueId: 0,

      poisLoading: false,
      poHistisLoading: false,
      poSignature: {},

      selectedPOOperation: { id: 0, name: "Add", disabled: false },
      selectedPOModalOperation: { id: 0, name: "Add" },

      handlePOModal: {
        handleSubmit: this.handlePOSubmit.bind(this),
        handleExit: this.handleExit.bind(this),
        show: this.handleShowModal.bind(this),
        hide: this.handleHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: this.props.isOpen,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      txtSearchPO: {
        value: "",
        placeholder: "Enter PO #",
        className: "",
        readOnly: false,
      },
      txtPONotes: {
        value: "",
        placeholder: "Comments, Notes, etc.",
        className: "",
        readOnly: true,
      },
      txtPODatePosted: {
        value: "",
        placeholder: "Date Posted",
        className: "",
        readOnly: true,
      },

      txtPOStatus: {
        value: 0,
        placeholder: "0",
        className: "",
        readOnly: true,
      },
      selPO: {
        value: 0,
        placeholder: "Name",
        className: "",
        readOnly: false,
        data: {},
      },
    };
  }

  componentDidMount() {
    this.getPO();
    this.getHistPO();
  }

  componentWillUnmount() {}

  toggleCancel = (e) => {
    this.setState((x) => ({
      isCancel: true,
      txtPONotes: {
        ...x.txtPONotes,
        readOnly: false,
      },
    }));
  };

  cancelPO = (e) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Submit PO for deletion? Please provide details of cancellation`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting PO Deletion",
              id: loadId,
            },
          });
          let data = {
            uniqueId: this.state.POUniqueId,
            notes: this.state.txtPONotes.value,
            status: 8,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "put",
              data: data,
              payload: "global/updateStatus",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.setState(
              (x) => ({
                selPO: {
                  ...x.selPO,
                  value: 0,
                },
                txtPONotes: {
                  ...x.txtPONotes,
                  readOnly: true,
                  value: "",
                },
                isCancel: false,
              }),
              (x) => {
                //this.searchPO(false);
                this.getPO();
              }
            );

            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
          });
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `${this.state.POUniqueId} Submitted`,
            },
          });
        },
      },
    });
  };

  setDeniedPo = (e) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Revert PO Now? Please read admin deny message before proceeding.`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting Revert PO",
              id: loadId,
            },
          });
          let data = {
            uniqueId: this.state.POUniqueId,
            status: 5,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "put",
              data: data,
              payload: "global/updateStatus",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.setState(
              (x) => ({
                selPO: {
                  ...x.selPO,
                  value: 0,
                },
              }),
              (x) => {
                //this.searchPO(false);
                this.getPO();
              }
            );

            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
          });
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `${this.state.POUniqueId} Deleted`,
            },
          });
        },
      },
    });
  };

  finishPO = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting PO Update", id: loadId },
    });
    let data = {
      uniqueId: this.state.POUniqueId,
      status: 2,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "global/updateStatus",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.setState(
        (x) => ({
          selPO: {
            ...x.selPO,
            value: 0,
          },
        }),
        (x) => {
          //this.searchPO(false);
          this.getPO();
          this.getHistPO();
        }
      );

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
    this.props.dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `${this.state.POUniqueId} Finished`,
      },
    });
  };

  handleGeneratePO = (cb) => {
    if (this.state.POUniqueId) {
      cb();
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select or search a PO`,
          isYesNo: false,
        },
      });
    }
  };
  handleGeneratePOHistory = (cb) => {
    if (Number(this.state.poHistSelection.length) > 0) {
      const selected = this.state.selectedPoHist;
      //console.log(selected);
      if (Number(selected.status) === 9 || Number(selected.status) === 3) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "PO Archived",
            desc: `Cant regenerate archived & Pending POs`,
            isYesNo: false,
          },
        });
      } else {
        if (selected.generatedBy.uploads.length > 0) {
          cb();
        } else {
          this.setState(
            (x) => ({
              poSignature: null,
            }),
            (x) => {
              cb();
            }
          );
        }
      }
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select PO`,
          isYesNo: false,
        },
      });
    }
  };

  handlePOFinishedClick = (e) => {
    if (this.state.POUniqueId) {
      //let isOk = true;
      //console.log(this.state.poData.multiMaterials);

      if (
        this.state.poData.multiMaterials.every((e) =>
          Number(e.status) === 8 || Number(e.status) === 4 ? false : true
        )
      ) {
        if (
          this.props.userType.includes(0) &&
          Number(this.state.poData.uploads.length) === 0
        ) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Notice",
              desc: `No DR from suppliers & other supporting docs. Are you sure you want to Override?`,
              isYesNo: true,
              cb: (e) => {
                this.finishPO();
              },
            },
          });
        } else {
          if (Number(this.state.poData.uploads.length) === 0) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `Please upload DR from suppliers & other supporting docs. Contact admin to override.`,
                isYesNo: false,
              },
            });
          } else {
            this.finishPO();
          }
        }
      } else {
        if (this.props.userType.includes(0)) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `PO materials status not finished. Are you sure you want to Override?`,
              isYesNo: true,
              cb: (e) => {
                if (Number(this.state.poData.uploads.length) === 0) {
                  this.props.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "Notice",
                      desc: `No DR from suppliers & other supporting docs. Are you sure you want to Override?`,
                      isYesNo: true,
                      cb: (e) => {
                        this.finishPO();
                      },
                    },
                  });
                }
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `PO materials status not finished.`,
              isYesNo: false,
            },
          });
        }
      }
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select or search a PO`,
          isYesNo: false,
        },
      });
    }
  };

  populatePOInfo = (e) => {
    this.setState((x) => ({
      txtPODatePosted: {
        ...x.txtPODatePosted,
        value:
          e.datePosted != null
            ? moment(e.datePosted, "YYYY-MM-DDTk:m:Z").format(
                "YYYY-MM-DDTHH:mm"
              )
            : "",
      },
      txtPOStatus: { ...x.txtPOStatus, value: e.status },
      txtPONotes: { ...x.txtPONotes, value: e.notes !== null ? e.notes : "" },
      POUniqueId: e.uniqueId,
    }));
  };

  handlePOChange = (e) => {
    const filteredPo = this.props.POData.filter(
      (x) => x.id === Number(e.target.value)
    );

    if (filteredPo.length > 0) {
      let materials = [];

      if (filteredPo[0].multiMaterials) {
        filteredPo[0].multiMaterials.forEach((x) => {
          materials.push({
            ...x,
            name: x.material.item.name + "-" + x.material.brand,
          });
        });
      }

      //console.log(materials);

      this.setState({
        poData: { ...filteredPo[0], multiMaterials: materials },
        poBakData: { ...filteredPo[0], multiMaterials: materials },
      });
      this.populatePOInfo(filteredPo[0]);
      console.log("SELECTED PO: ", filteredPo[0]);
    } else {
      this.setState({ poData: { uploads: [] }, poBakData: { uploads: [] } });
    }
    this.setState((x) => ({
      txtPONotes: {
        ...x.txtPONotes,
        readOnly: true,
      },
      isCancel: false,
    }));

    //console.log(filteredPo);
  };

  handleFileUploadSubmit = (e) => {
    e.preventDefault();
    let urlUpload = "global/uploadFiles";

    let currentFiles = this.state.fileUpload.value;
    const formData = new FormData();
    formData.append("uniqueId", this.state.POUniqueId);
    for (let index = 0; index < currentFiles.length; index++) {
      formData.append("uploadedFiles", currentFiles[index]);
    }

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: urlUpload,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.getPO();
      this.searchPO(false);

      // const selId = this.state.orderSelection.id;
      // this.readOrder(null, (x) => {
      //   const filteredOrder = this.state.orderData.filter((x) => {
      //     return Number(x.id) === selId;
      //   })[0];
      //   if (filteredOrder)
      //     this.setState((x) => ({ orderSelection: filteredOrder }));
      // });
    });
  };
  handleFileUploadChange = (e) => {
    let currentFiles = e.target.files;

    this.setState((x) => ({
      fileUpload: {
        ...x.fileUpload,
        value: currentFiles,
      },
    }));
  };

  downloadFile = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Download Data", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        payload: `global/getFile?uniqueId=${e.id}&filename=${e.name}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let dlData = api.data;

      const url = window.URL.createObjectURL(new Blob([dlData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${e.complete}`);
      document.body.appendChild(link);
      link.click();

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  deleteFile = (filename, origName) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to delete ${origName} ?`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting File Deletion",
              id: loadId,
            },
          });
          let dat = {
            uniqueId: this.state.POUniqueId,
            filename: filename,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "delete",
              data: dat,
              payload: "global/deleteFile",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.getPO();
            //this.handlePOChange({target:{value: this.state.selPO.value}})
            //const selId = this.state.orderSelection.id;
            // this.readOrder(null, (x) => {
            //   const filteredOrder = this.state.orderData.filter((x) => {
            //     return Number(x.id) === selId;
            //   })[0];

            //   if (filteredOrder)
            //     this.setState((x) => ({ orderSelection: filteredOrder }));
            // });
            this.setState((prevState) => ({
              isLoading: false,
            }));
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `File Deleted Successfully`,
              },
            });
          });
        },
      },
    });
  };

  downloadPDF = async (blobData, hist) => {
    let fileName = "";
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;

    if (Number(this.state.poData.isPrintable) === 1 && !hist) {
      fileName = "PO#-" + this.state.poData.uniqueId + "-SIGNED";
      new Promise((resolve, reject) => {
        this.props.dispatch({
          type: "USER_GET_REQUEST",
          payload: `global/updateStatus`,
          reqType: "put",
          data: {
            uniqueId: this.state.poData.uniqueId,
            status: 6,
          },
          resolve: resolve,
          reject: reject,
        });
      }).then((api) => {
        link.download = fileName + ".pdf";
        document.body.appendChild(link);
        link.click();
        this.getPO();
        this.getHistPO();
      });
    } else {
      if (!hist) {
        fileName = "PO#-" + this.state.poData.uniqueId + "-REF";
      } else {
        fileName = "PO#-" + this.state.selectedPoHist.uniqueId + "-REF";
      }
      if (Number(this.state.selectedPoHist.isPrintable) === 1) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `Original PO Not printed. Not allowed to export.`,
            isYesNo: false,
          },
        });
      } else {
        link.download = fileName + ".pdf";
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  handlePONotes = (e, poData) => {
    if (e.currentTarget.value !== poData.notes) {
      const loadId = Filters.uuidv4();
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: {
          isLoading: true,
          name: "Saving Notes",
          id: loadId,
        },
      });
      const data = {
        uniqueId: poData.uniqueId,
        status: poData.status,
        notes: e.currentTarget.value,
      };

      new Promise((resolve, reject) => {
        this.props.dispatch({
          type: "USER_GET_REQUEST",
          reqType: "put",
          data: data,
          payload: "global/updateStatus",
          resolve: resolve,
          reject: reject,
        });
      }).then((api) => {
        this.searchPO(false);
        this.getPO();
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
        this.props.dispatch({
          type: "TOAST_ADD",
          payload: {
            title: `PO Notes Saved`,
          },
        });
      });
    }
  };

  setPOPurchased = (id, uniqueId) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `set PO to purchased?`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting PO Update",
              id: loadId,
            },
          });
          let data = {
            uniqueId: uniqueId,
            status: 4,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "put",
              data: data,
              payload: "global/updateStatus",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.searchPO(false);
            this.getPO();
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `Material Edited Successfully`,
              },
            });
          });
        },
      },
    });
  };

  setPOFinished = (id, uniqueId) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `set PO to finished?`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting PO Update",
              id: loadId,
            },
          });
          let data = {
            uniqueId: uniqueId,
            status: 5,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "put",
              data: data,
              payload: "global/updateStatus",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.searchPO(false);
            this.getPO();
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `Material Edited Successfully`,
              },
            });
          });
        },
      },
    });
  };

  getHistPO = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching POs", id: loadId },
    });
    let url = `inventory/getPurchaseOrderByProjectId?projectId=${this.props.projectId}&status=0`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const poHistData = api.data;
      //console.log("HISTORY PO:", poHistData);
      this.setState((prevState) => ({
        poHistData: poHistData,
        poHistDataBak: poHistData,
        poHistisLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  getPO = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching POs", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getPO",
        payload: this.props.projectId,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        let poData = onfulfilled.data;
        //console.log("SEARCHED PO: ", poData);

        this.setState((x) => ({
          poList: poData,
          poBakData: poData,
          isLoading: false,
        }));

        this.populatePOInfo(poData);
        this.handlePOChange({ target: { value: this.state.selPO.value } });
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
      },
      (onrejected) => {
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `PO not found. Please check entered ID.`,
            isYesNo: false,
          },
        });
      }
    );
  };

  searchPO = (isSearched) => {
    let url = "";
    if (isSearched) {
      url = `inventory/getPurchaseOrderByUniqueId?uniqueId=${this.state.txtSearchPO.value}&status=1`;
    } else {
      url = `inventory/getPurchaseOrderByUniqueId?uniqueId=${this.state.POUniqueId}&status=1`;
    }
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PO", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        let poData = onfulfilled.data;
        console.log("SEARCHED PO: ", poData);
        //this.getPO();
        if (isSearched) {
          this.setState((x) => ({
            poData: poData,
            poBakData: poData,
            isLoading: false,
            selPO: { ...x.selPO, value: 0 },
          }));
          this.populatePOInfo(poData);
        } else {
          this.setState((x) => ({
            poData: poData,
            isLoading: false,
            //selPO: { ...x.selPO, value: 0 },
          }));
          this.populatePOInfo(poData);
        }
        this.setState((x) => ({
          txtPONotes: {
            ...x.txtPONotes,
            readOnly: true,
            //value: "",
          },
          isCancel: false,
        }));

        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
      },
      (onrejected) => {
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `PO not found. Please check entered ID.`,
            isYesNo: false,
          },
        });
      }
    );
  };

  resetPOModal = (e) => {
    this.setState((x) => ({
      selSupplier: {
        value: 0,
        readOnly: false,
      },
      filteredPo: [],
      selection: [],
      poSelection: [],
    }));
  };

  handleExit = (e) => {};

  handlePOSubmit = (e) => {};

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleShowModal = (e) => {
    this.setState((x) => ({
      handlePOModal: {
        ...x.handlePOModal,
        isShow: true,
      },
    }));
  };
  handleHideModal = (e) => {
    this.setState((x) => ({
      handlePOModal: {
        ...x.handlePOModal,
        isShow: false,
      },
    }));
  };

  handleActionChange = (e) => {};

  handlePOFilterChange = async (e) => {
    let filteredMatData = [];
    //console.log(this.state.poBakData);
    if (this.state.POUniqueId) {
      switch (Number(e)) {
        case 0:
          this.setState((x) => ({
            poData: this.state.poBakData,
          }));
          break;
        case 1:
          filteredMatData = await this.state.poBakData.multiMaterials.filter(
            (x) => Number(x.status) === 8
          );

          this.setState((x) => ({
            poData: { ...x.poData, multiMaterials: filteredMatData },
          }));
          break;
        case 2:
          filteredMatData = await this.state.poBakData.multiMaterials.filter(
            (x) => Number(x.status) === 4
          );
          this.setState((x) => ({
            poData: { ...x.poData, multiMaterials: filteredMatData },
          }));

          break;
        case 3:
          filteredMatData = await this.state.poBakData.multiMaterials.filter(
            (x) => Number(x.status) === 5
          );

          this.setState((x) => ({
            poData: { ...x.poData, multiMaterials: filteredMatData },
          }));
          break;
        default:
          break;
      }
    }
  };

  handleSearch = (event) => {
    //console.log(this.state.poBakData);

    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.poBakData.multiMaterials,
        event.target.value
      );

      if (data != null) {
        this.setState((x) => ({
          poData: { ...x.poData, multiMaterials: data },
        }));
      } else {
        this.setState({ poData: this.state.poBakData });
      }
    } else this.setState({ isFiltered: false });
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
      }
      if (handleOriginal) {
        handleOriginal();
      }
      //console.log(rowInfo.original.id);
    },
  });

  toggleSelection = (key, shift, row) => {
    let poHistSelection = this.state.poHistSelection;
    const keyIndex = poHistSelection.indexOf(key);

    if (keyIndex >= 0) {
      poHistSelection = [];
      this.setState((x) => ({
        selectedPoHist: null,
      }));
    } else {
      poHistSelection.length = 0;
      poHistSelection.push(key);
      console.log('SELECTED PO ===> ',row);
      this.setState((x) => ({
        selectedPoHist: row,
      }));
      //this.clearInputs();
      //this.populateModal(row, false, true);
    }

    this.setState((x) => ({
      poHistSelection,
    }));
  };

  histRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
        if (
          this.state.poHistSelection.length > 0 &&
          this.state.poHistSelection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            poHistSelection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            //this.handleActionChange({ target: { name: "btnEditActor" } });

            this.setState({
              poHistSelection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }
      if (handleOriginal) {
        handleOriginal();
      }
      //console.log(rowInfo.original.id);
    },
    style: {
      background:
        rowInfo &&
        this.state.poHistSelection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });
  isSelected = (key) => {
    return this.state.poHistSelection.includes(`select-${key}`);
  };

  render() {
    let tablelist = (
      <RTable
        data={this.state.poData.multiMaterials}
        columns={poColumn(
          this.props.userType,
          this.state.setPOPurchased,
          this.state.setPOFinished,
          this.state.poData,
          this.state.handlePONotes
        )}
        loading={this.state.poisLoading}
        getTrProps={this.rowFn}
        defaultPageSize={50}
        style={{
          height: "50vh",
        }}
        className="tableActor -highlight"
      />
    );
    let tableHist = (
      <SelectTable
        data={this.state.poHistData}
        columns={poHistoryColumn()}
        keyField="id"
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        loading={this.state.poHistisLoading}
        selectType="checkbox"
        //rowFn={this.histRowFn}
        isSelected={this.isSelected}
        getTrProps={this.histRowFn}
        defaultPageSize={15}
        style={{
          height: "70vh",
        }}
        className="tableHistory -highlight"
      />
    );

    return (
      <>
        <PO
          inputChange={this.inputChange}
          state={this.state}
          isShow={this.props.isPOModalOpen}
          dispatch={this.props.dispatch}
          tableList={tablelist}
          tableHist={tableHist}
          userType={this.props.userType}
          POData={this.props.POData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log(state.apiData.POData);
  return {
    POData: state.apiData.POData,
    isPOModalOpen: state.poModal.show,
  };
};
export default connect(mapStateToProps)(POProcess);
