import React from "react";
import { Profile } from "./Profile";
import { connect } from "react-redux";
//import DBStatus from "../../functions/DBStatus";
import Filters from "../../functions/filters";
let CryptoJS = require("crypto-js");

class ProfileProcess extends React.Component {
  constructor(props) {
    super(props);

    this.empData = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("LUD"),
        process.env.REACT_APP_SEK
      ).toString(CryptoJS.enc.Utf8)
    );
    this.state = {
      user: this.empData,
      downloadManual: this.downloadManual.bind(this),
    };
  }

  componentDidMount() {
    //console.log(this.empData);
  }

  downloadManual = async (e) => {
    const filename = e.currentTarget.name;
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Download Data", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        payload: `global/downloadManual?manualType=${filename}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let dlData = api.data;

      const url = window.URL.createObjectURL(
        new Blob([dlData], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename + "-Manual"}`);
      document.body.appendChild(link);
      link.click();

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  componentWillUnmount() {}

  render() {
    return (
      <>
        <Profile user={this.state.user} state={this.state} />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(ProfileProcess);
