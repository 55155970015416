import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from "react-select";
import "../css/customdrop.css";

import { connect } from "react-redux";

class CustomDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: this.props.componentName,
      componentType: this.props.componentType,
      selData: {},
      selCustDrop: {
        handler: this.handleCustDropChange.bind(this),
        id: 0,
        value: "",
        name: "",
        placeholder: `${this.props.componentType} ${this.props.componentName}`,
        buttonStat: [false, true, true, true, true],
        reqType: "",
        className: "mb-3",
        cont: {},
        isDropOpen: false,
        selValue: this.props.data,
        isLoading: this.props.isLoading,
      },
      txtCustDrop: {
        value: "",
        placeholder: `${this.props.componentType} ${this.props.componentName}`,
        className: "",
        isDisabled: true,
      },
      inputChange: this.inputChange.bind(this),
    };
  }

  inputChange = (e) => {
    this.setState({
      [e.currentTarget.name]: {
        value: e.currentTarget.value,
        placeholder: e.currentTarget.placeholder,
        className: e.currentTarget.className,
      },
    });
  };
  handleDropCancel = () => {
    this.setState((x) => ({
      selCustDrop: {
        ...x.selCustDrop,
        buttonStat: [false, true, true, true, true],
        selValue: "",
        isDropOpen: false,
      },
      txtCustDrop: {
        ...x.txtCustDrop,
        value: "",
        placeholder: `Enter ${this.state.componentName}`,
        isDisabled: true,
      },
    }));
    this.props.handler({
      target: {
        value: "",
        name: this.props.name,
        readOnly: this.props.readOnly,
      },
    });
    //this.props.handler("");
  };
  handleCustDropChange = (e, name) => {
    //console.log(this.state.selCustDrop);
    if (name) {
      if (name.name === "selCustDrop") {
        if (e) {
          const { id } = e;

          let _name = "";

          if (this.props.optionLabel) _name = e[this.props.optionLabel];
          else _name = e.name;

          this.setState((x) => ({
            selCustDrop: {
              ...x.selCustDrop,
              id: id,
              name: _name,
              cont: e,
              selValue: e,
              buttonStat: [false, false, true, false, true],
            },
            txtCustDrop: {
              ...x.txtCustDrop,

              value: _name,
            },
          }));
        } else {
          this.handleDropCancel();
          this.setState((x) => ({
            selCustDrop: {
              ...x.selCustDrop,
              isDropOpen: false,
            },
          }));
        }
      }
    } else {
      switch (e.currentTarget.name) {
        case "save":
          if (this.state.selCustDrop.reqType === "add") {
            if (
              this.state.txtCustDrop.value === "" ||
              this.state.txtCustDrop.value === " "
            ) {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Empty",
                  desc: `Please enter a name`,
                  isYesNo: false,
                },
              });
            } else {
              let data = "";
              switch (this.props.optionLabel) {
                case "company":
                  data = {
                    company: this.state.txtCustDrop.value.toUpperCase(),
                    type: 5,
                  };
                  break;

                default:
                  data = { name: this.state.txtCustDrop.value.toUpperCase() };
                  break;
              }

              new Promise((resolve, reject) => {
                this.props.dispatch({
                  type: "USER_GET_REQUEST",
                  payload: this.props.addUrl,
                  reqType: "post",
                  data: data,
                  resolve: resolve,
                  reject: reject,
                });
              }).then(
                (onfulfilled) => {
                  this.props.refreshData();
                  this.setState((x) => ({
                    selCustDrop: {
                      ...x.selCustDrop,
                      isDropOpen: false,
                      selValue: onfulfilled.data.data,
                    },
                  }));
                  this.handleDropCancel();

                  this.props.dispatch({
                    type: "TOAST_ADD",
                    payload: {
                      title: `${this.state.componentName} saved successfully`,
                    },
                  });
                  console.log(onfulfilled);
                  this.props.handler({
                    target: {
                      value: onfulfilled.data.data,
                      name: this.props.name,
                      readOnly: this.props.readOnly,
                    },
                  });
                  //this.props.handler(onfulfilled.data.data);
                },
                (onrejected) => {
                  //let errCode = onrejected.response.status;
                  this.handleDropCancel();
                }
              );
              // .then((api) => {
              //   //this.getInventoryVals();
              //   this.handleDropCancel();
              //   this.setState((x) => ({
              //     selCustDrop: {
              //       ...x.selCustDrop,
              //       isDropOpen: false,
              //     },
              //   }));
              // });
            }
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Confirmation",
                desc: `Edit ${this.state.selCustDrop.name} ${this.state.componentName}? Doing so will edit all ${this.state.componentType} that has this ${this.state.componentName}`,
                isYesNo: true,
                cb: (e) => {
                  let data = "";
                  switch (this.props.optionLabel) {
                    case "company":
                      data = {
                        data: {
                          company: this.state.txtCustDrop.value.toUpperCase(),
                        },
                        id: this.state.selCustDrop.id,
                      };
                      break;

                    default:
                      data = {
                        name: this.state.txtCustDrop.value.toUpperCase(),
                        id: this.state.selCustDrop.id,
                      };
                      break;
                  }

                  new Promise((resolve, reject) => {
                    this.props.dispatch({
                      type: "USER_GET_REQUEST",
                      payload: this.props.editUrl,
                      reqType: "put",
                      data: data,
                      resolve: resolve,
                      reject: reject,
                    });
                  }).then(
                    (onfulfilled) => {
                      this.props.refreshData();
                      this.handleDropCancel();
                      this.props.dispatch({
                        type: "TOAST_ADD",
                        payload: {
                          title: `${this.state.componentName} Edited Successfully`,
                        },
                      });
                      this.props.handler({
                        target: {
                          value: onfulfilled.data.data,
                          name: this.props.name,

                          readOnly: this.props.readOnly,
                        },
                      });
                      //this.props.handler(onfulfilled.data.data);
                    },
                    (onrejected) => {
                      //let errCode = onrejected.response.status;
                      this.handleDropCancel();
                    }
                  );
                },
              },
            });
          }

          break;
        case "edit":
          if (!this.state.selCustDrop.cont) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please make a selection`,
                isYesNo: false,
              },
            });
          } else {
            if (this.state.selCustDrop.cont.isDefault === 1) {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "No Selection",
                  desc: `You cannot edit default ${this.state.componentName}`,
                  isYesNo: false,
                },
              });
            } else {
              this.setState((x) => ({
                selCustDrop: {
                  ...x.selCustDrop,
                  buttonStat: [true, true, false, true, false],
                  reqType: "edit",
                },
                txtCustDrop: {
                  ...x.txtCustDrop,
                  isDisabled: false,
                },
              }));
            }
          }

          break;
        case "add":
          this.setState((x) => ({
            selCustDrop: {
              ...x.selCustDrop,
              buttonStat: [true, true, false, true, false],
              reqType: "add",
            },
            txtCustDrop: {
              ...x.txtCustDrop,
              value: "",
              placeholder: `Enter ${this.state.componentName}`,
              isDisabled: false,
            },
          }));
          break;
        case "openDrop":
          if (this.state.selCustDrop.isDropOpen) {
            //this.handleDropCancel();
            this.setState((x) => ({
              selCustDrop: {
                ...x.selCustDrop,
                isDropOpen: false,
              },
            }));
          } else
            this.setState((x) => ({
              selCustDrop: {
                ...x.selCustDrop,
                isDropOpen: true,
              },
            }));
          break;
        case "cancel":
          this.handleDropCancel();
          break;
        case "delete":
          if (this.state.selCustDrop.cont.isDefault === 1) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `You cannot delete default ${this.state.componentName}`,
                isYesNo: false,
              },
            });
          } else {
            if (!this.state.selCustDrop.cont) {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "No Selection",
                  desc: `Please make a selection`,
                  isYesNo: false,
                },
              });
            } else {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Confirmation",
                  desc: `Remove ${this.state.selCustDrop.name} ${this.state.componentName}? Doing so will delete all ${this.state.componentType} that has this ${this.state.componentName}`,
                  isYesNo: true,
                  cb: (e) => {
                    let data = "";
                    switch (this.props.optionLabel) {
                      case "company":
                        data = {
                          data: { company: this.state.txtCustDrop.value },
                          id: this.state.selCustDrop.id,
                        };
                        break;

                      default:
                        data = {
                          data: { name: this.state.txtCustDrop.value },
                          id: this.state.selCustDrop.id,
                        };
                        break;
                    }

                    new Promise((resolve, reject) => {
                      this.props.dispatch({
                        type: "USER_GET_REQUEST",
                        payload: this.props.deleteUrl,
                        reqType: "delete",
                        data: data,
                        resolve: resolve,
                        reject: reject,
                      });
                    }).then(
                      (onfulfilled) => {
                        this.props.refreshData();

                        this.props.dispatch({
                          type: "TOAST_ADD",
                          payload: {
                            title: `${this.state.componentName} Deleted Successfully`,
                          },
                        });
                        this.handleDropCancel();
                      },
                      (onrejected) => {
                        //let errCode = onrejected.response.status;
                        this.handleDropCancel();
                      }
                    );
                  },
                },
              });
            }
          }

          break;
        default:
          break;
      }
    }
  };

  componentDidMount() {
    //console.log(this.props);

    this.setState((x) => ({
      selCustDrop: {
        ...x.selCustDrop,
        selValue: this.props.data,
      },
    }));

    // if (this.props.selected !== "") {
    //   this.setState((x) => ({
    //     selCustDrop: {
    //       ...x.selCustDrop,
    //       selValue: this.props.selected,
    //     },
    //   }));
    // } else {
    //   //console.log("triggggger");
    //   this.setState((x) => ({
    //     selCustDrop: {
    //       ...x.selCustDrop,
    //       selValue: this.props.data,
    //     },
    //   }));
    // }
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    // if (this.props.data !== prevProps.data) {
    //   this.setState((x) => ({
    //     selCustDrop: {
    //       ...x.selCustDrop,
    //       selValue: this.props.selected,
    //     },
    //   }));
    // }

    if (this.state.selCustDrop.selValue !== prevProps.selected) {
      this.setState((x) => ({
        selCustDrop: {
          ...x.selCustDrop,
          selValue: this.props.selected,
        },
      }));
      this.handleCustDropChange(this.props.selected, { name: "selCustDrop" });
    }
  }

  render() {
    // this.setState(x => ({
    //   selCustDrop: { ...x.selCustDrop, isLoading: false }
    // }));
    let customStyles = {
      menu: (provided, state) => {
        const width = state.selectProps.width;
        //const borderBottom = "1px dotted pink";
        let color = state.selectProps.menuColor;
        const padding = 20;
        //console.log(provided, state);
        // if (state.data.isDefault == 1) {
        //   color = "red";
        // }

        return { ...provided, width, color, padding };
      },
      control: (provided) => ({
        ...provided,
        borderRadius: "5px 0px 0px 5px",
      }),

      container: (_, { selectProps: { width } }) => ({
        width: width,
        //borderRadius: "5px 0px 0px 5px",
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
        borderRadius: "5px 0px 0px 5px",
      }),

      option: (provided, state) => {
        let backgroundColor = "";
        let color = "black";
        if (state.data.isDefault === 1) {
          backgroundColor = "rgba(255, 0, 0, 0.2)";
        }
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition, backgroundColor, color };
      },
    };
    return (
      <div className="mb-3">
        <Form.Label hidden={this.props.lblHidden}>
          <strong>
            <span className="required-field">* </span>
            {this.state.componentType} {this.state.componentName}
          </strong>
        </Form.Label>
        <InputGroup>
          <InputGroup.Prepend style={{ flex: 1 }}>
            <Select
              styles={customStyles}
              isSearchable
              menuPortalTarget={document.body}
              className=""
              name="selCustDrop"
              width="100%"
              tabIndex={this.props.tabIndex}
              value={this.state.selCustDrop.selValue}
              options={this.props.data}
              isLoading={this.props.isLoading}
              isDisabled={this.props.disabled}
              getOptionLabel={(x) => {
                if (!this.props.optionLabel) {
                  return x.name;
                } else {
                  return x.company;
                }
              }}
              getOptionValue={(x) => x.id}
              onChange={(x, name) => {
                //console.log(x, name);
                this.state.selCustDrop.handler(x, name);
                this.props.handler({
                  target: {
                    value: x,
                    name: this.props.name,
                    readOnly: this.props.readOnly,
                  },
                });
                // this.props.handler(x, name, {
                //   target: { name: this.props.name },
                // });
              }}
            />
          </InputGroup.Prepend>
          <Dropdown
            style={{}}
            //show={this.state.selCustDrop.isDropOpen}
            drop="left"
            className="customDropCont"
          >
            <Dropdown.Toggle
              variant="success"
              className="customDropToggle"
              disabled={this.props.disabled}
              id={`dropdown-btn-${this.state.componentName}`}
            >
              <i className="icofont-navigation-menu "></i>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="customDropMenu"
              style={{ width: "100px" }}
            >
              <div id="example-collapse-text">
                <Form.Label>Modify {this.state.componentName} </Form.Label>
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder={this.state.txtCustDrop.placeholder}
                    name="txtCustDrop"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.txtCustDrop.value}
                    onChange={(e) => {
                      this.state.inputChange(e);
                    }}
                    className={this.state.txtCustDrop.className}
                    id="txtCustDrop"
                    disabled={this.state.txtCustDrop.isDisabled}
                  />
                  <InputGroup.Prepend>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Add</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Button
                          onClick={(e) => {
                            this.state.selCustDrop.handler(e);
                          }}
                          name="add"
                          //disabled={props.state.selCustDrop.buttonStat[0]}
                          hidden={this.state.selCustDrop.buttonStat[0]}
                          className="btnCustAdd"
                          style={{
                            borderRadius: 0,
                            //pointerEvents: `${props.state.selCustDrop.style[0]}`
                          }}
                        >
                          <i className="icofont-ui-add "></i>
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Button
                          onClick={(e) => {
                            this.state.selCustDrop.handler(e);
                          }}
                          name="edit"
                          className="btnCustEdit"
                          variant="info"
                          //disabled={props.state.selCustDrop.buttonStat[1]}
                          hidden={this.state.selCustDrop.buttonStat[1]}
                          style={{
                            borderRadius: 0,
                            //pointerEvents: `${props.state.selCustDrop.style[1]}`
                          }}
                        >
                          <i className="icofont-ui-edit "></i>
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Save</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Button
                          onClick={(e) => {
                            this.state.selCustDrop.handler(e);
                          }}
                          name="save"
                          variant="success"
                          className="btnCustSave"
                          //disabled={props.state.selCustDrop.buttonStat[2]}
                          hidden={this.state.selCustDrop.buttonStat[2]}
                          style={{
                            borderRadius: 0,
                            //pointerEvents: `${props.state.selCustDrop.style[2]}`
                          }}
                        >
                          <i className="icofont-save "></i>
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="danger"
                          onClick={(e) => {
                            this.state.selCustDrop.handler(e);
                          }}
                          name="delete"
                          //disabled={props.state.selCustDrop.buttonStat[3]}
                          hidden={this.state.selCustDrop.buttonStat[3]}
                          className="btnCustDelete"
                          style={{
                            borderRadius: 0,
                            //pointerEvents: `${props.state.selCustDrop.style[3]}`
                          }}
                        >
                          <i className="icofont-ui-remove "></i>
                        </Button>
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Cancel</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Button
                          variant="warning"
                          className="btnCustCancel"
                          onClick={(e) => {
                            this.state.selCustDrop.handler(e);
                          }}
                          name="cancel"
                          //disabled={props.state.selCustDrop.buttonStat[4]}
                          style={{
                            borderRadius: 0,
                            // pointerEvents: `${props.state.selCustDrop.style[4]}`
                          }}
                          hidden={this.state.selCustDrop.buttonStat[4]}
                        >
                          <i className="icofont-close "></i>
                        </Button>
                      </span>
                    </OverlayTrigger>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    inventory: state,
  };
};
export default connect(mapStateToProps)(CustomDrop);
