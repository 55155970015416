import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";

import Spinner from "react-bootstrap/Spinner";
import "../../css/loading.css";

class ScreenLoadingProcess extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    //console.log(this.props.loading.notifData);
    return (
      <>
        {this.props.loading.notifData.length > 0 ? (
          <div className="dataLoading">
            <div className="spinnercont">
              <Row className="d-flex justify-content-center mb-2">
                <Spinner animation="grow" size="lg" role="status"></Spinner>
              </Row>
              <h1 className="text-center">Data is loading</h1>
              {(() => {
                if (this.props.loading.notifData.length > 0) {
                  return (
                    <h2 className="text-center">
                      {this.props.loading.notifData[0].name}
                    </h2>
                  );
                }
              })()}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.getData,
  };
};
export default connect(mapStateToProps)(withRouter(ScreenLoadingProcess));
