import React from "react";
import {
  Dashboard,
  materialColumn,
  orderColumn,
  projectColumn,
  phaseColumn,
  historyColumn,
  poHistoryColumn,
  poSupplierColumn,
  topMaterialColumn,
  topItemColumn,
  SupplierItemColumn,
} from "./Dashboard";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import Table from "react-table";
import Filters from "../../functions/filters";
import RTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import moment from "moment";
const SelectTable = selectTableHOC(RTable);

class DashboardProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputChange: this.inputChange.bind(this),
      handleSearch: this.handleSearch.bind(this),
      handleMatFilterChange: this.handleMatFilterChange.bind(this),
      handleMaterialSearch: this.handleMaterialSearch.bind(this),
      dateChange: this.dateChange.bind(this),
      handleProjFilterChange: this.handleProjFilterChange.bind(this),
      handleProjectChange: this.handleProjectChange.bind(this),
      handlePhaseChange: this.handlePhaseChange.bind(this),
      downloadPDF: this.downloadPDF.bind(this),
      handleGeneratePOHistory: this.handleGeneratePOHistory.bind(this),
      handleTabChange: this.handleTabChange.bind(this),
      readProjectBOQ: this.readProjectBOQ.bind(this),
      handleCheckFilterChange: this.handleCheckFilterChange.bind(this),
      projectData: [],
      materialData: [],
      bakMaterialData: [],
      multiMaterialData: [],
      phaseData: [],
      historyData: [],
      POData: [],
      poHistSelection: [],
      projectBOQData: [],
      supplierData: [],
      topMaterialData: [],
      topItemData: [],
      supplierItems: [],
      user: null,
      selectedMaterial: false,
      selectedProject: false,
      selectedPhase: false,
      selectedOrder: false,
      selectedPO: false,
      selectedSupplier: false,
      isMaterialLoading: false,
      isOrderLoading: false,
      isProjectLoading: false,
      isPhaseLoading: false,
      isHistoryLoading: false,
      isPOLoading: false,
      isSupplierLoading: false,
      isTopMaterialLoading: false,
      isTopItemLoading: false,
      isSupplierItemsLoading: false,
      poSignature: {},
      isFiltered: false,
      searchInput: "",
      txtDateStart: {
        value: moment(new Date(2019, 12), "YYYY/MM/DD").format("YYYY-MM-DD"),

        className: "",
        readOnly: true,
      },
      txtDateEnd: {
        value: moment(new Date(), "YYYY/MM/DD").format("YYYY-MM-DD"),

        className: "",
        readOnly: true,
      },
      selProjFilter: {
        value: 0,
        readOnly: false,
      },
      selProj: {
        value: "",
        readOnly: false,
        isDisabled: false,
        isLoading: false,
      },
      selPhase: {
        value: "",
        readOnly: false,
        isDisabled: false,
        isLoading: false,
      },
      chkDateFilter: {
        value: false,
        readOnly: false,
        isDisabled: false,
      },
      selPdfGenerate: {
        value: 1,
        readOnly: false,
      },
      selMatFilter: {
        value: 0,
        readOnly: false,
      },
      handleMultiMaterialModal: {
        toggle: this.multiMatToggleModal.bind(this),
        isShow: false,
      },

      handleProjectModal: {
        toggle: this.projectToggleModal.bind(this),
        isShow: false,
      },
      handleMaterialModal: {
        toggle: this.materialToggleModal.bind(this),
        isShow: false,
      },
      handlePOModal: {
        toggle: this.POToggleModal.bind(this),
        isShow: false,
      },
      handleSelMultMatModal: {
        toggle: this.selMultMatToggleModal.bind(this),
        isShow: false,
      },
      handleSupplierItemsModal: {
        toggle: this.supplierItemsToggleModal.bind(this),
        isShow: false,
      },
    };
  }

  componentDidMount() {
    this.readProject(0, null, null, 1);
    if (this.props.state) {
      this.setState((x) => ({
        user: this.props.state.user,
      }));
    } else {
      this.setState((x) => ({
        user: this.props.location.user,
      }));
    }

    //console.log(this.props);
  }

  handleTabChange = (e) => {
    switch (e) {
      case "suppliers":
        this.readSuppliers();
        break;
      case "materials":
        this.readTopMaterials();
        this.readTopItems();
        break;
      default:
        break;
    }
  };

  handleCheckFilterChange = (e) => {
    e.persist();
    //console.log(e.currentTarget.value);

    this.setState({
      [e.target.name]: {
        value: e.target.checked ? true : false,
        readOnly: false,
      },
    });
    if (e.target.checked) {
      const dateStart = this.state.txtDateStart.value;
      const dateEnd = this.state.txtDateEnd.value;

      this.setState(
        (x) => ({
          txtDateStart: { ...x.txtDateStart, readOnly: false },
          txtDateEnd: { ...x.txtDateEnd, readOnly: false },
        }),
        (x) => {
          this.readProject(
            this.state.selProjFilter.value,
            dateStart,
            dateEnd,
            0
          );
        }
      );
    } else {
      this.setState(
        (x) => ({
          txtDateStart: { ...x.txtDateStart, readOnly: true },
          txtDateEnd: { ...x.txtDateEnd, readOnly: true },
        }),
        (x) => {
          this.readProject(this.state.selProjFilter.value, null, null, 1);
        }
      );
    }
  };

  componentWillUnmount() {}

  handleMatFilterChange = async (e) => {
    let filteredMatData = [];

    switch (Number(e)) {
      case 0:
        this.setState((x) => ({
          materialData: this.state.bakMaterialData,
        }));
        break;
      case 1:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) => Number(x.purchased) < Number(x.requiredQty)
        );

        this.setState((x) => ({
          materialData: filteredMatData,
        }));
        break;
      case 2:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) =>
            Number(x.requiredQty) === Number(x.purchased) &&
            Number(x.warn) === 0
        );
        this.setState((x) => ({
          materialData: filteredMatData,
        }));

        break;
      case 3:
        filteredMatData = await this.state.bakMaterialData.filter(
          (x) => Number(x.warn) > 0
        );

        this.setState((x) => ({
          materialData: filteredMatData,
        }));
        break;
      default:
        break;
    }
  };

  handleMaterialSearch = (event) => {
    //console.log(this.state.bakMaterialData);
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.bakMaterialData,
        event.target.value
      );

      if (data != null) {
        this.setState({ materialData: data });
      } else {
        this.setState({ materialData: this.state.bakMaterialData });
      }
    } else this.setState({ isFiltered: false });
  };

  handleGeneratePOHistory = (cb) => {
    if (Number(this.state.poHistSelection.length) > 0) {
      const selected = this.state.selectedPO;
      //console.log(selected);
      if (Number(selected.status) === 9) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "PO Archived",
            desc: `Cant regenerate archived POs`,
            isYesNo: false,
          },
        });
      } else {
        if (selected.generatedBy.uploads.length > 0) {
          cb();
        } else {
          this.setState(
            (x) => ({
              poSignature: null,
            }),
            (x) => {
              cb();
            }
          );
        }
      }
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select PO`,
          isYesNo: false,
        },
      });
    }
  };
  downloadPDF = async (blobData) => {
    const fileName =
      "PROJECT#-" + this.state.selectedProject.uniqueId + "-SUMMARY";
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();
  };
  getHistPO = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Historical PO", id: loadId },
    });
    this.setState((x) => ({
      isPOLoading: true,
    }));
    let url = `inventory/getPurchaseOrderByProjectId?projectId=${this.state.selectedProject.id}&status=0`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const poHistData = api.data;
      //console.log("HISTORY PO:", poHistData);
      this.setState((prevState) => ({
        POData: poHistData,

        isPOLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };
  readProjectBOQ = (cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Fetching Project Summary",
        id: loadId,
      },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `project/getProjectMaterials?id=${this.state.selectedProject.id}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const boqData = api.data;
      //console.log("OVERALL PROJ MATERIALS: ", boqData);
      this.setState((prevState) => ({
        projectBOQData: boqData,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
    });
  };
  POToggleModal = (e) => {
    if (this.state.handlePOModal.isShow) {
      this.setState((x) => ({
        handlePOModal: {
          ...x.handlePOModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handlePOModal: {
          ...x.handlePOModal,
          isShow: true,
        },
      }));
    }
  };
  selMultMatToggleModal = (e) => {
    if (this.state.handleSelMultMatModal.isShow) {
      this.setState((x) => ({
        handleSelMultMatModal: {
          ...x.handleSelMultMatModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handleSelMultMatModal: {
          ...x.handleSelMultMatModal,
          isShow: true,
        },
      }));
    }
  };
  supplierItemsToggleModal = (e) => {
    if (this.state.handleSupplierItemsModal.isShow) {
      this.setState((x) => ({
        handleSupplierItemsModal: {
          ...x.handleSupplierItemsModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handleSupplierItemsModal: {
          ...x.handleSupplierItemsModal,
          isShow: true,
        },
      }));
    }
  };
  multiMatToggleModal = (e) => {
    if (this.state.handleMultiMaterialModal.isShow) {
      this.setState((x) => ({
        handleMultiMaterialModal: {
          ...x.handleMultiMaterialModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handleMultiMaterialModal: {
          ...x.handleMultiMaterialModal,
          isShow: true,
        },
      }));
    }
  };
  projectToggleModal = (e) => {
    if (this.state.handleProjectModal.isShow) {
      this.setState((x) => ({
        handleProjectModal: {
          ...x.handleProjectModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handleProjectModal: {
          ...x.handleProjectModal,
          isShow: true,
        },
      }));
    }
  };
  materialToggleModal = (e) => {
    if (this.state.handleMaterialModal.isShow) {
      this.setState((x) => ({
        handleMaterialModal: {
          ...x.handleMaterialModal,
          isShow: false,
        },
      }));
    } else {
      this.setState((x) => ({
        handleMaterialModal: {
          ...x.handleMaterialModal,
          isShow: true,
        },
      }));
    }
  };

  handleMultiMaterialShowModal = (e) => {
    this.setState((x) => ({
      handleMultiMaterialModal: {
        ...x.handleMultiMaterialModal,
        isShow: true,
      },
    }));
  };
  handleMultiMaterialHideModal = (e) => {
    this.setState((x) => ({
      handleMultiMaterialModal: {
        ...x.handleMultiMaterialModal,
        isShow: false,
      },
    }));
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };
  dateChange = (e) => {
    this.setState(
      {
        [e.target.name]: {
          value: e.target.value,
          placeholder: e.target.placeholder,
          className: e.target.className,
        },
      },
      (x) => {
        this.readProject(
          this.state.selProjFilter.value,
          this.state.txtDateStart.value,
          this.state.txtDateEnd.value,
          0
        );
      }
    );
  };

  handlePhaseChange = async (e) => {
    if (e) {
      this.setState((prevState) => ({
        materialData: e.materials,
      }));
    }
  };

  handleProjectChange = (e) => {
    if (e) {
      this.setState((prevState) => ({
        phaseData: e.phases,
        selPhase: {
          value: "",
          readOnly: false,
          isDisabled: false,
          isLoading: false,
        },
      }));
    }
  };

  handleProjFilterChange = (e) => {
    const dateStart = this.state.txtDateStart.value;
    const dateEnd = this.state.txtDateEnd.value;
    if (dateStart && dateEnd && this.state.chkDateFilter.value) {
      this.readProject(e.target.value, dateStart, dateEnd, 0);
    } else {
      this.readProject(e.target.value, null, null, 1);
    }

    this.setState((prevState) => ({
      phaseData: [],
      selProj: {
        value: "",
        readOnly: false,
        isDisabled: false,
        isLoading: false,
      },
      selPhase: {
        value: "",
        readOnly: false,
        isDisabled: false,
        isLoading: false,
      },
    }));
  };

  readSuppliers = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Suppliers", id: loadId },
    });
    let url = `global/getTop10Suppliers`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("TOP SUPPLIERS:", api.data);

      this.setState((prevState) => ({
        supplierData: projData,
        isSupplierLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  readTopMaterials = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Top Materials", id: loadId },
    });
    let url = `global/getTop10MostOrderedItem`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("TOP MATERIALS:", api.data);

      this.setState((prevState) => ({
        topMaterialData: projData,
        isTopMaterialLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  readSupplierItems = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Supplier Items", id: loadId },
    });
    let url = `inventory/getSupplierItems?supplierId=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const supData = api.data;
      //console.log("SUPPLIER ITEMS:", api.data);

      this.setState(
        (prevState) => ({
          supplierItems: supData,
          isSupplierItemsLoading: false,
        }),
        (x) => {
          cb();
        }
      );
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  readTopItems = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Top Items", id: loadId },
    });
    let url = `inventory/getTop10Items`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("TOP ITEMS:", api.data);

      this.setState((prevState) => ({
        topItemData: projData,
        isTopItemLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  readSelectedproject = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Project", id: loadId },
    });
    let url = `project/getProject?id=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("SELECTED PROJECT:", api.data);

      this.setState((prevState) => ({
        selectedProject: projData[0],
        phaseData: projData[0].phases,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
      //console.log(this.props);
    });
  };

  readProject = (e, start, end, noDate) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Projects", id: loadId },
    });
    let url = `project/getProjectByDate?status=${e}&from=${start}&to=${end}&noDate=${noDate}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("PROJECTS:", api.data);

      this.props.dispatch({
        type: "API_STORE_PROJECT",
        payload: {
          data: projData,
          selected: this.state.projectSelection,
        },
      });
      this.props.dispatch({
        type: "API_STORE_PROJECT_BAK",
        payload: {
          data: projData,
        },
      });

      this.setState((prevState) => ({
        projectData: projData,
        isMaterialLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.props.bakProjData,
        event.target.value
      );

      if (data != null) {
        this.props.dispatch({
          type: "API_STORE_PROJECT",
          payload: {
            data: data,
          },
        });
        //this.setState({ projData: data });
      } else {
        this.props.dispatch({
          type: "API_STORE_PROJECT",
          payload: {
            data: this.props.state.bakProjData,
          },
        });
        //this.setState({ projData: this.state.bakProjData });
      }
    } else this.setState({ isFiltered: false });
  };

  materialRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED MATERIAL: ", rowInfo.original);
        this.setState((prevState) => ({
          selectedMaterial: rowInfo.original,
          multiMaterialData: rowInfo.original.multMaterials,
        }));
        this.handleMultiMaterialShowModal();
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });

  projectRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED PROJECT ===> ", rowInfo.original);

        this.readSelectedproject(rowInfo.original.id, (x) => {
          this.getHistPO();
          this.projectToggleModal();
          // this.setState(
          //   (prevState) => ({
          //     selectedProject: rowInfo.original,

          //   }),
          //   (x) => {

          //   }
          // );
        });
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });
  readItemHistoryPurchases = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Item History", id: loadId },
    });
    this.setState((prevState) => ({
      isHistoryLoading: true,
    }));
    let url = `inventory/getItemHistoryPrices?materialId=${this.state.selectedMaterial.id}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const API_DATA = api.data;
      this.setState((prevState) => ({
        historyData: API_DATA,
        isHistoryLoading: false,
      }));

      //console.log("ORDER HISTORY", API_DATA);
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };
  phaseRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED PHASE ===> ", rowInfo.original);
        this.setState(
          (prevState) => ({
            materialData: rowInfo.original.materials,
            bakMaterialData: rowInfo.original.materials,
            selectedPhase: rowInfo.original,
          }),
          (x) => {
            this.materialToggleModal();
          }
        );
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });
  supplierRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED SUPPLIER: ", rowInfo.original);
        this.readSupplierItems(rowInfo.original.supplier.id, (x) => {
          this.setState(
            (prevState) => ({
              selectedSupplier: rowInfo.original.supplier,
            }),
            (x) => {}
          );
          this.supplierItemsToggleModal();
        });
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });
  PORowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED PO ===> ".rowInfo.original);
        this.setState(
          (prevState) => ({
            // materialData: rowInfo.original.materials,
            // selectedPhase: rowInfo.original,
          }),
          (x) => {
            //this.materialToggleModal();
          }
        );
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });
  orderRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        console.log("SELECTED ORDER ===> ", rowInfo.original);
        this.setState(
          (prevState) => ({
            selectedOrder: rowInfo.original,
          }),
          (x) => {
            this.readItemHistoryPurchases();
            this.selMultMatToggleModal();
          }
        );
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
  });

  histRowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
        if (
          this.state.poHistSelection.length > 0 &&
          this.state.poHistSelection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            poHistSelection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            //this.handleActionChange({ target: { name: "btnEditActor" } });

            this.setState({
              poHistSelection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }
      if (handleOriginal) {
        handleOriginal();
      }
      //console.log(rowInfo.original.id);
    },
    style: {
      background:
        rowInfo &&
        this.state.poHistSelection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });
  isSelected = (key) => {
    return this.state.poHistSelection.includes(`select-${key}`);
  };
  toggleSelection = (key, shift, row) => {
    let poHistSelection = this.state.poHistSelection;
    const keyIndex = poHistSelection.indexOf(key);

    if (keyIndex >= 0) {
      poHistSelection = [];
      this.setState((x) => ({
        selectedPO: null,
      }));
    } else {
      poHistSelection.length = 0;
      poHistSelection.push(key);
      //console.log(row);
      this.setState((x) => ({
        selectedPO: row,
      }));
    }

    this.setState((x) => ({
      poHistSelection,
    }));
  };

  render() {
    //console.log(this.props);
    let tableMaterials = (
      <Table
        data={this.state.materialData}
        columns={materialColumn}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isMaterialLoading}
        getTrProps={this.materialRowFn}
        defaultPageSize={20}
        className="tableMaterials -highlight"
      />
    );
    let tableOrders = (
      <Table
        data={this.state.multiMaterialData}
        columns={orderColumn(this.state.selectedMaterial)}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isOrderLoading}
        getTrProps={this.orderRowFn}
        defaultPageSize={20}
        className="tableOrders -highlight"
      />
    );
    let tableProjects = (
      <Table
        data={this.props.project}
        columns={projectColumn}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isProjectLoading}
        getTrProps={this.projectRowFn}
        defaultPageSize={20}
        className="tableProjects -highlight"
      />
    );
    let tablePhase = (
      <Table
        data={this.state.phaseData}
        columns={phaseColumn}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isPhaseLoading}
        getTrProps={this.phaseRowFn}
        defaultPageSize={20}
        className="tablePhase -highlight"
      />
    );
    let tableSupplier = (
      <Table
        data={this.state.supplierData}
        columns={poSupplierColumn()}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isSupplierLoading}
        getTrProps={this.supplierRowFn}
        defaultPageSize={10}
        className="tableSupplier -highlight"
      />
    );
    let tableSupplierItems = (
      <Table
        data={this.state.supplierItems}
        columns={SupplierItemColumn}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isSupplierItemsLoading}
        //getTrProps={this.supplierRowFn}
        defaultPageSize={5}
        className="tableSupplierItems -highlight"
      />
    );
    let tableTopMaterials = (
      <Table
        data={this.state.topMaterialData}
        columns={topMaterialColumn()}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isTopMaterialLoading}
        //getTrProps={this.supplierRowFn}
        defaultPageSize={10}
        className="tableTopMaterials -highlight"
      />
    );
    let tableTopItems = (
      <Table
        data={this.state.topItemData}
        columns={topItemColumn()}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isTopItemLoading}
        //getTrProps={this.supplierRowFn}
        defaultPageSize={10}
        className="tableTopItems -highlight"
      />
    );
    let tableOrderHistory = (
      <Table
        data={this.state.historyData}
        columns={historyColumn(this.state.selectedMaterial)}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isHistoryLoading}
        //getTrProps={this.phaseRowFn}
        defaultPageSize={20}
        className="tableOrderHistory -highlight"
      />
    );
    let tablePOHistory = (
      <SelectTable
        data={this.state.POData}
        columns={poHistoryColumn()}
        keyField="id"
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        loading={this.state.poHistisLoading}
        selectType="checkbox"
        isSelected={this.isSelected}
        getTrProps={this.histRowFn}
        defaultPageSize={15}
        className="tablePOHistory -highlight"
      />
    );

    let dashboard = (
      <Dashboard
        inputChange={this.inputChange}
        state={this.state}
        tableMaterials={tableMaterials}
        tableOrders={tableOrders}
        tableProjects={tableProjects}
        tablePhase={tablePhase}
        tableOrderHistory={tableOrderHistory}
        tablePOHistory={tablePOHistory}
        tableSupplier={tableSupplier}
        tableTopMaterials={tableTopMaterials}
        tableTopItems={tableTopItems}
        tableSupplierItems={tableSupplierItems}
        dispatch={this.props.dispatch}
      />
    );

    return <>{dashboard}</>;
  }
}
const mapStateToProps = (state) => {
  return {
    project: state.apiData.projectData,
    bakProjData: state.apiData.bakProjectData,
  };
};
export default connect(mapStateToProps)(withRouter(DashboardProcess));
