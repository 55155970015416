import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
//import Card from "react-bootstrap/Card";
import moment from "moment";
//import Select from "react-select";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
//import { PieChart, Pie, Sector, Cell } from "recharts";
import DBStatus from "../../functions/DBStatus";
import { NumericFormat } from "react-number-format";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import JVLogo from "../../assets/jv-logo.png";
import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import misc from "../../functions/misc";
import DashPOProcess from "../DashPO/DashPOProcess";

import "../../css/dashboard.css";

import {PhasePDF, BOQPDF} from '../Phase/Phase'

import {POPDF} from '../PO/PO'

import {
  Canvas,
  BlobProvider,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: `/Roboto-Regular.ttf`,
    },
    {
      src: `/Roboto-Bold.ttf`,
      fontWeight: "bold",
    },
    {
      src: `/Roboto-Italic.ttf`,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: `/Roboto-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const tableStyles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 12,
    marginLeft: 2,
    marginRight: 2,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
// let PhasePDF = (props) => (
//   <Document>
//     <Page
//       size="A4"
//       style={[
//         {
//           //fontFamily: "Roboto",
//           paddingTop: 35,
//           paddingBottom: 65,
//           paddingHorizontal: 20,
//         },
//       ]}
//     >
//       <View
//         style={[
//           {
//             marginTop: 20,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 150, width: 80 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 5,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 20,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>PROJECT SUMMARY</Text>
//             <Text style={[{ textAlign: "left" }]}>{props.data.name}</Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date: {moment().format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>
//         <View style={[{ flex: 1, width: "100%" }]}>
//           <Text
//             style={[
//               {
//                 textAlign: "left",
//                 fontSize: 15,
//                 marginTop: 5,
//                 marginBottom: 5,
//                 paddingLeft: 80,
//                 paddingRight: 80,
//               },
//             ]}
//           >
//             GENERATED BY: {props.state.user.name}
//           </Text>
//         </View>
//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//       </View>

//       <View
//         style={[
//           {
//             height: 550,
//             paddingLeft: 100,
//             paddingRight: 100,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flexDirection: "row",
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text>Status:</Text>
//             <Text>Jobsite:</Text>
//             <Text>Completion:</Text>
//             <Text>Date Started:</Text>
//             <Text>Deadline:</Text>
//             <Text>Completion Date:</Text>
//             <Text>Total Budget:</Text>
//             <Text>Budget Used:</Text>
//             <Text>
//               {(() => {
//                 if (
//                   Number(
//                     props.data.finance.totalBudget - props.data.finance.used
//                   ) > 0
//                 ) {
//                   return "Budget Left";
//                 } else {
//                   return "Over Budget";
//                 }
//               })()}
//             </Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text>{DBStatus.parseProjectStatus(props.data.status)}</Text>
//             <Text>{props.data.address}</Text>
//             <Text>{props.data.percentage}</Text>
//             <Text>
//               {((x) => {
//                 if (
//                   props.data.dateStart === null ||
//                   props.data.dateStart === ""
//                 ) {
//                   return "Empty";
//                 } else {
//                   return moment(props.data.dateStart, "YYYY-MM-DD").format(
//                     "LL"
//                   );
//                 }
//               })()}
//             </Text>
//             <Text>
//               {((x) => {
//                 if (
//                   props.data.dateEstEnd === null ||
//                   props.data.dateEstEnd === ""
//                 ) {
//                   return "Empty";
//                 } else {
//                   return moment(props.data.dateEstEnd, "YYYY-MM-DD").format(
//                     "LL"
//                   );
//                 }
//               })()}
//             </Text>
//             <Text>
//               {((x) => {
//                 if (props.data.dateEnd === null || props.data.dateEnd === "") {
//                   return "Empty";
//                 } else {
//                   return moment(props.data.dateEnd, "YYYY-MM-DD").format("LL");
//                 }
//               })()}
//             </Text>
//             <Text>
//               {((e) => {
//                 return (
//                   new Intl.NumberFormat("en-US").format(
//                     props.data.finance.totalBudget
//                   ) + " PHP"
//                 );
//               })()}
//             </Text>
//             <Text>
//               {((e) => {
//                 return (
//                   new Intl.NumberFormat("en-US").format(
//                     props.data.finance.used
//                   ) + " PHP"
//                 );
//               })()}
//             </Text>
//             <Text>
//               {((e) => {
//                 return (
//                   new Intl.NumberFormat("en-US").format(
//                     props.data.finance.totalBudget - props.data.finance.used
//                   ) + " PHP"
//                 );
//               })()}
//             </Text>
//           </View>
//         </View>
//         <View>
//           <Text
//             style={[{ textAlign: "left", marginTop: 20, marginBottom: 10 }]}
//           >
//             ASSIGNED TEAM
//           </Text>
//           <View
//             style={[
//               {
//                 flexDirection: "row",
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View style={[{ flex: 1, width: "50%" }]}>
//               <Text>Project Manager:</Text>
//             </View>
//             <View style={[{ flex: 1, width: "50%" }]}>
//               {props.data.projectActors[7].actors.map((i, index) => (
//                 <Text key={index}>{i.name}</Text>
//               ))}
//             </View>
//           </View>
//           <View
//             style={[
//               {
//                 flexDirection: "row",
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View style={[{ flex: 1, width: "50%" }]}>
//               <Text>Site Manager:</Text>
//             </View>
//             <View style={[{ flex: 1, width: "50%" }]}>
//               {props.data.projectActors[6].actors.map((i, index) => (
//                 <Text key={index}>{i.name}</Text>
//               ))}
//             </View>
//           </View>
//           <View
//             style={[
//               {
//                 flexDirection: "row",
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View style={[{ flex: 1, width: "50%" }]}>
//               <Text>Requester:</Text>
//             </View>
//             <View style={[{ flex: 1, width: "50%" }]}>
//               {props.data.projectActors[8].actors.map((i, index) => (
//                 <Text key={index}>{i.name}</Text>
//               ))}
//             </View>
//           </View>
//           <View
//             style={[
//               {
//                 flexDirection: "row",
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View style={[{ flex: 1, width: "50%" }]}>
//               <Text>Purchaser:</Text>
//             </View>
//             <View style={[{ flex: 1, width: "50%" }]}>
//               {props.data.projectActors[5].actors.map((i, index) => (
//                 <Text key={index}>{i.name}</Text>
//               ))}
//             </View>
//           </View>
//         </View>
//       </View>
//       <View>
//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("#45A545")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//         <View
//           style={[
//             {
//               textAlign: "center",
//               color: "black",
//               width: "100%",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 textAlign: "left",
//                 marginBottom: 5,
//                 fontWeight: 1,
//                 fontSize: 12,
//               },
//             ]}
//           >
//             JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo City
//           </Text>
//         </View>
//       </View>
//     </Page>
//     {props.data.phases.map((i, index) => (
//       <>
//         <Page
//           size="A4"
//           style={[
//             {
//               //fontFamily: "Roboto",
//               paddingTop: 35,
//               paddingBottom: 65,
//               paddingHorizontal: 20,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 20,
//               },
//             ]}
//           >
//             <Image
//               style={[{ marginHorizontal: 150, width: 80 }]}
//               src={JVLogo}
//             />
//           </View>
//           <View
//             style={[
//               {
//                 textAlign: "center",
//                 color: "black",
//                 width: "100%",
//                 marginTop: 5,
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View
//               style={[
//                 {
//                   flex: 1,
//                   flexDirection: "row",
//                   paddingLeft: 80,
//                   paddingRight: 80,
//                   marginTop: 20,
//                 },
//               ]}
//             >
//               <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//                 <Text style={[{ textAlign: "left" }]}>PHASE SUMMARY</Text>
//                 <Text style={[{ textAlign: "left" }]}>{i.name}</Text>
//               </View>

//               <View style={[{ flex: 1, width: "50%" }]}>
//                 <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//                   Date: {moment().format("MMMM Do YYYY")}
//                 </Text>
//               </View>
//             </View>

//             <Canvas
//               style={[
//                 {
//                   width: 600,
//                   height: 3,
//                   position: "center",
//                 },
//               ]}
//               paint={(painter) => {
//                 painter
//                   .strokeColor("black")
//                   .lineWidth(3)
//                   .moveTo(50, 0)
//                   .lineTo(540, 0)
//                   .stroke();
//               }}
//             />
//           </View>

//           <View
//             style={[
//               {
//                 height: 580,
//                 paddingLeft: 80,
//                 paddingRight: 80,
//               },
//             ]}
//           >
//             <View
//               style={[
//                 {
//                   flexDirection: "row",
//                 },
//               ]}
//             >
//               <View style={[{ flex: 1, width: "50%" }]}>
//                 <Text>Date Started:</Text>
//                 <Text>Deadline:</Text>
//                 <Text>Completion Date:</Text>
//                 <Text>Total Budget:</Text>
//                 <Text>Budget Used:</Text>
//                 <Text>
//                   {(() => {
//                     if (Number(i.totalBudget - i.used) > 0) {
//                       return "Budget Left";
//                     } else {
//                       return "Over Budget";
//                     }
//                   })()}
//                 </Text>
//               </View>
//               <View style={[{ flex: 1, width: "50%" }]}>
//                 <Text>
//                   {((x) => {
//                     if (i.dateStart === null || i.dateStart === "") {
//                       return "Empty";
//                     } else {
//                       return moment(i.dateStart, "YYYY-MM-DD").format("LL");
//                     }
//                   })()}
//                 </Text>
//                 <Text>
//                   {((x) => {
//                     if (i.dateEstEnd === null || i.dateEstEnd === "") {
//                       return "Empty";
//                     } else {
//                       return moment(i.dateEstEnd, "YYYY-MM-DD").format("LL");
//                     }
//                   })()}
//                 </Text>
//                 <Text>
//                   {((x) => {
//                     if (i.dateEnd === null || i.dateEnd === "") {
//                       return "Empty";
//                     } else {
//                       return moment(i.dateEnd, "YYYY-MM-DD").format("LL");
//                     }
//                   })()}
//                 </Text>
//                 <Text>
//                   {((e) => {
//                     return (
//                       new Intl.NumberFormat("en-US").format(
//                         i.finance.totalBudget
//                       ) + " PHP"
//                     );
//                   })()}
//                 </Text>
//                 <Text>
//                   {((e) => {
//                     return (
//                       new Intl.NumberFormat("en-US").format(i.finance.used) +
//                       " PHP"
//                     );
//                   })()}
//                 </Text>
//                 <Text>
//                   {((e) => {
//                     return (
//                       new Intl.NumberFormat("en-US").format(
//                         Math.abs(i.finance.totalBudget - i.finance.used)
//                       ) + " PHP"
//                     );
//                   })()}
//                 </Text>
//               </View>
//             </View>
//           </View>
//           <View>
//             <Canvas
//               style={[
//                 {
//                   width: 600,
//                   height: 3,
//                 },
//               ]}
//               paint={(painter) => {
//                 painter
//                   .strokeColor("#45A545")
//                   .lineWidth(3)
//                   .moveTo(50, 0)
//                   .lineTo(540, 0)
//                   .stroke();
//               }}
//             />
//             <View
//               style={[
//                 {
//                   textAlign: "center",
//                   color: "black",
//                   width: "100%",
//                   paddingLeft: 80,
//                   paddingRight: 80,
//                   marginTop: 10,
//                 },
//               ]}
//             >
//               <Text
//                 style={[
//                   {
//                     textAlign: "left",
//                     marginBottom: 5,
//                     fontWeight: 1,
//                     fontSize: 12,
//                   },
//                 ]}
//               >
//                 JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo
//                 City
//               </Text>

//               <Text
//                 style={[
//                   {
//                     color: "#45A545",
//                     textAlign: "left",
//                     fontSize: 12,
//                     fontWeight: 700,
//                   },
//                 ]}
//               >
//                 TEL:
//                 <Text
//                   style={[{ color: "black", textAlign: "left", fontSize: 12 }]}
//                 >
//                   (033) 320 2392
//                 </Text>
//               </Text>
//             </View>
//           </View>
//         </Page>
//         <Page
//           size="A4"
//           orientation="landscape"
//           style={[
//             {
//               //fontFamily: "Roboto",
//               paddingTop: 35,
//               marginBottom: 10,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 15,
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <Image
//               style={[{ marginHorizontal: 300, width: 80 }]}
//               src={JVLogo}
//             />
//           </View>
//           <View
//             style={[
//               {
//                 textAlign: "center",
//                 color: "black",
//                 width: "100%",
//                 marginTop: 0,
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             <View
//               style={[
//                 {
//                   flex: 1,
//                   flexDirection: "row",
//                   paddingLeft: 80,
//                   paddingRight: 80,
//                   marginTop: 5,
//                 },
//               ]}
//             >
//               <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//                 <Text style={[{ textAlign: "left" }]}>PHASE MATERIALS</Text>
//               </View>
//               <View style={[{ flex: 1, width: "50%" }]}>
//                 <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//                   Date Generated: {moment().format("MMMM Do YYYY")}
//                 </Text>
//               </View>
//             </View>

//             <Canvas
//               style={[
//                 {
//                   width: 840,
//                   height: 3,
//                   position: "center",
//                 },
//               ]}
//               paint={(painter) => {
//                 painter
//                   .strokeColor("black")
//                   .lineWidth(3)
//                   .moveTo(60, 0)
//                   .lineTo(760, 0)
//                   .stroke();
//               }}
//             />
//           </View>

//           <View
//             style={[
//               {
//                 marginTop: 5,
//                 paddingLeft: 40,
//                 paddingRight: 40,
//               },
//             ]}
//           >
//             <Table data={i.materials}>
//               <TableHeader>
//                 <TableCell weighting={0.25} style={[tableStyles.tableHeader]}>
//                   MATERIAL NAME
//                 </TableCell>
//                 <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//                   USE
//                 </TableCell>
//                 <TableCell
//                   weighting={0.2}
//                   style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
//                 >
//                   QTY PURCHASED / REQUIRED
//                 </TableCell>

//                 <TableCell
//                   weighting={0.1}
//                   style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
//                 >
//                   BASE UNIT PRICE
//                 </TableCell>
//                 <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//                   BASE TOTAL
//                 </TableCell>
//                 <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//                   ACTUAL TOTAL
//                 </TableCell>
//               </TableHeader>
//               <TableBody>
//                 <DataTableCell
//                   style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//                   weighting={0.25}
//                   getContent={(d) => (d.item?.name ? d.item.name : '')}
//                 />
//                 <DataTableCell
//                   style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//                   weighting={0.15}
//                   getContent={(d) => (d.usage === null ? "" : d.usage)}
//                 />
//                 <DataTableCell
//                   style={[tableStyles.tableCell]}
//                   weighting={0.2}
//                   getContent={(d) => {
//                     if (d.requiredQty === null) {
//                       return "";
//                     } else {
//                       return (
//                         new Intl.NumberFormat("en-US").format(d.purchased) +
//                         " / " +
//                         new Intl.NumberFormat("en-US").format(d.requiredQty) +
//                         " " +
//                         (d.unit?.name ? d.unit?.name : '')
//                       );
//                     }
//                   }}
//                 />

//                 <DataTableCell
//                   style={[tableStyles.tableCell]}
//                   weighting={0.1}
//                   getContent={(d) => {
//                     if (d.purchased === null) {
//                       return "Empty";
//                     } else {
//                       return new Intl.NumberFormat("en-US").format(d.baseCost);
//                     }
//                   }}
//                 />
//                 <DataTableCell
//                   style={[tableStyles.tableCell]}
//                   weighting={0.15}
//                   getContent={(d) => {
//                     if (d.finance === null) {
//                       return "";
//                     } else {
//                       return new Intl.NumberFormat("en-US").format(
//                         d.baseCost * d.requiredQty
//                       );
//                     }
//                   }}
//                 />
//                 <DataTableCell
//                   style={[tableStyles.tableCell]}
//                   weighting={0.15}
//                   getContent={(d) => {
//                     if (d.finance === null) {
//                       return "";
//                     } else {
//                       return new Intl.NumberFormat("en-US").format(
//                         d.finance.used
//                       );
//                     }
//                   }}
//                 />
//               </TableBody>
//             </Table>
//           </View>
//         </Page>
//       </>
//     ))}
//   </Document>
// );

// let POPDF = (props) => (
//   <Document>
//     <Page
//       size="A4"
//       style={[
//         {
//           //fontFamily: "Roboto",
//           paddingTop: 35,
//           paddingBottom: 65,
//           paddingHorizontal: 20,
//         },
//       ]}
//     >
//       <View
//         style={[
//           {
//             marginTop: 20,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 150, width: 80 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 5,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 20,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>PURCHASE ORDER</Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date: {moment(props.poData.datePosted).format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>

//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>
//               PO #: {props.poData.uniqueId}
//             </Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             {(() => {
//               if (Number(props.poData.isPrintable) !== 1) {
//                 return (
//                   <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//                     Status:{" "}
//                     {Number(props.poData.status) === 1 ? "Ongoing" : "Finished"}
//                   </Text>
//                 );
//               }
//             })()}
//           </View>
//         </View>
//         <View
//           style={[
//             {
//               marginTop: 8,
//               marginBottom: 8,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             Supplier: {props.poData.multiMaterials[0].supplier.name}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 20,
//                 fontSize: 15,
//               },
//             ]}
//           >
//             Address: {props.poData.multiMaterials[0].supplier.address}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 10,
//                 fontSize: 15,
//               },
//             ]}
//           >
//             Deliver To: {props.poData.deliverTo}
//           </Text>
//         </View>
//       </View>

//       <View
//         style={[
//           {
//             height: 325,
//             paddingLeft: 50,
//             paddingRight: 50,
//           },
//         ]}
//       >
//         <Table data={props.poData.multiMaterials}>
//           <TableHeader>
//             {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               PR #
//             </TableCell> */}

//             <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
//               ITEM DESCRIPTION
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               QTY
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               UNIT PRICE
//             </TableCell>
//             <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
//               AMOUNT
//             </TableCell>
//           </TableHeader>
//           <TableBody>
//             {/* <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => (d.uniqueId === null ? "" : d.uniqueId)}
//             /> */}

//             {/* <DataTableCell
//               style={[tableStyles.tableCell]}
//               getContent={(d) =>
//                 d.material.unit.name === null ? "" : d.material.unit.name
//               }
//             /> */}
//             <DataTableCell
//               style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//               weighting={0.5}
//               getContent={(d) =>
//                 d.brand === null ? "" : d.brand + "-" + d.material.item.name
//               }
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) =>
//                 d.qty === null || d.material.unit.name === null
//                   ? ""
//                   : d.qty + " " + d.material.unit.name
//               }
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.cost === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.cost);
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.budget === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.qty * d.cost);
//                 }
//               }}
//             />
//           </TableBody>
//         </Table>
//         <Text
//           style={[
//             {
//               textAlign: "right",
//               marginTop: 10,
//             },
//           ]}
//         >
//           TOTAL PRICE:{" "}
//           {((e) => {
//             let total = 0;
//             props.poData.multiMaterials.forEach((x) => {
//               total += x.qty * x.cost;
//             });
//             return new Intl.NumberFormat("en-US").format(total);
//           })()}{" "}
//           PHP
//         </Text>
//       </View>
//       {(() => {
//         if (Number(props.poData.isPrintable) !== 1) {
//           return (
//             <View style={[{ marginTop: 5 }]}>
//               <Text style={[{ textAlign: "center", color: "red" }]}>
//                 --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
//               </Text>
//             </View>
//           );
//         }
//       })()}

//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,

//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Prepared by:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Approved By:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Received Original Copy:
//               </Text>
//             </View>
//           </View>
//         </View>
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,
//               marginBottom: 25,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 {props.poData.generatedBy.name}
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 {props.poData.approvedBy.name}
//               </Text>
//               {(() => {
//                 if (
//                   Number(props.poData.isPrintable) === 1 &&
//                   Number(props.poData.approvedBy.uploads.length) > 0
//                 ) {
//                   return (
//                     <Image
//                       style={[
//                         { position: "absolute", width: 150, marginTop: -30 },
//                       ]}
//                       src={{
//                         uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${props.poData.approvedBy.uniqueId}&filename=${props.poData.approvedBy.uploads[0].filename}`,
//                         method: "GET",
//                         headers: {
//                           Authorization:
//                             "Bearer " + localStorage.getItem("LUT"),
//                         },
//                         body: "",
//                       }}
//                     />
//                   );
//                 }
//               })()}
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                     textAlign: "center",
//                   },
//                 ]}
//               >
//                 __________________________
//               </Text>
//             </View>
//           </View>
//         </View>
//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("#45A545")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//         <View
//           style={[
//             {
//               textAlign: "center",
//               color: "black",
//               width: "100%",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 textAlign: "left",
//                 marginBottom: 5,
//                 fontWeight: 1,
//                 fontSize: 12,
//               },
//             ]}
//           >
//             JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo City
//           </Text>

//           <Text
//             style={[
//               {
//                 color: "#45A545",
//                 textAlign: "left",
//                 fontSize: 12,
//                 fontWeight: 700,
//               },
//             ]}
//           >
//             TEL:
//             <Text style={[{ color: "black", textAlign: "left", fontSize: 12 }]}>
//               (033) 320 2392
//             </Text>
//           </Text>
//         </View>
//       </View>
//     </Page>
//   </Document>
// );

// let BOQPDF = (props) => (
//   <Document>
//     <Page size="A4" orientation="landscape">
//       <View
//         style={[
//           {
//             marginTop: 15,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 300, width: 80 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 0,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 5,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>BILL OF MATERIALS</Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date Generated: {moment().format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>

//         <Canvas
//           style={[
//             {
//               width: 840,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(60, 0)
//               .lineTo(760, 0)
//               .stroke();
//           }}
//         />
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 5,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             PROJECT NAME: {props.projData.name}
//           </Text>
//           <Text
//             style={[
//               {
//                 fontSize: 15,
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             ADDRESS: {props.projData.address}
//           </Text>
//           <Text
//             style={[
//               {
//                 fontSize: 15,
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             GENERATED BY: {props.state.user.name}
//           </Text>
//         </View>
//       </View>
//       <View
//         style={[
//           {
//             marginTop: 5,
//             paddingLeft: 40,
//             paddingRight: 40,
//           },
//         ]}
//       >
//         <Table data={props.projData.overallMats}>
//           <TableHeader>
//             <TableCell weighting={0.25} style={[tableStyles.tableHeader]}>
//               MATERIAL NAME
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               USE
//             </TableCell>
//             <TableCell
//               weighting={0.2}
//               style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
//             >
//               QTY PURCHASED / REQUIRED
//             </TableCell>

//             <TableCell
//               weighting={0.1}
//               style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
//             >
//               BASE UNIT PRICE
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               BASE TOTAL
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               ACTUAL TOTAL
//             </TableCell>
//           </TableHeader>
//           <TableBody>
//             <DataTableCell
//               style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//               weighting={0.25}
//               getContent={(d) => (d.item?.name ? d.item.name : '')}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell, tableStyles.tableCellNames]}
//               weighting={0.15}
//               getContent={(d) => (d.usage === null ? "" : d.usage)}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.requiredQty === null) {
//                   return "";
//                 } else {
//                   return (
//                     new Intl.NumberFormat("en-US").format(d.purchased) +
//                     " / " +
//                     new Intl.NumberFormat("en-US").format(d.requiredQty) +
//                     " " +
//                     (d.unit?.name ? d.unit.name : '')
//                   );
//                 }
//               }}
//             />

//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.1}
//               getContent={(d) => {
//                 if (d.purchased === null) {
//                   return "Empty";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.baseCost);
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.finance === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(
//                     d.baseCost * d.requiredQty
//                   );
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.finance === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.finance.used);
//                 }
//               }}
//             />
//           </TableBody>
//         </Table>
//       </View>
//     </Page>

//     <Page size="A4" orientation="landscape">
//       <View
//         style={[
//           {
//             marginTop: 15,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 300, width: 80 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 0,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 5,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>
//               BILL OF MATERIALS SUMMARY
//             </Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date Generated: {moment().format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>

//         <Canvas
//           style={[
//             {
//               width: 840,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(60, 0)
//               .lineTo(760, 0)
//               .stroke();
//           }}
//         />
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 5,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             PROJECT NAME: {props.projData.name}
//           </Text>
//           <Text
//             style={[
//               {
//                 fontSize: 15,
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             ADDRESS: {props.projData.address}
//           </Text>
//           <Text
//             style={[
//               {
//                 fontSize: 15,
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             GENERATED BY: {props.state.user.name}
//           </Text>
//         </View>
//       </View>
//       <View
//         style={[
//           {
//             marginTop: 5,
//             paddingLeft: 40,
//             paddingRight: 40,
//           },
//         ]}
//       >
//         <Table data={props.phaseData}>
//           <TableHeader>
//             <TableCell weighting={0.35} style={[tableStyles.tableHeader]}>
//               PHASE
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               DATE STARTED
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               DEADLINE
//             </TableCell>

//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               PHASE BUDGET
//             </TableCell>

//             <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
//               BUDGET USED
//             </TableCell>
//           </TableHeader>
//           <TableBody>
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.35}
//               getContent={(d) => (d.name === null ? "" : d.name)}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.requiredQty === null) {
//                   return "";
//                 } else {
//                   return moment(d.dateStart, "YYYY-MM-DD").format("LL");
//                 }
//               }}
//             />

//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.purchased === null) {
//                   return "";
//                 } else {
//                   return moment(d.dateEstEnd, "YYYY-MM-DD").format("LL");
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => {
//                 if (d.finance === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(
//                     d.finance.totalBudget
//                   );
//                 }
//               }}
//             />

//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.finance === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.finance.used);
//                 }
//               }}
//             />
//           </TableBody>
//         </Table>
//       </View>
//     </Page>
//   </Document>
// );

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];
// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// const RADIAN = Math.PI / 180;

const SupplierItemColumn = [
  {
    Header: "Details",
    Cell: (row) => (
      <>
        <Row className="order-tc-cont" noGutters>
          <Col className="order-stat-cont" md={2}>
            <Form.Label
              className="text-center order-stat-label"
              style={{
                background:
                  (row && Number(row.original.status) === 1 && "#DFE24A") ||
                  (row && Number(row.original.status) === 2 && "#E24A4A") ||
                  (row && Number(row.original.status) === 3 && "#DFE24A") ||
                  (row && Number(row.original.status) === 4 && "#4A87E2") ||
                  (row && Number(row.original.status) === 5 && "#5DB55D") ||
                  (row && Number(row.original.status) === 6 && "#E2934A") ||
                  (row && Number(row.original.status) === 7 && "#E24A4A") ||
                  (row && Number(row.original.status) === 8 && "#E2934A"),
              }}
            >
              {(() => {
                return DBStatus.parseOrderStatus(row.original.status);
              })()}
            </Form.Label>
          </Col>
          <Col md={10}>
            <Row noGutters>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Name
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.material?.item?.name ? row.original.material?.item?.name : ''
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Qty
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      //console.log(row.original);
                      return row.original.qty === null
                        ? ""
                        : row.original.qty +
                            " " +
                            row.original.material?.unit?.name ? row.original.material?.unit?.name : '';
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Posted
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.datePosted === null
                        ? ""
                        : moment(
                            row.original.datePosted,
                            "YYYY-MM-DDTk:m:Z"
                          ).format("llll");
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Project
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.material.project.name === null
                        ? ""
                        : row.original.material.project.name;
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Cost
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.cost === null
                        ? ""
                        : "₱ " +
                            new Intl.NumberFormat("en-US").format(
                              row.original.cost
                            );
                    })()}
                  />
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Total
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.cost === null
                        ? ""
                        : "₱ " +
                            new Intl.NumberFormat("en-US").format(
                              row.original.cost * row.original.qty
                            );
                    })()}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ),
  },
];

function poSupplierColumn() {
  return [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => (d.supplier?.name ? d.supplier?.name : ""),
    },
    {
      Header: "Address",
      id: "Address",
      // accessor: d => d.name.title
      accessor: (d) => (d.supplier?.address ? d.supplier?.address : ""),
    },
    {
      Header: "Website",
      id: "website",
      accessor: (d) => (d.supplier?.website ? d.supplier?.website : ""),
    },
    {
      Header: "Contact",
      id: "contact",
      // accessor: d => d.contact.landline
      accessor: (d) => (d.supplier?.contact ? d.supplier?.contact : ""),
    },
    {
      Header: "Total Orders",
      id: "orders",
      // accessor: d => d.contact.landline
      accessor: (d) => (d.noOfOrder ? d.noOfOrder : ""),
    },
  ];
}
function topMaterialColumn() {
  return [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => (d.material?.item ? d.material?.item?.name : ""),
    },

    {
      Header: "Total Orders",
      id: "orders",
      // accessor: d => d.contact.landline
      accessor: (d) => (d.count ? d.count : ""),
    },
  ];
}
function topItemColumn() {
  return [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => (d?.item?.name ? d.item.name : ""),
    },

    {
      Header: "Total Order QTY",
      id: "orders",
      // accessor: d => d.contact.landline
      accessor: (d) => (d.count ? d.count : ""),
    },
  ];
}

function poHistoryColumn() {
  return [
    {
      Header: "ID",
      id: "brand",
      accessor: (d) => (d.uniqueId ? d.uniqueId : ""),
    },
    {
      Header: "Delivery Address",
      id: "address",
      accessor: (d) => (d.deliverTo ? d.deliverTo : ""),
    },
    {
      Header: "Date Posted",
      id: "posted",
      accessor: (d) =>
        d.datePosted
          ? moment(d.datePosted, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
    },
    {
      Header: "Posted By",
      id: "postedBy",
      accessor: (d) => (d.generatedBy?.name ? d.generatedBy?.name : ""),
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d) => DBStatus.parsePOType(d.status),
    },
  ];
}

function historyColumn(material) {
  return [
    {
      Header: "Brand",
      id: "brand",
      accessor: (d) => (d.brand ? d.brand : ""),
    },
    {
      Header: "Supplier",
      id: "supplier",
      accessor: (d) => (d.supplier?.name ? d.supplier?.name : ""),
    },
    {
      Header: "Date Purchased",
      id: "datePurchased",
      accessor: (d) =>
        d.dateFinished
          ? moment(d.dateFinished, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
    },
    {
      Header: "Qty",
      id: "qty",
      accessor: (d) =>
        d.qty ? (d.qty + " " + material.unit ? material.unit?.name : "") : "",
    },
    {
      Header: "Unit Price",
      id: "unitPrice",
      accessor: (d) =>
        d.cost ? "₱ " + misc.parseCurrencyNoCurrency(d.cost) : "",
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d) =>
        d.cost
          ? "₱ " + misc.parseCurrencyNoCurrency(Number(d.cost) * Number(d.qty))
          : "",
    },
  ];
}

const phaseColumn = [
  {
    Header: "Phases",
    Cell: (row) => (
      <Row className="project-tc-cont" noGutters>
        <Col md={6} lg={6}>
          <InputGroup className="project-tc-ig">
            <InputGroup.Prepend>
              <InputGroup.Text className="project-tc-labels">
                Name
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              readOnly
              disabled
              className="project-tc-txtbox"
              value={(() => {
                return row.original.name === null ? "" : row.original.name;
              })()}
            />
          </InputGroup>
        </Col>
        <Col md={6} lg={6}>
          <InputGroup className="project-tc-ig">
            <InputGroup.Prepend>
              <InputGroup.Text className="project-tc-labels">
                Start
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              readOnly
              disabled
              className="project-tc-txtbox"
              value={(() => {
                if (
                  row.original.dateStart === null ||
                  row.original.dateStart === ""
                ) {
                  return "Empty";
                } else {
                  return moment(row.original.dateStart, "YYYY-MM-DD").format(
                    "LL"
                  );
                }
              })()}
            />
          </InputGroup>
        </Col>

        <Col md={6} lg={6}>
          <InputGroup className="project-tc-ig">
            <InputGroup.Prepend>
              <InputGroup.Text className="project-tc-labels">
                Budget
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              readOnly
              disabled
              className="project-tc-txtbox"
              value={(() => {
                return row && row.original.budget === null
                  ? ""
                  : new Intl.NumberFormat("en-US").format(row.original.budget);
              })()}
            />
          </InputGroup>
        </Col>
        <Col md={6} lg={6}>
          <InputGroup className="project-tc-ig">
            <InputGroup.Prepend>
              <InputGroup.Text className="project-tc-labels">
                Deadline
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              readOnly
              disabled
              className="project-tc-txtbox"
              value={(() => {
                if (
                  row.original.dateEstEnd === null ||
                  row.original.dateEstEnd === ""
                ) {
                  return "Empty";
                } else {
                  return moment(row.original.dateEstEnd, "YYYY-MM-DD").format(
                    "LL"
                  );
                }
              })()}
            />
          </InputGroup>
        </Col>
      </Row>
    ),
  },
];

const projectColumn = [
  {
    Header: "Projects",
    Cell: (row) => (
      <Row className="project-tc-cont" noGutters>
        <Col md={2} lg={2}>
          <Form.Label
            className="text-center proj-stat-label m-0"
            style={{
              background:
                (row && Number(row.original.status) === 1 && "#DFE24A") ||
                (row && Number(row.original.status) === 2 && "#DFE24A") ||
                (row && Number(row.original.status) === 3 && "#DFE24A") ||
                (row && Number(row.original.status) === 4 && "#E24A4A") ||
                (row && Number(row.original.status) === 5 && "#E24A4A") ||
                (row && Number(row.original.status) === 6 && "#4A87E2") ||
                (row && Number(row.original.status) === 7 && "#5DB55D") ||
                (row && Number(row.original.status) === 8 && "#E2934A"),
            }}
          >
            {(() => {
              return DBStatus.parseProjectStatus(row.original.status);
            })()}
          </Form.Label>
        </Col>
        <Col md={10} lg={10}>
          <Row>
            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    Start
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    if (
                      row.original.dateStart === null ||
                      row.original.dateStart === ""
                    ) {
                      return "Empty";
                    } else {
                      return moment(
                        row.original.dateStart,
                        "YYYY-MM-DD"
                      ).format("LL");
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    Name
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    return row.original.name === null ? "" : row.original.name;
                  })()}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    Budget
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    return row && row.original.budget === null
                      ? ""
                      : new Intl.NumberFormat("en-US").format(
                          row.original.budget
                        );
                  })()}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    End
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    if (
                      row.original.dateEstEnd === null ||
                      row.original.dateEstEnd === ""
                    ) {
                      return "Empty";
                    } else {
                      return moment(
                        row.original.dateEstEnd,
                        "YYYY-MM-DD"
                      ).format("LL");
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    Jobsite
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    return row.original.address === null
                      ? ""
                      : row.original.address;
                  })()}
                />
              </InputGroup>
            </Col>

            <Col md={4}>
              <InputGroup className="project-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="project-tc-labels">
                    ID
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="project-tc-txtbox"
                  value={(() => {
                    return row.original.uniqueId === null
                      ? ""
                      : row.original.uniqueId;
                  })()}
                />
              </InputGroup>
              {/* <ProgressBar
                className="project-percentage "
                now={
                  row.original.purchasedPercentage === null ||
                  row.original.purchasedPercentage === "NaN%"
                    ? 0
                    : Number(row.original.purchasedPercentage.replace("%", ""))
                }
                label={`${
                  row.original.purchasedPercentage === null ||
                  row.original.purchasedPercentage === "NaN%"
                    ? "0%"
                    : row.original.purchasedPercentage
                } `}
              /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    ),
  },
];

function orderColumn(matData) {
  return [
    {
      Header: "Details",
      Cell: (row) => (
        <>
          <Row className="order-tc-cont" noGutters>
            <Col md={2}>
              <Form.Label
                className="text-center order-stat-label"
                style={{
                  background:
                    (row && Number(row.original.status) === 1 && "#DFE24A") ||
                    (row && Number(row.original.status) === 2 && "#E24A4A") ||
                    (row && Number(row.original.status) === 3 && "#DFE24A") ||
                    (row && Number(row.original.status) === 4 && "#4A87E2") ||
                    (row && Number(row.original.status) === 5 && "#5DB55D") ||
                    (row && Number(row.original.status) === 6 && "#E2934A") ||
                    (row && Number(row.original.status) === 7 && "#E24A4A") ||
                    (row && Number(row.original.status) === 8 && "#E2934A"),
                }}
              >
                {(() => {
                  return DBStatus.parseOrderStatus(row.original.status);
                })()}
              </Form.Label>
            </Col>
            <Col md={10}>
              <Row noGutters>
                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Brand
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.brand === null
                          ? ""
                          : row.original.brand;
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Qty
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        //console.log(row.original);
                        return row.original.qty === null
                          ? ""
                          : `${row.original.qty} ${
                              matData.unit?.name ? matData.unit?.name : ""
                            }`;
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Purchaser
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.purchaser.name === null
                          ? ""
                          : row.original.purchaser.name;
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Supplier
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        if (row.original.supplier) {
                          return row.original.supplier.name === null
                            ? "None"
                            : row.original.supplier.name;
                        } else {
                          return "None";
                        }
                      })()}
                    />
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Cost
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.cost === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.cost
                              );
                      })()}
                    />
                  </InputGroup>
                </Col>

                <Col md={4}>
                  <InputGroup className="order-tc-ig">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="order-tc-labels">
                        Total
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      readOnly
                      disabled
                      className="order-tc-txtbox"
                      value={(() => {
                        return row.original.cost === null
                          ? ""
                          : "₱ " +
                              new Intl.NumberFormat("en-US").format(
                                row.original.cost * row.original.qty
                              );
                      })()}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
    },
  ];
}

const materialColumn = [
  {
    show: false,
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },

  {
    Header: "Orders",
    Cell: (row) => (
      <>
        <Row
          className={(() => {
            let ordered =
              Number(row.original.requiredQty) - Number(row.original.purchased);
            //let percent = (ordered / Number(row.original.requiredQty)) * 100;
            //console.log(Math.round(percent));
            if (Number(ordered) === 0) {
              return " phase-tr-header prog-finished";
            } else {
              return " phase-tr-header prog-incomplete";
            }
          })()}
          noGutters
        >
          {/* {(() => {
            if (row.original) {
              if (row.original.warn) {
                if (Number(row.original.warn) > 0) {
                  return (
                    <Col
                      className="orderTableWarnCont"
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                    >
                      {" "}
                      <p className="orderTableWarnTxt font-weight-bolder text-center">
                        {row.original.warn}
                      </p>
                    </Col>
                  );
                }
              } else {
                return (
                  <Col className="w-100" xs={1} sm={1} md={1} lg={1}></Col>
                );
              }
            }
          })()} */}

          <Col xs={11} sm={11} md={7} lg={7}>
            <InputGroup>
              <InputGroup.Prepend
                className={
                  row.original.warn === 0
                    ? "orderTableWarnContOK"
                    : "orderTableWarnCont"
                }
              >
                <p className="orderTableWarnTxt font-weight-bolder text-center">
                  {row.original.warn}
                </p>
              </InputGroup.Prepend>
              <FormControl
                disabled
                readOnly
                className="phase-tc-txtbox-name phase-tc-txtbox"
                value={(() => {
                  return row.original?.item ? row.original?.item?.name : "";
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={10} sm={10} md={3} lg={3}>
            {(() => {
              let ordered =
                Number(row.original.requiredQty) -
                Number(row.original.purchased);
              if (ordered !== 0) {
                // return <h6>{"Unordered: " + ordered}</h6>;
                return (
                  <>
                    <InputGroup>
                      <FormControl
                        as={NumericFormat}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" " + row.original.unit?.name}
                        prefix={"Unordered: "}
                        value={ordered}
                        className="phase-tc-txtbox-name phase-tc-txtbox "
                        readOnly
                      />
                      <FormControl
                        as={NumericFormat}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={" " + row.original.unit?.name}
                        prefix={"Purchased: "}
                        value={Number(row.original.purchased)}
                        className="phase-tc-txtbox-name phase-tc-txtbox "
                        readOnly
                      />
                    </InputGroup>
                  </>
                );
              } else {
                return (
                  <>
                    <FormControl
                      disabled
                      readOnly
                      className="phase-tc-txtbox-name phase-tc-txtbox"
                      value={(() => {
                        return "Order Complete";
                      })()}
                    />
                  </>
                );
              }
            })()}
          </Col>
          <Col
            className="phaseTablePercentCont d-flex justify-content-end"
            xs={2}
            sm={2}
            md={2}
            lg={2}
          >
            {" "}
            <span className="phaseTableWPercentTxt font-weight-bolder ">
              {(row.original.purchased / row.original.requiredQty) * 100}%
            </span>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center phase-tc-cont">
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  ID
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.uniqueId === null
                    ? ""
                    : row.original.uniqueId;
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Qty Need
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as={NumericFormat}
                displayType={"text"}
                thousandSeparator={true}
                suffix={` ${row.original?.unit ? row.original.unit?.name : ""}`}
                //prefix={"Unordered: "}
                value={(() => {
                  return row.original.requiredQty === null
                    ? ""
                    : row.original.requiredQty;
                })()}
                className="phase-tc-txtbox number-format-center"
                readOnly
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Total Budget
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.budget === null
                    ? ""
                    : "₱ " +
                        new Intl.NumberFormat("en-US").format(
                          row.original.budget
                        );
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Deadline
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.deadline === null
                    ? "None"
                    : moment(row.original.deadline, "YYYY-MM-DD").format("LL");
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={
                    (() => {
                      if (row.original.finance.loss === 0) {
                        return "save";
                      } else {
                        return "loss";
                      }
                    })() + " phase-tc-labels"
                  }
                >
                  {(() => {
                    if (row.original.finance.loss === 0) {
                      return "Budget Left";
                    } else {
                      return "Over Budget";
                    }
                  })()}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className={
                  (() => {
                    if (row.original.finance.loss === 0) {
                      return "save";
                    } else {
                      return "loss";
                    }
                  })() + " phase-tc-txtbox"
                }
                // className="phase-tc-txtbox"
                value={(() => {
                  if (row.original.finance.loss === 0) {
                    return row.original.finance === null
                      ? ""
                      : "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            row.original.finance.totalBudget -
                              row.original.finance.used
                          );
                  } else {
                    return row.original.finance === null
                      ? ""
                      : "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            row.original.finance.loss
                          );
                  }
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Posted
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.datePosted === null
                    ? "None"
                    : moment(row.original.datePosted, "YYYY-MM-DD").format(
                        "LL"
                      );
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Requester
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original?.requester?.name ? row.original?.requester?.name : 'None'
                })()}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            {" "}
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Use
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.usage === null
                    ? "Empty"
                    : row.original.usage;
                })()}
              />
            </InputGroup>
          </Col>
        </Row>
      </>
    ),
  },
];

// let SelProjFilter = (props) => (
//   <>
//     <InputGroup>
//       <InputGroup.Text className="ico-search">
//         <i className="icofont-filter icofont-1x"></i>
//       </InputGroup.Text>
//       <FormControl
//         as="select"
//         value={props.state.selProjFilter.value}
//         name="selProjFilter"
//         className=""
//         disabled={props.state.selProjFilter.readOnly}
//         onChange={(e) => {
//           props.state.inputChange(e);
//           props.state.handleProjFilterChange(e);
//         }}
//       >
//         <option value={0} key={0}>
//           All
//         </option>
//         <option value={1} key={1}>
//           Pending Quotataion
//         </option>
//         <option value={2} key={2}>
//           Pending Admin Confirmation
//         </option>
//         <option value={3} key={3}>
//           Pending Client Confirmation
//         </option>
//         <option value={4} key={4}>
//           Admin Denied Quote
//         </option>
//         <option value={5} key={5}>
//           Client Denied Quote
//         </option>
//         <option value={6} key={6}>
//           Ongoing
//         </option>
//         <option value={7} key={7}>
//           Finished
//         </option>
//         <option value={8} key={8}>
//           Cancelled
//         </option>
//       </FormControl>
//     </InputGroup>
//     {/* <Form.Label className="font-weight-bold">Filter</Form.Label> */}
//   </>
// );
// let SelProj = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">Projects</Form.Label>
//     <Select
//       //styles={customStyles}
//       isSearchable
//       //menuPortalTarget={document.body}
//       className=""
//       name="selProj"
//       width="100%"
//       //tabIndex={this.props.tabIndex}
//       value={props.state.selectedProject}
//       options={props.state.projectData}
//       isLoading={props.state.selProj.isLoading}
//       isDisabled={props.state.selProj.disabled}
//       getOptionLabel={(x) => x.name}
//       getOptionValue={(x) => x.id}
//       onChange={(x, name) => {
//         props.state.handleProjectChange(x);
//         props.state.inputChange({
//           target: {
//             value: x,
//             name: "selProj",
//           },
//         });
//       }}
//     />
//   </>
// );
// let SelPhase = (props) => (
//   <>
//     <Form.Label className="font-weight-bold">Phase</Form.Label>
//     <Select
//       //styles={customStyles}
//       isSearchable
//       //menuPortalTarget={document.body}
//       className=""
//       name="selPhase"
//       width="100%"
//       //tabIndex={this.props.tabIndex}
//       value={props.state.selectedPhase}
//       options={props.state.phaseData}
//       isLoading={props.state.selPhase.isLoading}
//       isDisabled={props.state.selPhase.disabled}
//       getOptionLabel={(x) => x.name}
//       getOptionValue={(x) => x.id}
//       onChange={(x, name) => {
//         props.state.handlePhaseChange(x);
//         props.state.inputChange({
//           target: {
//             value: x,
//             name: "selPhase",
//           },
//         });
//       }}
//     />
//   </>
// );

function PDFGenerateProject(props) {
  const [btn, setBtn] = useState(false);
  const [drop, setDrop] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        className="form-control actor-selector "
        name="selPdfGenerate"
        value={props.state.selPdfGenerate.value}
        onChange={(e) => {
          props.state.inputChange(e);
        }}
        disabled={drop}
      >
        <option value={1} key={1}>
          Project Summary
        </option>
        <option value={2} key={2}>
          Bill of Materials
        </option>
      </FormControl>

      {(() => {
        if (btn) {
          return (
            <BlobProvider
              document={
                Number(props.state.selPdfGenerate.value) === 1 ? (
                  <PhasePDF
                    state={props.state}
                    data={props.state.selectedProject}
                    finance={props.state.selectedProject.finance}
                  />
                ) : (
                  <BOQPDF
                    state={props.state}
                    projData={props.state.projectBOQData}
                    phaseData={props.state.selectedProject.phases}
                  />
                )
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return (
                    <Button className="btnPhaseExport" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  );
                } else {
                  return (
                    <>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        key={123}
                        onClick={(e) => {
                          props.state.downloadPDF(blob);
                          setBtn(false);
                          //props.state.resetPOModal();
                        }}
                      >
                        {" "}
                        Download
                      </Button>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc:
                              Number(props.state.selPdfGenerate.value) === 1 ? (
                                <PhasePDF
                                  state={props.state}
                                  data={props.state.selectedProject}
                                  finance={props.state.selectedProject.finance}
                                />
                              ) : (
                                <BOQPDF
                                  state={props.state}
                                  projData={props.state.projectBOQData}
                                  phaseData={props.state.selectedProject.phases}
                                />
                              ),
                          });
                          setBtn(false);
                          setDrop(false);
                        }}
                      >
                        {" "}
                        View
                      </Button>
                    </>
                  );
                }
              }}
            </BlobProvider>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              className="btnPhaseExport"
              onClick={(e) => {
                props.state.readProjectBOQ((x) => {
                  setBtn(true);
                  setDrop(true);
                });
              }}
            >
              {" "}
              Generate
            </Button>
          );
        }
      })()}
    </InputGroup>
  );
}
function PDFGeneratePOHistory(props) {
  const [btn, setBtn] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend>

      {(() => {
        if (btn) {
          return (
            <BlobProvider
              document={
                <POPDF state={props.state} poData={props.state.selectedPO} />
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return (
                    <Button className="btnPhaseExport" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      name="btnGeneratePDF"
                      className="btnPhaseExport"
                      onClick={(e) => {
                        props.state.downloadPDF(blob, true);
                        setBtn(false);
                        //props.state.resetPOModal();
                      }}
                    >
                      {" "}
                      Download
                    </Button>
                  );
                }
              }}
            </BlobProvider>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              className="btnPhaseExport"
              size={"sm"}
              onClick={(e) => {
                props.state.handleGeneratePOHistory((x) => {
                  setBtn(true);
                });
              }}
            >
              {" "}
              Generate PO
            </Button>
          );
        }
      })()}
    </InputGroup>
  );
}

function Dashboard(props) {
  const [key, setKey] = useState("projects");
  return (
    <>
      <div className="dash-header-cont">
        <Row className="page-selectedProject-row">
          <h4>Dashboard</h4>
        </Row>
        <Row className="page-date-row">
          <h4>
            Date:{" "}
            <u className="project-date-date">
              {moment().format("MMMM Do YYYY")}
            </u>
          </h4>
        </Row>
      </div>
      <Tabs
        id="controlled-tab-example"
        className="dashboardTabs"
        activeKey={key}
        onSelect={(k) => {
          props.state.handleTabChange(k);
          setKey(k);
        }}
      >
        <Tab eventKey="projects" title="Projects" className="tabProjects">
          <Row className="mt-2 mb-2" noGutters>
            <Col xs={12} sm={12} md={4} lg={4} className="">
              <InputGroup className="searchInputCont">
                <InputGroup.Prepend>
                  <InputGroup.Text className="ico-search rounded-0">
                    <i className="icofont-search icofont-1x"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search"
                  name="searchInput"
                  className="txtProjSearch shadow-none rounded-0"
                  onChange={props.state.handleSearch}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="">
              <InputGroup className="selProjFilterCont">
                <InputGroup.Text className="ico-search rounded-0">
                  <i className="icofont-filter icofont-1x"></i>
                </InputGroup.Text>
                <FormControl
                  as="select"
                  value={props.state.selProjFilter.value}
                  name="selProjFilter"
                  className="selProjFilter rounded-0"
                  disabled={props.state.selProjFilter.readOnly}
                  onChange={(e) => {
                    props.state.inputChange(e);
                    props.state.handleProjFilterChange(e);
                  }}
                >
                  <option value={0} key={0}>
                    All
                  </option>
                  <option value={1} key={1}>
                    Pending Quotataion
                  </option>
                  <option value={2} key={2}>
                    Pending Admin Confirmation
                  </option>
                  <option value={3} key={3}>
                    Pending Client Confirmation
                  </option>
                  <option value={4} key={4}>
                    Admin Denied Quote
                  </option>
                  <option value={5} key={5}>
                    Client Denied Quote
                  </option>
                  <option value={6} key={6}>
                    Ongoing
                  </option>
                  <option value={7} key={7}>
                    Finished
                  </option>
                  <option value={8} key={8}>
                    Cancelled
                  </option>
                </FormControl>
              </InputGroup>
            </Col>
          </Row>
          <Row className=" mb-2" noGutters>
            <Col xs={12} sm={12} md={2} lg={2}>
              <Form.Check
                checked={props.state.chkDateFilter.value}
                disabled={props.state.chkDateFilter.readOnly}
                name="chkDateFilter"
                className="chkDateFilter"
                label="Filter By Date"
                onChange={(e) => {
                  props.state.handleCheckFilterChange(e);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <InputGroup className="txtDateStartCont">
                <InputGroup.Prepend>
                  <InputGroup.Text className="font-weight-bold rounded-0">
                    From
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="txtDateStart"
                  type="Date"
                  value={props.state.txtDateStart.value}
                  onChange={props.state.dateChange}
                  disabled={props.state.txtDateStart.readOnly}
                  className={"txtDateStart rounded-0"}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <InputGroup className="txtDateEndCont">
                <InputGroup.Prepend>
                  <InputGroup.Text className="font-weight-bold rounded-0">
                    To
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="txtDateEnd"
                  type="Date"
                  className={"txtDateEnd rounded-0"}
                  value={props.state.txtDateEnd.value}
                  onChange={props.state.dateChange}
                  disabled={props.state.txtDateEnd.readOnly}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              {/* <SelProjFilter state={props.state} /> */}
            </Col>
            {/* <Col xs={5} sm={5} md={5} lg={5}>
              <InputGroup className="">
                
              </InputGroup>
            </Col> */}
          </Row>
          {props.tableProjects}
        </Tab>
        <Tab eventKey="suppliers" title="Suppliers">
          <Row className="mb-3 mt-3">
            <h4>Top Suppliers</h4>
          </Row>
          <Row noGutters>
            <Col>{props.tableSupplier}</Col>
          </Row>
        </Tab>
        <Tab eventKey="materials" title="Materials">
          <Row className="mb-3 mt-3">
            <h4>Top Orders</h4>
          </Row>
          <Row noGutters>
            <Col>{props.tableTopMaterials}</Col>
          </Row>
          <Row className="mb-3 mt-3">
            <h4>Top Items</h4>
          </Row>
          <Row noGutters>
            <Col>{props.tableTopItems}</Col>
          </Row>
        </Tab>
      </Tabs>

      {/* <Row>
      
    </Row> */}
      {/* <Row className="CardContainerRow" noGutters={true}>
      <Col className="CardContainerCol">
        
        <Card>
          <Card.Header as="h5" className="font-weight-bold cardProjectsHeader">
            Projects
          </Card.Header>
          <Card.Body>
            

            
           
          </Card.Body>
        </Card>
      </Col>
    </Row> */}
      <Modal
        show={props.state.handleMultiMaterialModal.isShow}
        onHide={props.state.handleMultiMaterialModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleMultiMaterialModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Purchases Information
              </h4>
            </Col>
          </Row>

          {(() => {
            if (props.state.selectedMaterial) {
              if (Number(props.state.selectedMaterial.finance.loss) === 0) {
                return <></>;
              } else {
                return (
                  <Row noGutters={true}>
                    <Col>
                      <h4 className="text-center font-weight-bold notifOver p-2">
                        OVER BUDGET
                      </h4>
                    </Col>
                  </Row>
                );
              }
            }
          })()}
          {(() => {
            if (props.state.selectedMaterial) {
              if (
                Number(props.state.selectedMaterial.totalQty) >
                Number(props.state.selectedMaterial.requiredQty)
              ) {
                return (
                  <Row noGutters={true}>
                    <Col>
                      <h4 className="text-center font-weight-bold notifOver p-2">
                        OVER QTY
                      </h4>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            }
          })()}

          <Row className="mb-3" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend className="dashn-ig">
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Selected mtl.
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedMaterial?.item
                      ? props.state.selectedMaterial.item.name
                      : "Empty";
                  })()}
                />
              </InputGroup>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Qty Need
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedMaterial
                      ? props.state.selectedMaterial.requiredQty +
                        " " +
                        props.state.selectedMaterial?.unit
                        ? props.state.selectedMaterial?.unit?.name
                        : ""
                      : "Empty";
                  })()}
                />
              </InputGroup>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    {(() => {
                      if (props.state.selectedMaterial)
                        if (
                          Number(props.state.selectedMaterial.totalQty) >
                          Number(props.state.selectedMaterial.requiredQty)
                        ) {
                          return "OVER ORDER";
                        } else {
                          return "Unordered";
                        }
                      else return "Empty";
                    })()}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedMaterial)
                      return Math.abs(
                        Number(props.state.selectedMaterial.requiredQty) -
                          Number(props.state.selectedMaterial.totalQty)
                      ) +
                        " " +
                        props.state.selectedMaterial?.unit
                        ? props.state.selectedMaterial?.unit?.name
                        : "";
                    else return "Empty";
                  })()}
                />
              </InputGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Mtl. budget
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedMaterial)
                      return (
                        "₱ " +
                        new Intl.NumberFormat("en-US").format(
                          props.state.selectedMaterial.budget
                        )
                      );
                    else return "Empty";
                  })()}
                />
              </InputGroup>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    {(() => {
                      if (props.state.selectedMaterial)
                        if (
                          Number(props.state.selectedMaterial.finance.loss) ===
                          0
                        ) {
                          return "Budget Left";
                        } else {
                          return "Over Budget";
                        }
                      else return "Empty";
                    })()}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedMaterial)
                      if (
                        Number(props.state.selectedMaterial.finance.loss) === 0
                      ) {
                        return (
                          "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            props.state.selectedMaterial.finance.savings
                          )
                        );
                      } else {
                        return (
                          "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            props.state.selectedMaterial.finance.loss
                          )
                        );
                      }
                    else return "Empty";
                  })()}
                />
              </InputGroup>
              <InputGroup className="order-tc-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Use
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedMaterial)
                      return props.state.selectedMaterial.usage;
                    else return "Empty";
                  })()}
                />
              </InputGroup>
            </Col>
          </Row>

          {props.tableOrders}
        </Modal.Body>
      </Modal>
      {/*=== PROJECT MODAL ===*/}
      <Modal
        show={props.state.handleProjectModal.isShow}
        onHide={props.state.handleProjectModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleProjectModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={7} lg={7}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Project Information
              </h4>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="d-flex justify-content-end"
            >
              <ProgressBar
                className="project-percentage w-100"
                now={
                  props.state.selectedProject
                    ? props.state.selectedProject.purchasedPercentage === "" ||
                      props.state.selectedProject.purchasedPercentage === "NaN%"
                      ? 0
                      : Number(
                          props.state.selectedProject.purchasedPercentage.replace(
                            "%",
                            ""
                          )
                        )
                    : ""
                }
                label={`${
                  props.state.selectedProject.purchasedPercentage === null ||
                  props.state.selectedProject.purchasedPercentage === "NaN%"
                    ? "0% Completion"
                    : props.state.selectedProject.purchasedPercentage +
                      " Completion"
                } `}
              />
            </Col>
          </Row>
          <Row className="mb-2" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Button
                onClick={(x) => {
                  props.dispatch({
                    type: "DASH_PO_MODAL_TOGGLE",
                  });
                  //props.state.handlePOModal.toggle();
                }}
                className="btnProjectPO"
              >
                Project POs
              </Button>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <PDFGenerateProject
                state={props.state}
                data={props.state.selectedProject}
                finance={props.state.selectedProject.finance}
                dispatch={props.dispatch}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Description
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : props.state.selectedProject.description;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Address
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : props.state.selectedProject.address;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Budget
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            props.state.selectedProject.budget
                          );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    {(() => {
                      if (props.state.selectedProject) {
                        const BudgetLeft =
                          Number(
                            props.state.selectedProject.finance.totalBudget
                          ) - Number(props.state.selectedProject.finance.used);
                        if (BudgetLeft >= 0) {
                          return "Budget Left";
                        } else {
                          return "Over Budget";
                        }
                      } else {
                        return "None";
                      }
                    })()}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedProject) {
                      const Budget =
                        Number(
                          props.state.selectedProject.finance.totalBudget
                        ) - Number(props.state.selectedProject.finance.used);
                      return (
                        "₱ " + new Intl.NumberFormat("en-US").format(Budget)
                      );
                    } else {
                      return "None";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    ID
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : props.state.selectedProject.uniqueId;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    DateStart
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : moment(props.state.selectedProject.dateStart).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Deadline
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : moment(props.state.selectedProject.dateEstEnd).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Ended
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedProject === ""
                      ? "None"
                      : moment(props.state.selectedProject.dateEnd).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-2 mb-2" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Project mngr.
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedProject) {
                      let t = "";

                      props.state.selectedProject.projectActors[7].actors.map(
                        (i, index) => (t += i.name + ", ")
                      );
                      return t;
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>

              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Site mngr.
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedProject) {
                      let t = "";

                      props.state.selectedProject.projectActors[6].actors.map(
                        (i, index) => (t += i.name + ", ")
                      );
                      return t;
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Requester
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedProject) {
                      let t = "";

                      props.state.selectedProject.projectActors[8].actors.map(
                        (i, index) => (t += i.name + ", ")
                      );
                      return t;
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Purchaser
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedProject) {
                      let t = "";

                      props.state.selectedProject.projectActors[5].actors.map(
                        (i, index) => (t += i.name + ", ")
                      );
                      return t;
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
          </Row>

          {props.tablePhase}
          <DashPOProcess
            state={props.state}
            userType={[]}
            dispatch={props.dispatch}
            projectId={props.state.selectedProject.id}
          />
        </Modal.Body>
      </Modal>
      {/*=== MATERIAL MODAL ===*/}
      <Modal
        show={props.state.handleMaterialModal.isShow}
        onHide={props.state.handleMaterialModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleMaterialModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Phase Bill of Materials
              </h4>
            </Col>
          </Row>
          <Row className="pt-3 pb-3" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    ID
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedPhase === ""
                      ? "None"
                      : props.state.selectedPhase.uniqueId;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Description
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedPhase === ""
                      ? "None"
                      : props.state.selectedPhase.description;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Budget
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return props.state.selectedPhase === ""
                      ? "None"
                      : "₱ " +
                          new Intl.NumberFormat("en-US").format(
                            props.state.selectedPhase.budget
                          );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    {(() => {
                      if (props.state.selectedPhase) {
                        const BudgetLeft =
                          Number(
                            props.state.selectedPhase.finance.totalBudget
                          ) - Number(props.state.selectedPhase.finance.used);
                        if (BudgetLeft >= 0) {
                          return "Budget Left";
                        } else {
                          return "Over Budget";
                        }
                      } else {
                        return "None";
                      }
                    })()}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedPhase) {
                      const Budget =
                        Number(props.state.selectedPhase.finance.totalBudget) -
                        Number(props.state.selectedPhase.finance.used);
                      return (
                        "₱ " +
                        new Intl.NumberFormat("en-US").format(Math.abs(Budget))
                      );
                    } else {
                      return "None";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Date Start
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedPhase
                      ? "None"
                      : moment(props.state.selectedPhase.dateStart).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Deadline
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedPhase
                      ? "None"
                      : moment(props.state.selectedPhase.dateEstEnd).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Ended
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedPhase
                      ? "None"
                      : moment(props.state.selectedPhase.dateEnd).format(
                          "MMMM Do YYYY"
                        );
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Approved By
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedPhase) {
                      return props.state.selectedPhase === ""
                        ? "None"
                        : props.state.selectedPhase.phaseApprovedBy !== null
                        ? props.state.selectedPhase.phaseApprovedBy.name
                        : "None";
                    } else {
                      return "None";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2" noGutters>
            <Col xs={12} sm={12} md="auto" lg="auto" className="options-column">
              <InputGroup className="dashMaterialSearch">
                <InputGroup.Prepend>
                  <InputGroup.Text className="ico-search">
                    <i className="icofont-search icofont-1x"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Search"
                  aria-label="Username"
                  name="searchInput"
                  className="searchTable shadow-none"
                  aria-describedby="basic-addon1"
                  onChange={props.state.handleMaterialSearch}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md="auto" lg="auto">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="">Filter</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  value={props.state.selMatFilter.value}
                  name="selMatFilter"
                  className=""
                  disabled={props.state.selMatFilter.readOnly}
                  onChange={(e) => {
                    props.state.inputChange(e);
                    props.state.handleMatFilterChange(e.target.value);
                  }}
                >
                  <option value={0} key={0}>
                    All
                  </option>
                  <option value={1} key={1}>
                    Ongoing
                  </option>
                  <option value={2} key={2}>
                    Completed
                  </option>
                  <option value={3} key={3}>
                    Notices
                  </option>
                </FormControl>
              </InputGroup>
            </Col>
          </Row>
          {props.tableMaterials}
        </Modal.Body>
      </Modal>
      {/*=== SELECTED MULTI MAT MODAL ===*/}
      <Modal
        show={props.state.handleSelMultMatModal.isShow}
        onHide={props.state.handleSelMultMatModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleSelMultMatModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Selected Purchase
              </h4>
            </Col>
          </Row>
          {(() => {
            if (props.state.selectedMaterial) {
              if (Number(props.state.selectedMaterial.finance.loss) === 0) {
                return <></>;
              } else {
                return (
                  <Row noGutters={true}>
                    <Col>
                      <h4 className="text-center font-weight-bold notifOver p-2">
                        OVER BUDGET
                      </h4>
                    </Col>
                  </Row>
                );
              }
            }
          })()}
          {(() => {
            if (props.state.selectedMaterial) {
              if (
                Number(props.state.selectedMaterial.totalQty) >
                Number(props.state.selectedMaterial.requiredQty)
              ) {
                return (
                  <Row noGutters={true}>
                    <Col>
                      <h4 className="text-center font-weight-bold notifOver p-2">
                        OVER QTY
                      </h4>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            }
          })()}
          <Row className="pt-3 pb-3" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    ID
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "Empty"
                      : props.state.selectedOrder.uniqueId;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Selected mtl.
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "Empty"
                      : props.state.selectedMaterial.item
                      ? props.state.selectedMaterial.item?.name
                      : "";
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Brand
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "Empty"
                      : props.state.selectedOrder.brand;
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Qty
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "Empty"
                      : misc.parseCurrencyNoCurrency(
                          props.state.selectedOrder.qty
                        ) +
                        " " +
                        props.state.selectedMaterial.unit
                      ? props.state.selectedMaterial.unit?.name
                      : "";
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Cost
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedOrder) {
                      return (
                        "₱ " +
                        new Intl.NumberFormat("en-US").format(
                          props.state.selectedOrder.cost
                        )
                      );
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Base cost
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedOrder) {
                      return (
                        "₱ " +
                        new Intl.NumberFormat("en-US").format(
                          props.state.selectedMaterial.baseCost
                        )
                      );
                    } else {
                      return "Empty";
                    }
                  })()}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Date posted
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "None"
                      : moment(
                          props.state.selectedOrder.datePosted,
                          "YYYY-MM-DDTk:m:Z"
                        ).format("llll");
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Date finished
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "None"
                      : moment(
                          props.state.selectedOrder.dateFinished,
                          "YYYY-MM-DDTk:m:Z"
                        ).format("llll");
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Deadline
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedOrder
                      ? "None"
                      : moment(
                          props.state.selectedOrder.deadline,
                          "YYYY-MM-DDTk:m:Z"
                        ).format("llll");
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Approved By
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedOrder) {
                      return props.state.selectedOrder.multiMatApprovedBy !==
                        null
                        ? props.state.selectedOrder?.multiMatApprovedBy ? props.state.selectedOrder?.multiMatApprovedBy?.name : ''
                        : "None";
                    } else {
                      return "None";
                    }
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Purchaser
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedOrder) {
                      return props.state.selectedOrder.purchaser !== null
                        ? props.state.selectedOrder.purchaser ? props.state.selectedOrder.purchaser?.name : ''
                        : "None";
                    } else {
                      return "None";
                    }
                  })()}
                />
              </InputGroup>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Supplier
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    if (props.state.selectedOrder) {
                      return props.state.selectedOrder.supplier !== null
                        ? props.state.selectedOrder.supplier.name
                        : "None";
                    } else {
                      return "None";
                    }
                  })()}
                />
                <InputGroup.Prepend>
                  <OverlayTrigger
                    placement={"top"}
                    trigger="click"
                    overlay={
                      <Popover id={`popover-positioned-top`}>
                        <Popover.Title as="h3">
                          Supplier Information
                        </Popover.Title>
                        <Popover.Content>
                          <Row>
                            <Col>
                              <InputGroup>
                                <InputGroup.Text className="lblSupplierInfo">
                                  Name:
                                </InputGroup.Text>
                                <FormControl
                                  type="text"
                                  placeholder="Name"
                                  name="txtOrderNotes"
                                  value={
                                    props.state.selectedOrder
                                      ? props.state.selectedOrder.supplier
                                        ? props.state.selectedOrder.supplier
                                            ?.name
                                        : ""
                                      : "Empty"
                                  }
                                  className="txtSupplierInfo"
                                  disabled
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputGroup.Text className="lblSupplierInfo">
                                  Address:
                                </InputGroup.Text>
                                <FormControl
                                  type="text"
                                  placeholder="Address"
                                  name="txtOrderNotes"
                                  value={
                                    props.state.selectedOrder
                                      ? props.state.selectedOrder?.supplier
                                        ? props.state.selectedOrder?.supplier
                                            ?.address
                                        : ""
                                      : "Empty"
                                  }
                                  className="txtSupplierInfo"
                                  disabled
                                />
                              </InputGroup>
                              <InputGroup>
                                <InputGroup.Text className="lblSupplierInfo">
                                  Contact:
                                </InputGroup.Text>
                                <FormControl
                                  type="text"
                                  placeholder="Contact"
                                  name="txtOrderNotes"
                                  value={
                                    props.state.selectedOrder
                                      ? props.state.selectedOrder?.supplier
                                        ? props.state.selectedOrder?.supplier
                                            ?.contact
                                        : ""
                                      : "Empty"
                                  }
                                  className="txtSupplierInfo"
                                  disabled
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Button
                      variant="secondary"
                      name="btnInfoSupplier"
                      className="btnInfoSupplier "
                    >
                      <i className="icofont-info icofont-1x"></i>
                    </Button>
                  </OverlayTrigger>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
          </Row>
          {props.tableOrderHistory}
        </Modal.Body>
      </Modal>
      {/*=== PO MODAL ===*/}
      <Modal
        show={props.state.handlePOModal.isShow}
        onHide={props.state.handlePOModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handlePOModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Project POs
              </h4>
            </Col>
          </Row>

          <Row className="pt-3 pb-3" noGutters>
            <Col xs={12} sm={12} md={6} lg={6}></Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="d-flex justify-content-end"
            >
              {" "}
              <PDFGeneratePOHistory state={props.state} />
            </Col>
          </Row>
          {props.tablePOHistory}
        </Modal.Body>
      </Modal>
      {/*=== SUPPLEIR ITEM MODAL ===*/}
      <Modal
        show={props.state.handleSupplierItemsModal.isShow}
        onHide={props.state.handleSupplierItemsModal.toggle}
        dialogClassName="modal-100w"
        className="dashboard-modal"
        centered
      >
        <Modal.Body className="dashboard-modal-body">
          <Row className="dashboard-modal-header pt-3 pb-3 mb-3" noGutters>
            <Col xs={1} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.state.handleSupplierItemsModal.toggle();
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h4 className="font-weight-bold dashboard-modal-header-text">
                Supplier Items
              </h4>
            </Col>
          </Row>

          <Row className="pt-3 pb-3" noGutters>
            <Col>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Name
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedSupplier
                      ? "Empty"
                      : props.state.selectedSupplier.name;
                  })()}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="dashn-ig">
                <InputGroup.Prepend>
                  <InputGroup.Text className="dash-labels font-weight-bold">
                    Address
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="dash-txtbox"
                  value={(() => {
                    return !props.state.selectedSupplier
                      ? "Empty"
                      : props.state.selectedSupplier.address;
                  })()}
                />
              </InputGroup>
            </Col>
            {/* <Col xs={12} sm={12} md={6} lg={6}></Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="d-flex justify-content-end"
            >
              {" "}
              <PDFGeneratePOHistory state={props.state} />
            </Col> */}
          </Row>
          {props.tableSupplierItems}
        </Modal.Body>
      </Modal>
    </>
  );
}

export {
  Dashboard,
  materialColumn,
  orderColumn,
  projectColumn,
  phaseColumn,
  historyColumn,
  poHistoryColumn,
  poSupplierColumn,
  topMaterialColumn,
  topItemColumn,
  SupplierItemColumn,
};

/* <PieChart width={800} height={400}>
              <Pie
                data={data}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
              >
                {data.map((entry, index) => (
                  <Cell fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart> */
