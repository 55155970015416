import React from "react";
import { Project, projectColumn } from "./Project";
import Table from "react-table";
import Filters from "../../functions/filters";
import { connect } from "react-redux";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
//import LoadingProcess from "../Loading/LoadingProcess";
import moment from "moment";
//const SelectTable = selectTableHOC(Table);

class ProjectProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projData: [],
      bakProjData: [],

      actorData: [],
      actorDataIsLoading: false,

      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      projectStatusChange: this.projectStatusChange.bind(this),
      handleActionChange: this.handleActionChange.bind(this),
      handleStatusChange: this.handleStatusChange.bind(this),
      readActor: this.readActor.bind(this),
      addProjectActor: this.addProjectActor.bind(this),
      deleteProjectActor: this.deleteProjectActor.bind(this),
      resubmitDeniedProject: this.resubmitDeniedProject.bind(this),
      handleCheckFilterChange: this.handleCheckFilterChange.bind(this),
      dateChange: this.dateChange.bind(this),

      selectAll: false,
      selection: [],
      searchInput: "",
      projectSelection: { id: -1 },
      projectActors: false,
      projectActorsArr: false,
      checkboxTable: {},
      //columns: projectColumn,
      selectedProject: { id: 1, name: "User" },
      selectedOperation: { id: 0, name: "Add" },
      selectedModalOperation: { id: 0, name: "Add" },
      isLoading: false,
      isFiltered: false,

      projectFinance: {
        loss: 0,
        savings: 0,
        totalBudget: 0,
        used: 0,
      },

      handleProjectModal: {
        handleSubmit: this.handleProjectSubmit.bind(this),
        handleExit: this.handleExit.bind(this),
        show: this.handleShowModal.bind(this),
        hide: this.handleHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      txtFiltDateStart: {
        value: moment(new Date(2019, 12), "YYYY/MM/DD").format("YYYY-MM-DD"),

        className: "",
        readOnly: true,
      },
      txtFiltDateEnd: {
        value: moment(new Date(), "YYYY/MM/DD").format("YYYY-MM-DD"),

        className: "",
        readOnly: true,
      },
      chkDateFilter: {
        value: false,
        readOnly: false,
        isDisabled: false,
      },

      txtProjectName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtAddress: {
        value: "",
        placeholder: "Jobsite",
        className: "mb-3",
        readOnly: false,
      },
      txtDescription: {
        value: "",
        placeholder: "Description",
        className: "mb-3",
        readOnly: false,
      },
      txtNote: {
        value: "",
        placeholder: "Notes",
        className: "",
        readOnly: false,
      },
      txtBudget: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtDateStart: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtDateEnd: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtDateEstEnd: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtDateLastModified: {
        value: "",
        placeholder: "",
        className: "mb-3",
        readOnly: false,
      },
      txtLastModifiedBy: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtProjUniqueID: {
        value: "",
        placeholder: "ID",
        className: "mb-3",
        readOnly: false,
      },
      txtDatePosted: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      projectStatusSelect: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      selActor: {
        value: "",
        readOnly: false,
      },
      projectDelegateTypeSelect: {
        value: 0,
        readOnly: false,
      },
      projectDelegateActorSelect: {
        value: 0,
        readOnly: false,
      },
    };
  }

  componentDidMount() {
    this.readProject(0, (x) => {}, null, null, 1);

    const selProjId = sessionStorage.getItem("selProjId");
    //console.log(selProjId);
    if (selProjId !== "undefined" && selProjId !== null) {
      this.readSelectedproject(Number(selProjId), (projData) => {
        this.props.state.handleContentChange(
          "btnViewProject",
          projData,
          projData.finance
        );
      });
    }

    //this.readActor(this.state.projectDelegateTypeSelect.value);
  }

  componentWillUnmount() {}

  handleCheckFilterChange = (e) => {
    e.persist();
    //console.log(e.currentTarget.value);

    this.setState({
      [e.target.name]: {
        value: e.target.checked ? true : false,
        readOnly: false,
      },
    });
    if (e.target.checked) {
      const dateStart = this.state.txtFiltDateStart.value;
      const dateEnd = this.state.txtFiltDateEnd.value;

      this.setState(
        (x) => ({
          txtFiltDateStart: { ...x.txtFiltDateStart, readOnly: false },
          txtFiltDateEnd: { ...x.txtFiltDateEnd, readOnly: false },
        }),
        (x) => {
          this.readProject(
            this.state.selectedProject.id,
            (x) => {},
            dateStart,
            dateEnd,
            0
          );
        }
      );
    } else {
      this.setState(
        (x) => ({
          txtFiltDateStart: { ...x.txtFiltDateStart, readOnly: true },
          txtFiltDateEnd: { ...x.txtFiltDateEnd, readOnly: true },
        }),
        (x) => {
          this.readProject(
            this.state.selectedProject.id,
            (x) => {},
            null,
            null,
            1
          );
        }
      );
    }
  };
  dateChange = (e) => {
    this.setState(
      {
        [e.target.name]: {
          value: e.target.value,
          placeholder: e.target.placeholder,
          className: e.target.className,
        },
      },
      (x) => {
        this.readProject(
          this.state.selectedProject.id,
          (x) => {},
          this.state.txtFiltDateStart.value,
          this.state.txtFiltDateEnd.value,
          0
        );
      }
    );
  };

  projectStatusChange = (e) => {
    this.setState((x) => ({
      projectStatusSelect: {
        ...x.projectStatusSelect,
        isShow: true,
      },
    }));
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleShowModal = (e) => {
    this.setState((x) => ({
      handleProjectModal: {
        ...x.handleProjectModal,
        isShow: true,
      },
    }));
  };
  handleHideModal = (e) => {
    this.setState((x) => ({
      handleProjectModal: {
        ...x.handleProjectModal,
        isShow: false,
      },
    }));
  };

  resubmitDeniedProject = (e) => {};

  handleProjectSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    let isValid = true;

    //console.log(isValid);
    if (form.checkValidity() === false || !isValid) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleProjectModal: {
          ...x.handleProjectModal,
          isValidated: false,
        },
        selection: [],
      }));
    } else {
      this.handleHideModal();

      if (this.state.selectedOperation.id === 0) {
        this.createProject();
      } else {
        this.UpdateProject();
      }
      this.clearInputs();
      this.setState((x) => ({
        handleProjectModal: {
          ...x.handleProjectModal,
          isValidated: false,
        },

        selection: [],
      }));
    }
  };

  handleStatusChange = (e) => {
    const dateStart = this.state.txtFiltDateStart.value;
    const dateEnd = this.state.txtFiltDateEnd.value;
    if (dateStart && dateEnd && this.state.chkDateFilter.value) {
      this.readProject(e.target.value, (x) => {}, dateStart, dateEnd, 0);
    } else {
      this.readProject(e.target.value, (x) => {}, null, null, 1);
    }
    this.setState(
      {
        selectedProject: {
          id: e.target.value,
          name: e.target.options[e.target.selectedIndex].text,
        },
      }
      //this.readProject(e.target.value)
    );
  };

  handleExit = (e) => {};

  handleActionChange = (e) => {
    switch (e.target.name) {
      case "btnViewPhases":
        //console.log(this.state.projectSelection);
        this.props.state.handleContentChange(
          "btnViewProject",
          this.state.projectSelection,
          this.state.projectSelection.finance
        );
        break;
      case "btnAddProject":
        this.setState((x) => ({
          selectedOperation: { id: 0, name: "Add" },
          handleProjectModal: { ...x.handleProjectModal, isView: false },
          isPWDisabled: false,
          projectSelection: [],
          selection: [],
        }));
        this.clearInputs();
        this.handleExit();
        break;
      case "btnUnlockProject":
        let statSel = false;
        if (this.props.state.user.type.includes(7)) {
          statSel = true;
        }
        this.setState((x) => ({
          txtProjectName: {
            ...x.txtProjectName,

            readOnly: false,
          },
          txtDescription: {
            ...x.txtDescription,

            readOnly: false,
          },
          txtAddress: {
            ...x.txtAddress,

            readOnly: false,
          },
          txtNote: { ...x.txtNote, readOnly: false },
          txtDateStart: {
            ...x.txtDateStart,

            readOnly: false,
          },
          txtDateEnd: { ...x.txtDateEnd, readOnly: false },
          txtDateEstEnd: {
            ...x.txtDateEstEnd,

            readOnly: false,
          },
          txtBudget: { ...x.txtBudget, readOnly: false },
          handleProjectModal: {
            ...x.handleProjectModal,
            isView: false,
          },
          projectStatusSelect: {
            ...x.projectStatusSelect,

            readOnly: statSel,
          },
          projectDelegateTypeSelect: {
            ...x.projectDelegateTypeSelect,

            readOnly: false,
          },
        }));
        break;
      case "btnEditProject":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Please select 1 Project`,

              isYesNo: false,
            },
          });
        } else {
          if (this.state.selection.length === 1) {
            this.setState((x) => ({
              selectedOperation: { id: 1, name: "Edit" },
              handleProjectModal: { ...x.handleProjectModal, isView: true },
              isPWDisabled: true,
            }));
            this.handleShowModal();
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please select a Project`,
                isYesNo: false,
              },
            });
          }
        }

        break;
      case "btnDeleteProject":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Please select 1 Project`,
              isYesNo: false,
            },
          });
        } else {
          if (this.state.selection.length === 1) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Confirmation",
                desc: `Remove Project?`,
                isYesNo: true,
                cb: (e) => {
                  this.deleteProject();
                  this.handleHideModal();
                },
              },
            });
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please select a Project`,
                isYesNo: false,
              },
            });
          }
        }
        break;
      default:
        break;
    }
  };

  populateModal = (e, view, _readOnly) => {
    // console.log(
    //   moment(e.lastDateModified, "YYYY-MM-DDTk:m:Z").format("YYYY-MM-DDTHH:mm")
    // );
    this.setState((x) => ({
      projectId: e.id,
      txtProjectName: {
        ...x.txtProjectName,
        value: e.name,
        readOnly: _readOnly,
      },
      txtDescription: {
        ...x.txtDescription,
        value: e.description,
        readOnly: _readOnly,
      },
      txtAddress: {
        ...x.txtAddress,
        value: e.address,
        readOnly: _readOnly,
      },
      txtNote: { ...x.txtNote, value: e.note, readOnly: _readOnly },
      txtDateStart: {
        ...x.txtDateStart,
        value: e.dateStart != null ? e.dateStart : "",
        readOnly: _readOnly,
      },
      txtDateEnd: {
        ...x.txtDateEnd,
        value: e.dateEnd != null ? e.dateEnd : "",
        readOnly: _readOnly,
      },
      txtDateEstEnd: {
        ...x.txtDateEstEnd,
        value: e.dateEstEnd != null ? e.dateEstEnd : "",
        readOnly: _readOnly,
      },
      txtBudget: { ...x.txtBudget, value: e.budget, readOnly: _readOnly },
      handleProjectModal: {
        ...x.handleProjectModal,
        isView: view,
        readOnly: _readOnly,
      },
      projectStatusSelect: {
        ...x.projectStatusSelect,
        value: e.status,
        readOnly: _readOnly,
      },
      txtProjUniqueID: {
        ...x.txtProjUniqueID,
        value: e.uniqueId,
        readOnly: _readOnly,
      },
      txtLastModifiedBy: {
        ...x.txtLastModifiedBy,
        value: e.lastModifiedBy != null ? e.lastModifiedBy.name : "None",
        readOnly: _readOnly,
      },
      txtDatePosted: {
        ...x.txtDatePosted,
        value:
          e.datePosted != null
            ? moment(e.datePosted, "YYYY-MM-DDTk:m:Z").format(
                "YYYY-MM-DDTHH:mm"
              )
            : "",
        readOnly: _readOnly,
      },
      txtDateLastModified: {
        ...x.txtDateLastModified,
        value:
          e.lastDateModified != null
            ? moment(e.lastDateModified, "YYYY-MM-DDTk:m:Z").format(
                "YYYY-MM-DDTHH:mm"
              )
            : "",
        readOnly: _readOnly,
      },
      projectDelegateTypeSelect: {
        ...x.projectDelegateTypeSelect,

        readOnly: _readOnly,
      },
    }));
  };

  clearInputs = () => {
    this.setState((x) => ({
      txtProjectName: { ...x.txtProjectName, value: "", readOnly: false },
      txtDescription: { ...x.txtDescription, value: "", readOnly: false },
      txtAddress: { ...x.txtAddress, value: "", readOnly: false },
      txtNote: { ...x.txtNote, value: "", readOnly: false },
      txtDateStart: { ...x.txtDateStart, value: "", readOnly: false },
      txtDateEnd: { ...x.txtDateEnd, value: "", readOnly: false },
      txtDateEstEnd: { ...x.txtDateEstEnd, value: "", readOnly: false },
      txtBudget: { ...x.txtBudget, value: "", readOnly: false },
      projectStatusSelect: {
        ...x.projectStatusSelect,
        value: 1,
        readOnly: false,
      },
      txtProjUniqueID: {
        ...x.txtProjUniqueID,
        value: "",
        readOnly: false,
      },
      txtLastModifiedBy: {
        ...x.txtLastModifiedBy,
        value: "",
        readOnly: false,
      },
      txtDatePosted: {
        ...x.txtDatePosted,
        value: "",
        readOnly: false,
      },

      txtDateLastModified: {
        ...x.txtDateLastModified,
        value: "",
        readOnly: false,
      },
      projectDelegateTypeSelect: {
        ...x.projectDelegateTypeSelect,

        readOnly: false,
      },
    }));
  };

  deleteProjectActor = (id) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Unassign User? Doing so will remove his/her access to this project.`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting Project Modification",
              id: loadId,
            },
          });
          this.setState((prevState) => ({
            actorDataIsLoading: true,
          }));
          let data = {
            projectId: this.state.projectId,
            actorIds: [id],
          };
          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "delete",
              data: data,
              payload: "project/deleteActorsToProject",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.readSelectedproject(
              this.state.projectSelection.id,
              async (x) => {
                // const filtered = await this.props.project.filter(
                //   (x) => x.id === this.state.projectSelection.id
                // )[0];
                // this.setState((prevState) => ({
                //   projectSelection: filtered,
                // }));
              }
            );
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `Unassigned User Successfully`,
              },
            });
          });
        },
      },
    });
  };

  addProjectActor = (e) => {
    if (
      Number(this.state.projectDelegateActorSelect.value) === 0 ||
      (this.state.projectDelegateActorSelect.value === null &&
        Number(this.state.selectedOperation.id) === 1)
    ) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select a User`,
          isYesNo: false,
        },
      });
    } else {
      if (
        this.state.projectActorsArr.includes(
          this.state.projectDelegateActorSelect.value
        )
      ) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `User already assigned`,
            isYesNo: false,
          },
        });
      } else {
        const loadId = Filters.uuidv4();
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: {
            isLoading: true,
            name: "Assigning User",
            id: loadId,
          },
        });
        this.setState((prevState) => ({
          actorDataIsLoading: true,
        }));
        let data = {
          projectId: this.state.projectId,
          actorIds: [this.state.projectDelegateActorSelect.value],
        };
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            reqType: "post",
            data: data,
            payload: "project/addActorsToProject",
            resolve: resolve,
            reject: reject,
          });
        }).then((api) => {
          this.readSelectedproject(
            this.state.projectSelection.id,
            async (x) => {
              // const filtered = await this.props.project.filter(
              //   (x) => x.id === this.state.projectSelection.id
              // )[0];
              // this.setState((prevState) => ({
              //   projectSelection: filtered,
              // }));
            }
          );

          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: { isLoading: false, id: loadId },
          });
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `Assigned User Successfully`,
            },
          });
        });
      }
    }
  };

  readActor = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Actors", id: loadId },
    });
    this.setState((prevState) => ({
      actorDataIsLoading: true,
    }));
    let url = `actor/getActorByMultiTypes?types=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let actorData = api.data;

      if (Number(actorData.length) === 0) {
        this.setState((prevState) => ({
          actorData: actorData,
          actorDataIsLoading: false,
        }));
      }
      this.setState((x) => ({
        projectDelegateActorSelect: {
          ...x.projectDelegateActorSelect,
          value: 0,
        },

        actorData: actorData,
        actorDataIsLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readProjectFinance = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Project", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `project/getProjectFinance?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let PFD = api.data;
      this.setState((x) => ({
        projectFinance: {
          loss: PFD.loss,
          savings: PFD.savings,
          totalBudget: PFD.totalBudget,
          used: PFD.used,
          initialCostBudgetTotal: PFD.phaseBudgetCost,
        },
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
    });
  };

  readSelectedproject = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Project", id: loadId },
    });
    let url = `project/getProject?id=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      // console.log("SELECTED PROJECT:", api.data);

      this.props.dispatch({
        type: "API_STORE_PROJECT_SELECTED",
        payload: {
          data: { ...projData[0] },
        },
      });

      this.setState((prevState) => ({
        projectSelection: projData[0],
        //phaseData: projData[0].phases,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb(api.data);
      }

      //console.log(this.props);
    });
  };

  getProjectActors = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Fetching Project Delegation",
        id: loadId,
      },
    });
    let url = `project/getProjectActors?idList=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("SELECTED PROJECT ACTORS:", api.data);

      this.setState((prevState) => ({
        projectActors: projData,
        //phaseData: projData[0].phases,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
      //console.log(this.props);
    });
  };

  readProject = (e, cb, start, end, noDate) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Project", id: loadId },
    });
    let url = `project/getProjectByDate?status=${e}&from=${start}&to=${end}&noDate=${noDate}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let projData = api.data;
      //console.log("PROJECTS:", api.data);

      this.props.dispatch({
        type: "API_STORE_PROJECT",
        payload: {
          data: projData,
          selected: this.state.projectSelection,
        },
      });
      this.props.dispatch({
        type: "API_STORE_PROJECT_BAK",
        payload: {
          data: projData,
        },
      });

      this.setState(
        (prevState) => ({
          projData: projData,
          bakProjData: projData,
          isLoading: false,
        }),
        (x) => {
          if (cb) cb();
        }
      );
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      //console.log(this.props);
    });
  };

  createProject = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting new Project", id: loadId },
    });
    let urlProjectAdd = "project";
    let projMan = "";
    if (this.props.state.user.type.includes(7))
      projMan = this.props.state.user.id;
    let data = {
      name: this.state.txtProjectName.value.toUpperCase(),
      description: this.state.txtDescription.value.toUpperCase(),
      address: this.state.txtAddress.value.toUpperCase(),
      note: this.state.txtNote.value,
      dateStart:
        this.state.txtDateStart.value === ""
          ? null
          : this.state.txtDateStart.value,
      dateEnd:
        this.state.txtDateEnd.value === "" ? null : this.state.txtDateEnd.value,
      dateEstEnd:
        this.state.txtDateEstEnd.value === ""
          ? null
          : this.state.txtDateEstEnd.value,
      budget: Number(this.state.txtBudget.value),
      status: Number(this.state.projectStatusSelect.value),
      projectActors: [projMan],
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: urlProjectAdd,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readProject(0);
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Project Added Successfully`,
        },
      });
    });
  };

  submitForReApproval = (cb) => {
    const data = {
      uniqueId: this.state.projectSelection.uniqueId,
      status: 2,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "global/updateStatus",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      cb();
    });
  };

  UpdateProject = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Project Update",
        id: loadId,
      },
    });
    let projStatus = this.state.projectStatusSelect.value;

    if (Number(this.state.projectSelection.status) === 4) {
      projStatus = null;
    }
    let data = {
      id: this.state.projectId,
      name: this.state.txtProjectName.value.toUpperCase(),
      description: this.state.txtDescription.value.toUpperCase(),
      address: this.state.txtAddress.value.toUpperCase(),
      note: this.state.txtNote.value,
      dateStart:
        this.state.txtDateStart.value === ""
          ? null
          : this.state.txtDateStart.value,
      dateEnd:
        this.state.txtDateEnd.value === "" ? null : this.state.txtDateEnd.value,
      dateEstEnd:
        this.state.txtDateEstEnd.value === ""
          ? null
          : this.state.txtDateEstEnd.value,
      budget: Number(this.state.txtBudget.value),
      status: Number(projStatus),
    };
    let urlUpdateProject = "project/updateProject";
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: urlUpdateProject,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (Number(this.state.projectSelection.status) === 4) {
        this.submitForReApproval((x) => {
          this.readProject(0);
          this.setState((prevState) => ({
            isLoading: false,
          }));
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: { isLoading: false, id: loadId },
          });
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `Project Edited Successfully`,
            },
          });
        });
      } else {
        this.readProject(0);
        this.setState((prevState) => ({
          isLoading: false,
        }));
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
        this.props.dispatch({
          type: "TOAST_ADD",
          payload: {
            title: `Project Edited Successfully`,
          },
        });
      }
    });
  };

  deleteProject = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Project Deletion",
        id: loadId,
      },
    });
    let dat = {
      id: this.state.projectId,
    };

    let urlProjectDelete = "project/deleteProject";

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: urlProjectDelete,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readProject(0);
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Project Deleted Successfully`,
        },
      });
    });
  };

  toggleSelection = async (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
    } else {
      selection.length = 0;
      selection.push(key);
    }
    console.log("SELECTED PROJECT: ", row);
    sessionStorage.setItem("selProjId", row.id);
    this.setState((x) => ({
      selection,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    } else {
      this.setState({ selection: [] });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  testprint = (rowinfo) => {
    //console.log("sahfduiosahf");
  };

  rowFn = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        e.preventDefault();
        e.stopPropagation();
        // if (e.target.name === "btnViewPhases") {

        //   this.populateModal(rowInfo.original, true, true);

        //   this.props.state.handleContentChange(
        //     "btnViewProject",
        //     rowInfo.original,
        //     rowInfo.original.finance
        //   );

        // }
        if (
          e.currentTarget.className.search("expandable") < 0 &&
          e.target.name !== "btnViewRow"
        ) {
          if (
            this.state.selection.length > 0 &&
            this.state.selection[0] === `select-${rowInfo.original.id}`
          ) {
            this.setState((x) => ({
              selection: [],
            }));
          } else {
            if (rowInfo) {
              this.toggleSelection(rowInfo.index, null, rowInfo.original);
              //console.log(this.state.projectSelection);

              this.readSelectedproject(rowInfo.original.id, (fetched) => {
                //console.log(x);
                if (fetched.length > 0) {
                  const _proj = fetched[0];
                  this.setState((x) => ({
                    projectActorsArr: _proj.projectActors,
                  }));
                  this.clearInputs();
                  this.populateModal(_proj, false, true);

                  this.setState(
                    {
                      selection: ["select-" + rowInfo.original.id],
                      //projectSelection: rowInfo.original,
                      selectedOperation: { id: 6, name: "View" },
                    },
                    (x) => {
                      this.handleActionChange({
                        target: { name: "btnEditProject" },
                      });
                    }
                  );
                }
              });
            }
          }
        }

        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background:
          rowInfo &&
          this.state.selection.includes(`select-${rowInfo.original.id}`) &&
          "lightgreen",
        //   ||
        // (rowInfo && rowInfo.original.status == 6 && "green") ||
        // (rowInfo && rowInfo.original.status == 1 && "yellow"),
      },
    };
  };

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.props.bakProjData,
        event.target.value
      );

      if (data != null) {
        this.props.dispatch({
          type: "API_STORE_PROJECT",
          payload: {
            data: data,
          },
        });
        //this.setState({ projData: data });
      } else {
        this.props.dispatch({
          type: "API_STORE_PROJECT",
          payload: {
            data: this.props.state.bakProjData,
          },
        });
        //this.setState({ projData: this.state.bakProjData });
      }
    } else this.setState({ isFiltered: false });
  };

  render() {
    //console.log(this.props.state.user);
    let project = (
      <Project
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        handleShowModal={this.handleShowModal}
        handleHideModal={this.handleHideModal}
        onShow={this.state.isVisible}
        userType={this.props.state.user.type}
        dispatch={this.props.dispatch}
      />
    );
    let tablelist = (
      <Table
        data={this.props.project}
        columns={projectColumn(this.props.state.user.type)}
        keyField="id"
        {...this.props}
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        // selectAll={this.state.selectAll}
        // selectType="checkbox"
        loading={this.state.isLoading}
        // toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        defaultPageSize={20}
        style={{
          height: "60vh",
        }}
        className=" -highlight"
      />
    );
    return (
      <>
        {/* {((e) => {
          if (this.state.isLoading) {
            return (
              <>
                <LoadingProcess />
              </>
            );
          }
        })()} */}

        {project}
        {tablelist}
      </>
    );
  }
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.project !== prevProps.project) {
  //     this.setState({
  //       projData: [...this.state.projData],
  //     });
  //   }
  // }
}
const mapStateToProps = (state) => {
  return {
    project: state.apiData.projectData,
    bakProjData: state.apiData.bakProjectData,
  };
};
export default connect(mapStateToProps)(ProjectProcess);
