import React from "react";
import Workspace from "./Workspace";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import ProjectProcess from "../Project/ProjectProcess";
import DashboardProcess from "../Dashboard/DashboardProcess";
import ActorProcess from "../Actor/ActorProcess";
import ProfileProcess from "../Profile/ProfileProcess";
import VerificationProcess from "../Verification/VerificationProcess";
import Phase from "../Phase/PhaseProcess";

//import socketIOClient from "socket.io-client";
let CryptoJS = require("crypto-js");
//let socket = null;
//const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

const defaultNav = {
  btnContActor: { val: false, name: "Actor" },
  btnContVerification: { val: false, name: "Verification" },
  btnContProject: { val: false, name: "Project" },
  btnContDashboard: { val: false, name: "Dashboard" },
  btnContProfile: { val: false, name: "Profile" },
};

class WorkspaceProcess extends React.Component {
  constructor(props) {
    super(props);
    //var bytes  = CryptoJS.AES.decrypt(localStorage.getItem("LUD"), process.env.REACT_APP_SEK);
    this.empData = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem("LUD"),
        process.env.REACT_APP_SEK
      ).toString(CryptoJS.enc.Utf8)
    );

    this.state = {
      handleContentChange: this.handleContentChange.bind(this),
      handleLogout: this.handleLogout.bind(this),
      user: this.empData,
      content: 1,
      selectedNav: "Project Management",
      navItems: {
        btnContActor: { val: false, name: "Actor" },
        btnContVerification: { val: false, name: "Verification" },
        btnContProject: { val: true, name: "Project" },
        btnContDashboard: { val: false, name: "Dashboard" },
        btnContProfile: { val: false, name: "Profile" },
      },
    };
  }

  componentDidMount() {
    //this.checkSession();
    // if (localStorage.getItem("LUT") != null && !socket) {
    //   socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);
    // }
    // let userData = JSON.parse(
    //   CryptoJS.AES.decrypt(
    //     localStorage.getItem("LUD"),
    //     process.env.REACT_APP_SEK
    //   ).toString(CryptoJS.enc.Utf8)
    // );
    //console.log(this.props);
    // this.setState({ userType: userData.type });

    if (this.state.user.type.includes(4)) {
      this.setState((prevState) => ({
        content: <DashboardProcess />,
        selectedNav: "Dashboard",
        navItems: {
          ...defaultNav,
          btnContProject: { val: true, name: "Dashboard" },
        },
      }));
    } else {
      this.setState((prevState) => ({
        content: <ProjectProcess state={this.state} />,
        selectedNav: "Project Management",
        navItems: {
          ...defaultNav,
          btnContProject: { val: true, name: "Project Management" },
        },
      }));
    }
  }

  componentWillUnmount() {
    // if (this.props.socketio.socket) {
    //   this.props.socketio.socket.disconnect();
    //   this.props.socketio.socket.close();
    // }
  }

  handleLogout = (e) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to Logout?`,
        isYesNo: true,
        cb: (e) => {
          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "post",

              payload: "auth/logout",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            localStorage.removeItem("LUD");
            localStorage.removeItem("LUT");
            localStorage.removeItem("IA");
            this.props.socketio.socket.disconnect();
            window.location.reload();
          });
        },
      },
    });
  };

  handleContentChange = (e, dat, dat2) => {
    //console.log(e);
    if (!e.target) {
      if (e !== "btnProjectManagement" && e !== "btnViewProject") {
        sessionStorage.removeItem("selProjId");
      }
      switch (e) {
        case "btnActorManagement":
          this.setState((prevState) => ({
            content: <ActorProcess state={this.state} />,
            selectedNav: "Actor",
            navItems: {
              ...defaultNav,
              btnContActor: { val: true, name: "Actor" },
            },
          }));
          //console.log(this.state);
          break;
        case "btnVerification":
          this.setState((prevState) => ({
            content: <VerificationProcess />,
            selectedNav: "Verification",
            navItems: {
              ...defaultNav,
              btnContVerification: { val: true, name: "Verification" },
            },
          }));
          break;
        case "btnDashboard":
          this.setState((prevState) => ({
            content: <DashboardProcess state={this.state} />,
            selectedNav: "Dashboard",
            navItems: {
              ...defaultNav,
              btnContProject: { val: true, name: "Dashboard" },
            },
          }));

          break;
        case "btnProjectManagement":
          sessionStorage.removeItem("selProjId");
          this.setState((prevState) => ({
            content: <ProjectProcess state={this.state} />,
            selectedNav: "Project Management",
            navItems: {
              ...defaultNav,
              btnContProject: { val: true, name: "Project Management" },
            },
          }));

          break;
        case "btnViewProject":
          //console.log(dat2);
          this.setState((prevState) => ({
            content: <Phase state={this.state} />,
            selectedNav: "Project Management",
            navItems: {
              ...defaultNav,
              btnContProject: { val: true, name: "Project Management" },
            },
          }));

          break;
        default:
          break;
      }
    } else {
      if (e.target.name === "btnProfile")
        this.setState((prevState) => ({
          content: <ProfileProcess />,
          selectedNav: "Profile",
          navItems: {
            ...defaultNav,
            btnContProfile: { val: true, name: "Profile" },
          },
        }));
    }
  };

  render() {
    return (
      <>
        <Workspace
          content={this.state.content}
          state={this.state}
          decUser={this.empData}
          userType={this.userType}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { socketio: state.SocketHandler };
};
export default connect(mapStateToProps)(withRouter(WorkspaceProcess));
