import React from "react";

import Table from "react-table";
import Filters from "../../functions/filters";
import { connect } from "react-redux";
import selectTableHOC from "react-table/lib/hoc/selectTable";

import moment from "moment";
import { Order, orderColumn, historyColumn } from "./Order";
//import socketIOClient from "socket.io-client";
//const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

const SelectTable = selectTableHOC(Table);

class PhaseProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleOrderOverviewModal: this.props.state.handleOrderOverviewModal,
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      handlePDFExportOrder: this.handlePDFExportOrder.bind(this),

      parseOrderStatus: this.parseOrderStatus.bind(this),

      handleOrderChange: this.handleOrderChange.bind(this),
      handleActionChangeOrder: this.handleActionChangeOrder.bind(this),

      handleSupplierChange: this.handleSupplierChange.bind(this),
      deleteFile: this.deleteFile.bind(this),

      handleOrdFilterChange: this.handleOrdFilterChange.bind(this),
      submitForReApproval: this.submitForReApproval.bind(this),

      UpdateOrderStatus: this.UpdateOrderStatus.bind(this),

      handleOrderModal: {
        handleSubmit: this.handleOrderSubmit.bind(this),
        handleExit: this.handleOrderExit.bind(this),
        show: this.handleOrderShowModal.bind(this),
        hide: this.handleOrderHideModal.bind(this),
        isView: false,
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isEdit: false,
        title: "",
        desc: "",
        response: false,
      },

      selectAll: false,
      selection: [],
      searchInput: "",

      checkboxTable: {},
      columns: orderColumn,

      selectedOrderOperation: { id: 0, name: "Add", disabled: false },
      selectedOrderModalOperation: { id: 0, name: "Add" },

      isLoading: false,
      isFiltered: false,
      isDetailed: { val: false, name: "View Details" },

      isGenerate: false,

      historyData: [],
      isHistoryLoading: false,

      orderData: [],
      bakOrderData: [],
      orderSelection: null,
      orderId: 0,
      orderStatus: "",
      materialId: this.props.material.id,
      selMaterialData: {
        budget: 0,
        requiredQty: 0,
        totalQty: 0,
        unit: { name: "" },
        item: { name: "" },
      },
      selMaterialDataFinance: { used: 0, loss: 0, savings: 0 },
      supplierId: 0,

      supplierData: [],
      topSupplierData: [],

      txtOrderQty: {
        value: "",
        placeholder: "Order Qty",
        className: "mb-3",
        readOnly: false,
      },
      txtOrderNotes: {
        value: "",
        placeholder: "Notes",
        className: "mb-3",
        readOnly: false,
      },
      txtOrderBrand: {
        value: "",
        placeholder: "Brand",
        className: "mb-3",
        readOnly: false,
      },
      txtOrderCost: {
        value: "",
        placeholder: "Cost",
        className: "",
        readOnly: false,
      },
      txtOrderStatus: {
        value: "",
        placeholder: "Status",
        className: "mb-3",
        readOnly: false,
      },
      txtOrderDateReceived: {
        value: "",
        placeholder: "Cost",
        className: "mb-3",
        readOnly: false,
      },
      txtSupplierCrud: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
        data: {},
      },
      txtDateLastModified: {
        value: "",
        placeholder: "",
        className: "mb-3",
        readOnly: false,
      },
      txtLastModifiedBy: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtProjUniqueID: {
        value: "",
        placeholder: "ID",
        className: "mb-3",
        readOnly: false,
      },
      txtDatePosted: {
        value: "",
        placeholder: "None",
        className: "mb-3",
        readOnly: false,
      },
      txtBaseCost: {
        value: "",
        placeholder: "Base cost",
        className: "",
        readOnly: false,
      },
      fileUpload: {
        handleSubmit: this.handleFileUploadSubmit.bind(this),
        handler: this.handleFileUploadChange.bind(this),
        value: "",
      },
      //Supplier
      handleActorModal: {
        handleSubmit: this.handleActorSubmit.bind(this),
        show: this.handleActorShowModal.bind(this),
        hide: this.handleActorHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      txtActorName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtContact: {
        value: "",
        placeholder: "Contact",
        className: "mb-3",
        readOnly: false,
      },

      txtAddress: {
        value: "",
        placeholder: "Address",
        className: "mb-3",
        readOnly: false,
      },
      selMatFilter: {
        value: 1,
        readOnly: false,
      },
    };
  }

  handleOrdFilterChange = async (e) => {
    let filteredOrdData = [];

    if (Number(e) === 0) {
      this.setState((x) => ({
        orderData: this.state.bakOrderData,
      }));
    } else {
      filteredOrdData = await this.state.bakOrderData.filter(
        (x) => Number(x.status) === Number(e)
      );
      //console.log(filteredOrdData);
      this.setState((x) => ({
        orderData: filteredOrdData,
      }));
    }
  };

  handleActorSubmit = (e, cb) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },
      }));
    } else {
      this.createActor();
      this.handleActorHideModal();
      this.clearActorInputs();
      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },
      }));
    }
  };

  createActor = () => {
    let urlActorAdd = "actor";

    let data = {
      name: this.state.txtActorName.value.toUpperCase(),
      address: this.state.txtAddress.value.toUpperCase(),
      email: "",
      contact: this.state.txtContact.value,
      username: "",
      password: "",
      website: "",
      type: [3],
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: urlActorAdd,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readSupplier();

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Supplier Added Successfully`,
        },
      });
    });
  };

  clearActorInputs = () => {
    this.setState((x) => ({
      txtActorName: { ...x.txtActorName, value: "", readOnly: false },
      txtContact: { ...x.txtContact, value: "", readOnly: false },
      txtAddress: { ...x.txtAddress, value: "", readOnly: false },
    }));
  };

  handleActorShowModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: true,
      },
    }));
  };
  handleActorHideModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: false,
      },
    }));
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  deleteFile = (filename, origName) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to delete ${origName} ?`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: { isLoading: true, name: "Removing File", id: loadId },
          });
          let dat = {
            uniqueId: this.state.orderSelection.uniqueId,
            filename: filename,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "delete",
              data: dat,
              payload: "global/deleteFile",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            const selId = this.state.orderSelection.id;
            this.readOrder(null, (x) => {
              const filteredOrder = this.state.orderData.filter((x) => {
                return Number(x.id) === selId;
              })[0];

              if (filteredOrder)
                this.setState((x) => ({ orderSelection: filteredOrder }));
            });
            this.setState((prevState) => ({
              isLoading: false,
            }));
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `File Deleted Successfully`,
              },
            });
          });
        },
      },
    });
  };

  handleFileUploadSubmit = (e) => {
    e.preventDefault();
    let urlInventoryEdit = "global/uploadFiles";

    let currentFiles = this.state.fileUpload.value;
    const formData = new FormData();
    formData.append("uniqueId", this.state.txtProjUniqueID.value);
    for (let index = 0; index < currentFiles.length; index++) {
      formData.append("uploadedFiles", currentFiles[index]);
    }

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: urlInventoryEdit,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const selId = this.state.orderSelection.id;
      this.readOrder(null, (x) => {
        const filteredOrder = this.state.orderData.filter((x) => {
          return Number(x.id) === selId;
        })[0];

        if (filteredOrder)
          this.setState((x) => ({ orderSelection: filteredOrder }));
      });
    });
  };
  handleFileUploadChange = (e) => {
    let currentFiles = e.target.files;

    this.setState((x) => ({
      fileUpload: {
        ...x.fileUpload,
        value: currentFiles,
      },
    }));
  };

  handlePDFExportOrder = (e) => {
    this.setState((x) => ({
      isGenerate: true,
    }));
  };

  readTopSupplier = (cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Top Suppliers", id: loadId },
    });
    let url = `inventory/getTopSuppliers?materialId=${this.state.materialId}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const API_DATA = api.data;
      let supplierData = [];

      if (API_DATA) {
        API_DATA.forEach((s) => {
          supplierData.push(s.supplier);
        });
        //filter null
        supplierData = supplierData.filter((x) => x);
      }

      this.setState((prevState) => ({
        topSupplierData: supplierData,
        isLoading: false,
      }));
      //console.log("TOP SUPPLIER", API_DATA);
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
    });
  };

  readItemHistoryPurchases = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Item Purchases", id: loadId },
    });
    let url = `inventory/getItemHistoryPrices?materialId=${this.state.materialId}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const API_DATA = api.data;
      this.setState((prevState) => ({
        historyData: API_DATA,
        isLoading: false,
      }));

      //console.log("ORDER HISTORY", API_DATA);
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readSupplier = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Suppliers", id: loadId },
    });
    let url = `actor/getActorByType?type=${3}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let actorData = api.data;

      this.readTopSupplier((x) => {
        const tempSupp = [];

        if (this.state.topSupplierData) {
          tempSupp.push({
            options: this.state.topSupplierData,
            label: "Top Suppliers",
          });
        }
        if (actorData) {
          tempSupp.push({ options: actorData, label: "suppliers" });
        }

        this.setState((prevState) => ({
          supplierData: [...tempSupp],
          isLoading: false,
        }));
      });
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  handleSupplierChange = (e, isFront) => {
    if (!isFront) {
      if (e.length > 0) {
        this.setState((x) => ({
          supplierId: e[0].id,
          txtSupplierCrud: {
            ...x.txtSupplierCrud,
            data: e[0],
            value: e[0].id,
          },
        }));
        //this.populatePhaseModal(e[0], false, false);
        //this.readMaterial(e[0].id);
        //console.log(this.state.supplierId);
      } else {
        this.setState((x) => ({
          supplierId: 0,
          txtSupplierCrud: {
            ...x.txtSupplierCrud,
            data: {},
            value: 0,
          },
        }));
        //console.log(this.state.supplierId);
      }
    } else {
      let tempId = e.target.value;
      let dat =
        this.state.supplierData[
          this.state.supplierData.findIndex((x) => x.id === tempId)
        ];
      //this.populatePhaseModal(dat, false, false);
      //console.log(dat);
      this.setState((x) => ({
        supplierId: tempId,
        txtSupplierCrud: {
          ...x.txtSupplierCrud,
          data: dat,
          value: tempId,
        },
      }));
      //this.readMaterial(tempId);
      //console.log(this.state.supplierId, tempId);
    }
  };

  componentDidMount() {
    this.readSupplier();
    this.readItemHistoryPurchases();
    this.readOrder();

    this.props.socketio.socket.on(
      `multMaterialsUpdate${this.props.material.projectId}`,
      (data) => {
        this.readOrder();
        // this.props.dispatch({
        //   type: "TOAST_ADD",
        //   payload: {
        //     title: `Order refreshed`,
        //   },
        // });
      }
    );
    this.props.socketio.socket.on(`supplierRefresh`, (data) => {
      this.readSupplier();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Suppliers refreshed`,
      //   },
      // });
    });
  }

  componentWillUnmount() {
    //this.props.socketio.socket.disconnect();
  }

  handleOrderExit = (e) => {
    //this.props.showModal();
  };

  handleOrderSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    let isValid = true;

    if (
      Number(this.state.txtSupplierCrud.value) === 0 &&
      this.state.orderSelection?.material?.materialType === "material"
    )
      isValid = false;

    //console.log(isValid);
    if (form.checkValidity() === false || !isValid) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleOrderModal: {
          ...x.handleOrderModal,
          isValidated: false,
        },
        selection: [],
      }));
    } else {
      if (this.state.selectedOrderOperation.id === 0) {
        //console.log(this.state.phaseId, this.state.phaseData);
        if (Number(this.props.material.warn) > 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Notice",
              desc: `Order has warnings. Are you sure you want to continue?`,
              isYesNo: true,
              cb: (e) => {
                this.props.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Confirmation",
                    desc: `Please review before submitting to admin. Submit now?`,
                    isYesNo: true,
                    cb: (e) => {
                      this.createOrder();
                      this.handleOrderHideModal();
                    },
                  },
                });
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Please review before submitting to admin. Submit now?`,
              isYesNo: true,
              cb: (e) => {
                this.createOrder();
                this.handleOrderHideModal();
              },
            },
          });
        }
      } else {
        if (Number(this.props.material.warn) > 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Notice",
              desc: `Order has warnings. Are you sure you want to continue?`,
              isYesNo: true,
              cb: (e) => {
                this.props.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Confirmation",
                    desc: `Please review before submitting to admin. Submit now?`,
                    isYesNo: true,
                    cb: (e) => {
                      if (Number(this.state.orderSelection.status) === 2) {
                        this.submitForReApproval();
                      } else {
                        this.UpdateOrder();
                      }
                      this.handleOrderHideModal();
                    },
                  },
                });
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Please review before submitting to admin. Submit now?`,
              isYesNo: true,
              cb: (e) => {
                if (Number(this.state.orderSelection.status) === 2) {
                  this.submitForReApproval();
                } else {
                  this.UpdateOrder();
                }
                this.handleOrderHideModal();
              },
            },
          });
        }
      }
      //this.clearOrderInputs();
      this.setState((x) => ({
        handleOrderModal: {
          ...x.handleOrderModal,
          isValidated: false,
        },

        selection: [],
      }));
    }
  };

  handleOrderShowModal = (e) => {
    this.setState((x) => ({
      handleOrderModal: {
        ...x.handleOrderModal,
        isShow: true,
      },
    }));
  };
  handleOrderHideModal = (e) => {
    this.setState((x) => ({
      handleOrderModal: {
        ...x.handleOrderModal,
        isShow: false,
      },
    }));
  };
  handleOrderChange = (e, isFront) => {
    if (!isFront) {
      if (e.length > 0) {
        this.setState((x) => ({
          txtOrderCrud: {
            ...x.txtOrderCrud,
            data: e[0],
            value: e[0].id,
          },
        }));
        this.populateOrderModal(e[0], false, false);
      }
    } else {
      let dat =
        this.state.orderData[
          this.state.orderData.findIndex((x) => x.id === e.target.value)
        ];
      this.populateOrderModal(dat, false, false);
      this.setState((x) => ({
        txtOrderCrud: {
          ...x.txtOrderCrud,
          data: dat,
          value: e.value,
        },
      }));
    }
  };

  handleActionChangeOrder = (e, data) => {
    //e.stopPropagation();
    switch (e.target.name) {
      case "btnDLLink":
        this.downloadFile(data);
        //console.log(data);

        break;
      case "btnAddOrder":
        if (this.state.phaseId === 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Phase Found",
              desc: `please add a phase before adding orders`,
              isYesNo: false,
            },
          });
        } else {
          //console.log(this.props.project.status);
          if (Number(this.props.project.status) === 6) {
            this.setState((x) => ({
              selectedOrderOperation: {
                id: 0,
                name: "Add PR",
                disabled: false,
              },
              handleOrderModal: {
                ...x.handleOrderModal,
                isEdit: false,
                isView: false,
              },
              txtBaseCost: {
                ...x.txtBaseCost,
                value:
                  this.props.material != null
                    ? this.props.material.baseCost
                    : "None",
                readOnly: true,
              },
            }));
            this.clearOrderInputs();

            this.props.toggleBG(true);
            this.handleOrderShowModal();
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Project not confirmed",
                desc: `Project status should be ongoing to add PR. Please have the selected project verified by admin`,
                isYesNo: false,
              },
            });
          }
        }

        break;
      case "btnUnlockOrder":
        let ordStat = false;

        if (
          this.props.userType.includes(5) &&
          Number(this.state.orderSelection.status === 2)
        ) {
          ordStat = true;
        }

        this.setState((x) => ({
          txtOrderCost: {
            ...x.txtOrderCost,

            readOnly: false,
          },
          txtOrderNotes: {
            ...x.txtOrderNotes,

            readOnly: false,
          },
          txtOrderBrand: {
            ...x.txtOrderBrand,

            readOnly: false,
          },
          txtOrderQty: {
            ...x.txtOrderQty,

            readOnly: false,
          },
          txtOrderStatus: {
            ...x.txtOrderStatus,

            readOnly: ordStat,
          },
          txtSupplierCrud: {
            ...x.txtSupplierCrud,

            readOnly: false,
          },
          handleOrderModal: { ...x.handleOrderModal, isView: false },
        }));
        break;

      case "btnEditOrder":
        if (this.state.selection.length === 1) {
          this.setState((x) => ({
            selectedOrderOperation: { id: 1, name: "Edit", disabled: false },
            handleOrderModal: {
              ...x.handleOrderModal,
              isEdit: true,
              isView: true,
            },
          }));

          this.handleOrderShowModal();
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select a Order`,

              isYesNo: false,
            },
          });
        }

        break;
      case "btnDeleteOrder":
        if (this.state.selection.length === 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Remove Order?`,
              isYesNo: true,
              cb: (e) => {
                this.deleteOrder();
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select a Order`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnOpenDetails":
        if (this.state.isDetailed.val) {
          this.setState((x) => ({
            isDetailed: { val: false, name: "View Details" },
          }));
        } else {
          this.setState((x) => ({
            isDetailed: { val: true, name: "Hide Details" },
          }));
        }
        break;
      default:
        break;
    }
  };

  populateOrderModal = (e, view, _readOnly) => {
    //console.log(e);
    this.setState(
      (x) => ({
        orderId: e.id,
        txtOrderCost: {
          ...x.txtOrderCost,
          value: e.cost,
          readOnly: _readOnly,
        },
        txtOrderNotes: {
          ...x.txtOrderNotes,
          value: e.notes === null ? "" : e.notes,
          readOnly: _readOnly,
        },
        txtOrderBrand: {
          ...x.txtOrderBrand,
          value: e.brand === null ? "" : e.brand,
          readOnly: _readOnly,
        },
        txtOrderQty: {
          ...x.txtOrderQty,
          value: e.qty,
          readOnly: _readOnly,
        },
        txtOrderStatus: {
          ...x.txtOrderStatus,
          value: e.status,
          readOnly: _readOnly,
        },
        txtSupplierCrud: {
          ...x.txtSupplierCrud,
          value: e.supplier,
          readOnly: _readOnly,
        },
        txtProjUniqueID: {
          ...x.txtProjUniqueID,
          value: e.uniqueId,
          readOnly: true,
        },
        txtOrderDateReceived: {
          ...x.txtOrderDateReceived,
          value: e.dateReceived != null ? e.dateReceived : "None",
          readOnly: _readOnly,
        },

        txtLastModifiedBy: {
          ...x.txtLastModifiedBy,
          value: e.lastModifiedBy != null ? e.lastModifiedBy.name : "None",
          readOnly: true,
        },

        txtDatePosted: {
          ...x.txtDatePosted,
          value:
            e.datePosted != null
              ? moment(e.datePosted, "YYYY-MM-DDTk:m:Z").format(
                  "YYYY-MM-DDTHH:mm"
                )
              : "",
          readOnly: true,
        },
        txtDateLastModified: {
          ...x.txtDateLastModified,
          value:
            e.lastDateModified != null
              ? moment(e.lastDateModified, "YYYY-MM-DDTk:m:Z").format(
                  "YYYY-MM-DDTHH:mm"
                )
              : "",
          readOnly: true,
        },
        txtBaseCost: {
          ...x.txtBaseCost,
          value: e.material != null ? e.material.baseCost : "None",
          readOnly: true,
        },
      }),
      (x) => {
        if (this.props.userType.includes(5) && Number(e.status) === 2) {
          this.handleActionChangeOrder({
            target: { name: "btnUnlockOrder" },
          });
        }
      }
    );
  };

  clearOrderInputs = () => {
    this.setState((x) => ({
      txtOrderCost: { ...x.txtOrderCost, value: "", readOnly: false },
      txtOrderNotes: {
        ...x.txtOrderNotes,
        value: "",
        readOnly: false,
      },
      txtOrderBrand: { ...x.txtOrderBrand, value: "", readOnly: false },
      txtOrderQty: {
        ...x.txtOrderQty,
        value: "",
        readOnly: false,
      },
      txtSupplierCrud: {
        ...x.txtSupplierCrud,
        value: 0,
        readOnly: false,
      },
      txtOrderStatus: {
        ...x.txtOrderStatus,
        value: 3,
        readOnly: false,
      },

      txtOrderDateReceived: {
        ...x.txtOrderDateReceived,
        value: "",
        readOnly: false,
      },
      txtProjUniqueID: {
        ...x.txtProjUniqueID,
        value: "",
        readOnly: false,
      },
      txtDateLastModified: {
        ...x.txtDateLastModified,
        value: "",
        readOnly: false,
      },
      txtLastModifiedBy: {
        ...x.txtLastModifiedBy,
        value: "",
        readOnly: false,
      },
      txtDatePosted: {
        ...x.txtDatePosted,
        value: "",
        readOnly: false,
      },
    }));
  };

  parseOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "PR Pending Approval";
      case 2:
        return "PR Denied";
      case 3:
        return "PO Approved";
      case 4:
        return "PO Purchased";
      case 5:
        return "PO Finished";
      case 6:
        return "PO Cancelled";
      case 7:
        return "PO Dispute";

      default:
        break;
    }
  };

  submitForReApproval = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting Approval", id: loadId },
    });
    let data = {
      uniqueId: this.state.orderSelection.uniqueId,
      status: 1,
      notes: this.state.txtOrderNotes.value,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "global/updateStatus",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.UpdateOrder(true);

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Order Submitted for Approval`,
        },
      });
    });
  };

  downloadFile = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Download", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        payload: `global/getFile?uniqueId=${e.id}&filename=${e.name}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let dlData = api.data;

      const url = window.URL.createObjectURL(new Blob([dlData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${e.complete}`);
      document.body.appendChild(link);
      link.click();

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readMaterial = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Materials", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMaterial?id=${this.state.materialId}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let materialData = api.data;

      //console.log("ORDER MATERIAL: ", api.data);
      this.setState((x) => ({
        selMaterialData: materialData,
        selMaterialDataFinance: materialData.finance,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readOrder = (e, fn) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Orders", id: loadId },
    });
    this.readMaterial();

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMultMaterialByMaterial?id=${this.state.materialId}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let orderData = api.data;
      if (orderData.length === 0) {
        this.setState((x) => ({
          selectedOrderOperation: {
            ...x.selectedOrderOperation,
            disabled: true,
          },
        }));
      } else {
        this.setState((x) => ({
          selectedOrderOperation: {
            ...x.selectedOrderOperation,
            disabled: false,
          },
        }));
      }

      this.setState(
        (x) => ({
          orderData: orderData,
          bakOrderData: orderData,
          isLoading: false,
        }),
        (x) => {
          if (fn) fn();
        }
      );
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readPR = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PRs", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        //payload: `inventory/getApprovedMultMaterial`,
        reqType: "getPR",

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let PRData = api.data;

      //console.log("CONFIRMED PO: ", api.data);
      this.setState((x) => ({
        PRData: PRData,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  createOrder = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting Order", id: loadId },
    });
    //console.log(this.state.txtSupplierCrud.value);
    let data = {
      supplierId: this.state.txtSupplierCrud.value.id,
      materialId: this.state.materialId,
      qty: Number(this.state.txtOrderQty.value),
      brand: this.state.txtOrderBrand.value.toUpperCase(),
      cost: Number(this.state.txtOrderCost.value),
      notes: this.state.txtOrderNotes.value,
      status: Number(this.state.txtOrderStatus.value),
      // dateReceived:
      //   this.state.txtOrderDateReceived.value === ""
      //     ? null
      //     : this.state.txtOrderDateReceived.value,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: "inventory/createMultMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readOrder();
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.readPR();
      this.props.refreshPhase();
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Order Added Successfully`,
        },
      });
    });
  };

  UpdateOrder = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting Order Update", id: loadId },
    });
    //console.log(this.state.txtSupplierCrud);
    let data;
    if (e) {
      data = {
        id: this.state.orderId,
        supplierId: this.state.txtSupplierCrud?.value?.id,
        qty: Number(this.state.txtOrderQty.value),
        brand: this.state.txtOrderBrand.value.toUpperCase(),
        cost: Number(this.state.txtOrderCost.value),
      };
    } else {
      data = {
        id: this.state.orderId,
        supplierId: this.state.txtSupplierCrud?.value?.id,
        qty: Number(this.state.txtOrderQty.value),
        brand: this.state.txtOrderBrand.value.toUpperCase(),
        cost: Number(this.state.txtOrderCost.value),
        notes: this.state.txtOrderNotes.value,
        status: Number(this.state.txtOrderStatus.value),
      };
    }

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "inventory/updateMultMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readOrder();
      this.props.refreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.readPR();
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Order Edited Successfully`,
        },
      });
    });
  };
  UpdateOrderStatus = (multiMatID, status) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Submitting Order Update", id: loadId },
    });
    //console.log(this.state.txtSupplierCrud);
    let data;
    data = {
      id: multiMatID,
      status: status,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: "inventory/updateMultMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readOrder();
      this.props.refreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.readPR();
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Order Edited Successfully`,
        },
      });
    });
  };

  deleteOrder = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Submitting Order Deletion",
        id: loadId,
      },
    });
    let dat = {
      id: this.state.orderId,
    };

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: "inventory/deleteMultMaterial",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      this.readOrder();
      this.props.refreshPhase();
      this.setState((prevState) => ({
        isLoading: false,
      }));
      this.readPR();
      this.handleOrderHideModal();
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Order Deleted Successfully`,
        },
      });
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      this.setState((x) => ({
        orderSelection: null,
      }));
    } else {
      selection.length = 0;
      selection.push(key);
      this.setState((x) => ({
        orderSelection: row,
      }));
      console.log("SELECTED MULTIMAT: ", row);
      this.clearOrderInputs();
      this.populateOrderModal(row, false, true);
    }

    this.setState((x) => ({
      selection,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    } else {
      this.setState({ selection: [] });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.name === "btnModifyOrderRow") {
        //console.log(e.target.getAttribute("id"));
        // this.populateOrderModal(rowInfo.original, true, true);
        // this.setState((x) => ({
        //   handleOrderOverviewModal: {
        //     ...x.handleOrderOverviewModal,
        //     isShow: true,
        //   },
        // }));
      }
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyOrderRow"
      ) {
        if (
          this.state.selection.length > 0 &&
          this.state.selection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            selection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            //console.log(this.props);

            this.handleActionChangeOrder({ target: { name: "btnEditOrder" } });

            // if(Number(this.state.userType) === 5){

            // }else{
            //
            // }

            //console.log(this.props);
            this.setState({
              selection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.bakOrderData,
        event.target.value
      );

      if (data != null) {
        this.setState({ orderData: data });
      } else {
        this.setState({ orderData: this.state.bakOrderData });
      }
    } else this.setState({ isFiltered: false });
  };

  render() {
    let tableHistory = (
      <Table
        data={this.state.historyData}
        columns={historyColumn(this.props.material)}
        keyField="uniqueId"
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isHistoryLoading}
        //getTrProps={this.historyRowFn}
        defaultPageSize={20}
        className="tableHistory -highlight"
      />
    );
    let tablelist = (
      <SelectTable
        data={this.state.orderData}
        state={this.state}
        columns={this.state.columns}
        keyField="id"
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        selectAll={this.state.selectAll}
        selectType="checkbox"
        loading={this.state.isLoading}
        toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        defaultPageSize={10}
        className="tableProject -highlight"
      />
    );
    let order = (
      <Order
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        materialFinance={this.state.selMaterialDataFinance}
        userType={this.props.userType}
        tableHistory={tableHistory}
        tablelist={tablelist}
        material={this.props.material}
        dispatch={this.props.dispatch}
      />
    );
    return <>{order}</>;
  }
}
const mapStateToProps = (state) => {
  return {
    socketio: state.SocketHandler,
    project: state.apiData.projectDataSel,
  };
};
export default connect(mapStateToProps)(PhaseProcess);
