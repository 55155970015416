import React, { useState, useEffect } from "react";
//import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OrderProcess from "../Order/OrderProcess";
import PRProcess from "../PR/PRProcess";
import POProcess from "../PO/POProcess";
import JVLogo from "../../assets/rebLogoRed.png";
import DBStatus from "../../functions/DBStatus";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import "../../css/phase.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NumericFormat } from "react-number-format";
import ItemSelect from "../../components/CustomDrop";
import UnitSelect from "../../components/CustomDrop";
import misc from "../../functions/misc";
export const OrderOverviewModal = (props) => (
  <>
    <Modal
      show={props.state.handleOrderOverviewModal.isShow}
      onHide={props.state.handleOrderOverviewModal.hide}
      onExit={(e) => {
        props.state.handleOrderOverviewModal.handleExit(e);
      }}
      className={props.state.handleOrderOverviewModal.className + "  "}
      dialogClassName="modal-phase-90w "
      backdrop="static"
      centered
    >
      <Modal.Body className="modal-order-body">
        <Row className="order-modal-header pt-3 pb-3 mb-3" noGutters>
          <Col md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseProjectModal"
                className="btnAddProject project-btn-crud"
                onClick={(e) => {
                  props.state.handleOrderOverviewModal.hide();
                }}
              >
                <i className="icofont-rounded-left"></i>
              </Button>
            </OverlayTrigger>
          </Col>
          <Col md={11} lg={11}>
            <h4 className=" order-modal-header-text font-weight-bold">
              Manage Purchases
            </h4>
          </Col>
        </Row>
        {(() => {
          if (props.state.materialSelection) {
            if (Number(props.state.materialSelection.loss) === 0) {
              return <></>;
            } else {
              return (
                <Row noGutters={true}>
                  <Col>
                    <h4 className="text-center font-weight-bold notifOver p-2">
                      OVER BUDGET
                    </h4>
                  </Col>
                </Row>
              );
            }
          }
        })()}
        {(() => {
          if (props.state.materialSelection) {
            //console.log(props.state.materialSelection);
            if (
              Number(props.state.materialSelection.totalQty) >
              Number(props.state.materialSelection.requiredQty)
            ) {
              return (
                <Row noGutters={true}>
                  <Col>
                    <h4 className="text-center font-weight-bold notifOver p-2">
                      OVER QTY
                    </h4>
                  </Col>
                </Row>
              );
            } else {
              return <></>;
            }
          }
        })()}
        <OrderProcess
          material={props.state.materialSelection}
          state={props.state}
          refreshPhase={props.state.handleRefreshPhase}
          userType={props.userType}
          toggleBG={props.state.handleOrderOverviewModal.toggleBG}
          socket={props.socket}
        />
      </Modal.Body>
    </Modal>
  </>
);
