import React from "react";
import Button from "react-bootstrap/Button";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import "bootstrap/dist/js/bootstrap.js";
import "../../css/login.css";

import JVL from "../../assets/bg-illu.jpg";
import JVLogo from "../../assets/rebLogoRed.png";

const primary = {
  color: "var(--JV-primary)",
};

const Login = (props) => (
  <>
    <Row className="row-content" noGutters={true}>
      <Col className="landingContainer" lg={9}>
        <Row className="IlluContainer" noGutters>
          <Col xs={12} lg={12} xl={12} md={12}>
            <img
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              src={JVL}
              className="landing-illu"
              alt="jvLandmark-illu"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={12} lg={3} className="col-login">
        <Row className="logoContainer">
          <img src={JVLogo} className="landing-logo" alt="jvLandmark-logo" />
        </Row>
        <div className="form-login-container">
          <Form
            className="form-login"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.handleSubmit(e);
              }
            }}
          >
            <h4 className="text-center form-login-welcome">
              <span className="span-login-welcome">Welcome to </span>
              <span className="span-login-firmName" style={primary}>
                JNJ Builders
              </span>
            </h4>

            <h3 className="text-center lblLogin">Login</h3>

            <Form.Label>Username</Form.Label>
            <FormControl
              type="text"
              className={
                props.state.txtLogUsername.className + " css-loginInput"
              }
              placeholder={props.state.txtLogUsername.placeholder}
              value={props.state.txtLogUsername.value}
              onChange={props.inputChange}
              name="txtLogUsername"
              id="css-uname"
              required
            />
            <Form.Label>Password</Form.Label>
            <FormControl
              type="password"
              className={
                props.state.txtLogPassword.className + " css-loginInput"
              }
              placeholder={props.state.txtLogPassword.placeholder}
              value={props.state.txtLogPassword.value}
              onChange={props.inputChange}
              name="txtLogPassword"
              id="css-pword"
              required
            />
            <p
              className={props.state.lblLogininfo.className}
              style={{ color: "#d32f2f" }}
              id="css-loginInfo"
            >
              {props.state.lblLogininfo.value}
            </p>

            <Button
              type="button"
              className="btnLogin"
              onClick={props.handleSubmit}
              block
            >
              LOGIN
            </Button>
          </Form>
        </div>

        <div className="row-login-footer">
          <p className="text-center">Version 1.5.2</p>
          {/* <p className="text-center">click here to contact developers.</p> */}
        </div>
      </Col>
    </Row>
  </>
);

export { Login };
