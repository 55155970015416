import React from "react";
import PropTypes from "prop-types";
import Table from "react-table";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import {
  Actor,
  employeeColumn,
  supplierColumn,
  customerColumn,
  itemColumn,
  unitColumn,
} from "./Actor.js";
//import LoadingProcess from "../Loading/LoadingProcess";
import Filters from "../../functions/filters";

import { connect } from "react-redux";

//const SelectTable = selectTableHOC(Table);

class ActorProcess extends React.Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      handleActorChange: this.handleActorChange.bind(this),
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      handleActionChange: this.handleActionChange.bind(this),
      handleChangePW: this.handleChangePW.bind(this),
      handlePermsChange: this.handlePermsChange.bind(this),
      deleteFile: this.deleteFile.bind(this),
      downloadFile: this.downloadFile.bind(this),
      chkAdmin: { readOnly: false, value: false },
      chkExecutive: { readOnly: false, value: false },
      chkProjectManager: { readOnly: false, value: false },
      chkSiteManager: { readOnly: false, value: false },
      chkPurchaser: { readOnly: false, value: false },
      chkRequester: { readOnly: false, value: false },

      handleActorModal: {
        handleSubmit: this.handleSubmit.bind(this),
        handleExit: this.handleExit.bind(this),
        show: this.handleShowModal.bind(this),
        hide: this.handleHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      empData: [],
      bakEmpData: [],

      // Input
      txtActorName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },

      txtUserName: {
        value: "",
        placeholder: "Username",
        className: "mb-3",
        readOnly: false,
      },

      txtPassword: {
        value: "",
        placeholder: "Password",
        className: "mb-3",
        readOnly: false,
        isInvalid: false,
        isValid: true,
        errMsg: "",
      },

      txtConfirmPass: {
        value: "",
        placeholder: "Confirm Password",
        className: "mb-3",
        readOnly: false,
        isInvalid: false,
        isValid: true,
      },

      txtEmail: {
        value: "",
        placeholder: "Email",
        className: "mb-3",
        readOnly: false,
      },
      txtContact: {
        value: "",
        placeholder: "Contact",
        className: "mb-3",
        readOnly: false,
      },

      txtAddress: {
        value: "",
        placeholder: "Address",
        className: "mb-3",
        readOnly: false,
      },

      txtWebsite: {
        value: "",
        placeholder: "Website",
        className: "mb-3",
        readOnly: false,
      },

      selPermission: {
        value: "",
        handler: this.handlePermissionChange.bind(this),
        placeholder: "Permission",
        className: "mb-3",
        readOnly: false,
      },
      btnEditPW: {
        name: "btnChangePW",
        placeholder: "Change Password",
      },
      fileUpload: {
        handleSubmit: this.handleFileUploadSubmit.bind(this),
        handler: this.handleFileUploadChange.bind(this),
        value: "",
      },

      // GetFromDB

      selectAll: false,
      selection: [],
      searchInput: "",
      actorSelection: null,
      checkboxTable: {},
      columns: employeeColumn,
      selectedActor: { id: 1, name: "User" },
      selectedOperation: { id: 0, name: "Add" },
      selectedModalOperation: { id: 0, name: "Add" },
      isPWDisabled: true,
      isLoading: false,
      isFiltered: false,
      isPWEdit: false,
    };
  }

  componentDidMount() {
    if (this.props.state.user.type.includes(0)) this.readActor("0,1,4,5,6,7,8");
    else this.handleActorChange({ target: { value: 3 } });
  }

  componentWillUnmount() {}

  handleExit = (e) => {
    // $("#txtPassword, #txtConfirmPass")
    //   .removeClass("is-invalid")
    //   .removeClass("is-valid");
    this.setState((x) => ({
      txtPassword: {
        ...x.txtPassword,
        readOnly: true,
        isInvalid: false,
        errMsg: "",
      },
      txtConfirmPass: {
        ...x.txtConfirmPass,
        readOnly: true,
        isInvalid: false,
      },
      btnEditPW: {
        ...x.btnEditPW,

        name: "btnChangePW",
        placeholder: "Change Password",
      },
      isPWDisabled: true,
    }));
  };

  handleChangePW = (e) => {
    if (e.target.name === "btnChangePWCancel") {
      // this.setState((x) => ({
      //   txtPassword: {
      //     ...x.txtPassword,
      //     readOnly: true,
      //   },
      //   txtConfirmPass: {
      //     ...x.txtConfirmPass,
      //     readOnly: true,
      //   },
      //   btnEditPW: {
      //     ...x.btnEditPW,

      //     name: "btnChangePW",
      //     placeholder: "Change Password",
      //   },
      //   isPWDisabled: true,
      // }));
      this.handleExit();
    }
    if (e.target.name === "btnChangePW") {
      this.setState((x) => ({
        txtPassword: {
          ...x.txtPassword,
          readOnly: false,
        },
        txtConfirmPass: {
          ...x.txtConfirmPass,
          readOnly: false,
        },
        btnEditPW: {
          ...x.btnEditPW,

          name: "btnChangePWCancel",
          placeholder: "Cancel",
        },
        isPWDisabled: false,
      }));
    }
  };

  handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    let isValid = true;
    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (
      this.state.selectedActor.id === 4 ||
      this.state.selectedActor.id === 5
    ) {
    } else {
      if (
        this.state.selectedOperation.id === 0 &&
        !this.state.isPWDisabled &&
        this.state.selectedActor.id !== 3
      ) {
        if (this.state.txtPassword.value === this.state.txtConfirmPass.value) {
          if (re.exec(this.state.txtPassword.value)) {
            isValid = true;
            // $("#txtPassword, #txtConfirmPass")
            //   .removeClass("is-invalid")
            //   .addClass("is-valid");
            this.setState((x) => ({
              txtPassword: {
                ...x.txtPassword,
                isInvalid: false,
                isValid: true,
                errMsg: "",
              },
              txtConfirmPass: {
                ...x.txtConfirmPass,
                isInvalid: false,
                isValid: true,
              },
            }));
          } else {
            isValid = false;

            this.setState((x) => ({
              txtPassword: {
                ...x.txtPassword,
                isInvalid: true,
                isValid: false,
                errMsg:
                  "Password must contain atleast one digit, upper case, lower case and atleast 6 characters",
              },
              txtConfirmPass: {
                ...x.txtConfirmPass,
                isInvalid: true,
                isValid: false,
              },
            }));
            // $("#txtPassword, #txtConfirmPass")
            //   .addClass("is-invalid")
            //   .removeClass("is-valid");
          }
        } else {
          isValid = false;
          // $("#txtPassword, #txtConfirmPass")
          //   .addClass("is-invalid")
          //   .removeClass("is-valid");
          this.setState((x) => ({
            txtPassword: {
              ...x.txtPassword,
              isInvalid: true,
              isValid: false,
              errMsg: "Password mismatch",
            },
            txtConfirmPass: {
              ...x.txtConfirmPass,
              isInvalid: true,
              isValid: false,
            },
          }));
        }
      }
    }

    console.log(isValid);
    if (form.checkValidity() === false || !isValid) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });

      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },
        selection: [],
      }));
    } else {
      switch (Number(this.state.selectedActor.id)) {
        case 4:
          this.handleItem(this.state.selectedOperation.id);
          break;
        case 5:
          this.handleUnit(this.state.selectedOperation.id);
          break;

        default:
          if (this.state.selectedOperation.id === 0) {
            this.createActor();
          } else {
            this.UpdateActor();
          }
          break;
      }

      this.handleHideModal();
      this.clearInputs();
      this.setState((x) => ({
        handleActorModal: {
          ...x.handleActorModal,
          isValidated: false,
        },

        selection: [],
      }));
    }
  };

  // get Input of New Actor
  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleShowModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: true,
      },
    }));
  };
  handleHideModal = (e) => {
    this.setState((x) => ({
      handleActorModal: {
        ...x.handleActorModal,
        isShow: false,
      },
    }));
  };

  handleActionChange = (e) => {
    switch (e.target.name) {
      case "btnAddActor":
        this.clearInputs();
        //this.handleExit();
        this.setState((x) => ({
          selectedOperation: { id: 0, name: "Add" },
          handleActorModal: { ...x.handleActorModal, isView: false },
          isPWDisabled: false,
          selection: [],
        }));

        break;
      case "btnUnlockActor":
        this.setState((x) => ({
          txtActorName: { ...x.txtActorName, readOnly: false },
          txtAddress: {
            ...x.txtAddress,

            readOnly: false,
          },
          txtEmail: {
            ...x.txtEmail,

            readOnly: false,
          },
          txtContact: {
            ...x.txtContact,

            readOnly: false,
          },
          txtUserName: {
            ...x.txtUserName,

            readOnly: false,
          },
          txtWebsite: {
            ...x.txtWebsite,

            readOnly: false,
          },
          handleActorModal: {
            ...x.handleActorModal,
            isView: false,
          },
          selPermission: {
            ...x.selPermission,
            readOnly: false,
          },
          chkAdmin: { ...x.chkAdmin, readOnly: false },
          chkExecutive: { ...x.chkExecutive, readOnly: false },
          chkProjectManager: {
            ...x.chkProjectManager,

            readOnly: false,
          },
          chkSiteManager: { ...x.chkSiteManager, readOnly: false },
          chkPurchaser: { ...x.chkPurchaser, readOnly: false },
          chkRequester: { ...x.chkRequester, readOnly: false },
        }));

        break;
      case "btnEditActor":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Please select 1 ${this.state.selectedActor.name}`,

              isYesNo: false,
            },
          });
        } else {
          if (this.state.selection.length === 1) {
            this.setState((x) => ({
              selectedOperation: { id: 1, name: "Edit" },
              handleActorModal: { ...x.handleActorModal, isView: true },
              isPWDisabled: true,
            }));
            this.handleShowModal();
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please select a ${this.state.selectedActor.name}`,
                isYesNo: false,
              },
            });
          }
        }

        break;
      case "btnDeleteActor":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Please select 1 ${this.state.selectedActor.name}`,
              isYesNo: false,
            },
          });
        } else {
          if (this.state.selection.length === 1) {
            this.setState((x) => ({
              selectedOperation: { id: 2, name: "Delete" },
            }));
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Confirmation",
                desc: `Remove ${this.state.selectedActor.name} ?`,
                isYesNo: true,
                cb: (e) => {
                  if (this.props.state.user.type.includes(0)) {
                    switch (Number(this.state.selectedActor.id)) {
                      case 4:
                        this.handleItem(this.state.selectedOperation.id);
                        break;
                      case 5:
                        this.handleUnit(this.state.selectedOperation.id);
                        break;
                      default:
                        this.deleteActor();
                        break;
                    }
                  } else {
                    this.props.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `Only Administrator can remove ${this.state.selectedActor.name}`,
                        isYesNo: false,
                      },
                    });
                  }
                  this.handleHideModal();
                },
              },
            });
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please select a ${this.state.selectedActor.name}`,
                isYesNo: false,
              },
            });
          }
        }
        break;
      default:
        break;
    }
  };
  handlePermissionChange = (e) => {
    e.persist();
    this.setState((x) => ({
      selPermission: {
        ...x.selPermission,
        value: e.target.value,
        id: e.target[e.target.selectedIndex].getAttribute("id"),
      },
    }));
  };

  handleActorChange = (event) => {
    switch (Number(event.target.value)) {
      case 1:
        this.setState(
          {
            selectedActor: { id: 1, name: "User" },
            columns: employeeColumn,
          },
          this.readActor("0,1,4,5,6,7,8")
        );

        break;
      case 2:
        this.setState(
          {
            selectedActor: { id: 2, name: "Client" },
            columns: customerColumn,
          },
          this.readActor(2)
        );

        break;
      case 3:
        this.setState(
          {
            selectedActor: { id: 3, name: "Supplier" },
            columns: supplierColumn,
          },
          this.readActor(3)
        );

        break;
      case 4:
        this.setState(
          {
            selectedActor: { id: 4, name: "Items" },
            columns: itemColumn,
          },
          this.readItems()
        );

        break;
      case 5:
        this.setState(
          {
            selectedActor: { id: 5, name: "Units" },
            columns: unitColumn,
          },
          this.readUnits()
        );

        break;
      default:
        this.setState(
          {
            selectedActor: { id: 1, name: "User" },
            columns: employeeColumn,
          },
          this.readItems()
        );

        break;
    }
  };

  // handleSubmit = (e) => {
  //   switch (this.state.selectedOperation.id) {
  //     case 0:
  //       this.createActor();
  //       break;
  //     case 1:
  //       this.UpdateActor();
  //       break;

  //     default:
  //       break;
  //   }
  // };

  handlePermsChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.checked ? true : false,
        readOnly: false,
      },
    });
  };

  clearInputs = () => {
    this.setState((x) => ({
      txtActorName: { ...x.txtActorName, value: "", readOnly: false },
      txtUserName: { ...x.txtUserName, value: "", readOnly: false },
      txtPassword: { ...x.txtPassword, value: "", readOnly: false },
      txtConfirmPass: { ...x.txtConfirmPass, value: "", readOnly: false },
      txtEmail: { ...x.txtEmail, value: "", readOnly: false },
      txtContact: { ...x.txtContact, value: "", readOnly: false },
      txtAddress: { ...x.txtAddress, value: "", readOnly: false },
      txtWebsite: { ...x.txtWebsite, value: "", readOnly: false },
      chkAdmin: { ...x.chkAdmin, value: false, readOnly: false },
      chkExecutive: { ...x.chkExecutive, value: false, readOnly: false },
      chkProjectManager: {
        ...x.chkProjectManager,
        value: false,
        readOnly: false,
      },
      chkSiteManager: { ...x.chkSiteManager, value: false, readOnly: false },
      chkPurchaser: { ...x.chkPurchaser, value: false, readOnly: false },
      chkRequester: { ...x.chkRequester, value: false, readOnly: false },
    }));
  };

  getActorPermission = () => {
    let permission = [];
    if (this.state.chkAdmin.value) permission.push(0);
    if (this.state.chkExecutive.value) permission.push(4);
    if (this.state.chkProjectManager.value) permission.push(7);
    if (this.state.chkSiteManager.value) permission.push(6);
    if (this.state.chkPurchaser.value) permission.push(5);
    if (this.state.chkRequester.value) permission.push(8);
    return permission;
  };

  createActor = () => {
    let urlActorAdd = "actor";
    let userType = null;
    if (Number(this.state.selectedActor.id) === 1) {
      userType = this.getActorPermission();
    } else {
      userType = [3];
    }

    let data = {
      name: this.state.txtActorName.value.toUpperCase(),
      address: this.state.txtAddress.value.toUpperCase(),
      email: this.state.txtEmail.value,
      contact: this.state.txtContact.value,
      username: this.state.txtUserName.value,
      password: this.state.txtPassword.value,
      website: this.state.txtWebsite.value.toUpperCase(),
      type: userType,
    };
    console.log(data);

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: urlActorAdd,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (Number(this.state.selectedActor.id) === 1) {
        this.readActor("0,1,4,5,6,7,8");
      } else {
        this.readActor(3);
      }

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${this.state.selectedActor.name} Added Successfully`,
        },
      });
    });
  };

  UpdateActor = (e) => {
    let userType = this.getActorPermission();

    if (this.state.actorSelection.type.includes(3)) {
      userType = [3];
    }
    let data = null;
    if (this.state.isPWDisabled) {
      data = {
        id: this.state.actorId,
        name: this.state.txtActorName.value.toUpperCase(),
        address: this.state.txtAddress.value.toUpperCase(),
        email: this.state.txtEmail.value,
        contact: this.state.txtContact.value,
        username: this.state.txtUserName.value,

        website: this.state.txtWebsite.value.toUpperCase(),
        type: userType,
      };
    } else {
      data = {
        id: this.state.actorId,
        name: this.state.txtActorName.value.toUpperCase(),
        address: this.state.txtAddress.value.toUpperCase(),
        email: this.state.txtEmail.value,
        contact: this.state.txtContact.value,
        username: this.state.txtUserName.value,
        password: this.state.txtPassword.value,
        website: this.state.txtWebsite.value.toUpperCase(),
        type: userType,
      };
    }

    let urlUpdateActor = "actor/updateActor";
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: urlUpdateActor,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (Number(this.state.selectedActor.id) === 1) {
        this.readActor("0,1,4,5,6,7,8");
      } else {
        this.readActor(3);
      }
      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${this.state.selectedActor.name} Edited Successfully`,
        },
      });
    });
  };

  deleteActor = (e) => {
    let dat = {
      id: this.state.selection[0].slice(7),
    };

    let urlActorDelete = "actor/deleteActor";

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: urlActorDelete,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (Number(this.state.selectedActor.id) === 1) {
        this.readActor("0,1,4,5,6,7,8");
      } else {
        this.readActor(3);
      }

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${this.state.selectedActor.name} Deleted Successfully`,
        },
      });
    });
  };
  populateModal = (e, view, _readOnly) => {
    this.setState((x) => ({
      actorId: e.id,
      txtActorName: { ...x.txtActorName, value: e.name, readOnly: _readOnly },
      txtAddress: {
        ...x.txtAddress,
        value: e.address === null ? "" : e.address,
        readOnly: _readOnly,
      },
      txtEmail: {
        ...x.txtEmail,
        value: e.email === null ? "" : e.email,
        readOnly: _readOnly,
      },
      txtContact: {
        ...x.txtContact,
        value: e.contact === null ? "" : e.contact,
        readOnly: _readOnly,
      },
      txtUserName: {
        ...x.txtUserName,
        value: e.username === null ? "" : e.username,
        readOnly: _readOnly,
      },
      txtWebsite: {
        ...x.txtWebsite,
        value: e.website === null ? "" : e.website,
        readOnly: _readOnly,
      },
      handleActorModal: {
        ...x.handleActorModal,
        isView: view,
        readOnly: _readOnly,
      },

      // selPermission: {
      //   ...x.selPermission,
      //   value: e.type === null  ? "" : e.type[0],
      //   readOnly: _readOnly,
      // },
      chkAdmin: { readOnly: true, value: false },
      chkExecutive: { readOnly: true, value: false },
      chkProjectManager: { readOnly: true, value: false },
      chkSiteManager: { readOnly: true, value: false },
      chkPurchaser: { readOnly: true, value: false },
      chkRequester: { readOnly: true, value: false },
    }));
    if (
      this.state.selectedActor.id === 4 ||
      this.state.selectedActor.id === 5
    ) {
    } else {
      e.type.forEach((x) => {
        switch (x) {
          case 0:
            this.setState((x) => ({
              chkAdmin: { ...x.chkAdmin, value: true },
            }));
            break;
          case 4:
            this.setState((x) => ({
              chkExecutive: { ...x.chkExecutive, value: true },
            }));
            break;
          case 7:
            this.setState((x) => ({
              chkProjectManager: { ...x.chkProjectManager, value: true },
            }));
            break;
          case 6:
            this.setState((x) => ({
              chkSiteManager: { ...x.chkSiteManager, value: true },
            }));
            break;
          case 5:
            this.setState((x) => ({
              chkPurchaser: { ...x.chkPurchaser, value: true },
            }));
            break;
          case 8:
            this.setState((x) => ({
              chkRequester: { ...x.chkRequester, value: true },
            }));
            break;
          default:
            break;
        }
      });
      // e.type.map((x, index) => {

      // });
    }
  };

  readActor = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Actors", id: loadId },
    });
    let url = `actor/getActorByMultiTypes?types=${e}`;
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: url,

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let actorData = api.data;
      // console.log(actorData);
      this.setState(
        (prevState) => ({
          empData: actorData,
          bakEmpData: actorData,
          isLoading: false,
        }),
        (x) => {
          if (cb) {
            cb();
          }
        }
      );
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  handleFileUploadSubmit = (e) => {
    e.preventDefault();
    let urlUpload = "global/uploadFiles";

    let currentFiles = this.state.fileUpload.value;

    const fileType = currentFiles[0].type.split("/")[1];

    const formData = new FormData();
    formData.append("uniqueId", this.state.actorSelection.uniqueId);
    for (let index = 0; index < currentFiles.length; index++) {
      formData.append(
        "uploadedFiles",
        currentFiles[index],
        `signature.${fileType}`
      );
    }

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: urlUpload,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      const oldSel = this.state.actorSelection;
      console.log(oldSel);
      this.readActor("0,1,4,5,6,7,8", (x) => {
        this.setState((x) => ({
          actorSelection: this.state.empData.filter(
            (x) => x.id === oldSel.id
          )[0],
        }));
      });
    });
  };
  handleFileUploadChange = (e) => {
    let currentFiles = e.target.files;

    this.setState((x) => ({
      fileUpload: {
        ...x.fileUpload,
        value: currentFiles,
      },
    }));
  };

  downloadFile = (e) => {
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Data", id: 5 },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        payload: `global/getFile?uniqueId=${e.id}&filename=${e.name}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let dlData = api.data;

      const url = window.URL.createObjectURL(new Blob([dlData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${e.complete}`);
      document.body.appendChild(link);
      link.click();

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, name: "Fetching Data", id: 5 },
      });
    });
  };

  deleteFile = (filename, origName) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to delete ${origName} ?`,
        isYesNo: true,
        cb: (e) => {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: {
              isLoading: true,
              name: "Submitting File Deletion",
              id: loadId,
            },
          });
          let dat = {
            uniqueId: this.state.actorSelection.uniqueId,
            filename: filename,
          };

          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "delete",
              data: dat,
              payload: "global/deleteFile",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            const oldSel = this.state.actorSelection;
            console.log(oldSel);
            this.readActor("0,1,4,5,6,7,8", (x) => {
              this.setState((x) => ({
                actorSelection: this.state.empData.filter(
                  (x) => x.id === oldSel.id
                )[0],
                isLoading: false,
              }));
            });

            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `File Deleted Successfully`,
              },
            });
          });
        },
      },
    });
  };

  handleItem = (type) => {
    switch (Number(type)) {
      case 0:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/createItem",
            reqType: "post",
            data: { name: this.state.txtActorName.value.toUpperCase() },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readItems();
        });
        break;
      case 1:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/updateItem",
            reqType: "put",
            data: {
              name: this.state.txtActorName.value.toUpperCase(),
              id: this.state.actorSelection.id,
            },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readItems();
        });
        break;
      case 2:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/deleteItem",
            reqType: "delete",
            data: { id: this.state.actorSelection.id },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readItems();
        });
        break;
      default:
        break;
    }
  };

  handleUnit = (type) => {
    switch (Number(type)) {
      case 0:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/createUnit",
            reqType: "post",
            data: { name: this.state.txtActorName.value.toUpperCase() },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readUnits();
        });
        break;
      case 1:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/updateUnit",
            reqType: "put",
            data: {
              name: this.state.txtActorName.value.toUpperCase(),
              id: this.state.actorSelection.id,
            },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readUnits();
        });
        break;
      case 2:
        new Promise((resolve, reject) => {
          this.props.dispatch({
            type: "USER_GET_REQUEST",
            payload: "inventory/deleteUnit",
            reqType: "delete",
            data: { id: this.state.actorSelection.id },
            resolve: resolve,
            reject: reject,
          });
        }).then((x) => {
          this.readUnits();
        });
        break;
      default:
        break;
    }
  };

  readItems = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Items", id: loadId },
    });
    this.setState((prevState) => ({
      isLoading: true,
    }));
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getAllItems`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log("ITEMS: ", api.data);
      let itemData = api.data;
      this.setState((prevState) => ({
        empData: itemData,
        bakEmpData: itemData,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "API_STORE_ITEM",
        payload: {
          data: itemData,
        },
      });
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  readUnits = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching Units", id: loadId },
    });
    this.setState((prevState) => ({
      isLoading: true,
    }));
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getAllUnits`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let unitData = api.data;
      this.setState((prevState) => ({
        empData: unitData,
        bakEmpData: unitData,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "API_STORE_UNIT",
        payload: {
          data: unitData,
        },
      });
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      this.setState((x) => ({
        productSelection: null,
      }));
    } else {
      selection.length = 0;
      selection.push(key);
      this.setState((x) => ({
        actorSelection: row,
      }));
      this.clearInputs();
      this.populateModal(row, false, true);
    }

    this.setState((x) => ({
      selection,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    } else {
      this.setState({ selection: [] });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.name === "btnViewRow") {
        //console.log(e.target.getAttribute("id"));
        this.populateModal(rowInfo.original, true, true);
        this.setState((x) => ({
          handleActorModal: {
            ...x.handleActorModal,

            isShow: true,
          },
        }));
      }
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
        if (
          this.state.selection.length > 0 &&
          this.state.selection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            selection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            this.handleActionChange({ target: { name: "btnEditActor" } });
            // this.populateModal(rowInfo.original, true, true);
            // this.setState((x) => ({
            //   handleActorModal: {
            //     ...x.handleActorModal,

            //     isShow: true,
            //   },
            // }));
            this.setState({
              selection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.bakEmpData,
        event.target.value
      );

      if (data != null) {
        this.setState({ empData: data });
      } else {
        this.setState({ empData: this.state.bakEmpData });
      }
    } else {
      this.setState({ empData: this.state.bakEmpData });
      this.setState({ isFiltered: false });
    }
  };

  render() {
    let actor = null;
    let tablelist = null;
    //console.log(this.props);
    actor = (
      <Actor
        handleActorChange={this.handleActorChange}
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        handleShowModal={this.handleShowModal}
        handleHideModal={this.handleHideModal}
        onShow={this.state.isVisible}
        userType={this.props.state.user.type}
      />
    );

    tablelist = (
      <Table
        data={this.state.empData}
        columns={this.state.columns}
        keyField="id"
        {...this.props}
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        // selectAll={this.state.selectAll}
        // selectType="checkbox"
        loading={this.state.isLoading}
        // toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        defaultPageSize={50}
        style={{
          height: "68vh",
        }}
        className="tableActor -highlight"
      />
    );

    return (
      <>
        {/* {((e) => {
          if (this.state.isLoading) {
            return (
              <>
                <LoadingProcess />
              </>
            );
          }
        })()} */}

        {actor}
        {tablelist}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    units: state.apiData.unitData,
    items: state.apiData.itemData,
  };
};
export default connect(mapStateToProps)(ActorProcess);
