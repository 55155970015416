import React, { useState, useEffect } from "react";
//import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OrderProcess from "../Order/OrderProcess";
import PRProcess from "../PR/PRProcess";
import POProcess from "../PO/POProcess";
import JVLogo from "../../assets/rebLogoRed.png";
import DBStatus from "../../functions/DBStatus";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import "../../css/phase.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NumericFormat } from "react-number-format";
import ItemSelect from "../../components/CustomDrop";
import UnitSelect from "../../components/CustomDrop";
import misc from "../../functions/misc";
import { PhaseMaterial } from "./PhaseMaterial";
import Popover from "react-bootstrap/Popover";
import Select from "react-select";
import { SupplierModal } from "../Order/Order";
let customStyles = {
  menu: (provided, state) => {
    const width = state.selectProps.width;
    //const borderBottom = "1px dotted pink";
    let color = state.selectProps.menuColor;
    const padding = 20;
    //console.log(provided, state);
    // if (state.data.isDefault == 1) {
    //   color = "red";
    // }

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px 0px 0px 5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    //borderRadius: "5px 0px 0px 5px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, backgroundColor, color };
  },
};
let TxtDateLastModifiedMat = (props) => (
  <>
    <Form.Label>
      <strong>Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDateLastModifiedMat.placeholder}
      name="txtDateLastModifiedMat"
      value={props.state.txtDateLastModifiedMat.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModifiedMat.className + " css-projectInput"
      }
      readOnly={props.state.txtDateLastModifiedMat.readOnly}
    />
  </>
);
let TxtLastModifiedByMat = (props) => (
  <>
    <Form.Label>
      {" "}
      <strong>Last Modified By</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtLastModifiedByMat.placeholder}
      name="txtLastModifiedByMat"
      value={props.state.txtLastModifiedByMat.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtLastModifiedByMat.className + " css-projectInput"
      }
      readOnly={props.state.txtLastModifiedByMat.readOnly}
    />
  </>
);
let TxtDatePostedMat = (props) => (
  <>
    <Form.Label>
      <strong>Date Posted</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDatePostedMat.placeholder}
      name="txtDatePostedMat"
      value={props.state.txtDatePostedMat.value}
      onChange={props.state.inputChange}
      className={props.state.txtDatePostedMat.className + " css-projectInput"}
      readOnly={props.state.txtDatePostedMat.readOnly}
    />
  </>
);
let TxtMaterialReqQty = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Required Qty
      </strong>
    </Form.Label>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      suffix={" " + props.state.selUnit.value.name}
      placeholder={props.state.txtMaterialReqQty.placeholder}
      name="txtMaterialReqQty"
      value={props.state.txtMaterialReqQty.value}
      onValueChange={(x) => {
        props.state.inputChange({
          target: {
            value: Number(x.value),
            name: "txtMaterialReqQty",
            placeholder: props.state.txtMaterialReqQty.placeholder,
            className: props.state.txtMaterialReqQty.className,
          },
        });
      }}
      className={props.state.txtMaterialReqQty.className + " css-projectInput"}
      readOnly={props.state.txtMaterialReqQty.readOnly}
    />
  </>
);

let TxtMaterialUsage = (props) => (
  <>
    <Form.Label>
      <strong>Use</strong>
    </Form.Label>
    <InputGroup className="mb-3">
      <FormControl
        type="text"
        as="textarea"
        placeholder="Purpose / Where will you use this?"
        style={{ textTransform: "uppercase" }}
        name="txtMaterialUsage"
        value={props.state.txtMaterialUsage.value}
        onChange={props.state.inputChange}
        className={props.state.txtMaterialUsage.className + " css-projectInput"}
        readOnly={props.state.txtMaterialUsage.readOnly}
      />
      <InputGroup.Prepend>
        <Button
          name="btnMaterialUsage"
          disabled={props.state.txtMaterialUsage.readOnly}
          onClick={(e) => {
            props.state.handleCopyPhase(e.currentTarget.name);
          }}
        >
          <i className="icofont-ui-copy icofont-1x"></i>
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  </>
);

let TxtmatUniqueId = (props) => (
  <>
    <Form.Label>
      <strong>ID</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtMatUniqueID.placeholder}
      name="txtMatUniqueID"
      value={props.state.txtMatUniqueID.value}
      onChange={props.state.inputChange}
      className={props.state.txtMatUniqueID.className + " css-projectInput"}
      readOnly={props.state.txtMatUniqueID.readOnly}
    />
  </>
);
let TxtMaterialRequester = (props) => (
  <>
    <Form.Label>
      <strong>Requester</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtMaterialRequester.placeholder}
      name="txtMaterialRequester"
      value={props.state.txtMaterialRequester.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtMaterialRequester.className + " css-projectInput"
      }
      readOnly={props.state.txtMaterialRequester.readOnly}
    />
  </>
);
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
let TxtOrderSupplier = (props) => (
  <>
    <SupplierModal state={props.state} />
    <Form.Label>
      <span className="required-field">* </span>{" "}
      <strong>Supplier / Payee</strong>
    </Form.Label>
    <InputGroup className="mb-3">
      <InputGroup.Prepend style={{ flex: 1 }}>
        {/* {console.log(props.state.supplierData)} */}
        <Select
          // defaultValue={colourOptions[1]}
          options={props.state.supplierData}
          styles={customStyles}
          formatGroupLabel={formatGroupLabel}
          getOptionLabel={(x) => {
            return x.name;
          }}
          width="100%"
          getOptionValue={(x) => x.id}
          onChange={(x, name) => {
            props.state.inputChange(
              {
                target: {
                  value: x,
                  name: "txtSupplierCrud",
                },
              },
              true
            );
          }}
          value={props.state.txtSupplierCrud.value}
          name="txtSupplierCrud"
          isDisabled={props.state.txtMaterialName.readOnly}
        />
      </InputGroup.Prepend>

      {/* <FormControl
        as="select"
        value={props.state.txtSupplierCrud.value}
        name="supplierSelect"
        disabled={props.state.txtSupplierCrud.readOnly}
        
        onChange={(e) => {
          props.state.handleSupplierChange(e, true);
        }}
      >
        {props.state.supplierData.map((dat, index) => (
          <option key={dat.id} value={dat.id}>
            {dat.name}
          </option>
        ))}
        <option value={0} key={0} disabled hidden>
          No Supplier Selected
        </option>
      </FormControl> */}
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          trigger="click"
          overlay={
            <Popover id={`popover-positioned-top`}>
              <Popover.Title as="h3">Supplier Information</Popover.Title>
              <Popover.Content>
                <Row>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Name:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Name"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud?.value
                            ? props.state.txtSupplierCrud?.value?.name
                            : "Name"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Address:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Address"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud.value
                            ? props.state.txtSupplierCrud?.value?.address
                            : "Address"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Contact:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Contact"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud.value
                            ? props.state.txtSupplierCrud?.value?.contact
                            : "Contact"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Popover.Content>
            </Popover>
          }
        >
          <Button
            variant="secondary"
            name="btnInfoSupplier"
            className="btnInfoSupplier "
          >
            <i className="icofont-info icofont-1x"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>ADD</Tooltip>}
        >
          <Button
            variant="secondary"
            name="btnAddSupplier"
            className="btnAddSupplier order-btn-crud"
            disabled={props.state.txtMaterialBudget.readOnly}
            onClick={(e) => {
              props.state.handleActorModal.show(e);
            }}
          >
            <i className="icofont-ui-add icofont-1x"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </InputGroup>
  </>
);
let TxtMaterialBudget = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Budget
      </strong>
    </Form.Label>
    <InputGroup className="mb-3">
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        prefix={"₱"}
        placeholder={`ALLOCATED BUDGET FOR THIS SPECIFIC ${
          props.state.selMaterialEntryType?.value &&
          props.state.selMaterialEntryType?.value.toUpperCase()
        }`}
        name="txtMaterialBudget"
        value={props.state.txtMaterialBudget.value}
        onValueChange={(x) => {
          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtMaterialBudget",
              placeholder: props.state.txtMaterialBudget.placeholder,
              className: props.state.txtMaterialBudget.className,
            },
          });
        }}
        className={
          props.state.txtMaterialBudget.className + " css-projectInput"
        }
        readOnly={props.state.txtMaterialBudget.readOnly}
        required
      />
      <InputGroup.Prepend>
        <Button
          name="btnMaterialBudget"
          disabled={props.state.txtMaterialBudget.readOnly}
          onClick={(e) => {
            props.state.handleCopyPhase(e.currentTarget.name);
          }}
        >
          <i className="icofont-ui-calculator icofont-1x"></i>
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  </>
);
let TxtMaterialBaseCost = (props) => {
  let label = "Cost";
  let placeholder = "INITIAL / STARTING COST";
  if (props.state.selMaterialEntryType.value === "material") {
    label = "Base Cost";
  } else {
    placeholder = `${
      props.state.selMaterialEntryType.value
        ? props.state.selMaterialEntryType.value.toUpperCase()
        : ""
    } COST`;
  }
  return (
    <>
      <Form.Label>
        <strong>
          {" "}
          <span className="required-field">* </span>
          {label}
        </strong>
      </Form.Label>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        prefix={"₱"}
        placeholder={placeholder}
        name="txtMaterialBaseCost"
        value={props.state.txtMaterialBaseCost.value}
        onValueChange={(x) => {
          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtMaterialBaseCost",
              placeholder: props.state.txtMaterialBaseCost.placeholder,
              className: props.state.txtMaterialBaseCost.className,
            },
          });
        }}
        className={
          props.state.txtMaterialBaseCost.className + " css-projectInput mb-3"
        }
        readOnly={props.state.txtMaterialBaseCost.readOnly}
        //required
      />
    </>
  );
};
let TxtMaterialDeadline = (props) => (
  <>
    <Form.Label>
      <strong>Deadline</strong>
    </Form.Label>
    <InputGroup className="mb-3">
      <FormControl
        type="date"
        placeholder={props.state.txtMaterialDeadline.placeholder}
        name="txtMaterialDeadline"
        value={props.state.txtMaterialDeadline.value}
        onChange={props.state.inputChange}
        className={
          props.state.txtMaterialDeadline.className + " css-projectInput"
        }
        readOnly={props.state.txtMaterialDeadline.readOnly}
      />
      <InputGroup.Prepend>
        <Button
          name="btnMaterialDeadline"
          disabled={props.state.txtMaterialDeadline.readOnly}
          onClick={(e) => {
            props.state.handleCopyPhase(e.currentTarget.name);
          }}
        >
          <i className="icofont-ui-copy icofont-1x"></i>
        </Button>
      </InputGroup.Prepend>
    </InputGroup>
  </>
);

export const MaterialModal = (props) => {
  const [IsAddMatUsage, setIsAddMatUsage] = useState(false);
  useEffect(() => {
    setIsAddMatUsage(false);
  }, [props.state.handleMaterialModal.isShow]);
  return (
    <>
      {/* <Modal
          show={props.state.handleMaterialModal.isShow}
          onHide={props.state.handleMaterialModal.hide}
          onExit={(e) => {
            props.state.handleMaterialModal.handleExit(e);
          }}
          dialogClassName="modal-phase-90w "
          centered
        >
          <Modal.Body className="material-modal-body">
           
          </Modal.Body>
        </Modal> */}
      <Row className="material-modal-header  pt-3 pb-3" noGutters>
        <Col md={2} lg={2}>
          <OverlayTrigger
            placement={"right"}
            overlay={
              <Tooltip id={`project-tooltip-add`}>Back to Phase</Tooltip>
            }
          >
            <Button
              variant="secondary"
              name="btnCloseProjectModal"
              className="btnAddProject project-btn-crud"
              onClick={(e) => {
                props.state.handleMaterialModal.hide();
                props.state.changePageContent("PROJECT");
              }}
            >
              Back to Phase <i className="icofont-rounded-left"></i>
            </Button>
          </OverlayTrigger>
        </Col>
        <Col md={8} lg={8} style={{ alignSelf: "center" }}>
          <strong>
            <h4
              style={{ textAlign: "center" }}
              className=" p-0 m-0 material-modal-header-text font-weight-bold"
            >
              {props.state?.selItem?.value?.name}
            </h4>
          </strong>
        </Col>
        <Col md={2} lg={2} className="d-flex justify-content-end">
          {Number(props.state.selectedMaterialOperation.id) !== 0 &&
            props.userType.includes(0) && (
              <Button
                name="btnViewOrders"
                className="btnViewOrders "
                onClick={(e) => {
                  props.state.handleActionChangeMaterial(e);
                }}
              >
                <i className="icofont-rounded-right"></i> View Orders
              </Button>
            )}
        </Col>
      </Row>
      {/* <Row noGutters>
        <Col> <Button
                name="btnViewOrders"
                className="btnViewOrders "
                onClick={(e) => {
                
                }}
              >
                <i className="icofont-rounded-right"></i> Set as Finished
              </Button></Col>
              <Col> <Button
                name="btnViewOrders"
                className="btnViewOrders "
                onClick={(e) => {
                
                }}
              >
                <i className="icofont-rounded-right"></i> Re-submit for verification
              </Button></Col>
      </Row> */}

      <Form
        id="formMaterialInput"
        noValidate
        validated={props.state.handleMaterialModal.isValidated}
        onSubmit={props.state.handleMaterialModal.handleSubmit}
      >
        {/* MATERIAL = 'material',
                LABOUR = 'labour',
                VOUCHER = 'voucher',
                SUBCON = 'subcontract', */}
        <Row className="pt-3" noGutters>
          <Col xs={12} className="my-3">
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                  className=""
                >
                  Item Entry Type:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                name="selMaterialEntryType"
                value={props.state.selMaterialEntryType.value}
                style={{ fontWeight: "bold", fontSize: "1rem" }}
                disabled={props.state.txtMaterialName.readOnly}
                onChange={(e) => {
                  props.state.inputChange(e);
                }}
              >
                <option value={"material"} key={"option-material"}>
                  Material
                </option>
                <option value={"labour"} key={"option-labour"}>
                  Labour / Payroll
                </option>
                <option value={"voucher"} key={"option-voucher"}>
                  Voucher
                </option>
                <option value={"subcontract"} key={"option-subcontractor"}>
                  Subcontractor
                </option>
                <option value={""} key={0} disabled hidden>
                  Select Entry Type...
                </option>
              </FormControl>
            </InputGroup>
          </Col>
          <Col md={6} xl={6} className="pr-3">
            {(() => {
              if (Number(props.state.selectedMaterialOperation.id) !== 0) {
                return (
                  <>
                    <TxtmatUniqueId state={props.state} />
                  </>
                );
              }
            })()}

            {props.state.selMaterialEntryType.value !== "material" && (
              <>
                <Form.Label>
                  <strong>
                    <span className="required-field">* </span>
                    {props.state.selMaterialEntryType?.value &&
                      props.state.selMaterialEntryType?.value.toUpperCase()}{" "}
                    Name
                  </strong>
                </Form.Label>
                <FormControl
                  placeholder={`TITLE / DESCRIPTION OF THIS ${
                    props.state.selMaterialEntryType?.value &&
                    props.state.selMaterialEntryType?.value.toUpperCase()
                  }`}
                  name="txtMaterialName"
                  as="textarea"
                  value={props.state.txtMaterialName.value}
                  onChange={props.state.inputChange}
                  className={
                    props.state.txtMaterialName.className + " css-projectInput"
                  }
                  readOnly={props.state.txtMaterialName.readOnly}
                />
              </>
            )}
            {props.state.selMaterialEntryType.value === "material" && (
              <>
                <ItemSelect
                  state={props.state}
                  data={props.items}
                  name="selItem"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selItem.value}
                  //tabIndex={7}
                  lblHidden={false}
                  refreshData={props.state.readItems}
                  addUrl="inventory/createItem"
                  editUrl="inventory/updateItem"
                  deleteUrl="inventory/deleteItem"
                  componentName="Item"
                  componentType=""
                  //optionLabel="name"
                  handler={props.state.inputChange}
                  disabled={props.state.selItem.readOnly}
                />
                <UnitSelect
                  state={props.state}
                  data={props.units}
                  name="selUnit"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selUnit.value}
                  //tabIndex={7}
                  lblHidden={false}
                  refreshData={props.state.readUnits}
                  addUrl="inventory/createUnit"
                  editUrl="inventory/updateUnit"
                  deleteUrl="inventory/deleteUnit"
                  componentName="Unit"
                  componentType=""
                  //optionLabel="name"
                  handler={props.state.inputChange}
                  disabled={props.state.selUnit.readOnly}
                />
              </>
            )}

            {/* <TxtMaterialName state={props.state} />
                <TxtMaterialUnit state={props.state} /> */}
            <TxtMaterialDeadline state={props.state} />
            <TxtMaterialUsage state={props.state} />

            {(() => {
              if (Number(props.state.selectedMaterialOperation.id) !== 0) {
                return (
                  <>
                    <TxtLastModifiedByMat state={props.state} />
                  </>
                );
              }
            })()}
          </Col>

          <Col md={6} xl={6} className="pl-3">
            {props.state.selMaterialEntryType.value === "material" && (
              <>
                <TxtMaterialReqQty state={props.state} />
              </>
            )}

            <TxtMaterialBaseCost state={props.state} />
            <TxtMaterialBudget state={props.state} />
            {props.state.selMaterialEntryType.value !== "material" && (
                <TxtOrderSupplier state={props.state} />
)}
          

            {(() => {
              if (Number(props.state.selectedMaterialOperation.id) !== 0) {
                return (
                  <>
                    <TxtMaterialRequester state={props.state} />
                    <TxtDatePostedMat state={props.state} />
                    <TxtDateLastModifiedMat state={props.state} />
                  </>
                );
              }
            })()}
          </Col>
          <Col xs={12}>
          
          </Col>
          {/* <Col md={6} xl={6}>
                  <h5>Item Purchase History</h5>
                  {props.tableHistory}
                </Col> */}
        </Row>
        {/* {console.log(props)} */}
        {Number(props.state.selectedMaterialOperation.id) !== 0 && (
          <PhaseMaterial
            phase={null}
            material={props.state.materialSelection}
            IsAddMatUsage={IsAddMatUsage}
            setIsAddMatUsage={setIsAddMatUsage}
            state={props.state}
          />
        )}

        {!IsAddMatUsage && (
          <>
            {" "}
            {(() => {
              if (
                props.userType.includes(0) ||
                props.userType.includes(8) ||
                props.userType.includes(7)
              ) {
                return (
                  <Row
                    className="material-modal-footer pt-3 pb-3 mt-3"
                    noGutters
                  >
                    <Col md={6} lg={6} className="">
                      <p className="font-weight-bold">Note:</p>
                      <p className="">
                        Fields that has asterisk (
                        <span className="required-field font-weight-bold">
                          {" "}
                          *{" "}
                        </span>
                        ) requires an input.
                      </p>
                    </Col>
                    {!props.userType.includes(8) ? (
                      <Col md={2} lg={2} className="pl-3 pt-2">
                        <Button
                          block
                          className="btnMaterialModify"
                          name="btnUnlockMaterial"
                          disabled={!props.state.handleMaterialModal.isView}
                          onClick={(e) => {
                            props.state.handleActionChangeMaterial(e);
                          }}
                        >
                          MODIFY
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}

                    <Col md={2} lg={2} className="pl-3 pt-2">
                      <Button
                        block
                        className="btnMaterialSave"
                        disabled={props.state.handleMaterialModal.isView}
                        type="submit"
                      >
                        SAVE
                      </Button>
                    </Col>
                    {!props.userType.includes(8) ? (
                      <Col md={2} lg={2} className="pl-3 pt-2">
                        <Button
                          block
                          className="btnMaterialDelete"
                          name="btnDeleteMaterial"
                          disabled={
                            props.state.handleMaterialModal.isView ||
                            Number(props.state.selectedMaterialOperation.id) ===
                              0
                          }
                          onClick={(e) => {
                            props.state.handleActionChangeMaterial(e);
                          }}
                        >
                          DELETE
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                );
              }
            })()}
          </>
        )}
      </Form>
    </>
  );
};
