import React from "react";
import "./css/App.css";

import "./css/icofont.min.css";

import ModalProcess from "./components/Modals/ModalProcess";
import PDFModal from "./components/PDFModal/PDFModal";
import ToastProcess from "./components/Toast/ToastProcess";
import ScreenLoadingProcess from "./components/Loading/ScreenLoadingProcess";
import Login from "./pages/Login/LoginProcess";

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import reducer from "./reducer/reducer";
import rootSaga from "./sagas/sagas";
import history from "./functions/history";

//import socketIOClient from "socket.io-client";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

class App extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch({ type: "SOCKET_HANDLER_INIT" });
    //console.log("FIRST");
    //this.checkSession();
  }

  componentDidMount() {
    //console.log(socketIOClient(process.env.REACT_APP_SOCKET_URL));
    //const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);
    //store.dispatch({ type: "PDF_MODAL_TOGGLE" });
    // store.dispatch({ type: "TOAST_ADD", payload: { title: "2", desc: "" } });
    // store.dispatch({ type: "TOAST_ADD", payload: { title: "3", desc: "" } });
    //console.log("first");
  }

  componentWillUnmount() {}

  // checkSession = (cb) => {
  //   console.log(this.props.socketio.socket.id);
  //   const loadId = Filters.uuidv4();
  //   // this.props.dispatch({
  //   //   type: "TOGGLE_LOADING",
  //   //   payload: { isLoading: true, name: "Authenticating", id: loadId },
  //   // });
  //   new Promise((resolve, reject) => {
  //     this.props.dispatch({
  //       type: "USER_GET_REQUEST",
  //       reqType: "post",
  //       data: { sessionID: this.props.socketio.socket.id },
  //       payload: "auth/reAuthenticate",
  //       resolve: resolve,
  //       reject: reject,
  //     });
  //   }).then(
  //     (onfulfilled) => {
  //       console.log(onfulfilled);
  //       this.props.dispatch({
  //         type: "TOGGLE_LOADING",
  //         payload: { isLoading: false, id: loadId },
  //       });
  //     },
  //     (onrejected) => {
  //       console.log(onrejected);
  //       this.props.dispatch({
  //         type: "TOGGLE_LOADING",
  //         payload: { isLoading: false, id: loadId },
  //       });
  //       // new Promise((resolve, reject) => {
  //       //   this.props.dispatch({
  //       //     type: "USER_GET_REQUEST",
  //       //     reqType: "post",
  //       //     payload: "auth/logout",
  //       //     resolve: resolve,
  //       //     reject: reject,
  //       //   });
  //       // }).then((api) => {
  //       //   localStorage.removeItem("LUD");
  //       //   localStorage.removeItem("LUT");
  //       //   this.props.socketio.socket.disconnect();
  //       //   window.location.reload();
  //       // });
  //     }
  //   );
  // };

  render() {
    sagaMiddleware.run(rootSaga);

    return (
      <Provider store={store}>
        <Router history={history}>
          <ScreenLoadingProcess />
          <ModalProcess />
          <PDFModal />
          <ToastProcess />
          <Login />
        </Router>
      </Provider>
    );
  }
}

export default App;
