import Filters from "../../functions/filters";
class PhaseMaterialAPI {
  getAllMaterialUsage = async (dispatch, id, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        // name: "Submitting Project Modification",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "get",
        payload: `materialUsage/getAllMaterialUsage?id=${id}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });

      if (cb) {
        cb();
      }
      return api.data;
      // dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Unassigned User Successfully`,
      //   },
      // });
    });
    return data;
  };

  createMultiMaterialUsage = async (dispatch, postData, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Adding Material Usage",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: postData,
        payload: `materialUsage/createMultiMaterialUsage`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb();
      }
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Added Material Usage Successfully`,
        },
      });
      return api.data;
    });
    return data;
  };

  updateMaterialUsage = async (dispatch, postData, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Modifying Material Usage",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: postData,
        payload: `materialUsage/updateMaterialUsage`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb();
      }
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Modified Material Usage Successfully`,
        },
      });
      return api.data;
    });
    return data;
  };
  updateMultiMaterialUsage = async (dispatch, postData, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Modifying Multi Material Usage",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: postData,
        payload: `materialUsage/updateMultiMaterialUsage`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb();
      }
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Added Material Usage Successfully`,
        },
      });
      return api.data;
    });
    return data;
  };
  deleteMaterialUsage = async (dispatch, id, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Removing Material Usage",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: `materialUsage/deleteMaterialUsage`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb();
      }
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Removed Material Usage Successfully`,
        },
      });
      return api.data;
    });
    return data;
  };

  transferMaterialToPhase = async (dispatch, materialId, phaseId, cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Transferring Material",
        id: loadId,
      },
    });
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: { id: materialId, phaseId: phaseId },
        payload: `inventory/transferMaterialToPhase`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      console.log(api);
      dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      if (cb) {
        cb();
      }
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Material Transferred Successfully Successfully`,
        },
      });
      return api.data;
    });
    return data;
  };
}
export default new PhaseMaterialAPI();
