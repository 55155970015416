import React, { useState, useEffect } from "react";
//import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OrderProcess from "../Order/OrderProcess";
import PRProcess from "../PR/PRProcess";
import POProcess from "../PO/POProcess";
import JVLogo from "../../assets/rebLogoRed.png";
import DBStatus from "../../functions/DBStatus";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import "../../css/phase.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NumericFormat } from "react-number-format";
import ItemSelect from "../../components/CustomDrop";
import UnitSelect from "../../components/CustomDrop";
import misc from "../../functions/misc";
import {
  Canvas,
  BlobProvider,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { PhaseMaterial } from "./PhaseMaterial";
import { MaterialModal } from "./MaterialContent";
import { OrderOverviewModal } from "./OrderOverviewContent";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: `/Roboto-Regular.ttf`,
    },
    {
      src: `/Roboto-Bold.ttf`,
      fontWeight: "bold",
    },
    {
      src: `/Roboto-Italic.ttf`,
      fontWeight: "normal",
      fontStyle: "italic",
    },
    {
      src: `/Roboto-BoldItalic.ttf`,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const tableStyles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 12,
    marginLeft: 2,
    marginRight: 2,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
export const PhasePDF = (props) => (
  <Document>
    <Page
      size="A4"
      style={[
        {
          //fontFamily: "Roboto",
          paddingTop: 10,
          paddingBottom: 10,
          paddingHorizontal: 20,
        },
      ]}
    >
      <View
        style={[
          {
            marginTop: 10,
          },
        ]}
      >
        <Image style={[{ marginHorizontal: 220, width: 80 }]} src={JVLogo} />
      </View>
      <View
        style={[
          {
            textAlign: "center",
            color: "black",
            width: "100%",
            marginTop: 5,
            marginBottom: 10,
          },
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 20,
              marginBottom: 100,
            },
          ]}
        >
          <View
            style={[{ flex: 1, width: "50%", marginBottom: 10, height: 50 }]}
          >
            <Text style={[{ textAlign: "left" }]}>PROJECT SUMMARY</Text>
            <Text style={[{ textAlign: "left", fontSize: 13 }]}>
              {props.data.name}
            </Text>
          </View>
          <View style={[{ flex: 1, width: "50%" }]}>
            <Text style={[{ fontSize: 15, textAlign: "right" }]}>
              Date: {moment().format("MMMM Do YYYY")}
            </Text>
          </View>
        </View>
        <View style={[{ flex: 1, width: "100%", marginBottom: 35 }]}>
          <Text
            style={[
              {
                textAlign: "left",
                fontSize: 15,
                marginTop: 15,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            GENERATED BY: {props.state.userInfo}
          </Text>
        </View>
        <Canvas
          style={[
            {
              width: 600,
              height: 3,
              position: "center",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("black")
              .lineWidth(3)
              .moveTo(50, 0)
              .lineTo(540, 0)
              .stroke();
          }}
        />
      </View>

      <View
        style={[
          {
            paddingLeft: 20,
            paddingRight: 20,
          },
        ]}
      >
        <View
          style={[
            {
              flexDirection: "column",
            },
          ]}
        >
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Status:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {DBStatus.parseProjectStatus(props.data.status)}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Jobsite:</Text>
            <Text style={{ fontSize: 10, alignSelf: "flex-end", flex: 1 }}>{props.data.address}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Completion:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {((x) => {
                if (
                  props.data.dateStart === null ||
                  props.data.dateStart === ""
                ) {
                  return "Empty";
                } else {
                  return moment(props.data.dateStart, "YYYY-MM-DD").format(
                    "LL"
                  );
                }
              })()}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Date Started:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {((x) => {
                if (
                  props.data.dateStart === null ||
                  props.data.dateStart === ""
                ) {
                  return "Empty";
                } else {
                  return moment(props.data.dateStart, "YYYY-MM-DD").format(
                    "LL"
                  );
                }
              })()}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Deadline:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {" "}
              {((x) => {
                if (
                  props.data.dateEstEnd === null ||
                  props.data.dateEstEnd === ""
                ) {
                  return "Empty";
                } else {
                  return moment(props.data.dateEstEnd, "YYYY-MM-DD").format(
                    "LL"
                  );
                }
              })()}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Completion Date:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {((x) => {
                if (props.data.dateEnd === null || props.data.dateEnd === "") {
                  return "Empty";
                } else {
                  return moment(props.data.dateEnd, "YYYY-MM-DD").format("LL");
                }
              })()}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Total Budget:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {`${misc.parseCurrencyNoCurrency(
                props.data.finance.totalBudget
              )}  PHP`}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>Budget Used:</Text>
            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {`${misc.parseCurrencyNoCurrency(props.data.finance.used)}  PHP`}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={{ flex: 1 }}>
              {(() => {
                if (
                  Number(
                    props.data.finance.totalBudget - props.data.finance.used
                  ) > 0
                ) {
                  return "Budget Left";
                } else {
                  return "Over Budget";
                }
              })()}
            </Text>

            <Text style={{ alignSelf: "flex-end", flex: 1 }}>
              {`${misc.parseCurrencyNoCurrency(
                props.data.finance.totalBudget - props.data.finance.used
              )}  PHP`}
            </Text>
          </View>

        
        </View>
        <View>
          <Text
            style={[{ textAlign: "left", marginTop: 20, marginBottom: 10 }]}
          >
            ASSIGNED TEAM
          </Text>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text>Project Manager:</Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              {props.data.projectActors[7].actors.map((i, index) => (
                <Text key={index}>{i.name}</Text>
              ))}
            </View>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text>Site Manager:</Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              {props.data.projectActors[6].actors.map((i, index) => (
                <Text key={index}>{i.name}</Text>
              ))}
            </View>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text>Requester:</Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              {props.data.projectActors[8].actors.map((i, index) => (
                <Text key={index}>{i.name}</Text>
              ))}
            </View>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text>Purchaser:</Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              {props.data.projectActors[5].actors.map((i, index) => (
                <Text key={index}>{i.name}</Text>
              ))}
            </View>
          </View>
        </View>
      </View>
      {/* <View>
        <Canvas
          style={[
            {
              width: 600,
              height: 3,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("#0d0d0d")
              .lineWidth(3)
              .moveTo(50, 0)
              .lineTo(540, 0)
              .stroke();
          }}
        />
        <View
          style={[
            {
              textAlign: "center",
              color: "black",
              width: "100%",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 10,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",
                marginBottom: 5,
                fontWeight: 1,
                fontSize: 12,
              },
            ]}
          >
            JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo City
          </Text>
          <Text
            style={[
              {
                color: "#0d0d0d",
                textAlign: "left",
                marginBottom: 5,
                fontSize: 12,
              },
            ]}
          >
            EMAIL:
            <Text
              style={[
                {
                  color: "black",
                  textAlign: "left",
                  marginBottom: 5,
                  fontSize: 12,
                },
              ]}
            >
              jnjbuilders88@gmail.com
            </Text>
          </Text>
          <Text
            style={[
              {
                color: "#0d0d0d",
                textAlign: "left",
                fontSize: 12,
                fontWeight: 700,
              },
            ]}
          >
            TEL:
            <Text style={[{ color: "black", textAlign: "left", fontSize: 12 }]}>
              (033) 320 2392
            </Text>
          </Text>
        </View>
      </View> */}
    </Page>
    {props.data.phases.map((i, index) => (
      <>
        <Page
          size="A4"
          style={[
            {
              //fontFamily: "Roboto",
              paddingTop: 10,
              paddingBottom: 10,
              paddingHorizontal: 20,
            },
          ]}
        >
          <View
            style={[
              {
                marginTop: 10,
              },
            ]}
          >
            <Image
              style={[{ marginHorizontal: 220, width: 80 }]}
              src={JVLogo}
            />
          </View>
          <View
            style={[
              {
                textAlign: "center",
                color: "black",
                width: "100%",
                marginTop: 5,
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: "row",
                  paddingLeft: 80,
                  paddingRight: 80,
                  marginTop: 20,
                  marginBottom: 100,
                },
              ]}
            >
              <View
                style={[
                  { flex: 1, width: "50%", marginBottom: 10, height: 100 },
                ]}
              >
                <Text style={[{ textAlign: "left" }]}>PHASE SUMMARY</Text>
                <Text style={[{ textAlign: "left", fontSize: 12 }]}>
                  {i.name}
                </Text>
              </View>

              <View style={[{ flex: 1, width: "50%" }]}>
                <Text style={[{ fontSize: 15, textAlign: "right" }]}>
                  Date: {moment().format("MMMM Do YYYY")}
                </Text>
              </View>
            </View>

            <Canvas
              style={[
                {
                  width: 600,
                  height: 3,
                  position: "center",
                },
              ]}
              paint={(painter) => {
                painter
                  .strokeColor("black")
                  .lineWidth(3)
                  .moveTo(50, 0)
                  .lineTo(540, 0)
                  .stroke();
              }}
            />
          </View>

          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            <View
              style={[
                {
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1, width: "50%" }]}>
                <Text>Date Started:</Text>
                <Text>Deadline:</Text>
                <Text>Completion Date:</Text>
                <Text>Total Budget:</Text>
                <Text>Budget Used:</Text>
                <Text>
                  {(() => {
                    //console.log(i)
                    if (Number(i.finance.totalBudget - i.finance.used) > 0) {
                      return "Budget Left";
                    } else {
                      return "Over Budget";
                    }
                  })()}
                </Text>
              </View>
              <View style={[{ flex: 1, width: "50%" }]}>
                <Text>
                  {((x) => {
                    if (i.dateStart === null || i.dateStart === "") {
                      return "Empty";
                    } else {
                      return moment(i.dateStart, "YYYY-MM-DD").format("LL");
                    }
                  })()}
                </Text>
                <Text>
                  {((x) => {
                    if (i.dateEstEnd === null || i.dateEstEnd === "") {
                      return "Empty";
                    } else {
                      return moment(i.dateEstEnd, "YYYY-MM-DD").format("LL");
                    }
                  })()}
                </Text>
                <Text>
                  {((x) => {
                    if (i.dateEnd === null || i.dateEnd === "") {
                      return "Empty";
                    } else {
                      return moment(i.dateEnd, "YYYY-MM-DD").format("LL");
                    }
                  })()}
                </Text>
                <Text>
                  {`${misc.parseCurrencyNoCurrency(
                    i.finance.totalBudget
                  )}  PHP`}
                </Text>
                <Text>
                  {`${misc.parseCurrencyNoCurrency(i.finance.used)}  PHP`}
                </Text>
                <Text>
                  {`${misc.parseCurrencyNoCurrency(
                    i.finance.totalBudget - i.finance.used
                  )}  PHP`}
                </Text>
              </View>
            </View>
          </View>
          {/* <View>
            <Canvas
              style={[
                {
                  width: 600,
                  height: 3,
                },
              ]}
              paint={(painter) => {
                painter
                  .strokeColor("#0d0d0d")
                  .lineWidth(3)
                  .moveTo(50, 0)
                  .lineTo(540, 0)
                  .stroke();
              }}
            />
            <View
              style={[
                {
                  textAlign: "center",
                  color: "black",
                  width: "100%",
                  paddingLeft: 80,
                  paddingRight: 80,
                  marginTop: 10,
                },
              ]}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    marginBottom: 5,
                    fontWeight: 1,
                    fontSize: 12,
                  },
                ]}
              >
                JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo
                City
              </Text>
              <Text
                style={[
                  {
                    color: "#0d0d0d",
                    textAlign: "left",
                    marginBottom: 5,
                    fontSize: 12,
                  },
                ]}
              >
                EMAIL:
                <Text
                  style={[
                    {
                      color: "black",
                      textAlign: "left",
                      marginBottom: 5,
                      fontSize: 12,
                    },
                  ]}
                >
                  jnjbuilders88@gmail.com
                </Text>
              </Text>
              <Text
                style={[
                  {
                    color: "#0d0d0d",
                    textAlign: "left",
                    fontSize: 12,
                    fontWeight: 700,
                  },
                ]}
              >
                TEL:
                <Text
                  style={[{ color: "black", textAlign: "left", fontSize: 12 }]}
                >
                  (033) 320 2392
                </Text>
              </Text>
            </View>
          </View> */}
        </Page>
        <Page
          size="A4"
          orientation="landscape"
          style={[
            {
              //fontFamily: "Roboto",
              paddingTop: 35,
              marginBottom: 10,
            },
          ]}
        >
          <View
            style={[
              {
                marginTop: 15,
                marginBottom: 10,
              },
            ]}
          >
            <Image
              style={[{ marginHorizontal: 350, width: 80 }]}
              src={JVLogo}
            />
          </View>
          <View
            style={[
              {
                textAlign: "center",
                color: "black",
                width: "100%",
                marginTop: 0,
                marginBottom: 10,
              },
            ]}
          >
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: "row",
                  paddingLeft: 80,
                  paddingRight: 80,
                  marginTop: 5,
                },
              ]}
            >
              <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
                <Text style={[{ textAlign: "left" }]}>PHASE MATERIALS</Text>
              </View>
              <View style={[{ flex: 1, width: "50%" }]}>
                <Text style={[{ fontSize: 15, textAlign: "right" }]}>
                  Date Generated: {moment().format("MMMM Do YYYY")}
                </Text>
              </View>
            </View>

            <Canvas
              style={[
                {
                  width: 840,
                  height: 3,
                  position: "center",
                },
              ]}
              paint={(painter) => {
                painter
                  .strokeColor("black")
                  .lineWidth(3)
                  .moveTo(60, 0)
                  .lineTo(760, 0)
                  .stroke();
              }}
            />
          </View>

          <View
            style={[
              {
                marginTop: 5,
                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <Table data={i.materials}>
              <TableHeader>
                <TableCell weighting={0.25} style={[tableStyles.tableHeader]}>
                  MATERIAL NAME
                </TableCell>
                <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
                  USE
                </TableCell>
                <TableCell
                  weighting={0.2}
                  style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
                >
                  QTY PURCHASED / REQUIRED
                </TableCell>

                <TableCell
                  weighting={0.1}
                  style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
                >
                  BASE UNIT PRICE
                </TableCell>
                <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
                  BASE TOTAL
                </TableCell>
                <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
                  ACTUAL TOTAL
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={[tableStyles.tableCell, tableStyles.tableCellNames]}
                  weighting={0.25}
                  getContent={(d) => (d.item?.name ? d.item?.name : "")}
                />
                <DataTableCell
                  style={[tableStyles.tableCell, tableStyles.tableCellNames]}
                  weighting={0.15}
                  getContent={(d) => (d.usage === null ? "" : d.usage)}
                />
                <DataTableCell
                  style={[tableStyles.tableCell]}
                  weighting={0.2}
                  getContent={(d) => {
                    if (d.requiredQty === null) {
                      return "";
                    } else {
                      return (
                        misc.parseCurrencyNoCurrency(d.purchased) +
                        " / " +
                        misc.parseCurrencyNoCurrency(d.requiredQty) +
                        " " +
                        (d.unit?.name ? d.unit?.name : "")
                      );
                    }
                  }}
                />

                <DataTableCell
                  style={[tableStyles.tableCell]}
                  weighting={0.1}
                  getContent={(d) => {
                    if (d.purchased === null) {
                      return "Empty";
                    } else {
                      return misc.parseCurrencyNoCurrency(d.baseCost);
                    }
                  }}
                />
                <DataTableCell
                  style={[tableStyles.tableCell]}
                  weighting={0.15}
                  getContent={(d) => {
                    if (d.finance === null) {
                      return "";
                    } else {
                      return misc.parseCurrencyNoCurrency(
                        d.baseCost * d.requiredQty
                      );
                    }
                  }}
                />
                <DataTableCell
                  style={[tableStyles.tableCell]}
                  weighting={0.15}
                  getContent={(d) => {
                    if (d.finance === null) {
                      return "";
                    } else {
                      return misc.parseCurrencyNoCurrency(d.finance.used);
                    }
                  }}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      </>
    ))}
  </Document>
);

function historyColumn(material) {
  return [
    {
      Header: "Brand",
      id: "brand",
      accessor: (d) => (d.brand === null ? "" : d.brand),
    },
    {
      Header: "Supplier",
      id: "supplier",
      accessor: (d) => (d.supplier === null ? "" : d.supplier.name),
    },
    {
      Header: "Date Purchased",
      id: "datePurchased",
      accessor: (d) =>
        d.dateFinished === null
          ? ""
          : moment(d.dateFinished, "YYYY-MM-DD").format("YYYY-MM-DD"),
    },
    {
      Header: "Qty",
      id: "qty",
      accessor: (d) => (d.qty === null ? "" : d.qty + " " + material.unit.name),
    },
    {
      Header: "Unit Price",
      id: "unitPrice",
      accessor: (d) =>
        d.cost === null ? "" : "₱ " + misc.parseCurrencyNoCurrency(d.cost),
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d) =>
        d.cost === null
          ? ""
          : "₱ " + misc.parseCurrencyNoCurrency(Number(d.cost) * Number(d.qty)),
    },
  ];
}
export const BOQPDF = (props) => (
  <Document>
    <Page size="A4" orientation="landscape">
      <View
        style={[
          {
            marginTop: 15,
            marginBottom: 10,
          },
        ]}
      >
        <Image style={[{ marginHorizontal: 350, width: 80 }]} src={JVLogo} />
      </View>
      <View
        style={[
          {
            textAlign: "center",
            color: "black",
            width: "100%",
            marginTop: 0,
            marginBottom: 10,
          },
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
            <Text style={[{ textAlign: "left" }]}>BILL OF MATERIALS</Text>
          </View>
          <View style={[{ flex: 1, width: "50%" }]}>
            <Text style={[{ fontSize: 15, textAlign: "right" }]}>
              Date Generated: {moment().format("MMMM Do YYYY")}
            </Text>
          </View>
        </View>

        <Canvas
          style={[
            {
              width: 840,
              height: 3,
              position: "center",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("black")
              .lineWidth(3)
              .moveTo(60, 0)
              .lineTo(760, 0)
              .stroke();
          }}
        />
      </View>
      <View>
        <View
          style={[
            {
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 5,
            },
          ]}
        >
          <Text
            style={[
              {
                marginBottom: 5,
              },
            ]}
          >
            PROJECT NAME: {props.projData[0]?.name}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                marginBottom: 5,
              },
            ]}
          >
            ADDRESS: {props.projData[0]?.address}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                marginBottom: 5,
              },
            ]}
          >
            GENERATED BY: {props.state.userInfo}
          </Text>
        </View>
      </View>
      <View
        style={[
          {
            marginTop: 5,
            paddingLeft: 40,
            paddingRight: 40,
          },
        ]}
      >
        <Table data={props.projData[0]?.overallMats}>
          <TableHeader>
            <TableCell weighting={0.25} style={[tableStyles.tableHeader]}>
              MATERIAL NAME
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              USE
            </TableCell>
            <TableCell
              weighting={0.2}
              style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
            >
              QTY PURCHASED / REQUIRED
            </TableCell>

            <TableCell
              weighting={0.1}
              style={[tableStyles.tableHeader, tableStyles.tableCellNames]}
            >
              BASE UNIT PRICE
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              BASE TOTAL
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              ACTUAL TOTAL
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              style={[tableStyles.tableCell, tableStyles.tableCellNames]}
              weighting={0.25}
              getContent={(d) => (d.item?.name ? d.item?.name : "")}
            />
            <DataTableCell
              style={[tableStyles.tableCell, tableStyles.tableCellNames]}
              weighting={0.15}
              getContent={(d) => (d.usage === null ? "" : d.usage)}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.2}
              getContent={(d) => {
                if (d.requiredQty === null) {
                  return "";
                } else {
                  return (
                    misc.parseCurrencyNoCurrency(d.purchased) +
                    " / " +
                    misc.parseCurrencyNoCurrency(d.requiredQty) +
                    " " +
                    (d.unit?.name ? d.unit?.name : "")
                  );
                }
              }}
            />

            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.1}
              getContent={(d) => {
                if (d.purchased === null) {
                  return "Empty";
                } else {
                  return misc.parseCurrencyNoCurrency(d.baseCost);
                }
              }}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.finance === null) {
                  return "";
                } else {
                  return misc.parseCurrencyNoCurrency(
                    d.baseCost * d.requiredQty
                  );
                }
              }}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.finance === null) {
                  return "";
                } else {
                  return misc.parseCurrencyNoCurrency(d.finance.used);
                }
              }}
            />
          </TableBody>
        </Table>
      </View>
    </Page>

    <Page size="A4" orientation="landscape">
      <View
        style={[
          {
            marginTop: 15,
            marginBottom: 10,
          },
        ]}
      >
        <Image style={[{ marginHorizontal: 350, width: 80 }]} src={JVLogo} />
      </View>
      <View
        style={[
          {
            textAlign: "center",
            color: "black",
            width: "100%",
            marginTop: 0,
            marginBottom: 10,
          },
        ]}
      >
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
            <Text style={[{ textAlign: "left" }]}>
              BILL OF MATERIALS SUMMARY
            </Text>
          </View>
          <View style={[{ flex: 1, width: "50%" }]}>
            <Text style={[{ fontSize: 15, textAlign: "right" }]}>
              Date Generated: {moment().format("MMMM Do YYYY")}
            </Text>
          </View>
        </View>

        <Canvas
          style={[
            {
              width: 840,
              height: 3,
              position: "center",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("black")
              .lineWidth(3)
              .moveTo(60, 0)
              .lineTo(760, 0)
              .stroke();
          }}
        />
      </View>
      <View>
        <View
          style={[
            {
              paddingLeft: 80,
              paddingRight: 80,
              marginTop: 5,
            },
          ]}
        >
          <Text
            style={[
              {
                marginBottom: 5,
              },
            ]}
          >
            {/* {console.log(props.projData)} */}
            PROJECT NAME: {props.projData[0]?.name}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                marginBottom: 5,
              },
            ]}
          >
            ADDRESS: {props.projData[0]?.address}
          </Text>
          <Text
            style={[
              {
                fontSize: 15,
                marginBottom: 5,
              },
            ]}
          >
            GENERATED BY: {props.state.userInfo}
          </Text>
        </View>
      </View>
      <View
        style={[
          {
            marginTop: 5,
            paddingLeft: 40,
            paddingRight: 40,
          },
        ]}
      >
        <Table data={props.phaseData}>
          <TableHeader>
            <TableCell weighting={0.35} style={[tableStyles.tableHeader]}>
              PHASE
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              DATE STARTED
            </TableCell>
            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              DEADLINE
            </TableCell>

            <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
              PHASE BUDGET
            </TableCell>

            <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
              BUDGET USED
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.35}
              getContent={(d) => (d.name === null ? "" : d.name)}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.requiredQty === null) {
                  return "";
                } else {
                  return moment(d.dateStart, "YYYY-MM-DD").format("LL");
                }
              }}
            />

            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.purchased === null) {
                  return "";
                } else {
                  return moment(d.dateEstEnd, "YYYY-MM-DD").format("LL");
                }
              }}
            />
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.15}
              getContent={(d) => {
                if (d.finance === null) {
                  return "";
                } else {
                  return misc.parseCurrencyNoCurrency(d.finance.totalBudget);
                }
              }}
            />

            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.2}
              getContent={(d) => {
                if (d.finance === null) {
                  return "";
                } else {
                  return misc.parseCurrencyNoCurrency(d.finance.used);
                }
              }}
            />
          </TableBody>
        </Table>
      </View>
    </Page>
  </Document>
);

const materialColumn = [
  {
    show: false,
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },

  {
    Header: "Bill of material / quantity",
    Cell: (row) => (
      <>
        <Row
          className={(() => {
            let ordered =
              Number(row.original.requiredQty) - Number(row.original.purchased);
            //let percent = (ordered / Number(row.original.requiredQty)) * 100;
            //console.log(Math.round(percent));

            if (Number(ordered) <= 0) {
              return " phase-tr-header prog-finished";
            } else {
              return " phase-tr-header prog-incomplete";
            }
          })()}
        >
          {(() => {
            if (row.original) {
              if (row.original.warn) {
                if (Number(row.original.warn) > 0) {
                  return (
                    <Col className="phaseTableWarnCont" md={"auto"}>
                      {" "}
                      <span className="phaseTableWarnTxt font-weight-bolder ">
                        {row.original.warn}
                      </span>
                    </Col>
                  );
                }
              }
            }
          })()}

          {/* <Col md={"auto"}>
            <FormControl
              disabled
              readOnly
              className="phase-tc-txtbox-name phase-tc-txtbox"
              value={(() => {
                return row.original.item.name === null
                  ? ""
                  : row.original.item.name;
              })()}
            />
          </Col> */}
          <Col md={"auto"}>
            {(() => {
              let ordered =
                Number(row.original.requiredQty) -
                Number(row.original.purchased);
              if (Number(ordered) > 0) {
                // return <h6>{"Unordered: " + ordered}</h6>;
                return (
                  <>
                    <InputGroup>
                      {(() => {
                        return Number(row.original.requiredQty) -
                          Number(row.original.totalQty) <
                          0 ? (
                          <FormControl
                            as={NumericFormat}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={
                              " " + row.original?.unit?.name
                                ? row.original?.unit?.name
                                : ""
                            }
                            prefix={"OVER QTY: "}
                            value={Math.abs(
                              Number(row.original.requiredQty) -
                                Number(row.original.totalQty)
                            )}
                            className="phase-tc-txtbox-name phase-tc-txtbox "
                            readOnly
                          />
                        ) : (
                          <FormControl
                            as={NumericFormat}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={
                              " " + row.original?.unit?.name
                                ? row.original?.unit?.name
                                : ""
                            }
                            prefix={"Unordered: "}
                            value={
                              Number(row.original.requiredQty) -
                              Number(row.original.totalQty)
                            }
                            className="phase-tc-txtbox-name phase-tc-txtbox "
                            readOnly
                          />
                        );
                      })()}

                      <FormControl
                        as={NumericFormat}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={
                          " " + row.original?.unit?.name
                            ? row.original?.unit?.name
                            : ""
                        }
                        prefix={"Purchased: "}
                        value={Number(row.original.purchased)}
                        className="phase-tc-txtbox-name phase-tc-txtbox "
                        readOnly
                      />
                    </InputGroup>
                  </>
                );
              } else {
                return (
                  <>
                    <FormControl
                      disabled
                      readOnly
                      className="phase-tc-txtbox-name phase-tc-txtbox"
                      value={(() => {
                        return "Order Complete";
                      })()}
                    />
                  </>
                );
              }
            })()}
          </Col>
          <Col className="phaseTablePercentCont d-flex justify-content-end ">
            {" "}
            <span className="phaseTableWPercentTxt font-weight-bolder ">
              {(row.original.purchased / row.original.requiredQty) * 100}%
            </span>
          </Col>
        </Row>
        <Row className="phase-tc-cont" noGutters>
          {(() => {
            if (row.original) {
              if (Number(row.original.loss) === 0) {
                return <></>;
              } else {
                return (
                  <Col md={6}>
                    <p className="text-center font-weight-bold notifOver ">
                      OVER BUDGET
                    </p>
                  </Col>
                );
              }
            }
          })()}
          {(() => {
            if (row.original) {
              if (
                Number(row.original.totalQty) > Number(row.original.requiredQty)
              ) {
                return (
                  <Col md={6}>
                    <p className="text-center font-weight-bold notifOver ">
                      OVER QTY
                    </p>
                  </Col>
                );
              } else {
                return <></>;
              }
            }
          })()}
        </Row>

        <Row className="d-flex justify-content-center phase-tc-cont">
          <Col
            style={{
              backgroundColor: "var(--JV-primary)",
              color: "white",
              fontWeight: "bolder",
            }}
            xs={12}
          >
            <p className="pl-3">
              {row.original?.item?.name
                ? row.original?.item?.name
                : row.original?.name}
            </p>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  ID
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.uniqueId === null
                    ? ""
                    : row.original.uniqueId;
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Qty Need
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as={NumericFormat}
                displayType={"text"}
                thousandSeparator={true}
                suffix={
                  " " + row.original?.unit?.name ? row.original?.unit?.name : ""
                }
                //prefix={"Unordered: "}
                value={row.original?.requiredQty}
                className="phase-tc-txtbox number-format-center"
                readOnly
              />
            </InputGroup>
          </Col>

          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Total Budget
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original === null
                    ? ""
                    : "₱ " +
                        misc.parseCurrencyNoCurrency(row.original.totalBudget);
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Deadline
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                style={{
                  border: "none",
                  fontWeight: "bold",
                  color:
                    moment(row.original.deadline, "YYYY-MM-DD").diff(
                      moment(),
                      "days"
                    ) >= 1
                      ? "var(--JV-black)"
                      : "var(--JV-white)",
                  backgroundColor:
                    moment(row.original.deadline, "YYYY-MM-DD").diff(
                      moment(),
                      "days"
                    ) >= 1
                      ? "var(--JV-white)"
                      : "var(--JV-warning)",
                }}
                // className="phase-tc-txtbox"
                // value={(() => {
                //   return row.original.deadline === null
                //     ? "None"
                //     : moment(row.original.deadline, "YYYY-MM-DD").format("LL");
                // })()}
                value={(() => {
                  return row.original.deadline === null
                    ? "None"
                    : moment(row.original.deadline, "YYYY-MM-DD").format("LL");
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={
                    (() => {
                      if (row.original.loss === 0) {
                        return "save";
                      } else {
                        return "loss";
                      }
                    })() + " phase-tc-labels"
                  }
                >
                  {(() => {
                    if (row.original.loss === 0) {
                      return "Budget Left";
                    } else {
                      return "Over Budget";
                    }
                  })()}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className={
                  (() => {
                    if (row.original.loss === 0) {
                      return "save";
                    } else {
                      return "loss";
                    }
                  })() + " phase-tc-txtbox"
                }
                // className="phase-tc-txtbox"
                value={(() => {
                  if (row.original.loss === 0) {
                    return row.original === null
                      ? ""
                      : "₱ " +
                          misc.parseCurrencyNoCurrency(
                            row.original.totalBudget - row.original.used
                          );
                  } else {
                    return row.original === null
                      ? ""
                      : "₱ " + misc.parseCurrencyNoCurrency(row.original.loss);
                  }
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Posted
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.datePosted === null
                    ? "None"
                    : moment(row.original.datePosted, "YYYY-MM-DD").format(
                        "LL"
                      );
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Requester
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.requester === null
                    ? "None"
                    : row.original.requester.name;
                })()}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            {" "}
            <InputGroup size="sm" className="phase-tc-ig">
              <InputGroup.Prepend>
                <InputGroup.Text className="phase-tc-labels">
                  Use
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                disabled
                className="phase-tc-txtbox"
                value={(() => {
                  return row.original.usage === null
                    ? "Empty"
                    : row.original.usage;
                })()}
              />
            </InputGroup>
          </Col>
        </Row>
      </>
    ),
  },
];

let TxtPhaseName = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Name
      </strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtPhaseName.placeholder}
      style={{ textTransform: "uppercase" }}
      name="txtPhaseName"
      value={props.state.txtPhaseName.value}
      onChange={props.state.inputChange}
      className={props.state.txtPhaseName.className + " css-projectInput"}
      readOnly={props.state.txtPhaseName.readOnly}
      required
    />
  </>
);

let TxtPhaseBudget = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Budget
      </strong>
    </Form.Label>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      prefix={"₱"}
      placeholder={props.state.txtPhaseBudget.placeholder}
      name="txtPhaseBudget"
      value={props.state.txtPhaseBudget.value}
      onValueChange={(x) => {
        props.state.inputChange({
          target: {
            value: Number(x.value),
            name: "txtPhaseBudget",
            placeholder: props.state.txtPhaseBudget.placeholder,
            className: props.state.txtPhaseBudget.className,
          },
        });
      }}
      className={props.state.txtPhaseBudget.className + " css-projectInput"}
      readOnly={props.state.txtPhaseBudget.readOnly}
      required
    />

    {/* <FormControl
      type="number"
      placeholder={props.state.txtPhaseBudget.placeholder}
      name="txtPhaseBudget"
      value={props.state.txtPhaseBudget.value}
      onChange={props.state.inputChange}
      className={props.state.txtPhaseBudget.className + " css-projectInput"}
      readOnly={props.state.txtPhaseBudget.readOnly}
      required
    /> */}
  </>
);

// let TxtPhaseDescription = (props) => (
//   <>
//     <Form.Label>Description</Form.Label>
//     <FormControl
//       type="text"
//       placeholder={props.state.txtPhaseDescription.placeholder}
//       name="txtPhaseDescription"
//       value={props.state.txtPhaseDescription.value}
//       onChange={props.state.inputChange}
//       className={
//         props.state.txtPhaseDescription.className + " css-projectInput"
//       }
//       readOnly={props.state.txtPhaseDescription.readOnly}
//       required
//     />
//   </>
// );

let TxtPhaseDateStart = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Date Started
      </strong>
    </Form.Label>
    <FormControl
      type="date"
      placeholder={props.state.txtPhaseDateStart.placeholder}
      name="txtPhaseDateStart"
      value={props.state.txtPhaseDateStart.value}
      onChange={props.state.inputChange}
      className={props.state.txtPhaseDateStart.className + " css-projectInput"}
      readOnly={props.state.txtPhaseDateStart.readOnly}
      required
    />
  </>
);
let TxtPhaseDateEnd = (props) => (
  <>
    <strong>
      <Form.Label>Date Ended</Form.Label>
    </strong>

    <FormControl
      type="date"
      placeholder={props.state.txtPhaseDateEnd.placeholder}
      name="txtPhaseDateEnd"
      value={props.state.txtPhaseDateEnd.value}
      onChange={props.state.inputChange}
      className={props.state.txtPhaseDateEnd.className + " css-projectInput"}
      readOnly={props.state.txtPhaseDateEnd.readOnly}
    />
  </>
);
let TxtPhaseDateEstEnd = (props) => (
  <>
    <Form.Label>
      <strong>Date Deadline</strong>
    </Form.Label>
    <FormControl
      type="date"
      placeholder={props.state.txtPhaseDateEstEnd.placeholder}
      name="txtPhaseDateEstEnd"
      value={props.state.txtPhaseDateEstEnd.value}
      onChange={props.state.inputChange}
      className={props.state.txtPhaseDateEstEnd.className + " css-projectInput"}
      readOnly={props.state.txtPhaseDateEstEnd.readOnly}
    />
  </>
);

// let TxtPhaseClientPayable = (props) => (
//   <>
//     <Form.Label>
//       <strong>
//         <span className="required-field">* </span>Client Payable
//       </strong>
//     </Form.Label>
//     <FormControl
//       type="number"
//       placeholder={props.state.txtPhaseClientPayable.placeholder}
//       name="txtPhaseClientPayable"
//       value={props.state.txtPhaseClientPayable.value}
//       onChange={props.state.inputChange}
//       className={
//         props.state.txtPhaseClientPayable.className + " css-projectInput"
//       }
//       readOnly={props.state.txtPhaseClientPayable.readOnly}
//       required
//     />
//   </>
// );

// let TxtPhaseClientLastPayAmount = (props) => (
//   <>
//     <Form.Label>
//       <strong>
//         <span className="required-field">* </span>Client Last Payment Amount
//       </strong>
//     </Form.Label>
//     <FormControl
//       type="number"
//       placeholder={props.state.txtPhaseClientLastPayAmount.placeholder}
//       name="txtPhaseClientLastPayAmount"
//       value={props.state.txtPhaseClientLastPayAmount.value}
//       onChange={props.state.inputChange}
//       className={
//         props.state.txtPhaseClientLastPayAmount.className + " css-projectInput"
//       }
//       readOnly={props.state.txtPhaseClientLastPayAmount.readOnly}
//       required
//     />
//   </>
// );

// let TxtPhaseDateLastPayment = (props) => (
//   <>
//     <Form.Label>
//       <strong>Client Last Payment Date</strong>
//     </Form.Label>
//     <FormControl
//       type="date"
//       placeholder={props.state.txtPhaseDateLastPayment.placeholder}
//       name="txtPhaseDateLastPayment"
//       value={props.state.txtPhaseDateLastPayment.value}
//       onChange={props.state.inputChange}
//       className={
//         props.state.txtPhaseDateLastPayment.className + " css-projectInput"
//       }
//       readOnly={props.state.txtPhaseDateLastPayment.readOnly}
//     />
//   </>
// );

// let TxtExportType = (props) => (
//   <>
//     {/* <Form.Label>Project Status</Form.Label> */}
//     <FormControl
//       as="select"
//       value={props.state.phasePDFSelect.value}
//       name="phasePDFSelect"
//       className={""}
//       onChange={(e) => {
//         props.state.inputChange(e);
//       }}
//     >
//       <option value={1} key={1}>
//         Project Summary
//       </option>
//     </FormControl>
//   </>
// );

let TxtUniqueIDPhase = (props) => (
  <>
    <Form.Label>
      <strong>ID</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtProjUniqueIDPhase.placeholder}
      name="txtProjUniqueIDPhase"
      value={props.state.txtProjUniqueIDPhase.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtProjUniqueIDPhase.className + " css-projectInput"
      }
      readOnly={props.state.txtProjUniqueIDPhase.readOnly}
    />
  </>
);
let TxtDateLastModifiedPhase = (props) => (
  <>
    <Form.Label>
      <strong>Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDateLastModifiedPhase.placeholder}
      name="txtDateLastModifiedPhase"
      value={props.state.txtDateLastModifiedPhase.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModifiedPhase.className + " css-projectInput"
      }
      readOnly={props.state.txtDateLastModifiedPhase.readOnly}
    />
  </>
);
let TxtLastModifiedByPhase = (props) => (
  <>
    <Form.Label>
      {" "}
      <strong>Last Modified By</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtLastModifiedByPhase.placeholder}
      name="txtLastModifiedByPhase"
      value={props.state.txtLastModifiedByPhase.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtLastModifiedByPhase.className + " css-projectInput"
      }
      readOnly={props.state.txtLastModifiedByPhase.readOnly}
    />
  </>
);
let TxtDatePostedPhase = (props) => (
  <>
    <Form.Label>
      <strong>Date Posted</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDatePostedPhase.placeholder}
      name="txtDatePostedPhase"
      value={props.state.txtDatePostedPhase.value}
      onChange={props.state.inputChange}
      className={props.state.txtDatePostedPhase.className + " css-projectInput"}
      readOnly={props.state.txtDatePostedPhase.readOnly}
    />
  </>
);

let SelMatFilter = (props) => (
  <>
    <InputGroup className="pl-3" size="">
      <InputGroup.Prepend>
        <InputGroup.Text className="">Filter</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        value={props.state.selMatFilter.value}
        name="selMatFilter"
        className=""
        disabled={props.state.selMatFilter.readOnly}
        onChange={(e) => {
          props.state.inputChange(e);
          props.state.handleMatFilterChange(e.target.value);
        }}
      >
        <option value={0} key={0}>
          All
        </option>
        <option value={1} key={1}>
          Ongoing
        </option>
        <option value={2} key={2}>
          Completed
        </option>
        <option value={3} key={3}>
          Notices
        </option>
      </FormControl>
    </InputGroup>
  </>
);

function PDFGenerateProject(props) {
  const [btn, setBtn] = useState(false);
  const [drop, setDrop] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        className="form-control actor-selector "
        name="selPdfGenerate"
        value={props.state.selPdfGenerate.value}
        disabled={drop}
        onChange={(e) => {
          props.state.inputChange(e);
        }}
      >
        <option value={1} key={1}>
          Project Summary
        </option>
        <option value={2} key={2}>
          Bill of Materials
        </option>
      </FormControl>

      {(() => {
        if (btn) {
          return (
            <BlobProvider
              document={
                Number(props.state.selPdfGenerate.value) === 1 ? (
                  <PhasePDF
                    state={props.state}
                    data={props.data}
                    finance={props.data}
                  />
                ) : (
                  <BOQPDF
                    state={props.state}
                    projData={props.state.projectBOQData}
                    phaseData={props.data.phases}
                  />
                )
              }
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return (
                    <Button className="btnPhaseExport" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  );
                } else {
                  return (
                    <>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.state.downloadPDF(blob);
                          setBtn(false);
                          //props.state.resetPOModal();
                        }}
                      >
                        {" "}
                        Download
                      </Button>
                      <Button
                        name="btnGeneratePDF"
                        className="btnPhaseExport"
                        onClick={(e) => {
                          props.dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc:
                              Number(props.state.selPdfGenerate.value) === 1 ? (
                                <PhasePDF
                                  state={props.state}
                                  data={props.data}
                                  finance={props.data}
                                />
                              ) : (
                                <BOQPDF
                                  state={props.state}
                                  projData={props.state.projectBOQData}
                                  phaseData={props.data.phases}
                                />
                              ),
                          });
                          setBtn(false);
                          setDrop(false);
                        }}
                      >
                        {" "}
                        View
                      </Button>
                    </>
                  );
                }
              }}
            </BlobProvider>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              style={{ backgroundColor: "var(--JV-green)" }}
              onClick={(e) => {
                props.state.readProjectBOQ((x) => {
                  setBtn(true);
                  setDrop(true);
                });
              }}
            >
              {" "}
              Generate
            </Button>
          );
        }
      })()}
    </InputGroup>
  );
}

function Phase(props) {
  //const [isItemModalOpen, toggleItemModal] = useState(false);

  return (
    <div className="phase-cont">
      <Row className="page-selectedPhase-row mb-1" noGutters>
        <Col>
          <h4>Project Name: {props.data.name}</h4>
        </Col>
        <Col className="d-flex justify-content-end">
          {/* <h4>
            Status:{" "}
            <span
              style={{
                color:
                  (Number(props.data.status) === 1 && "#DFE24A") ||
                  (Number(props.data.status) === 2 && "#DFE24A") ||
                  (Number(props.data.status) === 3 && "#DFE24A") ||
                  (Number(props.data.status) === 4 && "#E24A4A") ||
                  (Number(props.data.status) === 5 && "#E24A4A") ||
                  (Number(props.data.status) === 6 && "#4A87E2") ||
                  (Number(props.data.status) === 7 && "#5DB55D") ||
                  (Number(props.data.status) === 8 && "#E2934A"),
              }}
            >
              {" "}
              {DBStatus.parseProjectStatus(props.data.status)}
            </span>
          </h4> */}
        </Col>
      </Row>
      <Row noGutters>
        <Col xs={12}>
          <div style={{ textAlign: "center" }}>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                  className=""
                >
                  Phase:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                value={props.state.txtPhaseCrud.value}
                name="phaseSelect"
                className=""
                style={{ fontWeight: "bold", fontSize: "1rem" }}
                onChange={(e) => {
                  props.state.handlePhaseChange(e, true);
                }}
                disabled={props.state.selectedContent !== "PROJECT"}
              >
                {props.state.phaseData.map((phase, index) => (
                  <option
                    key={phase.id}
                    value={phase.id}
                    className="phaseOptions "
                  >
                    ({phase.matOngoing}) {phase.name}
                  </option>
                ))}
                <option value={0} key={0} disabled hidden>
                  Phase empty
                </option>
              </FormControl>
              {props.state.selectedContent === "PROJECT" && (
                <>
                  {(() => {
                    //let t = Number(props.userType);
                    if (
                      props.userType.includes(0) ||
                      props.userType.includes(7)
                    ) {
                      return (
                        <ButtonGroup className="btnGroupPhaseCRUD">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id={`phase-tooltip-add`}>ADD</Tooltip>
                            }
                          >
                            <Button
                              variant="secondary"
                              name="btnAddPhase"
                              style={{ backgroundColor: "var(--JV-green)" }}
                              onClick={(e) => {
                                props.state.handleActionChangePhase(e);
                              }}
                            >
                              <i className="icofont-ui-add icofont-1x"></i>
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id={`phase-tooltip-edit`}>EDIT</Tooltip>
                            }
                          >
                            <Button
                              variant="secondary"
                              name="btnEditPhase"
                              className="phase-btn-crud btnEditPhase"
                              onClick={(e) => {
                                props.state.handleActionChangePhase(e);
                              }}
                            >
                              <i className="icofont-ui-edit icofont-1x"></i>
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id={`phase-tooltip-delete`}>
                                DELETE
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="secondary"
                              name="btnDeletePhase"
                              className="phase-btn-crud btnDeletePhase"
                              onClick={(e) => {
                                props.state.handleActionChangePhase(e);
                              }}
                            >
                              <i className="icofont-ui-remove icofont-1x"></i>
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                      );
                    }
                  })()}
                </>
              )}
            </InputGroup>
            {/* <h5>
              {props.state.txtPhaseCrud?.data
                ? props.state.txtPhaseCrud?.data?.name
                : "No Phase Selected"}
            </h5> */}
          </div>
        </Col>
      </Row>
      {props.state.selectedContent === "PROJECT" && (
        <>
          <Row className="page-info-row mt-3" noGutters>
            <Col md={8} className="phase-col pr-2">
              <Row className="row-crud" noGutters={true}>
                <Col md="auto" lg="auto" className="">
                  <InputGroup className="">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="ico-search">
                        <i className="icofont-search icofont-1x"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Search"
                      aria-label="Username"
                      name="searchInput"
                      className="searchTable shadow-none"
                      aria-describedby="basic-addon1"
                      onChange={props.state.handleSearch}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <SelMatFilter state={props.state} />
                </Col>

                {(() => {
                  if (
                    props.userType.includes(8) ||
                    props.userType.includes(0) ||
                    props.userType.includes(7)
                  ) {
                    return (
                      <Col className="material-btn-crud-col d-flex justify-content-end">
                        <ButtonGroup className="">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id={`material-tooltip-add`}>ADD</Tooltip>
                            }
                          >
                            <Button
                              variant="secondary"
                              name="btnAddMaterial"
                              style={{ backgroundColor: "var(--JV-green)" }}
                              onClick={(e) => {
                                //props.handleShowModal();
                                props.state.handleActionChangeMaterial(e);
                              }}
                            >
                              Add Entry
                              <i className="pl-2 icofont-ui-add icofont-1x"></i>
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                      </Col>
                    );
                  }
                })()}
              </Row>
              {props.tablelist}
            </Col>
            <Col md={4} className="phase-col pl-2">
              <Card
                className="phase-card-cont "
                style={{ borderRadius: 0, overflow: "auto" }}
              >
                <Card.Header
                  as="h5"
                  className="phase-card-header"
                  style={{ borderRadius: 0 }}
                >
                  Details
                </Card.Header>
                <Card.Body className="phase-card-body">
                  <Row>
                    <Col xs={12} className="mb-5">
                      {(() => {
                        if (
                          props.userType.includes(7) ||
                          props.userType.includes(0)
                        ) {
                          switch (props.data.status) {
                            case 1:
                              return (
                                <Button
                                  onClick={(e) => {
                                    props.state.handleActionChangePhase(e);
                                  }}
                                  style={{ backgroundColor: "var(--JV-green)" }}
                                  block
                                  name="btnForApproval"
                                >
                                  {" "}
                                  Submit for approval{" "}
                                </Button>
                              );
                            case 3:
                              return (
                                <Button
                                  onClick={(e) => {
                                    props.state.handleActionChangePhase(e);
                                  }}
                                  style={{ backgroundColor: "var(--JV-green)" }}
                                  block
                                  name="btnClientAccepted"
                                >
                                  {" "}
                                  Client Accepted{" "}
                                </Button>
                              );
                            case 4:
                              return (
                                <Button
                                  onClick={(e) => {
                                    props.state.handleActionChangePhase(e);
                                  }}
                                  style={{ backgroundColor: "var(--JV-green)" }}
                                  block
                                  name="btnForApproval"
                                >
                                  {" "}
                                  Submit for Re-approval{" "}
                                </Button>
                              );
                            case 5:
                              return (
                                <Button
                                  onClick={(e) => {
                                    props.state.handleActionChangePhase(e);
                                  }}
                                  style={{ backgroundColor: "var(--JV-green)" }}
                                  block
                                  name="btnForApproval"
                                >
                                  {" "}
                                  Submit for Re-approval{" "}
                                </Button>
                              );
                            default:
                              break;
                          }

                          if (
                            props.data.purchasedPercentage !== null ||
                            props.data.purchasedPercentage !== "NaN%" ||
                            props.data.purchasedPercentage
                          ) {
                            if (
                              Number(
                                props.data.purchasedPercentage.replace("%", "")
                              ) >= 100 &&
                              Number(props.data.status) === 6
                            ) {
                              return (
                                <Button
                                  onClick={(e) => {
                                    props.state.handleActionChangePhase(e);
                                  }}
                                  className="btnsetFinished ml-3"
                                  name="btnsetFinished"
                                >
                                  {" "}
                                  FINISH PROJECT{" "}
                                </Button>
                              );
                            }
                          }
                        }
                      })()}
                    </Col>

                    {(props.userType.includes(8) ||
                      props.userType.includes(6) ||
                      props.userType.includes(0)) && (
                      <Col xs={12} className="mb-2">
                        <Button
                          variant="secondary"
                          block
                          name="btnAddItem"
                          className=""
                          style={{ backgroundColor: "var(--JV-green)" }}
                          onClick={(e) => {
                            props.dispatch({
                              type: "PR_MODAL_TOGGLE",
                            });
                          }}
                        >
                          Manage Purchase Requests{" "}
                          <Badge
                            variant={
                              Number(props.PRData.length) === 0
                                ? "light"
                                : "danger"
                            }
                          >
                            {props.PRData.length}
                          </Badge>
                        </Button>
                      </Col>
                    )}
                    {/* {(() => {
                  if (
                    props.userType.includes(8) ||
                    props.userType.includes(6)
                  ) {
                    return <></>;
                  } else {
                    return (
                     
                    );
                  }
                })()} */}

                    <Col xs={12} className="mb-2">
                      {" "}
                      <Button
                        variant="secondary"
                        name="btnAddItem"
                        block
                        style={{ backgroundColor: "var(--JV-green)" }}
                        onClick={(e) => {
                          props.dispatch({
                            type: "PO_MODAL_TOGGLE",
                          });
                        }}
                      >
                        Manage Purchase Order{" "}
                        <Badge
                          variant={
                            Number(props.POData.length) === 0
                              ? "light"
                              : "danger"
                          }
                        >
                          {props.POData.length}
                        </Badge>
                      </Button>
                    </Col>
                    <Col xs={12} className="mb-2"></Col>
                    <Col xs={12} className="mb-2">
                      <hr></hr>
                      <Row className="" noGutters>
                        <Col xs={4}>
                          <h4 style={{}}>Status: </h4>
                        </Col>
                        <Col xs={8}>
                          <h4
                            style={{
                              color:
                                (Number(props.data.status) === 1 &&
                                  "#DFE24A") ||
                                (Number(props.data.status) === 2 &&
                                  "#DFE24A") ||
                                (Number(props.data.status) === 3 &&
                                  "#DFE24A") ||
                                (Number(props.data.status) === 4 &&
                                  "#E24A4A") ||
                                (Number(props.data.status) === 5 &&
                                  "#E24A4A") ||
                                (Number(props.data.status) === 6 &&
                                  "#4A87E2") ||
                                (Number(props.data.status) === 7 &&
                                  "#5DB55D") ||
                                (Number(props.data.status) === 8 && "#E2934A"),
                            }}
                          >
                            {DBStatus.parseProjectStatus(props.data.status)}
                          </h4>
                        </Col>
                      </Row>

                      <Row className="" noGutters>
                        <Col xs={4}>
                          <h4 style={{}}>Progress: </h4>
                        </Col>
                        <Col xs={8} className="">
                          <ProgressBar
                            className="project-percentage "
                            now={
                              props.data.purchasedPercentage === null ||
                              props.data.purchasedPercentage === "NaN%"
                                ? 0
                                : Number(
                                    props.data.purchasedPercentage.replace(
                                      "%",
                                      ""
                                    )
                                  )
                            }
                            label={`${
                              props.data.purchasedPercentage === null ||
                              props.data.purchasedPercentage === "NaN%"
                                ? "0% Completion"
                                : props.data.purchasedPercentage + " Completion"
                            } `}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <hr></hr>
                      <Card.Title>Phase</Card.Title>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Date Started
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={(() => {
                            return props.state.txtPhaseCrud.data.dateStart ===
                              null
                              ? "None"
                              : moment(
                                  props.state.txtPhaseCrud.data.dateStart,
                                  "YYYY-MM-DD"
                                ).format("LL");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Deadline
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          //className="phase-details-txtbox"
                          style={{
                            border: "none",
                            fontWeight: "bold",
                            color:
                              moment(
                                props.state.txtPhaseCrud.data.dateEstEnd,
                                "YYYY-MM-DD"
                              ).diff(moment(), "days") >= 1
                                ? "var(--JV-black)"
                                : "var(--JV-white)",
                            backgroundColor:
                              moment(
                                props.state.txtPhaseCrud.data.dateEstEnd,
                                "YYYY-MM-DD"
                              ).diff(moment(), "days") >= 1
                                ? "var(--JV-white)"
                                : "var(--JV-warning)",
                          }}
                          value={(() => {
                            return props.state.txtPhaseCrud.data.dateEstEnd ===
                              null
                              ? "None"
                              : moment(
                                  props.state.txtPhaseCrud.data.dateEstEnd,
                                  "YYYY-MM-DD"
                                ).format("LL");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Completion Date
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={(() => {
                            return props.state.txtPhaseCrud.data.dateEnd ===
                              null
                              ? "None"
                              : moment(
                                  props.state.txtPhaseCrud.data.dateEnd,
                                  "YYYY-MM-DD"
                                ).format("LL");
                          })()}
                        />
                      </InputGroup>
                      {/* {(()=>{
                        if (
                          props.userType.includes(0) ||
                          props.userType.includes(7)
                        ){
                          return(
                            <>
                            </>
                          )
                        }
                      })()} */}
                      {(() => {
                        //let t = Number(props.userType);
                        if (
                          props.userType.includes(0) ||
                          props.userType.includes(7)
                        ) {
                          return (
                            <>
                              <InputGroup size="sm" className="phase-tc-ig">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="phase-d-card-labels">
                                    Total Budget
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  readOnly
                                  disabled
                                  className="phase-details-txtbox"
                                  value={(() => {
                                    return props.state.phaseFinance
                                      .totalBudget === null
                                      ? ""
                                      : "₱ " +
                                          misc.parseCurrencyNoCurrency(
                                            props.state.phaseFinance.totalBudget
                                          );
                                  })()}
                                />
                              </InputGroup>
                            </>
                          );
                        }
                      })()}
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            className={
                              (() => {
                                if (
                                  Number(
                                    props.state.phaseFinance.totalBudget -
                                      props.state.phaseFinance.used
                                  ) > 0
                                ) {
                                  return "save";
                                } else {
                                  return "loss";
                                }
                              })() + " phase-d-card-labels"
                            }
                          >
                            {(() => {
                              if (
                                Number(
                                  props.state.phaseFinance.totalBudget -
                                    props.state.phaseFinance.used
                                ) > 0
                              ) {
                                return "Budget Left";
                              } else {
                                return "Over Budget";
                              }
                            })()}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className={
                            (() => {
                              if (
                                Number(
                                  props.state.phaseFinance.totalBudget -
                                    props.state.phaseFinance.used
                                ) > 0
                              ) {
                                return "save";
                              } else {
                                return "loss";
                              }
                            })() + " phase-details-txtbox"
                          }
                          // className="phase-tc-txtbox"
                          value={(() => {
                            return props.state.phaseFinance === null
                              ? ""
                              : "₱ " +
                                  misc.parseCurrencyNoCurrency(
                                    props.state.phaseFinance.totalBudget -
                                      props.state.phaseFinance.used
                                  );
                          })()}
                        />
                      </InputGroup>
                      {(() => {
                        //let t = Number(props.userType);
                        if (
                          props.userType.includes(0) ||
                          props.userType.includes(7)
                        ) {
                          return (
                            <>
                              <InputGroup size="sm" className="phase-tc-ig">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="phase-d-card-labels">
                                    Total Mat. Budget Cost
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  readOnly
                                  disabled
                                  className={
                                    (() => {
                                      //console.log(props.state.phaseFinance)
                                      if (
                                        Number(
                                          props.state.phaseFinance.totalBudget -
                                            props.state.phaseFinance
                                              .initialCostBudgetTotal
                                        ) > 0
                                      ) {
                                        return "save";
                                      } else {
                                        return "loss";
                                      }
                                    })() + " phase-details-txtbox"
                                  }
                                  value={(() => {
                                    return props.state.phaseFinance
                                      .initialCostBudgetTotal === null
                                      ? ""
                                      : "₱ " +
                                          misc.parseCurrencyNoCurrency(
                                            props.state.phaseFinance
                                              .initialCostBudgetTotal
                                          );
                                  })()}
                                />
                              </InputGroup>
                              {Number(
                                props.state.phaseFinance.totalBudget -
                                  props.state.phaseFinance
                                    .initialCostBudgetTotal
                              ) < 0 && (
                                <div
                                  className="m-0 p-0"
                                  style={{ width: "100%", textAlign: "right" }}
                                >
                                  <p
                                    className="m-0 p-0"
                                    style={{ color: "var(--JV-warning)" }}
                                  >
                                    Over budget
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        }
                      })()}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Card.Title>Project</Card.Title>
                  <Row>
                    <Col>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Job Site
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={(() => {
                            return props.data.address === null
                              ? "None"
                              : props.data.address;
                          })()}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Date Started
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={(() => {
                            return props.data.dateStart === null
                              ? "None"
                              : moment(
                                  props.data.dateStart,
                                  "YYYY-MM-DD"
                                ).format("LL");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            No. of days from start
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={`${moment().diff(
                            moment(props.data.dateStart, "YYYY-MM-DD"),
                            "days"
                          )} Days`}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Deadline
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          //className="phase-details-txtbox"
                          style={{
                            border: "none",
                            fontWeight: "bold",
                            color:
                              moment(props.data.dateEstEnd, "YYYY-MM-DD").diff(
                                moment(),
                                "days"
                              ) >= 1
                                ? "var(--JV-black)"
                                : "var(--JV-white)",
                            backgroundColor:
                              moment(props.data.dateEstEnd, "YYYY-MM-DD").diff(
                                moment(),
                                "days"
                              ) >= 1
                                ? "var(--JV-white)"
                                : "var(--JV-warning)",
                          }}
                          value={(() => {
                            return props.data.dateEstEnd === null
                              ? "None"
                              : moment(
                                  props.data.dateEstEnd,
                                  "YYYY-MM-DD"
                                ).format("LL");
                          })()}
                        />
                      </InputGroup>
                      <InputGroup size="sm" className="phase-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="phase-d-card-labels">
                            Completion Date
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          disabled
                          className="phase-details-txtbox"
                          value={(() => {
                            return props.data.dateEnd === null
                              ? "None"
                              : moment(props.data.dateEnd, "YYYY-MM-DD").format(
                                  "LL"
                                );
                          })()}
                        />
                      </InputGroup>
                      {(() => {
                        //let t = Number(props.userType);
                        if (
                          props.userType.includes(0) ||
                          props.userType.includes(7)
                        ) {
                          return (
                            <>
                              <InputGroup size="sm" className="phase-tc-ig">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="phase-d-card-labels">
                                    Total Budget
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  readOnly
                                  disabled
                                  className="phase-details-txtbox"
                                  value={(() => {
                                    return props.data.finance.totalBudget ===
                                      null
                                      ? ""
                                      : "₱ " +
                                          misc.parseCurrencyNoCurrency(
                                            props.data.finance.totalBudget
                                          );
                                  })()}
                                />
                              </InputGroup>
                              <InputGroup size="sm" className="phase-tc-ig">
                                <InputGroup.Prepend>
                                  <InputGroup.Text
                                    className={
                                      (() => {
                                        if (
                                          Number(
                                            props.data.finance.totalBudget -
                                              props.data.finance.used
                                          ) > 0
                                        ) {
                                          return "save";
                                        } else {
                                          return "loss";
                                        }
                                      })() + " phase-d-card-labels"
                                    }
                                  >
                                    {(() => {
                                      if (
                                        Number(
                                          props.data.finance.totalBudget -
                                            props.data.finance.used
                                        ) > 0
                                      ) {
                                        return "Budget Left";
                                      } else {
                                        return "Over Budget";
                                      }
                                    })()}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  readOnly
                                  disabled
                                  className={
                                    (() => {
                                      if (
                                        Number(
                                          props.data.finance.totalBudget -
                                            props.data.finance.used
                                        ) > 0
                                      ) {
                                        return "save";
                                      } else {
                                        return "loss";
                                      }
                                    })() + " phase-details-txtbox"
                                  }
                                  value={(() => {
                                    return props.state.phaseFinance === null
                                      ? ""
                                      : "₱ " +
                                          misc.parseCurrencyNoCurrency(
                                            props.data.finance.totalBudget -
                                              props.data.finance.used
                                          );
                                  })()}
                                />
                              </InputGroup>
                            </>
                          );
                        }
                      })()}
                      {(() => {
                        //let t = Number(props.userType);
                        if (
                          props.userType.includes(0) ||
                          props.userType.includes(7)
                        ) {
                          return (
                            <>
                              <InputGroup size="sm" className="phase-tc-ig">
                                <InputGroup.Prepend>
                                  <InputGroup.Text className="phase-d-card-labels">
                                    Total Mat. Budget Cost
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                {/* {console.log(props.data.finance)} */}
                                <FormControl
                                  readOnly
                                  disabled
                                  className={
                                    (() => {
                                      if (
                                        Number(
                                          props.data.finance.totalBudget -
                                            props.data.finance
                                              .initialCostBudgetTotal
                                        ) > 0
                                      ) {
                                        return "save";
                                      } else {
                                        return "loss";
                                      }
                                    })() + " phase-details-txtbox"
                                  }
                                  value={(() => {
                                    return props.data.finance
                                      .initialCostBudgetTotal === null
                                      ? ""
                                      : "₱ " +
                                          misc.parseCurrencyNoCurrency(
                                            props.data.finance
                                              .initialCostBudgetTotal
                                          );
                                  })()}
                                />
                              </InputGroup>
                              {Number(
                                props.data.finance.totalBudget -
                                  props.data.finance.initialCostBudgetTotal
                              ) < 0 && (
                                <div
                                  className="m-0 p-0"
                                  style={{ width: "100%", textAlign: "right" }}
                                >
                                  <p
                                    className="m-0 p-0"
                                    style={{ color: "var(--JV-warning)" }}
                                  >
                                    Over budget
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        }
                      })()}
                    </Col>
                  </Row>
                  <hr></hr>
                  {(() => {
                    if (
                      props.userType.includes(0) ||
                      props.userType.includes(7)
                    ) {
                      return (
                        <PDFGenerateProject
                          state={props.state}
                          data={props.data}
                          finance={props.data}
                          dispatch={props.dispatch}
                        />
                      );
                    }
                  })()}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {props.state.selectedContent === "MATERIAL_ITEM" && (
        <MaterialModal
          state={props.state}
          userType={props.userType}
          po={props.po}
          units={props.units}
          items={props.items}
          tableHistory={props.tableHistory}
        />
      )}

      {/* {props.state.handleMaterialModal.isShow && (
        <>
         
        </>
      )} */}

      <OrderOverviewModal
        state={props.state}
        userType={props.userType}
        socket={props.socket}
      />

      <PRProcess
        state={props.state}
        userType={props.userType}
        projectId={props.projectId}
      />

      <POProcess
        state={props.state}
        userType={props.userType}
        projectId={props.projectId}
        dispatch={props.dispatch}
      />

      <Modal
        show={props.state.handlePhaseModal.isShow}
        onHide={props.state.handlePhaseModal.hide}
        onExit={(e) => {
          props.state.handlePhaseModal.handleExit(e);
        }}
        dialogClassName="modal-90w"
        size="lg"
      >
        <Modal.Header closeButton className="phase-modal-header-cont">
          <Row className="phase-modal-header">
            <Col>
              <h4 className="text-center phase-modal-header-text">
                Manage Phase
              </h4>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <span className="flex-grow-1 ">
              <span className="required-field">* </span>are required fields
            </span>
          </div>
          <Form
            id="formPhaseInput"
            noValidate
            validated={props.state.handlePhaseModal.isValidated}
            onSubmit={props.state.handlePhaseModal.handleSubmit}
          >
            <Row>
              <Col className="">
                <Row>
                  <Col className="phase-col-innerCont-padding">
                    {(() => {
                      if (Number(props.state.selectedPhaseOperation.id) !== 0) {
                        return (
                          <>
                            <TxtUniqueIDPhase state={props.state} />
                          </>
                        );
                      }
                    })()}
                    <TxtPhaseName state={props.state} />
                    <TxtPhaseBudget state={props.state} />
                    {(() => {
                      if (Number(props.state.selectedPhaseOperation.id) !== 0) {
                        return (
                          <>
                            <TxtLastModifiedByPhase state={props.state} />
                          </>
                        );
                      }
                    })()}
                  </Col>
                  <Col className="phase-col-innerCont-padding">
                    <TxtPhaseDateStart state={props.state} />
                    <TxtPhaseDateEstEnd state={props.state} />
                    <TxtPhaseDateEnd state={props.state} />
                    {(() => {
                      if (Number(props.state.selectedPhaseOperation.id) !== 0) {
                        return (
                          <>
                            <TxtDatePostedPhase state={props.state} />
                            <TxtDateLastModifiedPhase state={props.state} />
                          </>
                        );
                      }
                    })()}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col className="phase-col-cont-padding">
                <Button block className="modalSavePhase" type="submit">
                  SAVE
                </Button>
              </Col>
              <Col className="phase-col-cont-padding">
                <Button
                  block
                  className="modalCancelPhase"
                  onClick={props.state.handlePhaseModal.hide}
                >
                  CANCEL
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

// const Phase = (props) => (

// );

export { Phase, materialColumn, historyColumn };
