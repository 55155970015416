import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  delay,
  select,
} from "redux-saga/effects";

import axios from "axios";
import history from "../functions/history";
//import Filters from "../functions/filters";

const getModal = (state) => state.globalModal;

export function* API_REQUEST(action) {
  //yield put({ type: "API_GET_LOADING" });

  let response = null;
  const socketio = yield select((state) => state.SocketHandler.socket);
  let serv = process.env.REACT_APP_API_URL;

  const API_CALL = () => {
    switch (action.reqType) {
      case "getPR":
        return axios
          .get(
            serv +
              "inventory/getApprovedMultMaterial?projectId=" +
              action.payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("LUT"),
              },
            }
          )
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "getPO":
        return axios
          .get(
            serv +
              "inventory/getPurchaseOrderByProjectId?projectId=" +
              action.payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("LUT"),
              },
            }
          )
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "getFile":
        return axios
          .get(serv + action.payload, {
            responseType: "arraybuffer",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "get":
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "post":
        //console.log(action.payload);
        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "postFiles":
        console.log(action.payload);

        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "put":
        return axios
          .put(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "delete":
        return axios
          .delete(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
            data: action.data,
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      default:
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
    }
  };

  try {
    if (socketio.disconnected) {
      yield delay(2000);

      yield axios
        .post(
          serv + "auth/reAuthenticate",
          { sessionID: socketio.id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          }
        )
        .then((resp) => {
          localStorage.setItem("LUT", resp.data.accessToken);
          //console.log(resp);

          return resp;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });

      response = yield call(API_CALL);
    } else {
      response = yield call(API_CALL);
    }

    switch (action.reqType) {
      case "getPO":
        yield put({ type: "API_STORE_PO", payload: { data: response.data } });
        break;
      case "getPR":
        yield put({ type: "API_STORE_PR", payload: { data: response.data } });
        break;

      default:
        break;
    }

    //yield delay(2000);
    action.resolve(response);
    yield put({ type: "API_GET_SUCCESS", payload: { data: response.data } });
  } catch (error) {
    //console.log(error);
    if (!error.response) {
      yield put({ type: "API_GET_ERROR" });
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: { payload: { isLoading: false, force: true } },
      });
      yield put({
        type: "MODAL_SHOW",
        payload: {
          title: "Server Offline",
          desc: "Please contact Administrator",
        },
      });
      yield call([history, history.push], "/");

      yield localStorage.clear();
      action.reject({ error: error, resp: response });
    } else {
      switch (error.response.status) {
        case 400:
          console.log(error.response);
          if (error.response.data.errorCode === "validationError") {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `Validation Error. 
                ${error.response.data.error.map((e) => {
                  return e.constraints + " ";
                })}`,
              },
            });
          } else {
            yield put({ type: "API_GET_ERROR" });

            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Server Error",
                desc: "Please submit a ticket regarding this error",
              },
            });
            action.reject({ error: error, resp: response });
          }
          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 401:
          action.reject({ error: error, resp: response });

          yield put({ type: "API_GET_UNAUTH" });
          yield call([history, history.push], "/");

          yield localStorage.clear();

          if (error.response.data.message === "userActive") {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: "User active from another device.",
              },
            });
          } else {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: "You have been logged out.",
              },
            });
          }

          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 409:
          yield put({
            type: "MODAL_SHOW",
            payload: {
              title: "Duplicate",
              desc: "Your input must be unique",
            },
          });

          break;
        default:
          yield put({ type: "API_GET_ERROR" });
          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          yield put({
            type: "MODAL_SHOW",
            payload: {
              title: "Server Error",
              desc: "Please submit a ticket regarding this error",
            },
          });
          action.reject({ error: error, resp: response });
          break;
      }
    }
  }
}

export function* GLOBAL_MODAL_CB(action) {
  try {
    const response = yield select(getModal);

    response.cb();
  } catch (error) {
    console.log(error);
  }
}

export function* LOADING_HANDLER(action) {
  //const response = yield select(getLoad);
  //console.log(action);
  try {
    if (action.payload)
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: action,
      });
    else {
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: action,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// export function* SOCKET_HANDLER(action) {
//   //const response = yield select(getLoad);
//   //console.log(action);
//   try {
//     if (action.payload)
//       yield put({
//         type: "API_TOGGLE_LOADING",
//         payload: action,
//       });
//     else {
//       yield put({
//         type: "API_TOGGLE_LOADING",
//         payload: action,
//       });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

// export function* testTimer() {
//   while (true) {
//     yield delay(5000);
//     yield cookies.remove("LUT");
//     yield console.log("tick");
//   }
// }

function* unauthSaga() {
  yield takeEvery("USER_GET_REQUEST", API_REQUEST);
}

function* globalModalSaga() {
  yield takeLatest("MODAL_RESP_YES", GLOBAL_MODAL_CB);
}

function* globalLoadingSagaStart() {
  yield takeEvery("TOGGLE_LOADING", LOADING_HANDLER);
}

// function* TimerSaga() {
//   yield takeLatest("TEST_TIMER", testTimer);
// }

export default function* rootSaga() {
  yield all([unauthSaga(), globalModalSaga(), globalLoadingSagaStart()]);
}
