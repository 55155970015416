import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import moment from "moment";
// import Tooltip from "react-bootstrap/Tooltip";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
import "../../css/po.css";
//import RTable from "react-table";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
//import JVLogo from "../../assets/jv-logo.png";
// import { noCB } from "../../css/noCB.css";

// import {
//   Canvas,
//   //BlobProvider,
//   Page,
//   Text,
//   View,
//   Image,
//   Document,
//   StyleSheet,
//   Font,
// } from "@react-pdf/renderer";

// import {
//   Table,
//   TableHeader,
//   TableCell,
//   TableBody,
//   DataTableCell,
// } from "@david.kucsai/react-pdf-table";

// //const SelectTable = selectTableHOC(RTable);

// Font.register({
//   family: "Roboto",
//   fonts: [
//     {
//       src: `/Roboto-Regular.ttf`,
//     },
//     {
//       src: `/Roboto-Bold.ttf`,
//       fontWeight: "bold",
//     },
//     {
//       src: `/Roboto-Italic.ttf`,
//       fontWeight: "normal",
//       fontStyle: "italic",
//     },
//     {
//       src: `/Roboto-BoldItalic.ttf`,
//       fontWeight: "bold",
//       fontStyle: "italic",
//     },
//   ],
// });

// const tableStyles = StyleSheet.create({
//   page: {
//     // flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   tableHeader: {
//     textAlign: "center",
//     fontSize: 12,
//     marginLeft: 2,
//     marginRight: 2,
//   },
//   tableCell: {
//     marginLeft: 2,
//     marginRight: 2,
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

// let POPDF = (props) => (
//   <Document>
//     <Page
//       size="A4"
//       style={[
//         {
//           //fontFamily: "Roboto",
//           paddingTop: 35,
//           paddingBottom: 65,
//           paddingHorizontal: 20,
//         },
//       ]}
//     >
//       <View
//         style={[
//           {
//             marginTop: 20,
//           },
//         ]}
//       >
//         <Image style={[{ marginHorizontal: 150 }]} src={JVLogo} />
//       </View>
//       <View
//         style={[
//           {
//             textAlign: "center",
//             color: "black",
//             width: "100%",
//             marginTop: 5,
//             marginBottom: 10,
//           },
//         ]}
//       >
//         <View
//           style={[
//             {
//               flex: 1,
//               flexDirection: "row",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 20,
//             },
//           ]}
//         >
//           <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
//             <Text style={[{ textAlign: "left" }]}>PURCHASE ORDER</Text>
//           </View>
//           <View style={[{ flex: 1, width: "50%" }]}>
//             <Text style={[{ fontSize: 15, textAlign: "right" }]}>
//               Date:{" "}
//               {moment(props.state.newPR.datePosted).format("MMMM Do YYYY")}
//             </Text>
//           </View>
//         </View>

//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//               position: "center",
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("black")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               marginTop: 20,
//               marginBottom: 10,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <Text style={[{ textAlign: "left", marginBottom: 20 }]}>
//             PO #: {props.state.newPR.uniqueId}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 5,
//               },
//             ]}
//           >
//             Supplier: {props.state.filteredPR.supplier.name}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 20,
//               },
//             ]}
//           >
//             Address: {props.state.filteredPR.supplier.address}
//           </Text>
//           <Text
//             style={[
//               {
//                 marginBottom: 10,
//               },
//             ]}
//           >
//             Deliver To: {props.state.txtPODeliverTo.value}
//           </Text>
//         </View>
//       </View>

//       <View
//         style={[
//           {
//             height: 340,
//             paddingLeft: 50,
//             paddingRight: 50,
//           },
//         ]}
//       >
//         <Table data={props.state.poSelection}>
//           <TableHeader>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               PR #
//             </TableCell>
//             <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
//               QTY
//             </TableCell>
//             <TableCell weighting={0.3} style={[tableStyles.tableHeader]}>
//               ITEM DESCRIPTION
//             </TableCell>
//             <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
//               UNIT PRICE
//             </TableCell>
//             <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
//               AMOUNT
//             </TableCell>
//           </TableHeader>
//           <TableBody>
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) => (d.uniqueId === null ? "" : d.uniqueId)}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.15}
//               getContent={(d) =>
//                 d.qty === null || d.material.unit.name === null
//                   ? ""
//                   : d.qty + " " + d.material.unit.name
//               }
//             />
//             {/* <DataTableCell
//               style={[tableStyles.tableCell]}
//               getContent={(d) =>
//                 d.material.unit.name === null ? "" : d.material.unit.name
//               }
//             /> */}
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.3}
//               getContent={(d) =>
//                 d.brand === null ? "" : d.brand + "-" + d.material.item.name
//               }
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.cost === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.cost);
//                 }
//               }}
//             />
//             <DataTableCell
//               style={[tableStyles.tableCell]}
//               weighting={0.2}
//               getContent={(d) => {
//                 if (d.budget === null) {
//                   return "";
//                 } else {
//                   return new Intl.NumberFormat("en-US").format(d.qty * d.cost);
//                 }
//               }}
//             />
//           </TableBody>
//         </Table>
//         <Text
//           style={[
//             {
//               textAlign: "right",
//               marginTop: 10,
//             },
//           ]}
//         >
//           TOTAL PRICE:{" "}
//           {((e) => {
//             let total = 0;
//             props.state.poSelection.forEach((x) => {
//               total += x.qty * x.cost;
//             });
//             return new Intl.NumberFormat("en-US").format(total);
//           })()}{" "}
//           PHP
//         </Text>
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,

//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Prepared by:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Approved By:
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Received Original Copy:
//               </Text>
//             </View>
//           </View>
//         </View>
//       </View>
//       <View>
//         <View
//           style={[
//             {
//               marginTop: 5,
//               marginBottom: 25,
//               paddingLeft: 80,
//               paddingRight: 80,
//             },
//           ]}
//         >
//           <View
//             style={[
//               {
//                 marginTop: 10,
//                 flex: 1,
//                 flexDirection: "row",
//               },
//             ]}
//           >
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 {props.state.newPR.generatedBy.name}
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Juan Luna
//               </Text>
//             </View>
//             <View style={[{ flex: 1 }]}>
//               <Text
//                 style={[
//                   {
//                     fontSize: 10,
//                   },
//                 ]}
//               >
//                 Juan Luna
//               </Text>
//             </View>
//           </View>
//         </View>
//         <Canvas
//           style={[
//             {
//               width: 600,
//               height: 3,
//             },
//           ]}
//           paint={(painter) => {
//             painter
//               .strokeColor("#45A545")
//               .lineWidth(3)
//               .moveTo(50, 0)
//               .lineTo(540, 0)
//               .stroke();
//           }}
//         />
//         <View
//           style={[
//             {
//               textAlign: "center",
//               color: "black",
//               width: "100%",
//               paddingLeft: 80,
//               paddingRight: 80,
//               marginTop: 10,
//             },
//           ]}
//         >
//           <Text
//             style={[
//               {
//                 textAlign: "left",
//                 marginBottom: 5,
//                 fontWeight: 1,
//                 fontSize: 12,
//               },
//             ]}
//           >
//             JNJ Building, Magsaysay Road, Magsaysay Village, Lapaz, Iloilo City
//           </Text>
//           <Text
//             style={[
//               {
//                 color: "#45A545",
//                 textAlign: "left",
//                 marginBottom: 5,
//                 fontSize: 12,
//               },
//             ]}
//           >
//             TIN:
//             <Text
//               style={[
//                 {
//                   color: "black",
//                   textAlign: "left",
//                   marginBottom: 5,
//                   fontSize: 12,
//                 },
//               ]}
//             >
//               004-866393-VAT
//             </Text>
//           </Text>
//           <Text
//             style={[
//               {
//                 color: "#45A545",
//                 textAlign: "left",
//                 fontSize: 12,
//                 fontWeight: 700,
//               },
//             ]}
//           >
//             TEL:
//             <Text style={[{ color: "black", textAlign: "left", fontSize: 12 }]}>
//               (033) 320 2392
//             </Text>
//           </Text>
//         </View>
//       </View>
//     </Page>
//   </Document>
// );

const poColumn = [
  {
    Header: "APPROVED PRs",
    Cell: (row) => (
      <>
        <Row className="order-tc-cont">
          <Col>
            <Row>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Material
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      //console.log(row.original);
                      return row.original.brand === null
                        ? ""
                        : row.original.material.item.name +
                            "-" +
                            row.original.brand;
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Qty
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      //console.log(row.original);
                      return row.original.qty === null
                        ? ""
                        : row.original.qty +
                            " " +
                            row.original.material.unit.name;
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Purchaser
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      if (row.original.purchaser) {
                        return row.original.purchaser.name === null
                          ? ""
                          : row.original.purchaser.name;
                      } else {
                        return "";
                      }
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Supplier
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      if (row.original.supplier) {
                        return row.original.supplier.name === null
                          ? "None"
                          : row.original.supplier.name;
                      } else {
                        return "None";
                      }
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Cost
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.cost === null
                        ? ""
                        : "₱ " +
                            new Intl.NumberFormat("en-US").format(
                              row.original.cost
                            );
                    })()}
                  />
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Total
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      return row.original.cost === null
                        ? ""
                        : "₱ " +
                            new Intl.NumberFormat("en-US").format(
                              row.original.cost * row.original.qty
                            );
                    })()}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ),
  },
];

// const POTable = (props) => (
//   <>
//     <SelectTable
//       data={props.state.poData}
//       columns={poColumn}
//       keyField="id"
//       //ref={(r) => (props.checkboxTable = r)}
//       toggleSelection={props.toggleSelection}
//       selectType="checkbox"
//       loading={props.state.isLoading}
//       isSelected={props.isSelected}
//       getTrProps={props.rowFn}
//       showPageJump={false}
//       showPageSizeOptions={false}
//       defaultPageSize={10}
//       style={{
//         height: "55vh",
//       }}
//       className="tableProject -highlight"
//     />
//   </>
// );
// let TxtPOUniqueId = (props) => (
//   <>
//     <Form.Label>
//       <strong>ID</strong>
//     </Form.Label>
//     <FormControl
//       type="textarea"
//       placeholder={props.state.txtPOUniqueId.placeholder}
//       name="txtPOUniqueId"
//       value={props.state.txtPOUniqueId.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtPOUniqueId.className + " css-projectInput"}
//       readOnly={props.state.txtPOUniqueId.readOnly}
//     />
//   </>
// );
// let TxtPOName = (props) => (
//   <>
//     <Form.Label>
//       <strong>
//         <span className="required-field">* </span>Name
//       </strong>
//     </Form.Label>
//     <FormControl
//       type="text"
//       placeholder={props.state.txtPOName.placeholder}
//       name="txtPOName"
//       value={props.state.txtPOName.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtPOName.className + " css-projectInput"}
//       readOnly={props.state.txtPOName.readOnly}
//     />
//   </>
// );

// function PDFGeneratePO(props) {
//   const [btn, setBtn] = useState(false);

//   return (
//     <InputGroup className="pdfOrder-select">
//       <InputGroup.Prepend>
//         <InputGroup.Text className="">PDF</InputGroup.Text>
//       </InputGroup.Prepend>

//       {(() => {
//         if (btn) {
//           return (
//             <BlobProvider
//               document={
//                 <POPDF
//                   state={props.state}
//                   material={props.material}
//                   phase={props.phase}
//                 />
//               }
//             >
//               {({ blob, url, loading, error }) => {
//                 if (!loading) {
//                   props.state.downloadPDF(blob);
//                   setBtn(false);
//                   return "";
//                 } else {
//                   return "";
//                 }

//                 // if (loading) {
//                 //   return "Loading";
//                 // } else {
//                 //   return (
//                 //     <Button
//                 //       name="btnGeneratePDF"
//                 //       className="btnPhaseExport"
//                 //       onClick={(e) => {
//                 //         props.state.downloadPDF(blob);
//                 //         setBtn(false);
//                 //         //props.state.resetPOModal();
//                 //       }}
//                 //     >
//                 //       {" "}
//                 //       Download
//                 //     </Button>
//                 //   );
//                 // }
//               }}
//             </BlobProvider>

//             // <PDFDownloadLink
//             //   className="btn btnPhaseExport"
//             //   onClick={(loading) => {
//             //     console.log(loading);
//             //   }}
//             //   document={
//             //     <POPDF
//             //       state={props.state}
//             //       material={props.material}
//             //       phase={props.phase}
//             //     />
//             //   }
//             //   fileName={"PO#-" + props.state.newPR.uniqueId}
//             // >
//             //   {({ blob, url, loading, error }) => {
//             //     if (loading) {
//             //       return "Loading";
//             //     } else {
//             //       //setBtn(false);
//             //       //props.state.resetPOModal();
//             //       return "Download PO";
//             //     }
//             //   }}
//             // </PDFDownloadLink>
//             // //loading ? "Loading" : "Download PO"
//           );
//         } else {
//           return (
//             <Button
//               name="btnGeneratePDF"
//               className="btnPhaseExport"
//               onClick={(e) => {
//                 props.state.addPO((x) => {
//                   setBtn(true);
//                 });
//               }}
//             >
//               {" "}
//               Generate PO
//             </Button>
//           );
//         }
//       })()}
//     </InputGroup>
//   );
// }

function PR(props) {
  const [isPOCRUDModalOpen, togglePOCRUDModal] = useState(false);
  return (
    <>
      <Modal
        show={props.isShow}
        onHide={(e) => {
          props.dispatch({
            type: "PR_MODAL_TOGGLE",
          });
        }}
        onExit={(e) => {
          props.state.handlePOModal.handleExit(e);
        }}
        onEntered={(e) => {
          props.state.readPR();
        }}
        dialogClassName="modal-po-90w"
        centered
      >
        <Modal.Body className="po-modal-body">
          <Row className="po-modal-header  pt-3 pb-3 mb-3" noGutters>
            <Col md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  className="btnAddProject project-btn-crud"
                  onClick={(e) => {
                    props.dispatch({
                      type: "PR_MODAL_TOGGLE",
                    });
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col md={11} lg={11}>
              <h4 className=" font-weight-bold po-modal-header-text">
                PR Management
              </h4>
            </Col>
          </Row>
          <Row className="row-crud" noGutters>
            <Col className="pr-5">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Deliver to</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  placeholder={props.state.txtPODeliverTo.placeholder}
                  style={{ textTransform: "uppercase" }}
                  name="txtPODeliverTo"
                  value={props.state.txtPODeliverTo.value}
                  onChange={props.state.inputChange}
                  className={
                    props.state.txtPODeliverTo.className + " css-projectInput"
                  }
                  readOnly={props.state.txtPODeliverTo.readOnly}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="">Supplier</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  value={props.state.selSupplier.value}
                  name="selSupplier"
                  className={props.state.selSupplier.className}
                  //disabled={actorDropIsDisabled}
                  onChange={(e) => {
                    props.state.inputChange(e);
                    props.state.handleSupplierChange(e.target.value);
                  }}
                >
                  {props.PRData.map((dat, index) => (
                    <option key={dat.supplier.id} value={dat.supplier.id}>
                      {dat.supplier ? dat.supplier.name : "None"} (
                      {dat.multiMaterials.length})
                    </option>
                  ))}
                  <option value={0} key={0} disabled hidden>
                    Select Supplier
                  </option>
                </FormControl>
              </InputGroup>
            </Col>
            <Col className="material-btn-crud-col d-flex justify-content-end">
              <Button
                className="btnGeneratePOApproval"
                onClick={(e) => {
                  props.state.addPO((x) => {});
                }}
              >
                Generate PO for Approval
              </Button>
              {/* <PDFGeneratePO state={props.state} /> */}
            </Col>
          </Row>
          <Row>
            <Col>{props.tableList}</Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={isPOCRUDModalOpen}
        onHide={(e) => {
          togglePOCRUDModal(false);
        }}
        onExit={(e) => {
          //props.state.handlePOModal.handleExit(e);
        }}
        dialogClassName=""
        centered
        size="md"
      >
        <Modal.Header closeButton className="po-modal-header-cont">
          <Row className="po-modal-header">
            <Col>
              <h4 className="text-center po-modal-header-text">Add POs</h4>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="formPOInput"
            noValidate
            validated={props.state.handlePOModal.isValidated}
            onSubmit={props.state.handlePOModal.handleSubmit}
          >
            <Row className="mb-3">
              <Col className="user-col-cont-padding">
                <Button
                  block
                  className="po-btn-crud"
                  name="btnUnlockPO"
                  disabled={!props.state.handlePOModal.isView}
                  onClick={(e) => {
                    props.state.handleActionChange(e);
                  }}
                >
                  MODIFY
                </Button>
              </Col>
              <Col className="user-col-cont-padding">
                <Button
                  block
                  className="po-btn-crud"
                  name="btnSubmitPO"
                  disabled={props.state.handlePOModal.isView}
                  type="submit"
                >
                  SAVE
                </Button>
              </Col>
              <Col className="user-col-cont-padding">
                <Button
                  block
                  className="po-btn-crud"
                  name="btnDeletePO"
                  disabled={
                    props.state.handlePOModal.isView ||
                    Number(props.state.selectedPOOperation.id) === 0
                  }
                  onClick={(e) => {
                    props.state.handleActionChange(e);
                  }}
                >
                  DELETE
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { PR, poColumn };
