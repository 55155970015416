import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import filters from "../../functions/filters";
import { NumericFormat } from "react-number-format";
import PhaseMaterialAPI from "./PhaseMaterialAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
const initMaterialData = [
  {
    id: 0,
    uniqueId: "",
    uid: filters.uuidv4(),
    material: { item: { name: "" } },
    qty: 0,
    notes: "",
    description: "",
    descriptionPurpose: "",
    datePosted: "",
  },
];

export const PhaseMaterial = (props) => {
  const { phase, material, IsAddMatUsage, setIsAddMatUsage, state } = props;
  const dispatch = useDispatch();
  // console.log(material);

  const [QtyUsed, setQtyUsed] = useState(0);
  const [QtyLimit, setQtyLimit] = useState(0);
  const [Purpose, setPurpose] = useState("");
  const [DateUsed, setDateUsed] = useState("");
  const [Description, setDescription] = useState("");
  const [Notes, setNotes] = useState("");
  const [MaterialUsageData, setMaterialUsageData] = useState([]);
  const [MaterialUsageOperation, setMaterialUsageOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const [TransferMaterialOperation, setTransferMaterialOperation] = useState(
    SELECTED_OPERATION.VIEW
  );
  const [SelMaterialPhaseTransfer, setSelMaterialPhaseTransfer] = useState(0);

  const TempMaterialUsageData = (value, name) => {
    setMaterialUsageData((trans) =>
      trans?.map((dat) => (dat.uid === value.uid ? { ...dat, ...value } : dat))
    );
    // if (name) {
    //   setMaterialUsageData((trans) =>
    //     trans.sort(function (a, b) {
    //       return Number(a.partIndex) - Number(b.partIndex);
    //     })
    //   );
    // }
  };
  useEffect(() => {
    FetchMaterialUsage();
  }, []);
  useEffect(() => {
    let reqQtyLimit = 0;
    reqQtyLimit = MaterialUsageData.reduce((a, c) => {
      // if (c.childId == null) return a + c.price * c.itemQty;
      // else return a + c.child.price * c.child.itemQty;
      return a + Number(c?.qty);
    }, 0);
    // console.log(reqQtyLimit);
    //console.log(Number(material?.requiredQty));
    setQtyLimit(reqQtyLimit);
  }, [MaterialUsageData]);

  const FetchMaterialUsage = async () => {
    let apiData = [];
    if (material?.id) {
      apiData = await PhaseMaterialAPI.getAllMaterialUsage(
        dispatch,
        material?.id
      );
    }

    if (apiData) {
      const modifiedData = apiData.map((x) => {
        return { ...x, operation: SELECTED_OPERATION.VIEW_CONTENT };
      });
      //console.log(modifiedData);
      setMaterialUsageData([...modifiedData]);
    }
  };

  const AddMaterialUsage = async () => {
    //Filter MaterialUsageData for new data based on isNew
    let postData = [];
    postData = MaterialUsageData.filter((x) => x.isNew);
    postData = postData.map((x) => {
      return {
        uid: x.uid,
        qty: x.qty,
        notes: x.notes,
        usedBy: x.usedBy,
        datePosted: x.datePosted,
        descriptionPurpose: x.descriptionPurpose,
        materialId: material.id,
      };
    });
    console.log("PRE-POST MATERIAL USAGE ===> ", postData);

    const apiData = await PhaseMaterialAPI.createMultiMaterialUsage(
      dispatch,
      postData
    );
    console.log("ADDED MATERIAL USAGE ===> ", apiData);
    FetchMaterialUsage();
  };
  const EditMaterialUsage = async (id) => {
    //Filter MaterialUsageData for new data based on isNew
    let postData = [];
    postData = MaterialUsageData.filter((x) => x.isEdit);

    postData = postData.map((x) => {
      return {
        id: id,
        uid: x.uid,
        qty: x.qty,
        notes: x.notes,
        usedBy: x.usedBy,
        datePosted: x.datePosted,
        descriptionPurpose: x.descriptionPurpose,
        materialId: material.id,
      };
    });
    console.log("PRE-POST MATERIAL USAGE ===> ", postData);

    const apiData = await PhaseMaterialAPI.updateMultiMaterialUsage(
      dispatch,
      postData
    );
    console.log("MODIFIED MATERIAL USAGE ===> ", apiData);
    FetchMaterialUsage();
  };
  const RemoveMaterialUsage = async (id) => {
    const apiData = await PhaseMaterialAPI.deleteMaterialUsage(dispatch, id);
    console.log("REMOVED MATERIAL USAGE ===> ", apiData);
    FetchMaterialUsage();
  };
  return (
    <>
      {TransferMaterialOperation === SELECTED_OPERATION.VIEW && (
        <Button
          onClick={() => {
            setTransferMaterialOperation(SELECTED_OPERATION.ADD);
          }}
          disabled={props.state.txtMaterialReqQty.readOnly}
        >
          Phase Transfer{" "}
          {props.state.selMaterialEntryType?.value &&
            props.state.selMaterialEntryType?.value.toUpperCase()}
        </Button>
      )}
      {TransferMaterialOperation === SELECTED_OPERATION.ADD && (
        <Row className="my-2" noGutters>
          <Col xs={12} className="">
            <div className="pt-3">
              <h5 className="m-0 p-0">
                {props.state.selMaterialEntryType?.value &&
                  props.state.selMaterialEntryType?.value.toUpperCase()}{" "}
                Transfer Options
              </h5>
              <hr className="" style={{ backgroundColor: "black" }}></hr>
            </div>
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                  className=""
                >
                  Phase:
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                value={SelMaterialPhaseTransfer}
                style={{ fontWeight: "bold", fontSize: "1rem" }}
                onChange={(e) => {
                  console.log(
                    Number(props.state.txtPhaseCrud.value),
                    Number(e.currentTarget.value)
                  );
                  if (
                    Number(props.state.txtPhaseCrud.value) !==
                    Number(e.currentTarget.value)
                  ) {
                    setSelMaterialPhaseTransfer(e.currentTarget.value);
                  } else {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Selection Notice",
                        desc: `Please select a different transfer location. Current selection is the same phase.`,
                        isYesNo: false,
                      },
                    });
                  }

                  //props.state.handlePhaseChange(e, true);
                }}
              >
                {props.state.phaseData.map((phase, index) => (
                  <option
                    key={phase.id}
                    value={phase.id}
                    className="phaseOptions "
                  >
                    ({phase.matOngoing}) {phase.name}
                  </option>
                ))}
                <option value={0} key={0} disabled hidden>
                  Select a phase...
                </option>
              </FormControl>
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Button
              onClick={() => {
                PhaseMaterialAPI.transferMaterialToPhase(
                  dispatch,
                  material.id,
                  Number(SelMaterialPhaseTransfer)
                );

                setTransferMaterialOperation(SELECTED_OPERATION.VIEW);
                props.state.changePageContent('PROJECT')
                props.state.handleRefreshPhase();
              }}
              style={{ backgroundColor: "var(--JV-green)" }}
            >
              Confirm Transfer
            </Button>
            <Button
              style={{
                backgroundColor: "var(--JV-yellow)",
                color: "var(--JV-black)",
              }}
              className="ml-5"
              onClick={() => {
                setTransferMaterialOperation(SELECTED_OPERATION.VIEW);
                setSelMaterialPhaseTransfer(0);
              }}
            >
              Cancel Transfer
            </Button>
          </Col>
        </Row>
      )}

      <div className="pt-3">
        <h5 className="m-0 p-0">
          {props.state.selMaterialEntryType?.value &&
            props.state.selMaterialEntryType?.value.toUpperCase()}{" "}
          Usage Record
        </h5>
        <hr className="" style={{ backgroundColor: "black" }}></hr>
      </div>

      {MaterialUsageData.map((val, index) => {
        return (
          <Row
            key={val.uid}
            style={{ border: "solid 1px #ced4da" }}
            className="py-3 mt-3 px-3"
            noGutters
          >
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="px-2 py-2"
              style={{ alignSelf: "center" }}
            >
              <Form.Label>
                <strong>QTY Used</strong>
              </Form.Label>

              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                suffix={` ${material?.unit?.name}`}
                value={val.qty}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  const reqQty = Number(material?.requiredQty) - QtyLimit;

                  return (
                    formattedValue === "" ||
                    (floatValue <= reqQty && floatValue >= 0)
                  );
                }}
                onValueChange={(x) => {
                  TempMaterialUsageData({
                    uid: val.uid,
                    qty: x.floatValue ? x.floatValue : 0,
                  });
                }}
                disabled={val.operation === SELECTED_OPERATION.VIEW_CONTENT}
              />
            </Col>

            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="px-2 py-2"
              style={{ alignSelf: "center" }}
            >
              <Form.Label>
                <strong>Date Used</strong>
              </Form.Label>
              <FormControl
                type="datetime-local"
                placeholder={""}
                name=""
                value={moment(val.datePosted).format("YYYY-MM-DDThh:mm")}
                onChange={(e) =>
                  TempMaterialUsageData({
                    uid: val.uid,
                    datePosted: e.currentTarget.value,
                  })
                }
                className={" css-projectInput"}
                disabled={val.operation === SELECTED_OPERATION.VIEW_CONTENT}
              />
            </Col>
            <Col
              xs={12}
              sm={6}
              md={6}
              lg={6}
              className="px-2 py-2"
              style={{ alignSelf: "center" }}
            >
              <Form.Label>
                <strong>Purpose</strong>
              </Form.Label>
              <FormControl
                type="text"
                placeholder={""}
                name=""
                value={val.descriptionPurpose}
                onChange={(e) =>
                  TempMaterialUsageData({
                    uid: val.uid,
                    descriptionPurpose: e.currentTarget.value,
                  })
                }
                className={" css-projectInput"}
                disabled={val.operation === SELECTED_OPERATION.VIEW_CONTENT}
              />
            </Col>

            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="px-2 py-2"
              style={{ alignSelf: "center" }}
            >
              <Form.Label>
                <strong>Used By</strong>
              </Form.Label>
              <FormControl
                type="text"
                placeholder={""}
                name=""
                value={val.usedBy}
                onChange={(e) =>
                  TempMaterialUsageData({
                    uid: val.uid,
                    usedBy: e.currentTarget.value,
                  })
                }
                className={" css-projectInput"}
                disabled={val.operation === SELECTED_OPERATION.VIEW_CONTENT}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="px-2 py-2"
              style={{ alignSelf: "center" }}
            >
              <Form.Label>
                <strong>Notes</strong>
              </Form.Label>
              <FormControl
                as="textarea"
                type="textarea"
                placeholder={""}
                name=""
                value={val.notes}
                onChange={(e) =>
                  TempMaterialUsageData({
                    uid: val.uid,
                    notes: e.currentTarget.value,
                  })
                }
                className={" css-projectInput"}
                disabled={val.operation === SELECTED_OPERATION.VIEW_CONTENT}
              />
            </Col>
            {(val.operation === SELECTED_OPERATION.EDIT_CONTENT ||
              val.operation === SELECTED_OPERATION.ADD_CONTENT) && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="px-2 py-2"
                style={{ textAlign: "right", alignSelf: "right" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`actor-tooltip-modify`}>Cancel Edit</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => {
                        if (val.operation === SELECTED_OPERATION.ADD_CONTENT) {
                          let modifiedMaterialUsageData = [
                            ...MaterialUsageData,
                          ];
                          modifiedMaterialUsageData =
                            modifiedMaterialUsageData.filter(
                              (x) => x.uid !== val.uid
                            );

                          setMaterialUsageData(modifiedMaterialUsageData);
                        }
                        if (val.operation === SELECTED_OPERATION.EDIT_CONTENT) {
                          TempMaterialUsageData({
                            uid: val.uid,
                            operation: SELECTED_OPERATION.VIEW_CONTENT,
                            isEdit: false,
                          });
                        }
                        setIsAddMatUsage(false);
                      }}
                      style={{ backgroundColor: "var(--JV-yellow)" }}
                    >
                      <i
                        style={{ color: "var(--JV-white)" }}
                        className="icofont-ui-close icofont-1x"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                  <p className="m-0 p-0 pl-2" style={{ alignSelf: "center" }}>
                    Cancel
                  </p>
                </div>
              </Col>
            )}
            {val.operation === SELECTED_OPERATION.EDIT_CONTENT && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="px-2 py-2"
                style={{ textAlign: "right", alignSelf: "right" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`actor-tooltip-modify`}>Delete Item</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => {
                        // let modifiedMaterialUsageData = [...MaterialUsageData];
                        // modifiedMaterialUsageData =
                        //   modifiedMaterialUsageData.filter(
                        //     (x) => x.uid !== val.uid
                        //   );

                        // setMaterialUsageData(modifiedMaterialUsageData);
                        // TempMaterialUsageData({
                        //   uid: val.uid,
                        //   operation: SELECTED_OPERATION.VIEW_CONTENT,
                        // });
                        RemoveMaterialUsage(val.id);
                      }}
                      style={{ backgroundColor: "var(--JV-warning)" }}
                    >
                      <i
                        style={{ color: "var(--JV-white)" }}
                        className="icofont-trash icofont-1x"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                  <p className="m-0 p-0 pl-2" style={{ alignSelf: "center" }}>
                    Delete Item
                  </p>
                </div>
              </Col>
            )}

            {val.operation === SELECTED_OPERATION.VIEW_CONTENT && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="px-2 py-2"
                style={{ textAlign: "right", alignSelf: "right" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`actor-tooltip-modify`}>Modify Item</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => {
                        TempMaterialUsageData({
                          uid: val.uid,
                          operation: SELECTED_OPERATION.EDIT_CONTENT,
                          isEdit: true,
                        });
                        // let modifiedMaterialUsageData = [
                        //   ...MaterialUsageData,
                        // ];
                        // modifiedMaterialUsageData =
                        //   modifiedMaterialUsageData.filter(
                        //     (x) => x.uid !== val.uid
                        //   );
                        // setMaterialUsageData(modifiedMaterialUsageData);
                      }}
                      style={{ backgroundColor: "var(--JV-secondary)" }}
                    >
                      <i
                        style={{ color: "var(--JV-white)" }}
                        className="icofont-ui-edit icofont-1x"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                  <p className="m-0 p-0 pl-2" style={{ alignSelf: "center" }}>
                    Modify Item
                  </p>
                </div>
              </Col>
            )}
            {(val.operation === SELECTED_OPERATION.EDIT_CONTENT ||
              val.operation === SELECTED_OPERATION.ADD_CONTENT) && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="px-2 py-2"
                style={{ textAlign: "right", alignSelf: "right" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`actor-tooltip-modify`}>Save Item</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => {
                        if (val.operation === SELECTED_OPERATION.ADD_CONTENT) {
                          AddMaterialUsage();
                        }
                        if (val.operation === SELECTED_OPERATION.EDIT_CONTENT) {
                          EditMaterialUsage(val.id);
                        }

                        // TempMaterialUsageData({
                        //   uid: val.uid,
                        //   operation: SELECTED_OPERATION.VIEW_CONTENT,
                        // });
                        // let modifiedMaterialUsageData = [
                        //   ...MaterialUsageData,
                        // ];
                        // modifiedMaterialUsageData =
                        //   modifiedMaterialUsageData.filter(
                        //     (x) => x.uid !== val.uid
                        //   );
                        // setMaterialUsageData(modifiedMaterialUsageData);
                      }}
                      style={{ backgroundColor: "var(--JV-green)" }}
                    >
                      <i
                        style={{ color: "var(--JV-white)" }}
                        className="icofont-save icofont-1x"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                  <p className="m-0 p-0 pl-2" style={{ alignSelf: "center" }}>
                    Save Item
                  </p>
                </div>
              </Col>
            )}
          </Row>
        );
      })}
      <Row>
        <Col
          xs={12}
          sm={6}
          md={4}
          lg={2}
          className="px-2 py-2"
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`actor-tooltip-modify`}>Add Item</Tooltip>}
            >
              <Button
                onClick={() => {
                  //tempCloseAllTransactionContent();
                  //console.log(TransModalContent);
                  setMaterialUsageOperation(SELECTED_OPERATION.ADD);
                  let modifiedMaterialUsageData = [...MaterialUsageData];
                  modifiedMaterialUsageData.push({
                    uid: filters.uuidv4(),
                    qty: 0,
                    notes: "",
                    usedBy: "",
                    descriptionPurpose: "",
                    datePosted: moment().format("YYYY-MM-DDThh:mm"),
                    isNew: true,
                    isEdit: false,
                    operation: SELECTED_OPERATION.ADD_CONTENT,
                  });
                  setMaterialUsageData(modifiedMaterialUsageData);
                  setIsAddMatUsage(true);
                  // const SortedData = modifiedTransModalContent.sort(
                  //   function (a, b) {
                  //     return (
                  //       Number(a.partIndex) - Number(b.partIndex)
                  //     );
                  //   }
                  // );
                  // setTransModalContent(SortedData);

                  // setTransactionModalSelectedOperation(
                  //   SELECTED_OPERATION.ADD_CONTENT
                  // );
                }}
                style={{ backgroundColor: "var(--JV-green)" }}
              >
                <i
                  style={{ color: "var(--JV-white)" }}
                  className="icofont-ui-add icofont-1x"
                ></i>
              </Button>
            </OverlayTrigger>
            <p className="m-0 p-0 pl-2" style={{ alignSelf: "center" }}>
              Add new Record
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row
        className="material-modal-footer pt-3 pb-3 mt-3 "
        style={{ justifyContent: "right" }}
        noGutters
      >
        {MaterialUsageOperation === SELECTED_OPERATION.VIEW &&
          MaterialUsageData.length > 0 && (
            <Col md={2} lg={2} className="pl-3 pt-2">
              <Button
                block
                className="btnMaterialModify"
                name="btnUnlockMaterial"
                onClick={(e) => {
                  setMaterialUsageOperation(SELECTED_OPERATION.EDIT);
                }}
              >
                MODIFY
              </Button>
            </Col>
          )}

        {(MaterialUsageOperation === SELECTED_OPERATION.EDIT ||
          MaterialUsageOperation === SELECTED_OPERATION.ADD) && (
          <Col md={2} lg={2} className="pl-3 pt-2">
            <Button
              block
              className="btnMaterialModify"
              name="btnUnlockMaterial"
              onClick={(e) => {
                setMaterialUsageOperation(SELECTED_OPERATION.VIEW);
                FetchMaterialUsage();
                setIsAddMatUsage(false);
              }}
            >
              CANCEL
            </Button>
          </Col>
        )}

        {(MaterialUsageOperation === SELECTED_OPERATION.EDIT ||
          MaterialUsageOperation === SELECTED_OPERATION.ADD) && (
          <Col md={2} lg={2} className="pl-3 pt-2">
            <Button
              block
              className="btnMaterialSave"
              onClick={() => {
                if (MaterialUsageOperation === SELECTED_OPERATION.ADD) {
                  AddMaterialUsage();
                }
                if (MaterialUsageOperation === SELECTED_OPERATION.EDIT) {
                }
                setMaterialUsageOperation(SELECTED_OPERATION.VIEW);
              }}
            >
              SAVE
            </Button>
          </Col>
        )}

        {MaterialUsageOperation === SELECTED_OPERATION.EDIT && (
          <Col md={2} lg={2} className="pl-3 pt-2">
            <Button
              block
              className="btnMaterialDelete"
              name="btnDeleteMaterial"
              onClick={(e) => {
                setMaterialUsageOperation(SELECTED_OPERATION.VIEW);
              }}
            >
              DELETE
            </Button>
          </Col>
        )}
      </Row> */}
    </>
  );
};
