import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DBStatus from "../../functions/DBStatus";
import { NumericFormat } from "react-number-format";
import Popover from "react-bootstrap/Popover";
import Select from "react-select";
import moment from "moment";
import "../../css/order.css";
import misc from "../../functions/misc";

const orderColumn = [
  {
    Header: "Details",
    Cell: (row) => (
      <>
        <Row className="order-tc-cont" noGutters>
          <Col className="order-stat-cont" md={2}>
            <Form.Label
              className="text-center order-stat-label"
              style={{
                background:
                  (row && Number(row.original.status) === 1 && "#DFE24A") ||
                  (row && Number(row.original.status) === 2 && "#E24A4A") ||
                  (row && Number(row.original.status) === 3 && "#DFE24A") ||
                  (row && Number(row.original.status) === 4 && "#4A87E2") ||
                  (row && Number(row.original.status) === 5 && "#5DB55D") ||
                  (row && Number(row.original.status) === 6 && "#E2934A") ||
                  (row && Number(row.original.status) === 7 && "#E24A4A") ||
                  (row && Number(row.original.status) === 8 && "#E2934A") ||
                  (row && Number(row.original.status) === 10 && "#E2934A"),
              }}
            >
              {(() => {
                return DBStatus.parseOrderStatus(row.original.status);
              })()}
            </Form.Label>
          </Col>
          <Col md={10}>
            <Row noGutters>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Brand
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={`${row.original.brand ? row.original.brand : ""}`}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Qty
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={(() => {
                      //console.log(row.original);
                      return `${row.original.qty} ${
                        row.original.material?.unit?.name
                          ? row.original.material?.unit?.name
                          : ""
                      }`;
                    })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Purchaser
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={`${
                      row.original.purchaser.name && row.original.purchaser.name
                    }`}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Supplier
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={`${
                      row.original.supplier?.name && row.original.supplier?.name
                    }`}
                    // value={(() => {
                    //   if (row.original.supplier) {
                    //     return row.original.supplier.name === null
                    //       ? "None"
                    //       : row.original.supplier.name;
                    //   } else {
                    //     return "None";
                    //   }
                    // })()}
                  />
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Cost
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={`${
                      row.original.cost &&
                      misc.parseCurrencyNoCurrency(row.original.cost)
                    }`}
                    // value={(() => {
                    //   return row.original.cost === null
                    //     ? ""
                    //     : "₱ " +
                    //         new Intl.NumberFormat("en-US").format(
                    //           row.original.cost
                    //         );
                    // })()}
                  />
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup className="order-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="order-tc-labels">
                      Total
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    disabled
                    className="order-tc-txtbox"
                    value={`${
                      row.original.cost &&
                      row.original.qty &&
                      misc.parseCurrencyNoCurrency(
                        row.original.cost * row.original.qty
                      )
                    }`}
                    // value={(() => {
                    //   return row.original.cost === null
                    //     ? ""
                    //     : "₱ " +
                    //         new Intl.NumberFormat("en-US").format(
                    //           row.original.cost * row.original.qty
                    //         );
                    // })()}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    ),
  },
];

function historyColumn(material) {
  return [
    {
      Header: "Brand",
      id: "brand",
      accessor: (d) => (d.brand === null ? "" : d.brand),
    },
    {
      Header: "Supplier",
      id: "supplier",
      accessor: (d) => (d.supplier === null ? "" : d.supplier.name),
    },
    {
      Header: "Date Purchased",
      id: "datePurchased",
      accessor: (d) =>
        d.dateFinished === null
          ? ""
          : moment(d.dateFinished, "YYYY-MM-DD").format("YYYY-MM-DD"),
    },
    {
      Header: "Qty",
      id: "qty",
      accessor: (d) =>
        d.qty === null ? "" : d.qty + " " + material?.unit?.name,
    },
    {
      Header: "Unit Price",
      id: "unitPrice",
      accessor: (d) =>
        d.cost === null
          ? ""
          : "₱ " + new Intl.NumberFormat("en-US").format(Number(d.cost)),
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d) =>
        d.cost === null
          ? ""
          : "₱ " +
            new Intl.NumberFormat("en-US").format(
              Number(d.cost) * Number(d.qty)
            ),
    },
  ];
}

function TxtOrderQty(props) {
  const reqQty =
    Number(props.state.selMaterialData.requiredQty) -
    Number(props.state.selMaterialData.totalQty);

  const [overQty, setOverQty] = useState(false);
  const [liveQty, setliveQty] = useState(reqQty);

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            <strong>
              <span className="required-field">* </span>Qty
            </strong>
          </Form.Label>
        </Col>

        <Col className="d-flex justify-content-end">
          {(() => {
            if (
              Number(props.state.selectedOrderOperation.id) !== 1 ||
              Number(props.state.orderSelection.status) === 2
            ) {
              return (
                <>
                  <Form.Label>
                    <span className={liveQty >= 0 ? "bg-ok" : "bg-warn"}>
                      {(() => {
                        if (liveQty >= 0) {
                          return "Unordered";
                        } else {
                          return "OVER";
                        }
                      })()}{" "}
                      {Math.abs(liveQty)}{" "}
                      {props.state.selMaterialData?.unit?.name
                        ? props.state.selMaterialData?.unit?.name
                        : ""}
                    </span>
                  </Form.Label>
                </>
              );
            }
          })()}
        </Col>
      </Row>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={
          " " + props.state.selMaterialData?.unit?.name
            ? props.state.selMaterialData?.unit?.name
            : ""
        }
        placeholder={props.state.txtOrderQty.placeholder}
        name="txtOrderQty"
        value={props.state.txtOrderQty.value}
        onValueChange={(x) => {
          setliveQty(reqQty - Number(x.value));

          if (reqQty - Number(x.value) < 0) {
            setOverQty(true);
          } else {
            setOverQty(false);
          }

          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtOrderQty",
              placeholder: props.state.txtOrderQty.placeholder,
              className: props.state.txtOrderQty.className,
            },
          });
        }}
        className={props.state.txtOrderQty.className + " css-projectInput"}
        readOnly={props.state.txtOrderQty.readOnly}
        isInvalid={overQty}
        isValid={
          Number(props.state.selectedOrderOperation.id) === 0 ||
          Number(props.state.orderSelection.status) === 2
            ? Number(props.state.txtOrderQty.value) > reqQty
              ? !overQty
              : null
            : null
        }
        required
      />
    </>
  );
}

function TxtOrderCost(props) {
  const budgetLeft =
    Number(props.state.selMaterialData.budget) -
    Number(props.state.selMaterialDataFinance.used);
  const [liveBudget, setliveBudget] = useState(budgetLeft);

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            <strong>
              <span className="required-field">* </span>Cost
            </strong>
          </Form.Label>
        </Col>
        <Col className="d-flex justify-content-end">
          {(() => {
            if (
              Number(props.state.selectedOrderOperation.id) !== 1 ||
              Number(props.state.orderSelection.status) === 2
            ) {
              return (
                <>
                  <Form.Label>
                    <span className={liveBudget >= 0 ? "bg-ok" : "bg-warn"}>
                      {(() => {
                        if (liveBudget >= 0) {
                          return "Budget: ₱";
                        } else {
                          return "OVER: ₱";
                        }
                      })()}

                      {new Intl.NumberFormat("en-US").format(
                        Math.abs(liveBudget)
                      )}
                    </span>
                  </Form.Label>
                </>
              );
            }
          })()}
        </Col>
      </Row>

      <InputGroup className="mb-3">
        <FormControl
          as={NumericFormat}
          inputMode="numeric"
          thousandSeparator={true}
          prefix={"₱"}
          placeholder={props.state.txtOrderCost.placeholder}
          name="txtOrderCost"
          value={props.state.txtOrderCost.value}
          onValueChange={(x) => {
            setliveBudget(
              budgetLeft -
                Number(x.value) * Number(props.state.txtOrderQty.value)
            );
            props.state.inputChange({
              target: {
                value: Number(x.value),
                name: "txtOrderCost",
                placeholder: props.state.txtOrderCost.placeholder,
                className: props.state.txtOrderCost.className,
              },
            });
          }}
          className={props.state.txtOrderCost.className + " css-projectInput"}
          readOnly={props.state.txtOrderCost.readOnly}
          isInvalid={
            Number(props.state.selectedOrderOperation.id) === 0 ||
            Number(props.state.orderSelection.status) === 2
              ? Number(liveBudget) >= 0
                ? false
                : true
              : null
          }
          isValid={
            Number(props.state.selectedOrderOperation.id) === 0 ||
            Number(props.state.orderSelection.status) === 2
              ? Number(props.state.txtOrderQty.value) *
                  Number(props.state.txtOrderCost.value) >
                liveBudget
                ? false
                : null
              : null
          }
          required
        />
        <InputGroup.Prepend>
          <InputGroup.Text className="">Base:</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          as={NumericFormat}
          inputMode="numeric"
          thousandSeparator={true}
          prefix={"₱"}
          placeholder={props.state.txtBaseCost.placeholder}
          name="txtBaseCost"
          value={props.state.txtBaseCost.value}
          className={props.state.txtBaseCost.className + ""}
          readOnly={true}
        />
      </InputGroup>
    </>
  );
}

let TxtOrderNotes = (props) => (
  <>
    <Form.Label>
      <strong>Notes</strong>
    </Form.Label>
    <FormControl
      as="textarea"
      placeholder={props.state.txtOrderNotes.placeholder}
      name="txtOrderNotes"
      value={props.state.txtOrderNotes.value}
      onChange={props.state.inputChange}
      className={props.state.txtOrderNotes.className + " css-projectInput"}
      readOnly={props.state.txtOrderNotes.readOnly}
    />
  </>
);

let TxtOrderBrand = (props) => (
  <>
    <Form.Label>
      <strong>Brand</strong>
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtOrderBrand.placeholder}
      name="txtOrderBrand"
      value={props.state.txtOrderBrand.value}
      onChange={props.state.inputChange}
      className={props.state.txtOrderBrand.className + " css-projectInput"}
      readOnly={props.state.txtOrderBrand.readOnly}
    />
  </>
);
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

let customStyles = {
  menu: (provided, state) => {
    const width = state.selectProps.width;
    //const borderBottom = "1px dotted pink";
    let color = state.selectProps.menuColor;
    const padding = 20;
    //console.log(provided, state);
    // if (state.data.isDefault == 1) {
    //   color = "red";
    // }

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px 0px 0px 5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    //borderRadius: "5px 0px 0px 5px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, backgroundColor, color };
  },
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

let TxtOrderSupplier = (props) => (
  <>
    <Form.Label>
      <span className="required-field">* </span>{" "}
      <strong>Supplier / Payee</strong>
    </Form.Label>
    <InputGroup className="mb-3">
      <InputGroup.Prepend style={{ flex: 1 }}>
        {/* {console.log(props.state.supplierData)} */}
        <Select
          // defaultValue={colourOptions[1]}
          options={props.state.supplierData}
          styles={customStyles}
          formatGroupLabel={formatGroupLabel}
          getOptionLabel={(x) => {
            return x.name;
          }}
          width="100%"
          getOptionValue={(x) => x.id}
          onChange={(x, name) => {
            props.state.inputChange(
              {
                target: {
                  value: x,
                  name: "txtSupplierCrud",
                },
              },
              true
            );
          }}
          value={props.state.txtSupplierCrud.value}
          name="txtSupplierCrud"
          isDisabled={props.state.txtSupplierCrud?.readOnly}
        />
      </InputGroup.Prepend>

      {/* <FormControl
        as="select"
        value={props.state.txtSupplierCrud.value}
        name="supplierSelect"
        disabled={props.state.txtSupplierCrud.readOnly}
        
        onChange={(e) => {
          props.state.handleSupplierChange(e, true);
        }}
      >
        {props.state.supplierData.map((dat, index) => (
          <option key={dat.id} value={dat.id}>
            {dat.name}
          </option>
        ))}
        <option value={0} key={0} disabled hidden>
          No Supplier Selected
        </option>
      </FormControl> */}
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          trigger="click"
          overlay={
            <Popover id={`popover-positioned-top`}>
              <Popover.Title as="h3">Supplier Information</Popover.Title>
              <Popover.Content>
                <Row>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Name:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Name"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud?.value
                            ? props.state.txtSupplierCrud?.value?.name
                            : "Name"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Address:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Address"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud.value
                            ? props.state.txtSupplierCrud?.value?.address
                            : "Address"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Text className="lblSupplierInfo">
                        Contact:
                      </InputGroup.Text>
                      <FormControl
                        type="text"
                        placeholder="Contact"
                        name="txtOrderNotes"
                        value={
                          props.state.txtSupplierCrud.value
                            ? props.state.txtSupplierCrud?.value?.contact
                            : "Contact"
                        }
                        className="txtSupplierInfo"
                        disabled
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Popover.Content>
            </Popover>
          }
        >
          <Button
            variant="secondary"
            name="btnInfoSupplier"
            className="btnInfoSupplier "
          >
            <i className="icofont-info icofont-1x"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>ADD</Tooltip>}
        >
          <Button
            variant="secondary"
            name="btnAddSupplier"
            className="btnAddSupplier order-btn-crud"
            disabled={props.state.handleOrderModal.isEdit}
            onClick={(e) => {
              props.state.handleActorModal.show(e);
            }}
          >
            <i className="icofont-ui-add icofont-1x"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </InputGroup>
  </>
);

// let TxtOrderDateReceived = (props) => (
//   <>
//     <Form.Label>
//       <strong>Date Received</strong>
//     </Form.Label>
//     <FormControl
//       type="date"
//       placeholder={props.state.txtOrderDateReceived.placeholder}
//       name="txtOrderDateReceived"
//       value={props.state.txtOrderDateReceived.value}
//       onChange={props.state.inputChange}
//       className={
//         props.state.txtOrderDateReceived.className + " css-projectInput"
//       }
//       readOnly={props.state.txtOrderDateReceived.readOnly}
//     />
//   </>
// );

let TxtOrderStatus = (props) => (
  <>
    <Form.Label>
      <strong>Order Status</strong>
    </Form.Label>
    <FormControl
      as="select"
      value={props.state.txtOrderStatus.value}
      disabled={props.state.txtOrderStatus.readOnly}
      name="txtOrderStatus"
      className={props.state.txtOrderStatus.className + " css-orderInput"}
      onChange={(e) => {
        props.state.inputChange(e);
      }}
    >
      <option value={1} key={1}>
        PR Pending Approval
      </option>
      <option value={2} key={2}>
        PR Denied
      </option>
      <option value={3} key={3}>
        PR Approved
      </option>
      <option value={4} key={4}>
        PO Purchased
      </option>
      <option value={5} key={5}>
        PO Finished
      </option>
      <option value={6} key={6}>
        PO Cancelled
      </option>
      <option value={7} key={7}>
        PO Dispute
      </option>
      <option value={8} key={8}>
        PO Released
      </option>
      <option value={10} key={10}>
        Transaction Ongoing
      </option>
    </FormControl>
  </>
);
let SelOrdFilter = (props) => (
  <>
    <InputGroup className="pl-3" size="">
      <InputGroup.Prepend>
        <InputGroup.Text className="">Filter</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        as="select"
        value={props.state.selMatFilter.value}
        name="selMatFilter"
        className=""
        disabled={props.state.selMatFilter.readOnly}
        onChange={(e) => {
          props.state.inputChange(e);
          props.state.handleOrdFilterChange(e.target.value);
        }}
      >
        <option value={0} key={0}>
          All
        </option>
        <option value={1} key={1}>
          PR Pending Approval
        </option>
        <option value={2} key={2}>
          PR Denied
        </option>
        <option value={3} key={3}>
          PR Approved
        </option>
        <option value={4} key={4}>
          PO Purchased
        </option>
        <option value={5} key={5}>
          PO Finished
        </option>
        <option value={6} key={6}>
          PO Cancelled
        </option>
        <option value={7} key={7}>
          PO Dispute
        </option>
        <option value={8} key={8}>
          PO Released
        </option>
        <option value={10} key={10}>
          Transaction Ongoing
        </option>
      </FormControl>
    </InputGroup>
  </>
);

let TxtUniqueID = (props) => (
  <>
    <Form.Label>
      <strong>ID</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtProjUniqueID.placeholder}
      name="txtProjUniqueID"
      value={props.state.txtProjUniqueID.value}
      onChange={props.state.inputChange}
      className={props.state.txtProjUniqueID.className + " css-projectInput"}
      readOnly={props.state.txtProjUniqueID.readOnly}
    />
  </>
);
let TxtDateLastModified = (props) => (
  <>
    <Form.Label>
      <strong>Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDateLastModified.placeholder}
      name="txtDateLastModified"
      value={props.state.txtDateLastModified.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModified.className + " css-projectInput"
      }
      readOnly={props.state.txtDateLastModified.readOnly}
    />
  </>
);
let TxtLastModifiedBy = (props) => (
  <>
    <Form.Label>
      {" "}
      <strong>Last Modified By</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtLastModifiedBy.placeholder}
      name="txtLastModifiedBy"
      value={props.state.txtLastModifiedBy.value}
      onChange={props.state.inputChange}
      className={props.state.txtLastModifiedBy.className + " css-projectInput"}
      readOnly={props.state.txtLastModifiedBy.readOnly}
    />
  </>
);
let TxtDatePosted = (props) => (
  <>
    <Form.Label>
      <strong>Date Posted</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDatePosted.placeholder}
      name="txtDatePosted"
      value={props.state.txtDatePosted.value}
      onChange={props.state.inputChange}
      className={props.state.txtDatePosted.className + " css-projectInput"}
      readOnly={props.state.txtDatePosted.readOnly}
    />
  </>
);

let TxtActorName = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Supplier Name
      </strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtActorName.placeholder}
      style={{ textTransform: "uppercase" }}
      name="txtActorName"
      value={props.state.txtActorName.value}
      onChange={props.state.inputChange}
      className={props.state.txtActorName.className + " css-actorInput"}
      readOnly={props.state.txtActorName.readOnly}
      required
    />
  </>
);

let TxtContact = (props) => (
  <>
    <Form.Label>
      <strong>Contact</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtContact.placeholder}
      name="txtContact"
      value={props.state.txtContact.value}
      onChange={props.state.inputChange}
      className={props.state.txtContact.className + " css-actorInput"}
      readOnly={props.state.txtContact.readOnly}
    />
  </>
);

let TxtAddress = (props) => (
  <>
    <Form.Label>
      <strong>Address</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtAddress.placeholder}
      style={{ textTransform: "uppercase" }}
      name="txtAddress"
      value={props.state.txtAddress.value}
      onChange={props.state.inputChange}
      className={props.state.txtAddress.className + " css-actorInput"}
      readOnly={props.state.txtAddress.readOnly}
    />
  </>
);

export const SupplierModal = (props) => (
  <>
    <Modal
      show={props.state.handleActorModal.isShow}
      onHide={props.state.handleActorModal.hide}
      dialogClassName="modal-90w"
      className="ord-bg-dark"
      size="md"
      centered
    >
      <Modal.Header className="supplier-modal-header-cont" closeButton>
        <Row className="supplier-modal-header">
          <Col>
            <h4 className="text-center supplier-modal-header-text">
              Add Supplier
            </h4>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <span>
            <span className="required-field">* </span>are required fields
          </span>
        </div>
        <Form
          id="formActorInput"
          noValidate
          validated={props.state.handleActorModal.isValidated}
          onSubmit={props.state.handleActorModal.handleSubmit}
        >
          <Row>
            <Col>
              <TxtActorName state={props.state} />
              <TxtContact state={props.state} />
              <TxtAddress state={props.state} />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="">
              <Button
                block
                className="actor-btn-crud"
                name="btnSubmitActor"
                disabled={props.state.handleActorModal.isView}
                type="submit"
                // onClick={(e) => {
                //   props.state.handleSubmit(e);
                //   props.handleHideModal(e);
                // }}
              >
                SAVE
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  </>
);

const OrderModal = (props) => (
  <>
    <Modal
      show={props.state.handleOrderModal.isShow}
      onHide={props.state.handleOrderModal.hide}
      onExit={(e) => {
        props.state.handleOrderModal.handleExit(e);
      }}
      className="ord-bg-dark "
      dialogClassName="modal-ord--90w "
      centered
    >
      <Modal.Body className="order-modal-body">
        <Row className="order-modal-header pt-3 pb-3 mb-3" noGutters>
          <Col md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseProjectModal"
                className="btnAddProject project-btn-crud"
                onClick={(e) => {
                  props.state.handleOrderModal.hide();
                }}
              >
                <i className="icofont-rounded-left"></i>
              </Button>
            </OverlayTrigger>
          </Col>
          <Col md={11} lg={11}>
            {(() => {
              if (Number(props.state.selectedOrderOperation.id) === 0) {
                if (props.userType.includes(5)) {
                  return (
                    <h4 className="order-modal-header-text font-weight-bold">
                      Add Pending PR
                    </h4>
                  );
                } else {
                  return (
                    <h4 className=" order-modal-header-text font-weight-bold">
                      Manage Selected Purchase
                    </h4>
                  );
                }
              } else {
                return (
                  <h4 className="order-modal-header-text font-weight-bold">
                    Manage Selected Purchase
                  </h4>
                );
              }
            })()}
          </Col>
        </Row>

        <Form
          id="formOrderInput"
          noValidate
          validated={props.state.handleOrderModal.isValidated}
          onSubmit={props.state.handleOrderModal.handleSubmit}
        >
          <Row className="mb-3" noGutters>
            <Col md={3} lg={3} className="">
              {(() => {
                if (Number(props.state.selectedOrderOperation.id) !== 0) {
                  return (
                    <>
                      <TxtUniqueID state={props.state} />
                    </>
                  );
                }
              })()}
              <>
                <Form.Label>
                  <strong>
                    {props.material?.materialType
                      ? `${props.material?.materialType.toUpperCase()} `
                      : ""}
                    Name
                  </strong>
                </Form.Label>
                <FormControl
                  type="text"
                  name="txtOrderSelMaterial"
                  value={
                    props.material?.item?.name
                      ? props.material?.item?.name
                      : props.material.name
                  }
                  className={
                    props.state.txtOrderBrand.className + " css-projectInput"
                  }
                  readOnly
                />
              </>
              {props.material?.materialType === "material" && (
                <TxtOrderQty state={props.state} />
              )}

              <TxtOrderCost state={props.state} />
              {props.material?.materialType === "material" && (
                <TxtOrderBrand state={props.state} />
              )}

              <TxtOrderSupplier state={props.state} />
            </Col>

            <Col md={3} lg={3} className="pl-3">
              {(() => {
                if (Number(props.state.selectedOrderOperation.id) !== 0) {
                  return (
                    <>
                      <TxtDatePosted state={props.state} />
                      <TxtDateLastModified state={props.state} />
                    </>
                  );
                }
              })()}
              {(() => {
                //let t = Number(props.userType);
                if (
                  props.userType.includes(0) ||
                  props.userType.includes(6) ||
                  (props.userType.includes(5) &&
                    Number(props.state.selectedOrderOperation.id) !== 0)
                ) {
                  return (
                    <>
                      <TxtOrderStatus state={props.state} />
                    </>
                  );
                }
              })()}

              {(() => {
                if (Number(props.state.selectedOrderOperation.id) !== 0) {
                  return (
                    <>
                      <TxtLastModifiedBy state={props.state} />
                    </>
                  );
                }
              })()}
              <TxtOrderNotes state={props.state} />
            </Col>

            <Col md={6} lg={6} className="pl-3">
              {props.tableHistory}
            </Col>
          </Row>
          <Row className="order-modal-footer pt-3 pb-3 mt-3" noGutters>
            <Col md={6} lg={6} className="">
              <p className="font-weight-bold">Note:</p>
              <p className="">
                Fields that has asterisk (
                <span className="required-field font-weight-bold"> * </span>)
                requires an input.
              </p>
            </Col>
            {(() => {
              const op = Number(props.state.selectedOrderOperation.id);
              if (props.userType.includes(5) && op === 0) {
                return (
                  <Col className="pl-3" md={2} lg={2}>
                    <Button
                      block
                      className=" order-btn-crud orderModalBtnSave"
                      disabled={props.state.handleOrderModal.isView}
                      type="submit"
                    >
                      SAVE
                    </Button>
                  </Col>
                );
              }
              if (props.state.orderSelection) {
                const s = Number(props.state.orderSelection.status);

                //console.log(s, props.userType.includes(5), op);
                if (props.userType.includes(5) && s === 2 && op === 1) {
                  return (
                    <Col md={2} lg={2} className="pl-3">
                      <Button
                        block
                        className=" order-btn-crud orderModalBtnSave"
                        type="submit"
                      >
                        Submit for Re-Approval
                      </Button>
                    </Col>
                  );
                }
              }
              if (props.userType.includes(0)) {
                return (
                  <>
                    <Col md={2} lg={2} className="pl-3">
                      <Button
                        block
                        className=" order-btn-crud orderModalBtnModify"
                        name="btnUnlockOrder"
                        disabled={!props.state.handleOrderModal.isView}
                        onClick={(e) => {
                          props.state.handleActionChangeOrder(e);
                        }}
                      >
                        MODIFY
                      </Button>
                    </Col>
                    <Col md={2} lg={2} className="pl-3">
                      <Button
                        block
                        className=" order-btn-crud orderModalBtnSave"
                        disabled={props.state.handleOrderModal.isView}
                        type="submit"
                      >
                        SAVE
                      </Button>
                    </Col>
                    <Col md={2} lg={2} className="pl-3">
                      <Button
                        block
                        className=" order-btn-crud  orderModalBtnDelete"
                        name="btnDeleteOrder"
                        disabled={
                          props.state.handleOrderModal.isView ||
                          Number(props.state.selectedOrderOperation.id) === 0
                        }
                        onClick={(e) => {
                          props.state.handleActionChangeOrder(e);
                        }}
                      >
                        DELETE
                      </Button>
                    </Col>
                  </>
                );
              }
            })()}
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  </>
);
const Order = (props) => (
  <>
    <Row className="page-selectedPhase-row" noGutters>
      <Col className="page-selectedPhase-col-l">
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              Selected Material
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                return props.material?.item?.name
                  ? props.material?.item?.name
                  : props.material.name;
              else return "Empty";
            })()}
          />
        </InputGroup>
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              Qty Need
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                return `${props.state.selMaterialData?.requiredQty} ${
                  props.state.selMaterialData?.unit?.name
                    ? props.state.selMaterialData?.unit?.name
                    : ""
                }`;
              else return "Empty";
            })()}
          />
        </InputGroup>
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              {(() => {
                if (props.state.selMaterialData.uniqueId)
                  if (
                    Number(props.state.selMaterialData.totalQty) >
                    Number(props.state.selMaterialData.requiredQty)
                  ) {
                    return "OVER ORDER";
                  } else {
                    return "Unordered";
                  }
                else return "Empty";
              })()}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                return `${Math.abs(
                  Number(props.state.selMaterialData.requiredQty) -
                    Number(props.state.selMaterialData.totalQty)
                )} ${
                  props.state.selMaterialData?.unit?.name
                    ? props.state.selMaterialData?.unit?.name
                    : ""
                }`;
              else return "Empty";
            })()}
          />
        </InputGroup>
      </Col>
      <Col className="page-selectedPhase-col-r">
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              Material Budget
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                return (
                  "₱ " +
                  new Intl.NumberFormat("en-US").format(
                    props.state.selMaterialData.budget
                  )
                );
              else return "Empty";
            })()}
          />
        </InputGroup>
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              {(() => {
                if (props.state.selMaterialData.uniqueId)
                  if (Number(props.state.selMaterialDataFinance.loss) === 0) {
                    return "Budget Left";
                  } else {
                    return "Over Budget";
                  }
                else return "Empty";
              })()}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                if (Number(props.state.selMaterialDataFinance.loss) === 0) {
                  return (
                    "₱ " +
                    new Intl.NumberFormat("en-US").format(
                      props.state.selMaterialDataFinance.savings
                    )
                  );
                } else {
                  return (
                    "₱ " +
                    new Intl.NumberFormat("en-US").format(
                      props.state.selMaterialDataFinance.loss
                    )
                  );
                }
              else return "Empty";
            })()}
          />
        </InputGroup>
        <InputGroup className="order-tc-ig">
          <InputGroup.Prepend>
            <InputGroup.Text className="order-lbl-labels font-weight-bold">
              Use
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            readOnly
            disabled
            className="order-tc-txtbox"
            value={(() => {
              if (props.state.selMaterialData.uniqueId)
                return props.state.selMaterialData.usage;
              else return "Empty";
            })()}
          />
        </InputGroup>
      </Col>
    </Row>

    <Row className="row-crud" noGutters={true}>
      <Col md="auto" lg="auto" className="options-column">
        <InputGroup className="">
          <InputGroup.Prepend>
            <InputGroup.Text className="ico-search">
              <i className="icofont-search icofont-1x"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search"
            aria-label="Username"
            name="searchInput"
            className="searchTable shadow-none"
            aria-describedby="basic-addon1"
            onChange={props.state.handleSearch}
          />
        </InputGroup>
      </Col>
      <Col md="auto" lg="auto">
        <SelOrdFilter state={props.state} />
      </Col>
      {(props.userType.includes(0) || props.userType.includes(5)) &&
        props.material?.materialType === "material" && (
          <Col className="material-btn-crud-col d-flex justify-content-end">
            <ButtonGroup className="">
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`material-tooltip-add`}>ADD</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnAddOrder"
                  className="btnAddOrder material-btn-crud"
                  onClick={(e) => {
                    //props.handleShowModal();
                    props.state.handleActionChangeOrder(e);
                  }}
                >
                  ADD <i className="icofont-ui-add icofont-1x"></i>
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Col>
        )}
      {(() => {
        let showFinished = true;

        const orderData = props.state.orderData;
        if (orderData) {
          for (const obj of orderData) {
            
            if (obj.status === 5 || obj.status === 2) showFinished = false;
          }
        }

        return (
          (props.userType.includes(0) || props.userType.includes(5)) &&
          props.material?.materialType !== "material" &&
          showFinished && (
            <Col className="material-btn-crud-col d-flex justify-content-end">
              <ButtonGroup className="">
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`material-tooltip-add`}>
                      Finishes this ongoing transaction
                    </Tooltip>
                  }
                >
                  <Button
                    variant="secondary"
                    name="btnAddOrder"
                    className="btnAddOrder material-btn-crud"
                    onClick={(e) => {
                      props.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Please make sure that this transaction is fully finished before proceeding. Are you sure you want to set this transaction to Finished?`,
                          isYesNo: true,
                          cb: (e) => {
                            if (orderData) {
                              const id = orderData[0].id;
                              props.state.handleOrderOverviewModal.hide();
                              props.state.UpdateOrderStatus(id, 5);
                            }
                          },
                        },
                      });

                      //props.state.handleActionChangeOrder(e);
                    }}
                  >
                    Set Finished <i className="icofont-ui-check icofont-1x"></i>
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </Col>
          )
        );
      })()}
    </Row>
    {props.tablelist}

    <OrderModal
      state={props.state}
      userType={props.userType}
      tableHistory={props.tableHistory}
      material={props.material}
    />
    <SupplierModal state={props.state} />
  </>
);
export { Order, orderColumn, historyColumn };
