import React from "react";
import Card from "react-bootstrap/Card";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
// import ProfileLogo from "../../assets/profile-logo.svg";
import "../../css/profile.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DBStatus from "../../functions/DBStatus";

const Profile = (props) => (
  <>
    <div className="profile-bg">
      <Card className="profile-card-cont">
        <Card.Header as="h5" className="profile-card-header">
          {props.user.name}
        </Card.Header>
        <Card.Body className="phase-card-body">
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className="profile-logo-container text-center"
            >
              <Col>
                <i className="icofont-user-alt-7 profile-logo   "></i>
              </Col>

              {/* <Card.Img
                variant="top"
                src={ProfileLogo}
                className="profile-logo"
              /> */}
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={9}
              className="profile-items-wrapper"
            >
              <InputGroup size="sm" className="profile-item-container">
                <InputGroup.Prepend>
                  <InputGroup.Text className="profile-d-card-labels">
                    Contact
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="profile-details-txtbox"
                  value={(() => {
                    return props.user.contact === null
                      ? "None"
                      : props.user.contact;
                  })()}
                />
              </InputGroup>

              <InputGroup size="sm" className="profile-item-container">
                <InputGroup.Prepend>
                  <InputGroup.Text className="profile-d-card-labels">
                    Username
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="profile-details-txtbox"
                  value={(() => {
                    return props.user.username === null
                      ? "None"
                      : props.user.username;
                  })()}
                />
              </InputGroup>
              <InputGroup size="sm" className="profile-item-container">
                <InputGroup.Prepend>
                  <InputGroup.Text className="profile-d-card-labels">
                    Usertype
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  disabled
                  className="profile-details-txtbox"
                  value={(() => {
                    return props.user.type === null
                      ? "None"
                      : DBStatus.parseActorType(Number(props.user.type[0]));
                  })()}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            {(() => {
              if (props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="Admin"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Administrator User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            {(() => {
              if (props.user.type.includes(5) || props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="Purchaser"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Purchaser User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            {(() => {
              if (props.user.type.includes(8) || props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="Requester"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Requester User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            {(() => {
              if (props.user.type.includes(7) || props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="ProjectManager"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Project Manager User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            {(() => {
              if (props.user.type.includes(6) || props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="SiteManager"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Site Manager User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            {(() => {
              if (props.user.type.includes(4) || props.user.type.includes(0)) {
                return (
                  <Col>
                    <Button
                      name="Executive"
                      onClick={(e) => {
                        props.state.downloadManual(e);
                      }}
                    >
                      Executives User Manual
                    </Button>
                  </Col>
                );
              } else {
                return <></>;
              }
            })()}
            <Col>
              <Button
                name="QA"
                onClick={(e) => {
                  props.state.downloadManual(e);
                }}
              >
                QA Manual
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  </>
);

export { Profile };
