import React from "react";
import PropTypes from "prop-types";
import Filters from "../../functions/filters";
import { connect } from "react-redux";
//import moment from "moment";
import { PR, poColumn } from "./PR";
import RTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
const SelectTable = selectTableHOC(RTable);

class PRProcess extends React.Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      PRData: [],
      filteredPR: [],
      newPR: [],
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      handleSupplierChange: this.handleSupplierChange.bind(this),
      addPO: this.addPO.bind(this),
      resetPOModal: this.resetPOModal.bind(this),
      downloadPDF: this.downloadPDF.bind(this),
      readPR: this.readPR.bind(this),

      selectAll: false,
      selection: [],
      searchInput: "",

      checkboxTable: {},
      poSelection: [],

      selectedPOOperation: { id: 0, name: "Add", disabled: false },
      selectedPOModalOperation: { id: 0, name: "Add" },

      handlePOModal: {
        handleSubmit: this.handlePOSubmit.bind(this),
        handleExit: this.handleExit.bind(this),
        show: this.handleShowModal.bind(this),
        hide: this.handleHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: this.props.isOpen,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      selPO: {
        //handler: this.handleSupplierChange.bind(this),
        value: "",
      },
      txtPOName: {
        value: "",
        placeholder: "Name",
        className: "mb-3",
        readOnly: false,
      },
      txtPODeliverTo: {
        value: "",
        placeholder: "Delivery Address",
        className: "",
        readOnly: false,
      },
      txtPOUniqueId: {
        value: "",
        placeholder: "Unique ID",
        className: "mb-3",
        readOnly: false,
      },
      selSupplier: {
        value: 0,
        readOnly: false,
      },
    };
  }

  componentDidMount() {
    this.readPR();
  }

  componentWillUnmount() {}

  getPO = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching POs", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getPO",
        payload: this.props.projectId,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        let PRData = onfulfilled.data;
        //console.log("SEARCHED PO PR: ", PRData);

        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
      },
      (onrejected) => {
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
      }
    );
  };

  addPO = (cb) => {
    if (
      Number(this.state.selSupplier.value) === 0 ||
      this.state.selSupplier.value === null
    ) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select a Supplier`,
          isYesNo: false,
        },
      });
    } else {
      if (Number(this.state.poSelection.length) === 0) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Selection",
            desc: `Please select a PR`,
            isYesNo: false,
          },
        });
      } else {
        if (
          this.state.txtPODeliverTo.value === null ||
          this.state.txtPODeliverTo.value === ""
        ) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Empty Address",
              desc: `Delivery Address should not be empty`,
              isYesNo: false,
            },
          });
        } else {
          const loadId = Filters.uuidv4();
          this.props.dispatch({
            type: "TOGGLE_LOADING",
            payload: { isLoading: true, name: "Submitting PO", id: loadId },
          });
          // this.setState((prevState) => ({
          //   actorDataIsLoading: true,
          // }));
          let multimat = [];

          this.state.poSelection.forEach((x) => {
            multimat.push(x.id);
          });

          let data = {
            multiMatIds: multimat,
            deliverTo: this.state.txtPODeliverTo.value.toUpperCase(),
            projectId: this.props.projectId,
          };
          new Promise((resolve, reject) => {
            this.props.dispatch({
              type: "USER_GET_REQUEST",
              reqType: "post",
              data: data,
              payload: "inventory/addPurchaseOrder",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: { isLoading: false, id: loadId },
            });
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `Added PO Successfully`,
              },
            });
            this.readNewPR(api.data.data.id, cb);
            this.getPO();
            this.resetPOModal();
          });
        }
      }
    }
  };

  readNewPR = (e, cb) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PRs", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getPurchaseOrderById?id=${e}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((getApi) => {
      let PRData = getApi.data;
      //console.log(PRData);
      this.setState((x) => ({
        newPR: PRData,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
      cb();
      this.readPR();
    });
  };

  downloadPDF = async (blobData) => {
    const fileName = "PO#-" + this.state.newPR.uniqueId;
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();
    this.resetPOModal();
    //cb();
  };

  resetPOModal = () => {
    this.setState((x) => ({
      selSupplier: {
        value: 0,
        readOnly: false,
      },
      txtPODeliverTo: { ...x.txtPODeliverTo, value: "" },
      filteredPR: [],
      selection: [],
      poSelection: [],
    }));
  };

  handleSupplierChange = (e) => {
    let filtered = this.state.PRData.filter(
      (x) => Number(x.supplier.id) === Number(e)
    );
    this.setState(
      (x) => ({
        filteredPR: filtered[0],
        selection: [],
        poSelection: [],
        txtPODeliverTo: {
          ...x.txtPODeliverTo,
          value: filtered[0].supplier.address,
        },
      }),
      (x) => {
        //console.log(this.state.filteredPR);
      }
    );
  };

  handleExit = (e) => {};

  handlePOSubmit = (e) => {};

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleShowModal = (e) => {
    this.setState((x) => ({
      handlePOModal: {
        ...x.handlePOModal,
        isShow: true,
      },
    }));
  };
  handleHideModal = (e) => {
    this.setState((x) => ({
      handlePOModal: {
        ...x.handlePOModal,
        isShow: false,
      },
    }));
  };

  handleActionChange = (e) => {};

  readPR = (e) => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PRs", id: loadId },
    });

    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: this.props.projectId,
        reqType: "getPR",

        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let PRData = api.data;

      //console.log("CONFIRMED MULTIMAT PRs: ", api.data);
      this.setState((x) => ({
        PRData: PRData,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    let poSelection = this.state.poSelection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      //console.log(this.state.selection, key);

      selection = selection.filter((x) => {
        return x !== key;
      });
      poSelection = poSelection.filter((x) => {
        return x.id !== row.id;
      });

      //selection = [];
    } else {
      //selection.length = 0;

      if (poSelection.length > 20) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "PO Material Exceeded",
            desc: `Please create another PO for additional Items. Max materials per PO 20`,
            isYesNo: false,
          },
        });
      } else {
        selection.push(key);
        poSelection.push(row);
      }
    }
   // console.log(poSelection);
    this.setState((x) => ({
      selection,
      poSelection,
      //actorId: row.id,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];
    const poSelection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
        poSelection.push(item._original);
      });
    } else {
      this.setState({ selection: [], poSelection: [] });
    }
    this.setState({ selectAll, selection, poSelection });
    //console.log(poSelection);
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
        this.toggleSelection(
          `select-${rowInfo.original.id}`,
          null,
          rowInfo.original
        );
      }
      if (handleOriginal) {
        handleOriginal();
      }
      //console.log(rowInfo.original.id);
    },
    style: {
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "lightgreen",
    },
  });

  handleSearch = (event) => {
    //console.log(event.target.value);
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(this.state.bakPRData, event.target.value);

      if (data != null) {
        this.setState({ PRData: data });
      } else {
        this.setState({ PRData: this.state.bakPRData });
      }
    } else this.setState({ isFiltered: false });
  };

  render() {
    let tablelist = (
      <SelectTable
        data={this.state.filteredPR.multiMaterials}
        columns={poColumn}
        keyField="id"
        {...this.props}
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        selectAll={this.state.selectAll}
        selectType="checkbox"
        loading={this.state.isLoading}
        toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        defaultPageSize={10}
        style={{
          height: "55vh",
        }}
        className="tableActor -highlight"
      />
    );

    return (
      <>
        <PR
          handleSearch={this.handleSearch}
          inputChange={this.inputChange}
          state={this.state}
          rowFn={this.rowFn}
          isSelected={this.isSelected}
          toggleSelection={this.toggleSelection}
          isShow={this.props.isPOModalOpen}
          dispatch={this.props.dispatch}
          tableList={tablelist}
          PRData={this.props.PRData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PRData: state.apiData.PRData,
    isPOModalOpen: state.prModal.show,
  };
};
export default connect(mapStateToProps)(PRProcess);
