import React from "react";
import "../../css/workspace.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import JVLogo from "../../assets/rebLogo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Workspace = (props) => (
  <div className="workspaceContainer">
    <Navbar className="workspace-nav" expand="lg">
      <Row className="top-nav-cont" noGutters>
        <Col xs={3} lg={0}>
          <Navbar.Toggle
            className="workspace-nav-toggle"
            aria-controls="basic-navbar-nav"
          />
        </Col>
        <Col
          xs={9}
          lg={12}
          className="workspace-brand-cont d-flex justify-content-center"
        >
          <Navbar.Brand>
            {" "}
            <img
              src={JVLogo}
              className="workspace-logo"
              name="btnHome"
              alt="jvLandmark-logo"
            />
          </Navbar.Brand>
        </Col>
      </Row>

      <Navbar.Collapse id="basic-navbar-nav">
        <Row className="navWorkspace" noGutters>
          <Col xs={12} sm={12} md={12} lg={12} className="align-self-center">
            <Nav variant="pills" fill defaultActiveKey="btnProjectManagement">
              {(() => {
                if (
                  props.decUser.type.includes(0) ||
                  props.decUser.type.includes(4)
                ) {
                  return (
                    <>
                      <Nav.Item className="nav-workspace-links">
                        <Nav.Link
                          className="btnDashLink workspace-links"
                          onSelect={(x) => {
                            props.state.handleContentChange(x);
                          }}
                          eventKey="btnVerification"
                        >
                          VERIFICATION
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-workspace-links">
                        <Nav.Link
                          className="btnDashLink workspace-links"
                          onSelect={(x) => {
                            props.state.handleContentChange(x);
                          }}
                          eventKey="btnDashboard"
                        >
                          DASHBOARD
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  props.decUser.type.includes(0) ||
                  !props.decUser.type.includes(4)
                ) {
                  return (
                    <Nav.Item className="nav-workspace-links">
                      <Nav.Link
                        className="btnDashLink workspace-links"
                        onSelect={(x) => {
                          props.state.handleContentChange(x);
                        }}
                        eventKey="btnProjectManagement"
                      >
                        PROJECT MANAGEMENT
                      </Nav.Link>
                    </Nav.Item>
                  );
                }
              })()}

              {(() => {
                if (
                  props.decUser.type.includes(0) ||
                  props.decUser.type.includes(5)
                ) {
                  return (
                    <Nav.Item className="nav-workspace-links">
                      <Nav.Link
                        className="btnDashLink workspace-links"
                        onSelect={(x) => {
                          props.state.handleContentChange(x);
                        }}
                        eventKey="btnActorManagement"
                      >
                        APP MANAGEMENT
                      </Nav.Link>
                    </Nav.Item>
                  );
                }
              })()}

              <Button
                name="btnProfile"
                className="btnProfile"
                onClick={(x) => {
                  //console.log(props.decUser);
                  props.state.handleContentChange(x);
                }}
              >
                {props.decUser.name}
              </Button>
              <Button
                size="sm"
                className="btnLogout"
                onClick={props.state.handleLogout}
              >
                LOG OUT
              </Button>
            </Nav>
          </Col>
          {/* <Col
            xs={12}
            sm={12}
            md={12}
            lg={3}
            className="d-flex justify-content-end"
          >
            
          </Col> */}
        </Row>
      </Navbar.Collapse>
    </Navbar>

    <Row className="stage-container" noGutters={true}>
      <Col className="">
        <div className="stage">{props.content}</div>
      </Col>
    </Row>
  </div>
);

export default Workspace;
