import React from "react";
import { Login } from "./Login";
import auth from "../../functions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import WorkspaceProcess from "../Workspace/WorkspaceProcess";
import DashboardProcess from "../Dashboard/DashboardProcess";
//import HomeProcess from "../Home/HomeProcess";
import Filters from "../../functions/filters";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";

import { isChrome, browserName } from "react-device-detect";

let CryptoJS = require("crypto-js");

class LoginProcess extends React.Component {
  constructor(props) {
    super(props);

    this.inputChange = this.inputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isRegister: false,

      txtPassword: { value: "", placeholder: "Password", className: "mb-3" },
      txtCPassword: {
        value: "",
        placeholder: "Confirm Password",
        className: "mb-3",
      },
      txtLogUsername: {
        value: "",
        placeholder: "Enter here",
        className: "mb-3",
      },
      txtLogPassword: {
        value: "",
        placeholder: "Enter here",
        className: "mb-3",
      },
      lblLogininfo: {
        value: "",
        className: "",
      },

      onHide: false,
      userType: null,
      authUser: null,
    };
  }

  componentDidMount() {
    //console.log(browserName);
    //console.log(this.props.socketio.socket.id);
    if (!isChrome) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Browser not Supported",
          desc: `This app is optimized for google chrome browser use only. Use of other browser can cause issues.`,
          isYesNo: false,
        },
      });
    }
    if (localStorage.getItem("LUT") != null) {
      let userData = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("LUD"),
          process.env.REACT_APP_SEK
        ).toString(CryptoJS.enc.Utf8)
      );
      this.setState({ userType: userData.type });
      auth.login((x) => {});
      if (
        Number(userData.type) === 0 &&
        Number(localStorage.getItem("LP")) === 1
      ) {
        this.props.history.push({
          pathname: "/workspace",
          user: userData,
        });
        //this.props.history.push("/dashboard");
      } else {
        this.props.history.push({
          pathname: "/workspace",
          user: userData,
        });
        //this.props.history.push("/workspace");
      }
    } else {
      //console.log("trigger this");
      if (this.props.location.pathname === "/login") {
      }
    }
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    auth.login(this.authenticate);
  };

  authenticate = () => {
    let authResponse = { status: 0, user: {}, token: "", set: true };
    let logDat = {
      username: this.state.txtLogUsername.value,
      password: this.state.txtLogPassword.value,
      sessionID: this.props.socketio.socket.id,
    };
    let urlBrand = "auth/login";
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Authenticating", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: logDat,
        payload: urlBrand,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        authResponse.status = onfulfilled.status;
        authResponse.user = onfulfilled.data.user;
        authResponse.token = onfulfilled.data.accessToken;

        //console.log(authResponse);
        /**
         * LUD = Logged User Details
         * LUT = Logged user Token
         */
        //console.log(JSON.stringify(authResponse.user));

        console.log("LOGGED USER: ", authResponse.user);
        this.setState({ userType: authResponse.user.type });

        // this.props.dispatch({
        //   type: "API_STORE_USER",
        //   payload: {
        //     data: authResponse.user,
        //   },
        // });

        let enLUD = CryptoJS.AES.encrypt(
          JSON.stringify(authResponse.user),
          process.env.REACT_APP_SEK
        );
        // localStorage.setItem("LUD", enLUD);
        // localStorage.setItem("LUT", authResponse.token);

        localStorage.setItem("LUD", enLUD);
        localStorage.setItem("LUT", authResponse.token);

        //console.log(enLUD, process.env.REACT_APP_SEK);
        // this.props.dispatch({ type: "TEST_TIMER" });
        // console.log(this.props);
        // cookies.set("LUT", authResponse.token, {
        //   path: "/",
        //   expires: new Date(Date.now() + 12000)
        // });

        // const decryptedString = cryptr.decrypt(encryptedString);

        // console.log(encryptedString);
        // console.log(decryptedString);

        // if (authResponse.status === 200) {
        //   if (authResponse.user.type == 0) {
        //     this.props.history.push("/portal");
        //   } else {
        //     this.props.history.push("/workspace");
        //   }
        // }
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });

        this.props.history.push({
          pathname: "/workspace",
          userType: this.state.userType,
        });
      },
      (onrejected) => {
        this.setState({
          txtLogUsername: {
            value: "",
            placeholder: "Username",
            className: "mb-3 is-invalid",
          },
          txtLogPassword: {
            value: "",
            placeholder: "Password",
            className: "mb-3 is-invalid",
          },
          lblLogininfo: {
            value: "Username or password incorrect",
            className: "mb-3 is-invalid",
          },
        });
        this.props.dispatch({
          type: "TOGGLE_LOADING",
          payload: { isLoading: false, id: loadId },
        });
      }
    );
  };

  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact
          strict
          render={() => (
            <Login
              inputChange={this.inputChange}
              handleSubmit={this.handleSubmit}
              state={this.state}
            />
          )}
        />
        {/* <Route
          path="/bypass"
          exact
          strict
          render={() => <DashboardProcess />}
        /> */}
        <Route
          path="/login"
          render={() => (
            <Login
              inputChange={this.inputChange}
              handleSubmit={this.handleSubmit}
              state={this.state}
            />
          )}
        />
        <ProtectedRoute
          exact
          path="/dashboard"
          userType={this.state.userType}
          component={() => <DashboardProcess />}
        />
        {/* <ProtectedRoute
          exact
          path="/portal"
          userType={this.state.userType}
          component={() => <HomeProcess />}
        /> */}
        <ProtectedRoute
          exact
          path="/workspace"
          userType={this.state.userType}
          component={() => <WorkspaceProcess />}
        />

        <Route path="*" component={() => "404 Not Found"} />
      </Switch>
    );
  }
}
const mapStateToProps = (state) => {
  return { socketio: state.SocketHandler };
};
export default connect(mapStateToProps)(withRouter(LoginProcess));
