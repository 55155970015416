import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DBStatus from "../../functions/DBStatus";
import "../../css/actor.css";
import Card from "react-bootstrap/Card";

import moment from "moment";

const employeeColumn = [
  {
    Header: "Name",
    id: "userFullname",
    accessor: (d) => (d.name === null ? "" : d.name),
  },
  {
    Header: "Type",
    id: "type",

    accessor: (d) => {
      //console.log(d);
      if (d.type) {
        if (d.type.length === 1) return DBStatus.parseActorType(Number(d.type));
        else return "Multi Type";
      } else {
        return "";
      }
    },
  },
  {
    Header: "Username",
    id: "Username",
    // accessor: d => d.name.title
    accessor: (d) => (d.username === null ? "" : d.username),
  },
  {
    Header: "Email",
    id: "Email",
    // accessor: d => d.name.title
    accessor: (d) => (d.email === null ? "" : d.email),
  },
  {
    Header: "Address",
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "" : d.address),
  },
  {
    Header: "Contact",
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "" : d.contact),
  },
  // {
  //   Header: "View",
  //   Cell: (row) => (
  //     <Row className="d-flex justify-content-center">
  //       <Button
  //         name="btnViewRow"
  //         className="actorBtnViewRow"
  //         id={row.original.id}
  //         // onClick={(x) => {
  //         //   console.log(row.original.id);
  //         // }}
  //       >
  //         View
  //       </Button>
  //     </Row>
  //   ),
  // },
];

const supplierColumn = [
  {
    Header: "Name",
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },
  {
    Header: "Address",
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "" : d.address),
  },
  {
    Header: "Website",
    id: "website",
    accessor: (d) => (d.website === null ? "" : d.website),
  },
  {
    Header: "Contact",
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "" : d.contact),
  },
];

const itemColumn = [
  {
    Header: "Items",
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },
];

const unitColumn = [
  {
    Header: "Units",
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },
];

const customerColumn = [
  {
    Header: "Name",
    id: "name",
    accessor: (d) => (d.name === null ? "" : d.name),
  },
  {
    Header: "Address",
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "" : d.address),
  },
  {
    Header: "Website",
    id: "website",
    accessor: (d) => (d.website === null ? "" : d.website),
  },
  {
    Header: "Contact",
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "" : d.contact),
  },
  {
    Header: "View",
    Cell: (row) => (
      <Row className="d-flex justify-content-center">
        <Button
          name="btnViewRow"
          id={row.original.id}
          // onClick={(x) => {
          //   console.log(row.original.id);
          // }}
        >
          View
        </Button>
      </Row>
    ),
  },
];

let TxtActorName = (props) => (
  <>
    <Form.Label className="font-weight-bold">
      <span className="required-field">* </span>
      {props.name}
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtActorName.placeholder}
      name="txtActorName"
      value={props.state.txtActorName.value}
      onChange={props.state.inputChange}
      className={props.state.txtActorName.className + " css-actorInput"}
      readOnly={props.state.txtActorName.readOnly}
      required
    />
  </>
);

let TxtUserName = (props) => (
  <>
    <Form.Label className="font-weight-bold">Username</Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtUserName.placeholder}
      name="txtUserName"
      value={props.state.txtUserName.value}
      onChange={props.state.inputChange}
      className={props.state.txtUserName.className + " css-actorInput"}
      readOnly={props.state.txtUserName.readOnly}
      required
    />
  </>
);

let TxtPassword = (props) => (
  <div className="container cont-pw mb-3 pt-3 pb-3">
    <Form.Label className="font-weight-bold">Password</Form.Label>
    <FormControl
      type="password"
      placeholder={props.state.txtPassword.placeholder}
      name="txtPassword"
      id="txtPassword"
      value={props.state.txtPassword.value}
      onChange={props.state.inputChange}
      className={props.state.txtPassword.className + " css-actorInput"}
      disabled={props.state.isPWDisabled}
      readOnly={props.state.txtPassword.readOnly}
      isInvalid={props.state.txtPassword.isInvalid}
      //isValid={props.state.txtPassword.isValid}
    />
    <Form.Label className="font-weight-bold">Confirm Password</Form.Label>
    <FormControl
      type="password"
      placeholder={props.state.txtConfirmPass.placeholder}
      name="txtConfirmPass"
      id="txtConfirmPass"
      value={props.state.txtConfirmPass.value}
      onChange={props.state.inputChange}
      disabled={props.state.isPWDisabled}
      readOnly={props.state.txtConfirmPass.readOnly}
      isInvalid={props.state.txtConfirmPass.isInvalid}
      //isValid={props.state.txtConfirmPass.isValid}
      className={props.state.txtConfirmPass.className + " css-actorInput"}
    />
    <p className="lblPasswordMsg mb-2">{props.state.txtPassword.errMsg}</p>
    {(() => {
      if (props.state.selectedOperation.id === 0) {
        return <> </>;
      } else {
        if (props.state.isPWEdit) {
        } else {
          return (
            <>
              <Button
                block
                name={props.state.btnEditPW.name}
                onClick={(e) => {
                  props.state.handleChangePW(e);
                }}
              >
                {props.state.btnEditPW.placeholder}
              </Button>
            </>
          );
        }
      }
    })()}
  </div>
);

let TxtEmail = (props) => (
  <>
    <Form.Label className="font-weight-bold">Email</Form.Label>
    <FormControl
      type="email"
      placeholder={props.state.txtEmail.placeholder}
      name="txtEmail"
      value={props.state.txtEmail.value}
      onChange={props.state.inputChange}
      className={props.state.txtEmail.className + " css-actorInput"}
      readOnly={props.state.txtEmail.readOnly}
    />
  </>
);

let TxtContact = (props) => (
  <>
    <Form.Label className="font-weight-bold">Contact</Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtContact.placeholder}
      name="txtContact"
      value={props.state.txtContact.value}
      onChange={props.state.inputChange}
      className={props.state.txtContact.className + " css-actorInput"}
      readOnly={props.state.txtContact.readOnly}
    />
  </>
);

let TxtAddress = (props) => (
  <>
    <Form.Label className="font-weight-bold">Address</Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtAddress.placeholder}
      name="txtAddress"
      value={props.state.txtAddress.value}
      onChange={props.state.inputChange}
      className={props.state.txtAddress.className + " css-actorInput"}
      readOnly={props.state.txtAddress.readOnly}
    />
  </>
);

let TxtWebsite = (props) => (
  <>
    <Form.Label className="font-weight-bold">Website</Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtWebsite.placeholder}
      name="txtWebsite"
      value={props.state.txtWebsite.value}
      onChange={props.state.inputChange}
      className={props.state.txtWebsite.className + " css-actorInput"}
      readOnly={props.state.txtWebsite.readOnly}
    />
  </>
);

let SelPermission = (props) => (
  <>
    <Card>
      <Card.Header className="font-weight-bold">Permissions</Card.Header>
      <Card.Body disabled>
        <Form.Check
          checked={props.state.chkAdmin.value}
          disabled={props.state.chkAdmin.readOnly}
          name="chkAdmin"
          label="Admin"
          onChange={(e) => {
            props.state.handlePermsChange(e);
          }}
        />
        <Form.Check
          checked={props.state.chkExecutive.value}
          disabled={props.state.chkExecutive.readOnly}
          name="chkExecutive"
          label="Executive"
          onChange={(e) => {
            props.state.handlePermsChange(e);
          }}
        />
        <Form.Check
          checked={props.state.chkProjectManager.value}
          disabled={props.state.chkProjectManager.readOnly}
          name="chkProjectManager"
          label="Project Manager"
          onChange={(e) => {
            props.state.handlePermsChange(e);
          }}
        />
        <Form.Check
          checked={props.state.chkSiteManager.value}
          disabled={props.state.chkSiteManager.readOnly}
          name="chkSiteManager"
          label="Site Manager"
          onChange={(e) => {
            props.state.handlePermsChange(e);
          }}
        />
        <Form.Check
          checked={props.state.chkPurchaser.value}
          disabled={props.state.chkPurchaser.readOnly}
          name="chkPurchaser"
          label="Purchaser"
          onChange={(e) => {
            props.state.handlePermsChange(e);
          }}
        />
        <Form.Check
          checked={props.state.chkRequester.value}
          disabled={props.state.chkRequester.readOnly}
          name="chkRequester"
          label="Requester"
          onChange={(e) => {
            props.state.handlePermsChange(e);
            console.log(e.target.checked);
          }}
        />
      </Card.Body>
    </Card>

    {/* <InputGroup>
      <FormControl
        as="select"
        className="form-control actor-selector "
        name="selPermission"
        value={props.state.selPermission.value}
        onChange={(e) => {
          props.state.inputChange(e);
        }}
        disabled={props.state.selPermission.readOnly}
      >
      
        <option value={4} key={4}>
          Executive
        </option>
        <option value={5} key={5}>
          Purchaser
        </option>
        <option value={6} key={6}>
          Site Manager
        </option>
        <option value={7} key={7}>
          Project Manager
        </option>
        <option value={8} key={8}>
          Requester
        </option>
        <option value={0} key={0}>
          Admin
        </option>
      </FormControl>
    </InputGroup> */}
  </>
);

function TxtFileUpload(props) {
  const [btnSave, setbtnSave] = useState(true);

  return (
    <>
      <Card className="">
        <Card.Header>
          <strong>Signature Upload</strong>
        </Card.Header>
        <Card.Body className="overflow-auto h-25">
          <h6 className="text-center font-weight-bold">
            *only upload 300x100 png files
          </h6>
          <InputGroup className="mb-3">
            <FormControl
              type="file"
              custom
              data-browse="Browse"
              className="inputFileUpload"
              onChange={(e) => {
                props.state.fileUpload.handler(e);
                console.log(Array.from(e.target.files));

                if (Array.from(e.target.files).length === 0) {
                  setbtnSave(true);
                } else {
                  setbtnSave(false);
                }
              }}
            />

            <ButtonGroup className="">
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name=""
                  size="sm"
                  className="btnSaveFileMultimat"
                  onClick={(e) => {
                    props.state.fileUpload.handleSubmit(e);
                  }}
                  disabled={btnSave}
                >
                  Save
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </InputGroup>
          {props.state.actorSelection
            ? props.state.actorSelection.uploads.map((i, index) => (
                <Row key={index}>
                  <Col key={index}>
                    <InputGroup>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">Remove</Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <Button
                            name={`delete${i.filename}`}
                            value={i.filename}
                            size="sm"
                            variant="danger"
                            className=""
                            onClick={(e) => {
                              props.state.deleteFile(
                                i.filename,
                                i.originalname
                              );
                            }}
                            style={{
                              borderRadius: 0,
                            }}
                          >
                            X
                          </Button>
                        </span>
                      </OverlayTrigger>
                      <Button
                        onClick={(e) => {
                          props.state.downloadFile({
                            name: i.filename,
                            complete: i.originalname,
                            id: props.state.actorSelection.uniqueId,
                          });
                          // props.state.handleActionChangeOrder(e, {
                          //   name: i.filename,
                          //   complete: i.originalname,
                          //   id: props.state.orderSelection.uniqueId,
                          // });
                        }}
                        name="btnDLLink"
                        key={index}
                        variant="link"
                      >
                        {i.originalname}
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              ))
            : ""}
        </Card.Body>
      </Card>
    </>
  );
}

const Actor = (props) => (
  <>
    <Row className="page-selectedUser-row">
      <h4>{props.state.selectedActor.name} Management</h4>
    </Row>
    <Row className="page-date-row">
      <h4>
        Date:{" "}
        <u className="actor-date-date">{moment().format("MMMM Do YYYY")}</u>
      </h4>
    </Row>

    <Row className="row-crud" noGutters={true}>
      <Col md="auto" lg="auto" className="options-column">
        <InputGroup className="">
          <InputGroup.Prepend>
            <InputGroup.Text className="actor-ico-search">
              <i className="icofont-search icofont-1x"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search"
            aria-label="Username"
            name="searchInput"
            className="actor-searchTable shadow-none"
            aria-describedby="basic-addon1"
            onChange={props.state.handleSearch}
          />
        </InputGroup>
      </Col>
      <Col md="auto" lg="auto" className="user-select">
        <InputGroup>
          <select
            className="form-control actor-selector w-auto"
            name="actorSelect"
            value={props.value}
            onChange={props.state.handleActorChange}
          >
            {(() => {
              if (props.userType.includes(0)) {
                return (
                  <>
                    <option value="1">User</option>
                  </>
                );
              }
            })()}
            <option value="3">Supplier</option>
            <option value="4">Items</option>
            <option value="5">Units</option>
          </select>
        </InputGroup>
      </Col>
      {/* <Col md="auto" lg="auto" className="permission-crud">
        <Col className="actor-btn-crud-inner-col">
          <Button
            variant="secondary"
            name="btnAddActor"
            className="actor-btn-crud"
            onClick={(e) => {}}
          >
            PERMISSIONS
          </Button>
        </Col>
      </Col> */}
      <Col className="actor-btn-crud-col d-flex justify-content-end">
        <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`actor-tooltip-add`}>ADD</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnAddActor"
              className="btnAddActor actor-btn-crud"
              onClick={(e) => {
                props.handleShowModal();
                props.state.handleActionChange(e);
              }}
            >
              <i className="icofont-ui-add icofont-1x"></i>
            </Button>
          </OverlayTrigger>

          {/* <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`actor-tooltip-edit`}>EDIT</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnEditActor"
              className="actor-btn-crud btnEditActor"
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              <i className="icofont-ui-edit icofont-1x"></i>
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`actor-tooltip-delete`}>DELETE</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnDeleteActor"
              className="actor-btn-crud btnDeleteActor"
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              <i className="icofont-ui-remove icofont-1x"></i>
            </Button>
          </OverlayTrigger> */}
        </ButtonGroup>
      </Col>
    </Row>

    <Modal
      show={props.state.handleActorModal.isShow}
      onHide={props.state.handleActorModal.hide}
      onExit={(e) => {
        props.state.handleActorModal.handleExit(e);
      }}
      dialogClassName="modal-actor-100w"
      //size="xl"
      centered
    >
      <Modal.Body className="actor-modal-body">
        <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
          <Col xs={2} sm={1} md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseProjectModal"
                className="btnAddProject project-btn-crud"
                onClick={(e) => {
                  props.state.handleActorModal.hide();
                }}
              >
                <i className="icofont-rounded-left"></i>
              </Button>
            </OverlayTrigger>
          </Col>
          <Col
            xs={10}
            sm={11}
            md={11}
            lg={11}
            className="actor-modal-header-col"
          >
            {(() => {
              if (props.state.handleActorModal.isView) {
                return (
                  <h4 className=" actor-modal-header-text">
                    {"View "}
                    {props.state.selectedActor.name}
                  </h4>
                );
              } else {
                return (
                  <h4 className=" actor-modal-header-text">
                    {props.state.selectedOperation.name}{" "}
                    {props.state.selectedActor.name}
                  </h4>
                );
              }
            })()}
          </Col>
        </Row>
        <Form
          id="formActorInput"
          noValidate
          validated={props.state.handleActorModal.isValidated}
          onSubmit={props.state.handleActorModal.handleSubmit}
        >
          {(() => {
            switch (props.state.selectedActor.id) {
              case 1:
                return (
                  <Row>
                    <Col className="">
                      <TxtActorName state={props.state} name="Full name" />
                      <TxtUserName state={props.state} />
                      <TxtEmail state={props.state} />
                      <TxtContact state={props.state} />
                      <TxtAddress state={props.state} />
                    </Col>

                    <Col className="user-col-cont-padding">
                      {(() => {
                        if (props.state.handleActorModal.isView) {
                        } else {
                          return <TxtPassword state={props.state} />;
                        }
                      })()}
                      <SelPermission state={props.state} />
                    </Col>
                    <Col className="user-col-cont-padding">
                      <TxtFileUpload state={props.state} />
                    </Col>
                  </Row>
                );
              case 2:
                return (
                  <>
                    <TxtActorName state={props.state} />
                    <TxtEmail state={props.state} />
                    <TxtContact state={props.state} />
                    <TxtAddress state={props.state} />
                    <TxtWebsite state={props.state} />
                  </>
                );
              case 3:
                return (
                  <>
                    <TxtActorName state={props.state} name="Supplier name" />
                    <TxtEmail state={props.state} />
                    <TxtContact state={props.state} />
                    <TxtAddress state={props.state} />
                    <TxtWebsite state={props.state} />
                  </>
                );
              case 4:
                return (
                  <>
                    <TxtActorName state={props.state} name="Item name" />
                  </>
                );
              case 5:
                return (
                  <>
                    <TxtActorName state={props.state} name="Unit name" />
                  </>
                );

              default:
                return "Employee";
            }
          })()}
          <Row
            className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
            noGutters
          >
            <Col md={6} lg={6} className="">
              <p className="font-weight-bold">Note:</p>
              <p className="">
                Fields that has asterisk (
                <span className="required-field font-weight-bold"> * </span>)
                requires an input.
              </p>
            </Col>
            <Col md={2} lg={2} className="user-col-cont-padding">
              <Button
                block
                className="actor-btn-crud btnEditActor"
                name="btnUnlockActor"
                disabled={!props.state.handleActorModal.isView}
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                MODIFY
              </Button>
            </Col>
            <Col md={2} lg={2} className="user-col-cont-padding">
              <Button
                block
                className="actor-btn-crud btnSaveActor"
                name="btnSubmitActor"
                disabled={props.state.handleActorModal.isView}
                type="submit"
                // onClick={(e) => {
                //   props.state.handleSubmit(e);
                //   props.handleHideModal(e);
                // }}
              >
                SAVE
              </Button>
            </Col>
            <Col md={2} lg={2} className="user-col-cont-padding">
              <Button
                block
                className="actor-btn-crud btnDeleteActor"
                name="btnDeleteActor"
                disabled={
                  props.state.handleActorModal.isView ||
                  Number(props.state.selectedOperation.id) === 0
                }
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                DELETE
              </Button>
            </Col>
          </Row>
          {/* {(() => {
            if (props.state.handleActorModal.isView) {
              return <Row className="mb-3"></Row>;
            } else {
              return (
                
              );
            }
          })()} */}
        </Form>
      </Modal.Body>
    </Modal>
  </>
);

export {
  Actor,
  employeeColumn,
  supplierColumn,
  customerColumn,
  itemColumn,
  unitColumn,
};
