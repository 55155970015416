import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "moment";
import DBStatus from "../../functions/DBStatus";
//import ProgressBar from "react-bootstrap/ProgressBar";
import { NumericFormat } from "react-number-format";
import Dropdown from "react-bootstrap/Dropdown";
import misc from "../../functions/misc";
import "../../css/project.css";

function projectColumn(userType) {
  return [
    {
      Header: "Projects",
      Cell: (row) => (
        <Row className="project-tc-cont my-2" noGutters>
          <Col md={2} lg={2}>
            <Form.Label
              className="text-center proj-stat-label m-0"
              style={{
                background:
                  (row && Number(row.original.status) === 1 && "#DFE24A") ||
                  (row && Number(row.original.status) === 2 && "#DFE24A") ||
                  (row && Number(row.original.status) === 3 && "#DFE24A") ||
                  (row && Number(row.original.status) === 4 && "#E24A4A") ||
                  (row && Number(row.original.status) === 5 && "#E24A4A") ||
                  (row && Number(row.original.status) === 6 && "#4A87E2") ||
                  (row && Number(row.original.status) === 7 && "#5DB55D") ||
                  (row && Number(row.original.status) === 8 && "#E2934A"),
              }}
            >
              {(() => {
                return DBStatus.parseProjectStatus(row.original.status);
              })()}
            </Form.Label>
          </Col>
          <Col className="pl-1" md={10} lg={10}>
            <Row>
              <Col md={4}>
                <InputGroup className="project-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="project-tc-labels">
                      Start
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <p>
                    {(() => {
                      if (
                        row.original.dateStart === null ||
                        row.original.dateStart === ""
                      ) {
                        return "Empty";
                      } else {
                        return moment(
                          row.original.dateStart,
                          "YYYY-MM-DD"
                        ).format("LL");
                      }
                    })()}
                  </p>
                  {/* <FormControl
                    readOnly
                    disabled
                    className="project-tc-txtbox"
                    value={(() => {
                      if (
                        row.original.dateStart === null ||
                        row.original.dateStart === ""
                      ) {
                        return "Empty";
                      } else {
                        return moment(
                          row.original.dateStart,
                          "YYYY-MM-DD"
                        ).format("LL");
                      }
                    })()}
                  /> */}
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="project-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="project-tc-labels">
                      Name
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {/* <FormControl
                    readOnly
                    disabled
                    className="project-tc-txtbox"
                    value={(() => {
                      return row.original.name === null
                        ? ""
                        : row.original.name;
                    })()}
                  /> */}
                  <p>
                    {(() => {
                      return row.original.name === null
                        ? ""
                        : row.original.name;
                    })()}
                  </p>
                </InputGroup>
              </Col>
              <Col md={4}>
                {(() => {
                  if (userType.includes(0) || userType.includes(7)) {
                    return (
                      <InputGroup className="project-tc-ig">
                        <InputGroup.Prepend>
                          <InputGroup.Text className="project-tc-labels">
                            Budget
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        {/* <FormControl
                          readOnly
                          disabled
                          className="project-tc-txtbox"
                          value={(() => {
                            return row && row.original.budget === null
                              ? ""
                              : misc.parseCurrencyNoCurrency(
                                  row?.original?.budget
                                );
                          })()}
                        /> */}
                        <p>
                          {(() => {
                            return row && row.original.budget === null
                              ? ""
                              : misc.parseCurrencyNoCurrency(
                                  row?.original?.budget
                                );
                          })()}
                        </p>
                      </InputGroup>
                    );
                  }
                })()}
              </Col>
              <Col md={4}>
                <InputGroup className="project-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="project-tc-labels">
                      End
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {/* <FormControl
                    readOnly
                    disabled
                    className="project-tc-txtbox"
                    value={(() => {
                      if (
                        row.original.dateEstEnd === null ||
                        row.original.dateEstEnd === ""
                      ) {
                        return "Empty";
                      } else {
                        return moment(
                          row.original.dateEstEnd,
                          "YYYY-MM-DD"
                        ).format("LL");
                      }
                    })()}
                  /> */}
                  <p>
                    {(() => {
                      if (
                        row.original.dateEstEnd === null ||
                        row.original.dateEstEnd === ""
                      ) {
                        return "Empty";
                      } else {
                        return moment(
                          row.original.dateEstEnd,
                          "YYYY-MM-DD"
                        ).format("LL");
                      }
                    })()}
                  </p>
                </InputGroup>
              </Col>
              <Col md={4}>
                <InputGroup className="project-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="project-tc-labels">
                      Jobsite
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {/* <FormControl
                    readOnly
                    disabled
                    className="project-tc-txtbox"
                    value={(() => {
                      return row.original.address === null
                        ? ""
                        : row.original.address;
                    })()}
                  /> */}
                  <p>
                    {(() => {
                      return row.original.address === null
                        ? ""
                        : row.original.address;
                    })()}
                  </p>
                </InputGroup>
              </Col>

              <Col md={4}>
                <InputGroup className="project-tc-ig">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="project-tc-labels">
                      ID
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <p>
                    {(() => {
                      return row.original.uniqueId === null
                        ? ""
                        : row.original.uniqueId;
                    })()}
                  </p>
                  {/* <FormControl
                    readOnly
                    disabled
                    className="project-tc-txtbox"
                    value={(() => {
                      return row.original.uniqueId === null
                        ? ""
                        : row.original.uniqueId;
                    })()}
                  /> */}
                </InputGroup>
                {/* <ProgressBar
                  className="project-percentage "
                  now={
                    row.original.purchasedPercentage === null ||
                    row.original.purchasedPercentage === "NaN%"
                      ? 0
                      : Number(row.original.purchasedPercentage.replace("%", ""))
                  }
                  label={`${
                    row.original.purchasedPercentage === null ||
                    row.original.purchasedPercentage === "NaN%"
                      ? "0%"
                      : row.original.purchasedPercentage
                  } `}
                /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
  ];
}

// const projectColumn = [
//   {
//     Header: "Projects",
//     Cell: (row) => (
//       <Row className="project-tc-cont" noGutters>
//         <Col md={2} lg={2}>
//           <Form.Label
//             className="text-center proj-stat-label m-0"
//             style={{
//               background:
//                 (row && Number(row.original.status) === 1 && "#DFE24A") ||
//                 (row && Number(row.original.status) === 2 && "#DFE24A") ||
//                 (row && Number(row.original.status) === 3 && "#DFE24A") ||
//                 (row && Number(row.original.status) === 4 && "#E24A4A") ||
//                 (row && Number(row.original.status) === 5 && "#E24A4A") ||
//                 (row && Number(row.original.status) === 6 && "#4A87E2") ||
//                 (row && Number(row.original.status) === 7 && "#5DB55D") ||
//                 (row && Number(row.original.status) === 8 && "#E2934A"),
//             }}
//           >
//             {(() => {
//               return DBStatus.parseProjectStatus(row.original.status);
//             })()}
//           </Form.Label>
//         </Col>
//         <Col md={10} lg={10}>
//           <Row>
//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     Start
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     if (
//                       row.original.dateStart === null ||
//                       row.original.dateStart === ""
//                     ) {
//                       return "Empty";
//                     } else {
//                       return moment(
//                         row.original.dateStart,
//                         "YYYY-MM-DD"
//                       ).format("LL");
//                     }
//                   })()}
//                 />
//               </InputGroup>
//             </Col>
//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     Name
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     return row.original.name === null ? "" : row.original.name;
//                   })()}
//                 />
//               </InputGroup>
//             </Col>
//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     Budget
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     return row && row.original.budget === null
//                       ? ""
//                       : new Intl.NumberFormat("en-US").format(
//                           row.original.budget
//                         );
//                   })()}
//                 />
//               </InputGroup>
//             </Col>
//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     End
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     if (
//                       row.original.dateEstEnd === null ||
//                       row.original.dateEstEnd === ""
//                     ) {
//                       return "Empty";
//                     } else {
//                       return moment(
//                         row.original.dateEstEnd,
//                         "YYYY-MM-DD"
//                       ).format("LL");
//                     }
//                   })()}
//                 />
//               </InputGroup>
//             </Col>
//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     Jobsite
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     return row.original.address === null
//                       ? ""
//                       : row.original.address;
//                   })()}
//                 />
//               </InputGroup>
//             </Col>

//             <Col md={4}>
//               <InputGroup className="project-tc-ig">
//                 <InputGroup.Prepend>
//                   <InputGroup.Text className="project-tc-labels">
//                     ID
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 <FormControl
//                   readOnly
//                   disabled
//                   className="project-tc-txtbox"
//                   value={(() => {
//                     return row.original.uniqueId === null
//                       ? ""
//                       : row.original.uniqueId;
//                   })()}
//                 />
//               </InputGroup>
//               {/* <ProgressBar
//                 className="project-percentage "
//                 now={
//                   row.original.purchasedPercentage === null ||
//                   row.original.purchasedPercentage === "NaN%"
//                     ? 0
//                     : Number(row.original.purchasedPercentage.replace("%", ""))
//                 }
//                 label={`${
//                   row.original.purchasedPercentage === null ||
//                   row.original.purchasedPercentage === "NaN%"
//                     ? "0%"
//                     : row.original.purchasedPercentage
//                 } `}
//               /> */}
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     ),
//   },
// ];

let TxtProjectName = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Project Name
      </strong>
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtProjectName.placeholder}
      name="txtProjectName"
      value={props.state.txtProjectName.value}
      onChange={props.state.inputChange}
      className={props.state.txtProjectName.className + " css-projectInput"}
      readOnly={props.state.txtProjectName.readOnly}
      required
    />
  </>
);
let TxtAddress = (props) => (
  <>
    <Form.Label>
      <strong>Address</strong>
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtAddress.placeholder}
      name="txtAddress"
      value={props.state.txtAddress.value}
      onChange={props.state.inputChange}
      className={props.state.txtAddress.className + " css-projectInput"}
      readOnly={props.state.txtAddress.readOnly}
    />
  </>
);
let TxtDescription = (props) => (
  <>
    <Form.Label>
      <strong>Description</strong>
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder={props.state.txtDescription.placeholder}
      name="txtDescription"
      value={props.state.txtDescription.value}
      onChange={props.state.inputChange}
      className={props.state.txtDescription.className + " css-projectInput"}
      readOnly={props.state.txtDescription.readOnly}
    />
  </>
);
let TxtNote = (props) => (
  <>
    <Form.Label className="font-weight-bold">Notes</Form.Label>
    <FormControl
      as="textarea"
      placeholder={props.state.txtNote.placeholder}
      name="txtNote"
      value={props.state.txtNote.value}
      onChange={props.state.inputChange}
      className={props.state.txtNote.className + " css-projectInput"}
      readOnly={props.state.txtNote.readOnly}
    />
  </>
);
let TxtBudget = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Budget
      </strong>
    </Form.Label>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      prefix={"₱"}
      placeholder={props.state.txtBudget.placeholder}
      name="txtBudget"
      value={props.state.txtBudget.value}
      onValueChange={(x) => {
        props.state.inputChange({
          target: {
            value: Number(x.value),
            name: "txtBudget",
            placeholder: props.state.txtBudget.placeholder,
            className: props.state.txtBudget.className,
          },
        });
      }}
      className={props.state.txtBudget.className + " css-projectInput"}
      readOnly={props.state.txtBudget.readOnly}
      required
    />
  </>
);
let TxtDateLastModified = (props) => (
  <>
    <Form.Label>
      <strong>Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDateLastModified.placeholder}
      name="txtDateLastModified"
      value={props.state.txtDateLastModified.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModified.className + " css-projectInput"
      }
      readOnly={props.state.txtDateLastModified.readOnly}
    />
  </>
);
let TxtLastModifiedBy = (props) => (
  <>
    <Form.Label>
      {" "}
      <strong>Last Modified By</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtLastModifiedBy.placeholder}
      name="txtLastModifiedBy"
      value={props.state.txtLastModifiedBy.value}
      onChange={props.state.inputChange}
      className={props.state.txtLastModifiedBy.className + " css-projectInput"}
      readOnly={props.state.txtLastModifiedBy.readOnly}
    />
  </>
);
let TxtDatePosted = (props) => (
  <>
    <Form.Label>
      <strong>Date Posted</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDatePosted.placeholder}
      name="txtDatePosted"
      value={props.state.txtDatePosted.value}
      onChange={props.state.inputChange}
      className={props.state.txtDatePosted.className + " css-projectInput"}
      readOnly={props.state.txtDatePosted.readOnly}
    />
  </>
);
let TxtProjUniqueId = (props) => (
  <>
    <Form.Label>
      <strong>ID</strong>
    </Form.Label>
    <FormControl
      type="text"
      placeholder={props.state.txtProjUniqueID.placeholder}
      name="txtProjUniqueID"
      value={props.state.txtProjUniqueID.value}
      onChange={props.state.inputChange}
      className={props.state.txtProjUniqueID.className + " css-projectInput"}
      readOnly={props.state.txtProjUniqueID.readOnly}
    />
  </>
);
let TxtDateStart = (props) => (
  <>
    <Form.Label>
      <strong>
        <span className="required-field">* </span>Date Started
      </strong>
    </Form.Label>
    <FormControl
      type="date"
      placeholder={props.state.txtDateStart.placeholder}
      name="txtDateStart"
      value={props.state.txtDateStart.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateStart.className + " css-projectInput"}
      readOnly={props.state.txtDateStart.readOnly}
      required
    />
  </>
);
let TxtDateEnd = (props) => (
  <>
    <Form.Label>
      <strong>Date Ended</strong>
    </Form.Label>
    <FormControl
      type="date"
      placeholder={props.state.txtDateEnd.placeholder}
      name="txtDateEnd"
      value={props.state.txtDateEnd.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateEnd.className + " css-projectInput"}
      readOnly={props.state.txtDateEnd.readOnly}
    />
  </>
);
let TxtDateEstEnd = (props) => (
  <>
    <Form.Label>
      <strong>Deadline</strong>
    </Form.Label>
    <FormControl
      type="date"
      placeholder={props.state.txtDateEstEnd.placeholder}
      name="txtDateEstEnd"
      value={props.state.txtDateEstEnd.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateEstEnd.className + " css-projectInput"}
      readOnly={props.state.txtDateEstEnd.readOnly}
    />
  </>
);
let TxtStatus = (props) => (
  <>
    <Form.Label>
      <strong>Project Status</strong>
    </Form.Label>
    <FormControl
      as="select"
      value={props.state.projectStatusSelect.value}
      name="projectStatusSelect"
      className="mb-3"
      disabled={props.state.projectStatusSelect.readOnly}
      onChange={(e) => {
        props.state.inputChange(e);
      }}
    >
      <option value={1} key={1}>
        Pending Quotataion
      </option>
      <option value={2} key={2}>
        Pending Admin Confirmation
      </option>
      <option value={3} key={3}>
        Pending Client Confirmation
      </option>
      <option value={4} key={4}>
        Admin Denied Quote
      </option>
      <option value={5} key={5}>
        Customer Denied Quote
      </option>
      <option value={6} key={6}>
        Ongoing
      </option>
      <option value={7} key={7}>
        Finished
      </option>
      <option value={8} key={8}>
        Cancelled
      </option>
    </FormControl>
  </>
);

function TxtDelegate(props) {
  const [addIsDisabled, setaddIsDisabled] = useState(false);
  const [saveIsDisabled, setsaveIsDisabled] = useState(true);
  const [cancelIsDisabled, setcancelIsDisabled] = useState(true);
  const [actorDropIsDisabled, setactorDropIsDisabled] = useState(true);

  return (
    <div className="mb-3">
      <Form.Label>
        <strong>Delegation</strong>
      </Form.Label>
      <InputGroup>
        <InputGroup.Prepend style={{ flex: 1 }}>
          <FormControl
            as="select"
            value={props.state.projectDelegateTypeSelect.value}
            name="projectDelegateTypeSelect"
            className=""
            disabled={props.state.projectDelegateTypeSelect.readOnly}
            onChange={(e) => {
              //console.log(e.target.value);
              props.state.inputChange(e);
              props.state.readActor(e.target.value);
            }}
          >
            <option value={0} key={0} disabled hidden>
              None
            </option>
            <option value={5} key={1}>
              Purchaser
            </option>
            <option value={6} key={2}>
              Site Manager
            </option>
            <option value={7} key={3}>
              Project Manager
            </option>
            <option value={8} key={4}>
              Requester
            </option>
          </FormControl>
        </InputGroup.Prepend>
        <Dropdown
          drop="left"
          className="customDropCont"
          onToggle={(isOpen, event, metadata) => {
            if (!isOpen) {
              setsaveIsDisabled(true);
              setcancelIsDisabled(true);
              setaddIsDisabled(false);
              setactorDropIsDisabled(true);
            }
          }}
        >
          <Dropdown.Toggle
            variant="success"
            className="customDropToggle"
            disabled={props.state.projectDelegateTypeSelect.readOnly}
            id={`dropdown-btn-projectDelegateActorSelect`}
          >
            <i className="icofont-navigation-menu "></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="customDropMenu" style={{ width: "100px" }}>
            <div id="example-collapse-text">
              <Form.Label className="mb-3">Modify Delegation</Form.Label>
              <InputGroup>
                <FormControl
                  as="select"
                  value={props.state.projectDelegateActorSelect.value}
                  name="projectDelegateActorSelect"
                  className={props.state.projectDelegateActorSelect.className}
                  disabled={actorDropIsDisabled}
                  onChange={(e) => {
                    props.state.inputChange(e);
                  }}
                >
                  {props.state.actorData.map((dat, index) => (
                    <option key={dat.id} value={dat.id}>
                      {dat.name}
                    </option>
                  ))}
                  <option value={0} key={0} disabled hidden>
                    Select User
                  </option>
                </FormControl>

                <InputGroup.Prepend>
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Assign</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button
                        onClick={(e) => {
                          if (Number(props.state.actorData.length) === 0) {
                            props.dispatch({
                              type: "MODAL_SHOW",
                              payload: {
                                title: "Data Empty",
                                desc: `No users on selected type`,
                                isYesNo: false,
                              },
                            });
                          } else {
                            setactorDropIsDisabled(false);
                            setsaveIsDisabled(false);
                            setcancelIsDisabled(false);
                            setaddIsDisabled(true);
                          }

                          //this.state.selCustDrop.handler(e);
                        }}
                        name="add"
                        hidden={addIsDisabled}
                        className="btnCustAdd"
                        style={{
                          borderRadius: 0,
                        }}
                      >
                        <i className="icofont-ui-add "></i>
                      </Button>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Save</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button
                        onClick={(e) => {
                          setsaveIsDisabled(true);
                          setcancelIsDisabled(true);
                          setaddIsDisabled(false);
                          setactorDropIsDisabled(true);
                          props.state.addProjectActor(e);
                        }}
                        name="save"
                        variant="success"
                        className="btnCustSave"
                        hidden={saveIsDisabled}
                        style={{
                          borderRadius: 0,
                        }}
                      >
                        <i className="icofont-contact-add"></i>
                      </Button>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Cancel</Tooltip>}
                  >
                    <span className="d-inline-block">
                      <Button
                        variant="warning"
                        className="btnCustCancel"
                        onClick={(e) => {
                          setsaveIsDisabled(true);
                          setcancelIsDisabled(true);
                          setaddIsDisabled(false);
                          setactorDropIsDisabled(true);
                          //this.state.selCustDrop.handler(e);
                        }}
                        name="cancel"
                        style={{
                          borderRadius: 0,
                        }}
                        hidden={cancelIsDisabled}
                      >
                        <i className="icofont-close "></i>
                      </Button>
                    </span>
                  </OverlayTrigger>
                </InputGroup.Prepend>
              </InputGroup>
              <div className="mt-2">
                <h6 className="font-weight-bold">Project manager</h6>
                {Number(props.state.selection.length) > 0
                  ? props.state.projectSelection.projectActors[7].actors.map(
                      (i, index) => (
                        <Row key={index}>
                          <Col key={index}>
                            <InputGroup>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Remove
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <Button
                                    onClick={(e) => {
                                      setsaveIsDisabled(true);
                                      setcancelIsDisabled(true);
                                      setaddIsDisabled(false);
                                      setactorDropIsDisabled(true);

                                      props.state.deleteProjectActor(i.id);
                                    }}
                                    name={`delete${i.id}`}
                                    value={i.id}
                                    size="sm"
                                    variant="danger"
                                    className=""
                                    style={{
                                      borderRadius: 0,
                                    }}
                                  >
                                    X
                                  </Button>
                                </span>
                              </OverlayTrigger>
                              <p className="pl-2"> {i.name}</p>
                            </InputGroup>
                          </Col>
                        </Row>
                      )
                    )
                  : ""}
                <hr />
                <h6 className="font-weight-bold">Site manager</h6>
                {Number(props.state.selection.length) > 0
                  ? props.state.projectSelection.projectActors[6].actors.map(
                      (i, index) => (
                        <Row key={index}>
                          <Col key={index}>
                            <InputGroup>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Remove
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <Button
                                    onClick={(e) => {
                                      setsaveIsDisabled(true);
                                      setcancelIsDisabled(true);
                                      setaddIsDisabled(false);
                                      setactorDropIsDisabled(true);

                                      props.state.deleteProjectActor(i.id);
                                    }}
                                    name={`delete${i.id}`}
                                    value={i.id}
                                    size="sm"
                                    variant="danger"
                                    className=""
                                    style={{
                                      borderRadius: 0,
                                    }}
                                  >
                                    X
                                  </Button>
                                </span>
                              </OverlayTrigger>
                              <p className="pl-2"> {i.name}</p>
                            </InputGroup>
                          </Col>
                        </Row>
                      )
                    )
                  : ""}
                <hr />
                <h6 className="font-weight-bold">Requester</h6>
                {Number(props.state.selection.length) > 0
                  ? props.state.projectSelection.projectActors[8].actors.map(
                      (i, index) => (
                        <Row key={index}>
                          <Col key={index}>
                            <InputGroup>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Remove
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <Button
                                    onClick={(e) => {
                                      setsaveIsDisabled(true);
                                      setcancelIsDisabled(true);
                                      setaddIsDisabled(false);
                                      setactorDropIsDisabled(true);

                                      props.state.deleteProjectActor(i.id);
                                    }}
                                    name={`delete${i.id}`}
                                    value={i.id}
                                    size="sm"
                                    variant="danger"
                                    className=""
                                    style={{
                                      borderRadius: 0,
                                    }}
                                  >
                                    X
                                  </Button>
                                </span>
                              </OverlayTrigger>
                              <p className="pl-2"> {i.name}</p>
                            </InputGroup>
                          </Col>
                        </Row>
                      )
                    )
                  : ""}
                <hr />
                <h6 className="font-weight-bold">Purchaser</h6>
                {Number(props.state.selection.length) > 0
                  ? props.state.projectSelection.projectActors[5].actors.map(
                      (i, index) => (
                        <Row key={index}>
                          <Col key={index}>
                            <InputGroup>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Remove
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <Button
                                    onClick={(e) => {
                                      setsaveIsDisabled(true);
                                      setcancelIsDisabled(true);
                                      setaddIsDisabled(false);
                                      setactorDropIsDisabled(true);

                                      props.state.deleteProjectActor(i.id);
                                    }}
                                    name={`delete${i.id}`}
                                    value={i.id}
                                    size="sm"
                                    variant="danger"
                                    className=""
                                    style={{
                                      borderRadius: 0,
                                    }}
                                  >
                                    X
                                  </Button>
                                </span>
                              </OverlayTrigger>
                              <p className="pl-2"> {i.name}</p>
                            </InputGroup>
                          </Col>
                        </Row>
                      )
                    )
                  : ""}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup>
    </div>
  );
}

const Project = (props) => (
  <>
    <Row className="page-selectedProject-row">
      <h4>Project Management</h4>
    </Row>
    <Row className="page-date-row">
      <h4>
        Date:{" "}
        <u className="project-date-date">{moment().format("MMMM Do YYYY")}</u>
      </h4>
    </Row>

    <Row className="row-crud" noGutters={true}>
      <Col md="auto" lg="auto" className="options-column">
        <InputGroup className="">
          <InputGroup.Prepend>
            <InputGroup.Text className="ico-search">
              <i className="icofont-search icofont-1x"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search"
            aria-label="Username"
            name="searchInput"
            className="searchTable shadow-none "
            aria-describedby="basic-addon1"
            onChange={props.state.handleSearch}
          />
        </InputGroup>
      </Col>
      <Col md="auto" lg="auto" className="project-select">
        <InputGroup>
          <select
            className="form-control project-selector w-auto rounded-0"
            name="projectSelect"
            value={props.value}
            onChange={(x) => {
              props.state.handleStatusChange(x);
            }}
          >
            <option value={0} key={0}>
              All
            </option>
            <option value={1} key={1}>
              Pending Quotataion
            </option>
            <option value={2} key={2}>
              Pending Admin Confirmation
            </option>
            <option value={3} key={3}>
              Pending Client Confirmation
            </option>
            <option value={4} key={4}>
              Admin Denied Quote
            </option>
            <option value={5} key={5}>
              Client Denied Quote
            </option>
            <option value={6} key={6}>
              Ongoing
            </option>
            <option value={7} key={7}>
              Finished
            </option>
            <option value={8} key={8}>
              Cancelled
            </option>
          </select>
        </InputGroup>
      </Col>

      <Col className="project-btn-crud-col d-flex justify-content-end">
        <ButtonGroup className="">
          {(() => {
            //let t = Number(props.userType);
            if (props.userType.includes(0) || props.userType.includes(7)) {
              return (
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip id={`project-tooltip-add`}>ADD</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    name="btnAddProject"
                    className="btnAddProject project-btn-crud rounded-0"
                    onClick={(e) => {
                      props.handleShowModal();
                      props.state.handleActionChange(e);
                    }}
                  >
                    <i className="icofont-ui-add icofont-1x"></i>
                  </Button>
                </OverlayTrigger>
              );
            }
          })()}
        </ButtonGroup>
      </Col>
    </Row>
    <Row className="mb-2" noGutters>
      <Col md={2} lg={2}>
        <Form.Check
          checked={props.state.chkDateFilter.value}
          disabled={props.state.chkDateFilter.readOnly}
          name="chkDateFilter"
          className="chkDateFilter"
          label="Filter By Date"
          onChange={(e) => {
            props.state.handleCheckFilterChange(e);
          }}
        />
      </Col>
      <Col md="auto" lg="auto">
        <InputGroup className="txtFiltDateStartCont pr-2">
          <InputGroup.Prepend>
            <InputGroup.Text className="font-weight-bold rounded-0">
              From
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            name="txtFiltDateStart"
            type="Date"
            value={props.state.txtFiltDateStart.value}
            onChange={props.state.dateChange}
            disabled={props.state.txtFiltDateStart.readOnly}
            className={"txtFiltDateStart rounded-0"}
          />
        </InputGroup>
      </Col>
      <Col md="auto" lg="auto">
        <InputGroup className="txtFiltDateEndCont">
          <InputGroup.Prepend>
            <InputGroup.Text className="font-weight-bold rounded-0">
              To
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            name="txtFiltDateEnd"
            type="Date"
            className={"txtFiltDateEnd rounded-0"}
            value={props.state.txtFiltDateEnd.value}
            onChange={props.state.dateChange}
            disabled={props.state.txtFiltDateEnd.readOnly}
          />
        </InputGroup>
      </Col>
    </Row>

    <Modal
      show={props.state.handleProjectModal.isShow}
      onHide={props.state.handleProjectModal.hide}
      onExit={(e) => {
        props.state.handleProjectModal.handleExit(e);
      }}
      dialogClassName="modal-project-100w"
      centered
    >
      {/* <Modal.Header className="project-modal-header-cont">
        
      </Modal.Header> */}
      <Modal.Body className="project-modal-body">
        <Row className="project-modal-header" noGutters>
          <Col md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseProjectModal"
                className="btnAddProject project-btn-crud"
                onClick={(e) => {
                  props.handleHideModal();
                }}
              >
                <i className="icofont-rounded-left"></i>
              </Button>
            </OverlayTrigger>
          </Col>
          <Col md={9} lg={9}>
            {(() => {
              if (props.state.handleProjectModal.isView) {
                return (
                  <h4 className=" project-modal-header-text">
                    Project Information
                  </h4>
                );
              } else {
                return (
                  <h4 className=" project-modal-header-text">
                    {props.state.selectedOperation.name} Project
                  </h4>
                );
              }
            })()}
          </Col>
          <Col md={2} lg={2} className="d-flex justify-content-end">
            {Number(props.state.selectedOperation.id) !== 0 ? (
              <Button
                name="btnViewPhases"
                className="project-btn-crud btnViewPhases"
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                View Phases
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Form
          id="formProjectInput"
          noValidate
          validated={props.state.handleProjectModal.isValidated}
          onSubmit={props.state.handleProjectModal.handleSubmit}
        >
          <Row noGutters className="mt-3">
            <Col className="project-col-cont-padding-left">
              {(() => {
                if (Number(props.state.selectedOperation.id) !== 0) {
                  return (
                    <>
                      <TxtProjUniqueId state={props.state} />
                    </>
                  );
                }
              })()}

              <TxtProjectName state={props.state} />
              <TxtAddress state={props.state} />
              <TxtDescription state={props.state} />

              {(() => {
                if (props.userType.includes(0) || props.userType.includes(7)) {
                  return <TxtBudget state={props.state} />;
                }
              })()}

              {(() => {
                if (Number(props.state.selectedOperation.id) !== 0) {
                  return (
                    <>
                      <TxtLastModifiedBy state={props.state} />
                    </>
                  );
                }
              })()}
            </Col>
            <Col className="">
              <TxtDateStart state={props.state} />
              <TxtDateEstEnd state={props.state} />
              <TxtDateEnd state={props.state} />
              {/* {(()=>{})()} */}

              {(() => {
                if (Number(props.state.selectedOperation.id) !== 0) {
                  return (
                    <>
                      <TxtStatus state={props.state} />
                      <TxtDatePosted state={props.state} />
                      <TxtDateLastModified state={props.state} />
                    </>
                  );
                }
              })()}
            </Col>

            {(() => {
              if (Number(props.state.selectedOperation.id) !== 0) {
                return (
                  <Col className="project-col-cont-padding-right">
                    <TxtDelegate
                      state={props.state}
                      dispatch={props.dispatch}
                    />
                    <div className="mt-2">
                      <h6 className="font-weight-bold">Project manager</h6>
                      {Number(props.state.projectSelection.id) !== -1
                        ? props.state.projectSelection.projectActors[7].actors.map(
                            (i, index) => (
                              <Row key={index}>
                                <Col key={index}>
                                  <InputGroup>
                                    <p className="pl-2"> {i.name}</p>
                                  </InputGroup>
                                </Col>
                              </Row>
                            )
                          )
                        : ""}
                      <hr />
                      <h6 className="font-weight-bold">Site manager</h6>
                      {Number(props.state.projectSelection.id) !== -1
                        ? props.state.projectSelection.projectActors[6].actors.map(
                            (i, index) => (
                              <Row key={index}>
                                <Col key={index}>
                                  <InputGroup>
                                    <p className="pl-2"> {i.name}</p>
                                  </InputGroup>
                                </Col>
                              </Row>
                            )
                          )
                        : ""}
                      <hr />
                      <h6 className="font-weight-bold">Requester</h6>
                      {Number(props.state.projectSelection.id) !== -1
                        ? props.state.projectSelection.projectActors[8].actors.map(
                            (i, index) => (
                              <Row key={index}>
                                <Col key={index}>
                                  <InputGroup>
                                    <p className="pl-2"> {i.name}</p>
                                  </InputGroup>
                                </Col>
                              </Row>
                            )
                          )
                        : ""}
                      <hr />
                      <h6 className="font-weight-bold">Purchaser</h6>
                      {Number(props.state.projectSelection.id) !== -1
                        ? props.state.projectSelection.projectActors[5].actors.map(
                            (i, index) => (
                              <Row key={index}>
                                <Col key={index}>
                                  <InputGroup>
                                    <p className="pl-2"> {i.name}</p>
                                  </InputGroup>
                                </Col>
                              </Row>
                            )
                          )
                        : ""}
                    </div>
                    <TxtNote state={props.state} />
                  </Col>
                );
              }
            })()}
          </Row>
          {(() => {
            //let t = Number(props.userType);
            if (props.userType.includes(0) || props.userType.includes(7)) {
              return (
                <Row
                  className="mb-3 mt-3 project-modal-footer-row project-modal-footer"
                  noGutters
                >
                  <Col md={6} lg={6} className="">
                    <p className="font-weight-bold">Note:</p>
                    <p className="">
                      Fields that has asterisk (
                      <span className="required-field font-weight-bold">
                        {" "}
                        *{" "}
                      </span>
                      ) requires an input.
                    </p>
                  </Col>
                  <Col md={2} lg={2} className="project-col-cont-padding">
                    <Button
                      block
                      className="actor-btn-crud"
                      name="btnUnlockProject"
                      disabled={!props.state.handleProjectModal.isView}
                      onClick={(e) => {
                        props.state.handleActionChange(e);
                      }}
                    >
                      MODIFY
                    </Button>
                  </Col>

                  <Col md={2} lg={2} className="project-col-cont-padding">
                    {(() => {
                      if (props.state.projectSelection)
                        if (Number(props.state.projectSelection.status) !== 4) {
                          return (
                            <Button
                              block
                              className="project-btn-crud projectModalBtnSave"
                              disabled={props.state.handleProjectModal.isView}
                              type="submit"
                            >
                              SAVE
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              block
                              className="project-btn-crud projectModalBtnSave"
                              disabled={props.state.handleProjectModal.isView}
                              type="submit"
                            >
                              Re-submit Project for approval
                            </Button>
                          );
                        }
                    })()}
                  </Col>
                  <Col md={2} lg={2} className="project-col-cont-padding">
                    <Button
                      block
                      className="project-btn-crud projectModalBtnCancel"
                      name="btnDeleteProject"
                      disabled={
                        props.state.handleProjectModal.isView ||
                        Number(props.state.selectedOperation.id) === 0
                      }
                      onClick={(e) => {
                        props.state.handleActionChange(e);
                      }}
                    >
                      DELETE
                    </Button>
                  </Col>
                </Row>
              );
            }
          })()}
        </Form>
      </Modal.Body>
    </Modal>
  </>
);

export { Project, projectColumn };
