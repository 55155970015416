import React from "react";
import { Verification, verifyColumn } from "./Verification";
import Table from "react-table";
import Filters from "../../functions/filters";
import DBStatus from "../../functions/DBStatus";
import { connect } from "react-redux";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import { withRouter } from "react-router-dom";
//import socketIOClient from "socket.io-client";
//const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

//const SelectTable = selectTableHOC(Table);

class VerificationProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleSearch: this.handleSearch.bind(this),
      getPO: this.getPO.bind(this),
      inputChange: this.inputChange.bind(this),
      downloadPDF: this.downloadPDF.bind(this),
      handleVerifyModal: {
        handleSubmit: this.handleVerifySubmit.bind(this),
        handleExit: this.handleVerifyExit.bind(this),
        show: this.handleVerifyShowModal.bind(this),
        hide: this.handleVerifyHideModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isEdit: false,
        title: "",
        desc: "",
        response: false,
      },

      selectAll: false,
      selection: [],
      searchInput: "",
      poData: {},
      isPOLoading: false,
      checkboxTable: {},
      columns: verifyColumn,
      isLoading: false,
      isFiltered: false,
      verifyData: [],
      bakVerifyData: [],
      selectedVerification: [],
      //selectedVerification: [],
      verifyId: 0,
      txtVerNotes: {
        value: "",
        placeholder: "Notes, Comments, etc.",
        className: "mb-3",
        readOnly: false,
      },
      txtVerBy: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtVerStatus: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtVerPosted: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
      txtVerName: {
        value: "",
        placeholder: "0",
        className: "mb-3",
        readOnly: false,
      },
    };
  }

  componentDidMount() {
    this.readAllPending();
    this.props.socketio.socket.on("createMultiMaterial", (data) => {
      this.readAllPending();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `New Pending Order`,
      //   },
      // });
    });
    this.props.socketio.socket.on("admin", (data) => {
      this.readAllPending();
      // this.props.dispatch({
      //   type: "TOAST_ADD",
      //   payload: {
      //     title: `Data Refresh`,
      //   },
      // });
    });
  }

  componentWillUnmount() {
    //this.props.socketio.socket.disconnect();
  }
  handleVerifyExit = (e) => {};
  handleVerifySubmit = (e) => {
    if (e.target.name === "btnVarConfirm") {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to confirm this?`,
          isYesNo: true,
          cb: (e) => {
            let statType = -1;
            const loadId = Filters.uuidv4();
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: {
                isLoading: true,
                name: "Submitting Verification",
                id: loadId,
              },
            });
            switch (this.state.selectedVerification.tableName) {
              case "Project":
                statType = 3;
                break;
              case "MultipleMaterials":
                statType = 3;
                const transactionStatus = ['labour',
                  'voucher',
                  'subcontract']
                if(transactionStatus.includes(this.state.selectedVerification.object.material?.materialType)){
                  statType = 10;
                }
                break;
              case "PurchaseOrder":
                if (
                  Number(this.state.selectedVerification.object.status) === 8
                ) {
                  statType = 9;
                } else {
                  statType = 1;
                }
                break;
              default:
                break;
            }
            new Promise((resolve, reject) => {
              this.props.dispatch({
                type: "USER_GET_REQUEST",
                payload: `global/updateStatus`,
                reqType: "put",
                data: {
                  uniqueId: this.state.selectedVerification.uniqueId,
                  status: statType,
                  notes: this.state.txtVerNotes.value,
                  note: this.state.txtVerNotes.value,
                },
                resolve: resolve,
                reject: reject,
              });
            }).then((api) => {
              this.readAllPending();
              this.setState((x) => ({
                isLoading: false,
                handleVerifyModal: {
                  ...x.handleVerifyModal,
                  isShow: false,
                },
              }));
              this.clearVerifyModal();
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Verified Successfully`,
                },
              });
              this.props.dispatch({
                type: "TOGGLE_LOADING",
                payload: { isLoading: false, id: loadId },
              });
            });
          },
        },
      });
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to deny this?`,
          isYesNo: true,
          cb: (e) => {
            let statType = -1;
            const loadId = Filters.uuidv4();
            this.props.dispatch({
              type: "TOGGLE_LOADING",
              payload: {
                isLoading: true,
                name: "Submitting Verification",
                id: loadId,
              },
            });
            switch (this.state.selectedVerification.tableName) {
              case "Project":
                statType = 4;
                break;
              case "MultipleMaterials":
                statType = 2;
                break;
              case "PurchaseOrder":
                if (
                  Number(this.state.selectedVerification.object.status) === 8
                ) {
                  statType = 1;
                } else {
                  statType = 4;
                }

                break;
              default:
                break;
            }
            new Promise((resolve, reject) => {
              this.props.dispatch({
                type: "USER_GET_REQUEST",
                payload: `global/updateStatus`,
                reqType: "put",
                data: {
                  uniqueId: this.state.selectedVerification.uniqueId,
                  status: statType,
                  notes: this.state.txtVerNotes.value,
                  note: this.state.txtVerNotes.value,
                },
                resolve: resolve,
                reject: reject,
              });
            }).then((api) => {
              this.readAllPending();
              this.setState((x) => ({
                isLoading: false,
                handleVerifyModal: {
                  ...x.handleVerifyModal,
                  isShow: false,
                },
              }));
              this.clearVerifyModal();
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Denied Successfully`,
                },
              });
              this.props.dispatch({
                type: "TOGGLE_LOADING",
                payload: { isLoading: false, id: loadId },
              });
            });
          },
        },
      });
    }
  };

  handleVerifyShowModal = (e) => {
    this.setState((x) => ({
      handleVerifyModal: {
        ...x.handleVerifyModal,
        isShow: true,
      },
    }));
  };
  handleVerifyHideModal = (e) => {
    this.setState((x) => ({
      handleVerifyModal: {
        ...x.handleVerifyModal,
        isShow: false,
      },
    }));
  };

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  readAllPending = () => {
    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: {
        isLoading: true,
        name: "Fetching Pending Verification",
        id: loadId,
      },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `global/getAllPending`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let VD = api.data;
      //console.log(api.data);
      this.setState((x) => ({
        verifyData: VD,
        isLoading: false,
      }));
      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  clearVerifyModal = (e) => {
    this.setState((x) => ({
      txtVerNotes: {
        ...x.txtVerNotes,
        value: "",
      },
      txtVerBy: {
        ...x.txtVerBy,
        value: "",
      },
      txtVerStatus: {
        ...x.txtVerStatus,
        value: "",
      },
      txtVerPosted: {
        ...x.txtVerPosted,
        value: "",
      },
      txtVerName: {
        ...x.txtVerName,
        value: "",
      },
    }));
  };

  populateVerifyModal = (e) => {
    //console.log(e);
    switch (e.tableName) {
      case "Project":
        this.setState((x) => ({
          verifyId: e.uniqueId,
          txtVerNotes: {
            ...x.txtVerNotes,
            value: e.object.note === null ? "" : e.object.note,
          },
          txtVerBy: {
            ...x.txtVerBy,
            value:
              e.object.projCreatedBy === null
                ? ""
                : e.object.projCreatedBy.name,
          },
          txtVerStatus: {
            ...x.txtVerStatus,
            value:
              e.object.status === null
                ? ""
                : DBStatus.parseProjectStatus(e.object.status),
          },
          txtVerPosted: {
            ...x.txtVerPosted,
            value: e.object.datePosted === null ? "" : e.object.datePosted,
          },
          txtVerName: {
            ...x.txtVerName,
            value: e.object.name === null ? "" : e.object.name,
          },
        }));
        break;
      case "PurchaseOrder":
        this.setState((x) => ({
          txtVerNotes: {
            ...x.txtVerNotes,
            value: e.object.notes === null ? "" : e.object.notes,
          },
        }));
        break;
      case "MultipleMaterials":
        const item = e.object.material.item;
        const brand = e.object.brand;
        //console.log(item, brand);
        this.setState((x) => ({
          verifyId: e.uniqueId,
          txtVerNotes: {
            ...x.txtVerNotes,
            value: e.object.notes === null ? "" : e.object.notes,
          },
          txtVerBy: {
            ...x.txtVerBy,
            value:
              e.object.purchaser.name === null ? "" : e.object.purchaser.name,
          },
          txtVerStatus: {
            ...x.txtVerStatus,
            value:
              e.object.status === null
                ? ""
                : DBStatus.parseOrderStatus(e.object.status),
          },
          txtVerPosted: {
            ...x.txtVerPosted,
            value: e.object.datePosted === null ? "" : e.object.datePosted,
          },
          txtVerName: {
            ...x.txtVerName,
            value: item
              ? item.name === null
                ? ""
                : item.name + " - " + brand
              : "",
          },
        }));
        break;
      default:
        break;
    }
  };

  downloadPDF = async (blobData, hist) => {
    let fileName = "PO#-" + this.state.poData.uniqueId + "-REF";
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;

    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();
  };

  getPO = (cb) => {
    //console.log(this.state.selectedVerification);
    this.setState((x) => ({
      isPOLoading: true,
    }));

    const loadId = Filters.uuidv4();
    this.props.dispatch({
      type: "TOGGLE_LOADING",
      payload: { isLoading: true, name: "Fetching PO", id: loadId },
    });
    new Promise((resolve, reject) => {
      this.props.dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getPurchaseOrderByUniqueId?uniqueId=${this.state.selectedVerification.uniqueId}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let poData = api.data;
      //console.log("VERIFICATION PO: ", poData);

      this.setState(
        (x) => ({
          poData: poData,
          isPOLoading: false,
        }),
        (x) => {
          cb();
        }
      );

      this.props.dispatch({
        type: "TOGGLE_LOADING",
        payload: { isLoading: false, id: loadId },
      });
    });
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      this.setState((x) => ({
        selectedVerification: null,
      }));
    } else {
      selection.length = 0;
      selection.push(key);
      this.setState(
        (x) => ({
          selectedVerification: row,
        }),
        (x) => {
          this.setState((x) => ({
            handleVerifyModal: {
              ...x.handleVerifyModal,
              isShow: true,
            },
          }));
          // if (row.tableName === "PurchaseOrder") {
          //   this.getPO((x) => {
          //     this.setState((x) => ({
          //       handleVerifyModal: {
          //         ...x.handleVerifyModal,
          //         isShow: true,
          //       },
          //     }));
          //     //console.log(this.state.poData);
          //   });
          // } else {
          //   this.setState((x) => ({
          //     handleVerifyModal: {
          //       ...x.handleVerifyModal,
          //       isShow: true,
          //     },
          //   }));
          // }
        }
      );
      //this.clearVerifyInputs();
    }

    this.setState((x) => ({
      selection,
    }));
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(`select-${item._original[keyField]}`);
      });
    } else {
      this.setState({ selection: [] });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.name === "btnModifyVerifyRow") {
        //console.log(e.target.getAttribute("id"));
        this.populateVerifyModal(rowInfo.original);
        //console.log(rowInfo.original);
      }
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnModifyVerifyRow"
      ) {
        if (
          this.state.selection.length > 0 &&
          this.state.selection[0] === `select-${rowInfo.original.uniqueId}`
        ) {
          this.setState((x) => ({
            selection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);
            this.populateVerifyModal(rowInfo.original);

            //console.log(this.props);
            this.setState({
              selection: ["select-" + rowInfo.original.uniqueId],
            });
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.uniqueId}`) &&
        "lightgreen",
    },
  });

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.bakVerifyData,
        event.target.value
      );

      if (data != null) {
        this.setState({ verifyData: data });
      } else {
        this.setState({ verifyData: this.state.bakVerifyData });
      }
    } else this.setState({ isFiltered: false });
  };

  render() {
    //console.log(this.state.selMaterialData.finance);

    let tablelist = (
      <Table
        data={this.state.verifyData}
        //state={this.state}
        columns={this.state.columns}
        keyField="uniqueId"
        //ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        //selectAll={this.state.selectAll}
        //selectType="checkbox"
        defaultSorted={[
          // { id: "transPrio", desc: true },
          {
            id: "datePosted",
            desc: true,
          },
        ]}
        showPageJump={false}
        showPageSizeOptions={false}
        loading={this.state.isLoading}
        //toggleAll={this.toggleAll}
        isSelected={this.isSelected}
        getTrProps={this.rowFn}
        defaultPageSize={20}
        style={
          {
            // height: "65vh",
          }
        }
        className="tableVerification -highlight"
      />
    );
    let verify = (
      <Verification
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        selObj={this.state.selectedVerification.object}
        tableList={tablelist}
        dispatch={this.props.dispatch}
      />
    );
    return (
      <>
        {verify}
        {/* {tablelist} */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    socketio: state.SocketHandler,
  };
};
export default connect(mapStateToProps)(withRouter(VerificationProcess));
